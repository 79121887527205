export default [
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 165.3,
    "out:Total Energy Use Post PV": 165.3,
    "out:Primary Energy": 151.32,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 56.25,
    "out:Total CO2/m2": 56.25,
    "out:Total CO2 (tons)": 289.61,
    "out:Klimatpaverkan": 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 46209,
    "out:Running Cost over RSP/MSEK": 19.504,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 0,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 143,
    "out:El price/MSEK": 87,
    "out:Heating price/MSEK": 48,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 7,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 159.3,
    "out:Total Energy Use Post PV": 159.3,
    "out:Primary Energy": 146.64,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 54.09,
    "out:Total CO2/m2": 54.09,
    "out:Total CO2 (tons)": 278.49,
    "out:Klimatpaverkan": -11.12,
    "out:Initial Cost/MSEK": 0.129,
    "out:Running cost/(Apt SEK y)": 44482,
    "out:Running Cost over RSP/MSEK": 18.774,
    "out:LCP/MSEK": 0.601,
    "out:ROI% old": 80.99,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 3.8,
    "out:Op energy cost/MSEK": 137,
    "out:El price/MSEK": 84,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 72,
    "out:Return/kSEK/y": 93,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 24,
    "out:Payback Time": 4.166667,
    "out:DH kWh savings": 30894,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 30894,
    "out:DH kr savings": 30894,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 161.3,
    "out:Total Energy Use Post PV": 161.3,
    "out:Primary Energy": 148.69,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.28,
    "out:CO2 Operational/m2": 54.81,
    "out:Total CO2/m2": 55.09,
    "out:Total CO2 (tons)": 283.64,
    "out:Klimatpaverkan": -5.97,
    "out:Initial Cost/MSEK": 0.176,
    "out:Running cost/(Apt SEK y)": 45058,
    "out:Running Cost over RSP/MSEK": 19.018,
    "out:LCP/MSEK": 0.311,
    "out:ROI% old": 39.57,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 2.5,
    "out:Op energy cost/MSEK": 139,
    "out:El price/MSEK": 85,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 35,
    "out:Return/kSEK/y": 62,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 20596,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 20596,
    "out:DH kr savings": 20596,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 155.3,
    "out:Total Energy Use Post PV": 155.3,
    "out:Primary Energy": 144.02,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.28,
    "out:CO2 Operational/m2": 52.65,
    "out:Total CO2/m2": 52.93,
    "out:Total CO2 (tons)": 272.52,
    "out:Klimatpaverkan": -17.09,
    "out:Initial Cost/MSEK": 0.304,
    "out:Running cost/(Apt SEK y)": 43330,
    "out:Running Cost over RSP/MSEK": 18.288,
    "out:LCP/MSEK": 0.912,
    "out:ROI% old": 57.09,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 134,
    "out:El price/MSEK": 82,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 51,
    "out:Return/kSEK/y": 156,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 17,
    "out:Payback Time": 5.882353,
    "out:DH kWh savings": 51490,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 51490,
    "out:DH kr savings": 51490,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 165.3,
    "out:Total Energy Use Post PV": 161.29,
    "out:Primary Energy": 144.1,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.06,
    "out:CO2 Operational/m2": 43.82,
    "out:Total CO2/m2": 50.88,
    "out:Total CO2 (tons)": 261.96,
    "out:Klimatpaverkan": -27.65,
    "out:Initial Cost/MSEK": 0.612,
    "out:Running cost/(Apt SEK y)": 45105,
    "out:Running Cost over RSP/MSEK": 19.05,
    "out:LCP/MSEK": -0.158,
    "out:ROI% old": 10.6,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 2.5,
    "out:Op energy cost/MSEK": 140,
    "out:El price/MSEK": 84,
    "out:Heating price/MSEK": 48,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 60,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 59658,
    "out:DH kr savings": 0,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 159.3,
    "out:Total Energy Use Post PV": 155.29,
    "out:Primary Energy": 139.42,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.06,
    "out:CO2 Operational/m2": 41.66,
    "out:Total CO2/m2": 48.72,
    "out:Total CO2 (tons)": 250.84,
    "out:Klimatpaverkan": -38.77,
    "out:Initial Cost/MSEK": 0.741,
    "out:Running cost/(Apt SEK y)": 43377,
    "out:Running Cost over RSP/MSEK": 18.32,
    "out:LCP/MSEK": 0.443,
    "out:ROI% old": 22.83,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 134,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 153,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 30894,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 90552,
    "out:DH kr savings": 30894,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 161.3,
    "out:Total Energy Use Post PV": 157.29,
    "out:Primary Energy": 141.47,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.34,
    "out:CO2 Operational/m2": 42.38,
    "out:Total CO2/m2": 49.72,
    "out:Total CO2 (tons)": 255.99,
    "out:Klimatpaverkan": -33.62,
    "out:Initial Cost/MSEK": 0.788,
    "out:Running cost/(Apt SEK y)": 43953,
    "out:Running Cost over RSP/MSEK": 18.564,
    "out:LCP/MSEK": 0.153,
    "out:ROI% old": 17.06,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 5.1,
    "out:Op energy cost/MSEK": 136,
    "out:El price/MSEK": 82,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 122,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 20596,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 80254,
    "out:DH kr savings": 20596,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 155.3,
    "out:Total Energy Use Post PV": 151.29,
    "out:Primary Energy": 136.8,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.34,
    "out:CO2 Operational/m2": 40.22,
    "out:Total CO2/m2": 47.56,
    "out:Total CO2 (tons)": 244.87,
    "out:Klimatpaverkan": -44.74,
    "out:Initial Cost/MSEK": 0.916,
    "out:Running cost/(Apt SEK y)": 42225,
    "out:Running Cost over RSP/MSEK": 17.834,
    "out:LCP/MSEK": 0.754,
    "out:ROI% old": 26.04,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 9.3,
    "out:Op energy cost/MSEK": 131,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 23,
    "out:Return/kSEK/y": 215,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 51490,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111148,
    "out:DH kr savings": 51490,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 159.3,
    "out:Total Energy Use Post PV": 159.3,
    "out:Primary Energy": 147.82,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 54.09,
    "out:Total CO2/m2": 54.09,
    "out:Total CO2 (tons)": 278.49,
    "out:Klimatpaverkan": -11.12,
    "out:Initial Cost/MSEK": 0.143,
    "out:Running cost/(Apt SEK y)": 44482,
    "out:Running Cost over RSP/MSEK": 18.774,
    "out:LCP/MSEK": 0.587,
    "out:ROI% old": 73.15,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 3.8,
    "out:Op energy cost/MSEK": 137,
    "out:El price/MSEK": 84,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 65,
    "out:Return/kSEK/y": 93,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 22,
    "out:Payback Time": 4.545455,
    "out:DH kWh savings": 30894,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 30894,
    "out:DH kr savings": 30894,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 154.3,
    "out:Total Energy Use Post PV": 154.3,
    "out:Primary Energy": 143.14,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 52.29,
    "out:Total CO2/m2": 52.29,
    "out:Total CO2 (tons)": 269.22,
    "out:Klimatpaverkan": -20.39,
    "out:Initial Cost/MSEK": 0.271,
    "out:Running cost/(Apt SEK y)": 43042,
    "out:Running Cost over RSP/MSEK": 18.166,
    "out:LCP/MSEK": 1.067,
    "out:ROI% old": 70.46,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 7.1,
    "out:Op energy cost/MSEK": 133,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 63,
    "out:Return/kSEK/y": 171,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 21,
    "out:Payback Time": 4.761905,
    "out:DH kWh savings": 56639,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 56639,
    "out:DH kr savings": 56639,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 156.3,
    "out:Total Energy Use Post PV": 156.3,
    "out:Primary Energy": 145.72,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.28,
    "out:CO2 Operational/m2": 53.01,
    "out:Total CO2/m2": 53.29,
    "out:Total CO2 (tons)": 274.38,
    "out:Klimatpaverkan": -15.23,
    "out:Initial Cost/MSEK": 0.318,
    "out:Running cost/(Apt SEK y)": 43618,
    "out:Running Cost over RSP/MSEK": 18.41,
    "out:LCP/MSEK": 0.776,
    "out:ROI% old": 49.15,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 5.8,
    "out:Op energy cost/MSEK": 134,
    "out:El price/MSEK": 82,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 44,
    "out:Return/kSEK/y": 140,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 46341,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 46341,
    "out:DH kr savings": 46341,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 151.3,
    "out:Total Energy Use Post PV": 151.3,
    "out:Primary Energy": 141.04,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.28,
    "out:CO2 Operational/m2": 51.21,
    "out:Total CO2/m2": 51.49,
    "out:Total CO2 (tons)": 265.11,
    "out:Klimatpaverkan": -24.5,
    "out:Initial Cost/MSEK": 0.447,
    "out:Running cost/(Apt SEK y)": 42178,
    "out:Running Cost over RSP/MSEK": 17.801,
    "out:LCP/MSEK": 1.256,
    "out:ROI% old": 54.43,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 9.3,
    "out:Op energy cost/MSEK": 130,
    "out:El price/MSEK": 80,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 49,
    "out:Return/kSEK/y": 218,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 72086,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 72086,
    "out:DH kr savings": 72086,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 159.3,
    "out:Total Energy Use Post PV": 155.29,
    "out:Primary Energy": 140.6,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.06,
    "out:CO2 Operational/m2": 41.66,
    "out:Total CO2/m2": 48.72,
    "out:Total CO2 (tons)": 250.84,
    "out:Klimatpaverkan": -38.77,
    "out:Initial Cost/MSEK": 0.755,
    "out:Running cost/(Apt SEK y)": 43377,
    "out:Running Cost over RSP/MSEK": 18.32,
    "out:LCP/MSEK": 0.429,
    "out:ROI% old": 22.41,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 134,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 153,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 30894,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 90552,
    "out:DH kr savings": 30894,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 154.3,
    "out:Total Energy Use Post PV": 150.29,
    "out:Primary Energy": 135.92,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.06,
    "out:CO2 Operational/m2": 39.86,
    "out:Total CO2/m2": 46.92,
    "out:Total CO2 (tons)": 241.57,
    "out:Klimatpaverkan": -48.04,
    "out:Initial Cost/MSEK": 0.883,
    "out:Running cost/(Apt SEK y)": 41937,
    "out:Running Cost over RSP/MSEK": 17.712,
    "out:LCP/MSEK": 0.909,
    "out:ROI% old": 28.98,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 130,
    "out:El price/MSEK": 78,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 26,
    "out:Return/kSEK/y": 231,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 56639,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116297,
    "out:DH kr savings": 56639,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 156.3,
    "out:Total Energy Use Post PV": 152.29,
    "out:Primary Energy": 138.5,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.34,
    "out:CO2 Operational/m2": 40.58,
    "out:Total CO2/m2": 47.92,
    "out:Total CO2 (tons)": 246.73,
    "out:Klimatpaverkan": -42.88,
    "out:Initial Cost/MSEK": 0.93,
    "out:Running cost/(Apt SEK y)": 42513,
    "out:Running Cost over RSP/MSEK": 17.955,
    "out:LCP/MSEK": 0.619,
    "out:ROI% old": 23.79,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 8.6,
    "out:Op energy cost/MSEK": 132,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 200,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 46341,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105999,
    "out:DH kr savings": 46341,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 151.3,
    "out:Total Energy Use Post PV": 147.29,
    "out:Primary Energy": 133.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.34,
    "out:CO2 Operational/m2": 38.78,
    "out:Total CO2/m2": 46.12,
    "out:Total CO2 (tons)": 237.46,
    "out:Klimatpaverkan": -52.15,
    "out:Initial Cost/MSEK": 1.059,
    "out:Running cost/(Apt SEK y)": 41073,
    "out:Running Cost over RSP/MSEK": 17.347,
    "out:LCP/MSEK": 1.098,
    "out:ROI% old": 29.1,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 12.2,
    "out:Op energy cost/MSEK": 127,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 26,
    "out:Return/kSEK/y": 277,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 72086,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131744,
    "out:DH kr savings": 72086,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 159.69,
    "out:Total Energy Use Post PV": 159.69,
    "out:Primary Energy": 153.88,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.34,
    "out:CO2 Operational/m2": 53,
    "out:Total CO2/m2": 55.34,
    "out:Total CO2 (tons)": 284.95,
    "out:Klimatpaverkan": -4.66,
    "out:Initial Cost/MSEK": 0.797,
    "out:Running cost/(Apt SEK y)": 44070,
    "out:Running Cost over RSP/MSEK": 18.596,
    "out:LCP/MSEK": 0.112,
    "out:ROI% old": 16.29,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 3.1,
    "out:Op energy cost/MSEK": 136,
    "out:El price/MSEK": 84,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 116,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": ">100",
    "out:DH kWh savings": 56639,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 1104,
    "out:DH kr savings": 56639,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 154.69,
    "out:Total Energy Use Post PV": 154.69,
    "out:Primary Energy": 149.2,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.34,
    "out:CO2 Operational/m2": 51.2,
    "out:Total CO2/m2": 53.54,
    "out:Total CO2 (tons)": 275.68,
    "out:Klimatpaverkan": -13.93,
    "out:Initial Cost/MSEK": 0.926,
    "out:Running cost/(Apt SEK y)": 42630,
    "out:Running Cost over RSP/MSEK": 17.988,
    "out:LCP/MSEK": 0.591,
    "out:ROI% old": 23.41,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 131,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 193,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 26849,
    "out:DH kr savings": 82384,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 155.69,
    "out:Total Energy Use Post PV": 155.69,
    "out:Primary Energy": 151.25,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.62,
    "out:CO2 Operational/m2": 51.56,
    "out:Total CO2/m2": 54.18,
    "out:Total CO2 (tons)": 278.98,
    "out:Klimatpaverkan": -10.63,
    "out:Initial Cost/MSEK": 0.972,
    "out:Running cost/(Apt SEK y)": 42918,
    "out:Running Cost over RSP/MSEK": 18.109,
    "out:LCP/MSEK": 0.423,
    "out:ROI% old": 20.49,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 5.8,
    "out:Op energy cost/MSEK": 132,
    "out:El price/MSEK": 82,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 18,
    "out:Return/kSEK/y": 178,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 77235,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 21700,
    "out:DH kr savings": 77235,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 150.69,
    "out:Total Energy Use Post PV": 150.69,
    "out:Primary Energy": 146.58,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.62,
    "out:CO2 Operational/m2": 49.76,
    "out:Total CO2/m2": 52.38,
    "out:Total CO2 (tons)": 269.72,
    "out:Klimatpaverkan": -19.89,
    "out:Initial Cost/MSEK": 1.101,
    "out:Running cost/(Apt SEK y)": 41479,
    "out:Running Cost over RSP/MSEK": 17.501,
    "out:LCP/MSEK": 0.902,
    "out:ROI% old": 25.99,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 9.3,
    "out:Op energy cost/MSEK": 127,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 23,
    "out:Return/kSEK/y": 255,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 47445,
    "out:DH kr savings": 102980,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 159.69,
    "out:Total Energy Use Post PV": 154.84,
    "out:Primary Energy": 145.14,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.4,
    "out:CO2 Operational/m2": 44.76,
    "out:Total CO2/m2": 54.17,
    "out:Total CO2 (tons)": 278.91,
    "out:Klimatpaverkan": -10.7,
    "out:Initial Cost/MSEK": 1.409,
    "out:Running cost/(Apt SEK y)": 42925,
    "out:Running Cost over RSP/MSEK": 18.125,
    "out:LCP/MSEK": -0.03,
    "out:ROI% old": 13.99,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 133,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 177,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 56639,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62931,
    "out:DH kr savings": 56639,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 154.69,
    "out:Total Energy Use Post PV": 149.84,
    "out:Primary Energy": 140.47,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.4,
    "out:CO2 Operational/m2": 42.96,
    "out:Total CO2/m2": 52.37,
    "out:Total CO2 (tons)": 269.64,
    "out:Klimatpaverkan": -19.97,
    "out:Initial Cost/MSEK": 1.538,
    "out:Running cost/(Apt SEK y)": 41485,
    "out:Running Cost over RSP/MSEK": 17.517,
    "out:LCP/MSEK": 0.45,
    "out:ROI% old": 18.47,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 128,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 255,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88676,
    "out:DH kr savings": 82384,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 155.69,
    "out:Total Energy Use Post PV": 150.84,
    "out:Primary Energy": 142.52,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.68,
    "out:CO2 Operational/m2": 43.32,
    "out:Total CO2/m2": 53.01,
    "out:Total CO2 (tons)": 272.94,
    "out:Klimatpaverkan": -16.67,
    "out:Initial Cost/MSEK": 1.585,
    "out:Running cost/(Apt SEK y)": 41773,
    "out:Running Cost over RSP/MSEK": 17.638,
    "out:LCP/MSEK": 0.281,
    "out:ROI% old": 16.82,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 9.3,
    "out:Op energy cost/MSEK": 129,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 240,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 77235,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83527,
    "out:DH kr savings": 77235,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 150.69,
    "out:Total Energy Use Post PV": 145.84,
    "out:Primary Energy": 137.84,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.68,
    "out:CO2 Operational/m2": 41.52,
    "out:Total CO2/m2": 51.21,
    "out:Total CO2 (tons)": 263.67,
    "out:Klimatpaverkan": -25.94,
    "out:Initial Cost/MSEK": 1.713,
    "out:Running cost/(Apt SEK y)": 40334,
    "out:Running Cost over RSP/MSEK": 17.03,
    "out:LCP/MSEK": 0.761,
    "out:ROI% old": 20.63,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 317,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109272,
    "out:DH kr savings": 102980,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 154.69,
    "out:Total Energy Use Post PV": 154.69,
    "out:Primary Energy": 150.38,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.34,
    "out:CO2 Operational/m2": 51.2,
    "out:Total CO2/m2": 53.54,
    "out:Total CO2 (tons)": 275.68,
    "out:Klimatpaverkan": -13.93,
    "out:Initial Cost/MSEK": 0.939,
    "out:Running cost/(Apt SEK y)": 42630,
    "out:Running Cost over RSP/MSEK": 17.988,
    "out:LCP/MSEK": 0.577,
    "out:ROI% old": 23.07,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 131,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 193,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 26849,
    "out:DH kr savings": 82384,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 149.69,
    "out:Total Energy Use Post PV": 149.69,
    "out:Primary Energy": 145.7,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.34,
    "out:CO2 Operational/m2": 49.4,
    "out:Total CO2/m2": 51.74,
    "out:Total CO2 (tons)": 266.41,
    "out:Klimatpaverkan": -23.2,
    "out:Initial Cost/MSEK": 1.068,
    "out:Running cost/(Apt SEK y)": 41191,
    "out:Running Cost over RSP/MSEK": 17.379,
    "out:LCP/MSEK": 1.057,
    "out:ROI% old": 28.42,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 127,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 25,
    "out:Return/kSEK/y": 271,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 108129,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 52594,
    "out:DH kr savings": 108129,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 151.69,
    "out:Total Energy Use Post PV": 151.69,
    "out:Primary Energy": 148.28,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.62,
    "out:CO2 Operational/m2": 50.12,
    "out:Total CO2/m2": 52.74,
    "out:Total CO2 (tons)": 271.57,
    "out:Klimatpaverkan": -18.04,
    "out:Initial Cost/MSEK": 1.115,
    "out:Running cost/(Apt SEK y)": 41767,
    "out:Running Cost over RSP/MSEK": 17.623,
    "out:LCP/MSEK": 0.767,
    "out:ROI% old": 24.11,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 8.6,
    "out:Op energy cost/MSEK": 128,
    "out:El price/MSEK": 80,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 22,
    "out:Return/kSEK/y": 240,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 42296,
    "out:DH kr savings": 97831,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 145.69,
    "out:Total Energy Use Post PV": 145.69,
    "out:Primary Energy": 143.6,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.62,
    "out:CO2 Operational/m2": 47.96,
    "out:Total CO2/m2": 50.58,
    "out:Total CO2 (tons)": 260.45,
    "out:Klimatpaverkan": -29.16,
    "out:Initial Cost/MSEK": 1.244,
    "out:Running cost/(Apt SEK y)": 40039,
    "out:Running Cost over RSP/MSEK": 16.893,
    "out:LCP/MSEK": 1.368,
    "out:ROI% old": 30,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 27,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 73190,
    "out:DH kr savings": 128725,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 154.69,
    "out:Total Energy Use Post PV": 149.84,
    "out:Primary Energy": 141.64,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.4,
    "out:CO2 Operational/m2": 42.96,
    "out:Total CO2/m2": 52.37,
    "out:Total CO2 (tons)": 269.64,
    "out:Klimatpaverkan": -19.97,
    "out:Initial Cost/MSEK": 1.551,
    "out:Running cost/(Apt SEK y)": 41485,
    "out:Running Cost over RSP/MSEK": 17.517,
    "out:LCP/MSEK": 0.436,
    "out:ROI% old": 18.3,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 128,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 255,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88676,
    "out:DH kr savings": 82384,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 149.69,
    "out:Total Energy Use Post PV": 144.84,
    "out:Primary Energy": 136.97,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.4,
    "out:CO2 Operational/m2": 41.16,
    "out:Total CO2/m2": 50.57,
    "out:Total CO2 (tons)": 260.37,
    "out:Klimatpaverkan": -29.24,
    "out:Initial Cost/MSEK": 1.68,
    "out:Running cost/(Apt SEK y)": 40046,
    "out:Running Cost over RSP/MSEK": 16.909,
    "out:LCP/MSEK": 0.915,
    "out:ROI% old": 22.07,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 124,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 108129,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114421,
    "out:DH kr savings": 108129,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 151.69,
    "out:Total Energy Use Post PV": 146.84,
    "out:Primary Energy": 139.54,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.68,
    "out:CO2 Operational/m2": 41.88,
    "out:Total CO2/m2": 51.57,
    "out:Total CO2 (tons)": 265.53,
    "out:Klimatpaverkan": -24.08,
    "out:Initial Cost/MSEK": 1.727,
    "out:Running cost/(Apt SEK y)": 40622,
    "out:Running Cost over RSP/MSEK": 17.152,
    "out:LCP/MSEK": 0.625,
    "out:ROI% old": 19.46,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 12.2,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 302,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 104123,
    "out:DH kr savings": 97831,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 145.69,
    "out:Total Energy Use Post PV": 140.84,
    "out:Primary Energy": 134.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.68,
    "out:CO2 Operational/m2": 39.72,
    "out:Total CO2/m2": 49.41,
    "out:Total CO2 (tons)": 254.41,
    "out:Klimatpaverkan": -35.2,
    "out:Initial Cost/MSEK": 1.856,
    "out:Running cost/(Apt SEK y)": 38894,
    "out:Running Cost over RSP/MSEK": 16.422,
    "out:LCP/MSEK": 1.226,
    "out:ROI% old": 23.73,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135017,
    "out:DH kr savings": 128725,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 150.3,
    "out:Total Energy Use Post PV": 150.3,
    "out:Primary Energy": 138.95,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.99,
    "out:CO2 Operational/m2": 50.85,
    "out:Total CO2/m2": 53.83,
    "out:Total CO2 (tons)": 277.19,
    "out:Klimatpaverkan": -12.42,
    "out:Initial Cost/MSEK": 1.293,
    "out:Running cost/(Apt SEK y)": 41890,
    "out:Running Cost over RSP/MSEK": 17.68,
    "out:LCP/MSEK": 0.531,
    "out:ROI% old": 20.16,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 129,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 18,
    "out:Return/kSEK/y": 233,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 77235,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77235,
    "out:DH kr savings": 77235,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 146.3,
    "out:Total Energy Use Post PV": 146.3,
    "out:Primary Energy": 134.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.99,
    "out:CO2 Operational/m2": 49.41,
    "out:Total CO2/m2": 52.39,
    "out:Total CO2 (tons)": 269.78,
    "out:Klimatpaverkan": -19.83,
    "out:Initial Cost/MSEK": 1.422,
    "out:Running cost/(Apt SEK y)": 40738,
    "out:Running Cost over RSP/MSEK": 17.193,
    "out:LCP/MSEK": 0.889,
    "out:ROI% old": 23.22,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 21,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 97831,
    "out:DH kr savings": 97831,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 146.3,
    "out:Total Energy Use Post PV": 146.3,
    "out:Primary Energy": 136.32,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.27,
    "out:CO2 Operational/m2": 49.41,
    "out:Total CO2/m2": 52.68,
    "out:Total CO2 (tons)": 271.23,
    "out:Klimatpaverkan": -18.38,
    "out:Initial Cost/MSEK": 1.469,
    "out:Running cost/(Apt SEK y)": 40738,
    "out:Running Cost over RSP/MSEK": 17.193,
    "out:LCP/MSEK": 0.842,
    "out:ROI% old": 22.48,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 20,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 97831,
    "out:DH kr savings": 97831,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 142.3,
    "out:Total Energy Use Post PV": 142.3,
    "out:Primary Energy": 132.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.27,
    "out:CO2 Operational/m2": 47.97,
    "out:Total CO2/m2": 51.24,
    "out:Total CO2 (tons)": 263.81,
    "out:Klimatpaverkan": -25.8,
    "out:Initial Cost/MSEK": 1.598,
    "out:Running cost/(Apt SEK y)": 39586,
    "out:Running Cost over RSP/MSEK": 16.707,
    "out:LCP/MSEK": 1.2,
    "out:ROI% old": 25.02,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 22,
    "out:Return/kSEK/y": 358,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 118427,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118427,
    "out:DH kr savings": 118427,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 150.3,
    "out:Total Energy Use Post PV": 146.29,
    "out:Primary Energy": 131.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.05,
    "out:CO2 Operational/m2": 38.42,
    "out:Total CO2/m2": 48.46,
    "out:Total CO2 (tons)": 249.54,
    "out:Klimatpaverkan": -40.07,
    "out:Initial Cost/MSEK": 1.905,
    "out:Running cost/(Apt SEK y)": 40785,
    "out:Running Cost over RSP/MSEK": 17.226,
    "out:LCP/MSEK": 0.373,
    "out:ROI% old": 17.09,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 293,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 77235,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136893,
    "out:DH kr savings": 77235,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 146.3,
    "out:Total Energy Use Post PV": 142.29,
    "out:Primary Energy": 127.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.05,
    "out:CO2 Operational/m2": 36.98,
    "out:Total CO2/m2": 47.02,
    "out:Total CO2 (tons)": 242.13,
    "out:Klimatpaverkan": -47.48,
    "out:Initial Cost/MSEK": 2.034,
    "out:Running cost/(Apt SEK y)": 39633,
    "out:Running Cost over RSP/MSEK": 16.739,
    "out:LCP/MSEK": 0.731,
    "out:ROI% old": 19.42,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 355,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157489,
    "out:DH kr savings": 97831,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 146.3,
    "out:Total Energy Use Post PV": 142.29,
    "out:Primary Energy": 129.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.33,
    "out:CO2 Operational/m2": 36.98,
    "out:Total CO2/m2": 47.31,
    "out:Total CO2 (tons)": 243.58,
    "out:Klimatpaverkan": -46.03,
    "out:Initial Cost/MSEK": 2.081,
    "out:Running cost/(Apt SEK y)": 39633,
    "out:Running Cost over RSP/MSEK": 16.739,
    "out:LCP/MSEK": 0.684,
    "out:ROI% old": 18.98,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 355,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157489,
    "out:DH kr savings": 97831,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 142.3,
    "out:Total Energy Use Post PV": 138.29,
    "out:Primary Energy": 124.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.33,
    "out:CO2 Operational/m2": 35.54,
    "out:Total CO2/m2": 45.87,
    "out:Total CO2 (tons)": 236.16,
    "out:Klimatpaverkan": -53.45,
    "out:Initial Cost/MSEK": 2.21,
    "out:Running cost/(Apt SEK y)": 38481,
    "out:Running Cost over RSP/MSEK": 16.253,
    "out:LCP/MSEK": 1.042,
    "out:ROI% old": 21.02,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 417,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 118427,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178085,
    "out:DH kr savings": 118427,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 145.3,
    "out:Primary Energy": 135.45,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.99,
    "out:CO2 Operational/m2": 49.05,
    "out:Total CO2/m2": 52.03,
    "out:Total CO2 (tons)": 267.92,
    "out:Klimatpaverkan": -21.69,
    "out:Initial Cost/MSEK": 1.436,
    "out:Running cost/(Apt SEK y)": 40450,
    "out:Running Cost over RSP/MSEK": 17.072,
    "out:LCP/MSEK": 0.997,
    "out:ROI% old": 24.21,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 22,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102980,
    "out:DH kr savings": 102980,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 140.3,
    "out:Primary Energy": 131.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.99,
    "out:CO2 Operational/m2": 47.25,
    "out:Total CO2/m2": 50.23,
    "out:Total CO2 (tons)": 258.66,
    "out:Klimatpaverkan": -30.95,
    "out:Initial Cost/MSEK": 1.564,
    "out:Running cost/(Apt SEK y)": 39010,
    "out:Running Cost over RSP/MSEK": 16.464,
    "out:LCP/MSEK": 1.476,
    "out:ROI% old": 27.77,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 25,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128725,
    "out:DH kr savings": 128725,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 142.3,
    "out:Total Energy Use Post PV": 142.3,
    "out:Primary Energy": 133.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.27,
    "out:CO2 Operational/m2": 47.97,
    "out:Total CO2/m2": 51.24,
    "out:Total CO2 (tons)": 263.81,
    "out:Klimatpaverkan": -25.8,
    "out:Initial Cost/MSEK": 1.611,
    "out:Running cost/(Apt SEK y)": 39586,
    "out:Running Cost over RSP/MSEK": 16.707,
    "out:LCP/MSEK": 1.186,
    "out:ROI% old": 24.8,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 22,
    "out:Return/kSEK/y": 358,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 118427,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118427,
    "out:DH kr savings": 118427,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 137.3,
    "out:Total Energy Use Post PV": 137.3,
    "out:Primary Energy": 129.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.27,
    "out:CO2 Operational/m2": 46.17,
    "out:Total CO2/m2": 49.44,
    "out:Total CO2 (tons)": 254.55,
    "out:Klimatpaverkan": -35.06,
    "out:Initial Cost/MSEK": 1.74,
    "out:Running cost/(Apt SEK y)": 38146,
    "out:Running Cost over RSP/MSEK": 16.099,
    "out:LCP/MSEK": 1.666,
    "out:ROI% old": 27.96,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 25,
    "out:Return/kSEK/y": 435,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 144172,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 144172,
    "out:DH kr savings": 144172,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 141.29,
    "out:Primary Energy": 128.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.05,
    "out:CO2 Operational/m2": 36.62,
    "out:Total CO2/m2": 46.66,
    "out:Total CO2 (tons)": 240.27,
    "out:Klimatpaverkan": -49.34,
    "out:Initial Cost/MSEK": 2.048,
    "out:Running cost/(Apt SEK y)": 39345,
    "out:Running Cost over RSP/MSEK": 16.618,
    "out:LCP/MSEK": 0.839,
    "out:ROI% old": 20.14,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 18,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162638,
    "out:DH kr savings": 102980,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 136.29,
    "out:Primary Energy": 124.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.05,
    "out:CO2 Operational/m2": 34.82,
    "out:Total CO2/m2": 44.86,
    "out:Total CO2 (tons)": 231.01,
    "out:Klimatpaverkan": -58.6,
    "out:Initial Cost/MSEK": 2.176,
    "out:Running cost/(Apt SEK y)": 37906,
    "out:Running Cost over RSP/MSEK": 16.009,
    "out:LCP/MSEK": 1.318,
    "out:ROI% old": 22.94,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188383,
    "out:DH kr savings": 128725,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 142.3,
    "out:Total Energy Use Post PV": 138.29,
    "out:Primary Energy": 126.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.33,
    "out:CO2 Operational/m2": 35.54,
    "out:Total CO2/m2": 45.87,
    "out:Total CO2 (tons)": 236.16,
    "out:Klimatpaverkan": -53.45,
    "out:Initial Cost/MSEK": 2.223,
    "out:Running cost/(Apt SEK y)": 38481,
    "out:Running Cost over RSP/MSEK": 16.253,
    "out:LCP/MSEK": 1.028,
    "out:ROI% old": 20.89,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 417,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 118427,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178085,
    "out:DH kr savings": 118427,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 137.3,
    "out:Total Energy Use Post PV": 133.29,
    "out:Primary Energy": 122.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.33,
    "out:CO2 Operational/m2": 33.74,
    "out:Total CO2/m2": 44.07,
    "out:Total CO2 (tons)": 226.9,
    "out:Klimatpaverkan": -62.71,
    "out:Initial Cost/MSEK": 2.352,
    "out:Running cost/(Apt SEK y)": 37042,
    "out:Running Cost over RSP/MSEK": 15.644,
    "out:LCP/MSEK": 1.508,
    "out:ROI% old": 23.44,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 495,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 144172,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 203830,
    "out:DH kr savings": 144172,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 145.69,
    "out:Total Energy Use Post PV": 145.69,
    "out:Primary Energy": 141.51,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.33,
    "out:CO2 Operational/m2": 47.96,
    "out:Total CO2/m2": 53.29,
    "out:Total CO2 (tons)": 274.39,
    "out:Klimatpaverkan": -15.22,
    "out:Initial Cost/MSEK": 2.09,
    "out:Running cost/(Apt SEK y)": 40039,
    "out:Running Cost over RSP/MSEK": 16.893,
    "out:LCP/MSEK": 0.521,
    "out:ROI% old": 17.85,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 16,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 73190,
    "out:DH kr savings": 128725,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 141.69,
    "out:Total Energy Use Post PV": 141.69,
    "out:Primary Energy": 137.4,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.33,
    "out:CO2 Operational/m2": 46.52,
    "out:Total CO2/m2": 51.85,
    "out:Total CO2 (tons)": 266.97,
    "out:Klimatpaverkan": -22.64,
    "out:Initial Cost/MSEK": 2.219,
    "out:Running cost/(Apt SEK y)": 38887,
    "out:Running Cost over RSP/MSEK": 16.406,
    "out:LCP/MSEK": 0.879,
    "out:ROI% old": 19.95,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 18,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93786,
    "out:DH kr savings": 149321,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 141.69,
    "out:Total Energy Use Post PV": 141.69,
    "out:Primary Energy": 138.88,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.61,
    "out:CO2 Operational/m2": 46.52,
    "out:Total CO2/m2": 52.13,
    "out:Total CO2 (tons)": 268.42,
    "out:Klimatpaverkan": -21.19,
    "out:Initial Cost/MSEK": 2.266,
    "out:Running cost/(Apt SEK y)": 38887,
    "out:Running Cost over RSP/MSEK": 16.406,
    "out:LCP/MSEK": 0.832,
    "out:ROI% old": 19.53,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 17,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93786,
    "out:DH kr savings": 149321,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 137.69,
    "out:Total Energy Use Post PV": 137.69,
    "out:Primary Energy": 134.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.61,
    "out:CO2 Operational/m2": 45.08,
    "out:Total CO2/m2": 50.69,
    "out:Total CO2 (tons)": 261.01,
    "out:Klimatpaverkan": -28.6,
    "out:Initial Cost/MSEK": 2.394,
    "out:Running cost/(Apt SEK y)": 37735,
    "out:Running Cost over RSP/MSEK": 15.92,
    "out:LCP/MSEK": 1.19,
    "out:ROI% old": 21.39,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 19,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 169917,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114382,
    "out:DH kr savings": 169917,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 145.69,
    "out:Total Energy Use Post PV": 140.84,
    "out:Primary Energy": 132.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.39,
    "out:CO2 Operational/m2": 39.72,
    "out:Total CO2/m2": 52.12,
    "out:Total CO2 (tons)": 268.34,
    "out:Klimatpaverkan": -21.27,
    "out:Initial Cost/MSEK": 2.702,
    "out:Running cost/(Apt SEK y)": 38894,
    "out:Running Cost over RSP/MSEK": 16.422,
    "out:LCP/MSEK": 0.38,
    "out:ROI% old": 16.3,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135017,
    "out:DH kr savings": 128725,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 141.69,
    "out:Total Energy Use Post PV": 136.84,
    "out:Primary Energy": 128.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.39,
    "out:CO2 Operational/m2": 38.29,
    "out:Total CO2/m2": 50.68,
    "out:Total CO2 (tons)": 260.93,
    "out:Klimatpaverkan": -28.68,
    "out:Initial Cost/MSEK": 2.831,
    "out:Running cost/(Apt SEK y)": 37742,
    "out:Running Cost over RSP/MSEK": 15.936,
    "out:LCP/MSEK": 0.738,
    "out:ROI% old": 18.01,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 155613,
    "out:DH kr savings": 149321,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 141.69,
    "out:Total Energy Use Post PV": 136.84,
    "out:Primary Energy": 130.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.67,
    "out:CO2 Operational/m2": 38.29,
    "out:Total CO2/m2": 50.96,
    "out:Total CO2 (tons)": 262.38,
    "out:Klimatpaverkan": -27.23,
    "out:Initial Cost/MSEK": 2.878,
    "out:Running cost/(Apt SEK y)": 37742,
    "out:Running Cost over RSP/MSEK": 15.936,
    "out:LCP/MSEK": 0.691,
    "out:ROI% old": 17.72,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155613,
    "out:DH kr savings": 149321,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 137.69,
    "out:Total Energy Use Post PV": 132.84,
    "out:Primary Energy": 126.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.67,
    "out:CO2 Operational/m2": 36.85,
    "out:Total CO2/m2": 49.52,
    "out:Total CO2 (tons)": 254.96,
    "out:Klimatpaverkan": -34.65,
    "out:Initial Cost/MSEK": 3.006,
    "out:Running cost/(Apt SEK y)": 36590,
    "out:Running Cost over RSP/MSEK": 15.449,
    "out:LCP/MSEK": 1.049,
    "out:ROI% old": 19.27,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 519,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 169917,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176209,
    "out:DH kr savings": 169917,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 140.69,
    "out:Total Energy Use Post PV": 140.69,
    "out:Primary Energy": 138.01,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.33,
    "out:CO2 Operational/m2": 46.16,
    "out:Total CO2/m2": 51.49,
    "out:Total CO2 (tons)": 265.12,
    "out:Klimatpaverkan": -24.49,
    "out:Initial Cost/MSEK": 2.232,
    "out:Running cost/(Apt SEK y)": 38599,
    "out:Running Cost over RSP/MSEK": 16.285,
    "out:LCP/MSEK": 0.987,
    "out:ROI% old": 20.6,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 18,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98935,
    "out:DH kr savings": 154470,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 135.69,
    "out:Primary Energy": 133.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.33,
    "out:CO2 Operational/m2": 44.36,
    "out:Total CO2/m2": 49.69,
    "out:Total CO2 (tons)": 255.85,
    "out:Klimatpaverkan": -33.76,
    "out:Initial Cost/MSEK": 2.361,
    "out:Running cost/(Apt SEK y)": 37159,
    "out:Running Cost over RSP/MSEK": 15.677,
    "out:LCP/MSEK": 1.467,
    "out:ROI% old": 23.16,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 489,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124680,
    "out:DH kr savings": 180215,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 137.69,
    "out:Total Energy Use Post PV": 137.69,
    "out:Primary Energy": 135.91,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.61,
    "out:CO2 Operational/m2": 45.08,
    "out:Total CO2/m2": 50.69,
    "out:Total CO2 (tons)": 261.01,
    "out:Klimatpaverkan": -28.6,
    "out:Initial Cost/MSEK": 2.408,
    "out:Running cost/(Apt SEK y)": 37735,
    "out:Running Cost over RSP/MSEK": 15.92,
    "out:LCP/MSEK": 1.176,
    "out:ROI% old": 21.26,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 19,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 169917,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114382,
    "out:DH kr savings": 169917,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 132.69,
    "out:Total Energy Use Post PV": 132.69,
    "out:Primary Energy": 131.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.61,
    "out:CO2 Operational/m2": 43.28,
    "out:Total CO2/m2": 48.89,
    "out:Total CO2 (tons)": 251.74,
    "out:Klimatpaverkan": -37.87,
    "out:Initial Cost/MSEK": 2.537,
    "out:Running cost/(Apt SEK y)": 36295,
    "out:Running Cost over RSP/MSEK": 15.312,
    "out:LCP/MSEK": 1.656,
    "out:ROI% old": 23.61,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140127,
    "out:DH kr savings": 195662,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 140.69,
    "out:Total Energy Use Post PV": 135.84,
    "out:Primary Energy": 129.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.39,
    "out:CO2 Operational/m2": 37.93,
    "out:Total CO2/m2": 50.32,
    "out:Total CO2 (tons)": 259.08,
    "out:Klimatpaverkan": -30.53,
    "out:Initial Cost/MSEK": 2.845,
    "out:Running cost/(Apt SEK y)": 37454,
    "out:Running Cost over RSP/MSEK": 15.814,
    "out:LCP/MSEK": 0.846,
    "out:ROI% old": 18.53,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 473,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160762,
    "out:DH kr savings": 154470,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 130.84,
    "out:Primary Energy": 125.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.39,
    "out:CO2 Operational/m2": 36.13,
    "out:Total CO2/m2": 48.52,
    "out:Total CO2 (tons)": 249.81,
    "out:Klimatpaverkan": -39.8,
    "out:Initial Cost/MSEK": 2.973,
    "out:Running cost/(Apt SEK y)": 36014,
    "out:Running Cost over RSP/MSEK": 15.206,
    "out:LCP/MSEK": 1.325,
    "out:ROI% old": 20.65,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186507,
    "out:DH kr savings": 180215,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 137.69,
    "out:Total Energy Use Post PV": 132.84,
    "out:Primary Energy": 127.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.67,
    "out:CO2 Operational/m2": 36.85,
    "out:Total CO2/m2": 49.52,
    "out:Total CO2 (tons)": 254.96,
    "out:Klimatpaverkan": -34.65,
    "out:Initial Cost/MSEK": 3.02,
    "out:Running cost/(Apt SEK y)": 36590,
    "out:Running Cost over RSP/MSEK": 15.449,
    "out:LCP/MSEK": 1.035,
    "out:ROI% old": 19.18,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 519,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 169917,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176209,
    "out:DH kr savings": 169917,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 132.69,
    "out:Total Energy Use Post PV": 127.84,
    "out:Primary Energy": 123.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.67,
    "out:CO2 Operational/m2": 35.05,
    "out:Total CO2/m2": 47.72,
    "out:Total CO2 (tons)": 245.7,
    "out:Klimatpaverkan": -43.91,
    "out:Initial Cost/MSEK": 3.149,
    "out:Running cost/(Apt SEK y)": 35150,
    "out:Running Cost over RSP/MSEK": 14.841,
    "out:LCP/MSEK": 1.514,
    "out:ROI% old": 21.16,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 597,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201954,
    "out:DH kr savings": 195662,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 146.3,
    "out:Total Energy Use Post PV": 146.3,
    "out:Primary Energy": 135.14,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.55,
    "out:CO2 Operational/m2": 49.41,
    "out:Total CO2/m2": 61.96,
    "out:Total CO2 (tons)": 319.01,
    "out:Klimatpaverkan": 29.4,
    "out:Initial Cost/MSEK": 4.459,
    "out:Running cost/(Apt SEK y)": 40738,
    "out:Running Cost over RSP/MSEK": 17.193,
    "out:LCP/MSEK": -2.148,
    "out:ROI% old": 7.4,
    "out:Payback discounted": 16,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 7,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 97831,
    "out:DH kr savings": 97831,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 142.3,
    "out:Total Energy Use Post PV": 142.3,
    "out:Primary Energy": 131.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.55,
    "out:CO2 Operational/m2": 47.97,
    "out:Total CO2/m2": 60.52,
    "out:Total CO2 (tons)": 311.6,
    "out:Klimatpaverkan": 21.99,
    "out:Initial Cost/MSEK": 4.588,
    "out:Running cost/(Apt SEK y)": 39586,
    "out:Running Cost over RSP/MSEK": 16.707,
    "out:LCP/MSEK": -1.791,
    "out:ROI% old": 8.71,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 8,
    "out:Return/kSEK/y": 358,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118427,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118427,
    "out:DH kr savings": 118427,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 142.3,
    "out:Total Energy Use Post PV": 142.3,
    "out:Primary Energy": 132.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.83,
    "out:CO2 Operational/m2": 47.97,
    "out:Total CO2/m2": 60.8,
    "out:Total CO2 (tons)": 313.05,
    "out:Klimatpaverkan": 23.44,
    "out:Initial Cost/MSEK": 4.635,
    "out:Running cost/(Apt SEK y)": 39586,
    "out:Running Cost over RSP/MSEK": 16.707,
    "out:LCP/MSEK": -1.838,
    "out:ROI% old": 8.62,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 8,
    "out:Return/kSEK/y": 358,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118427,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118427,
    "out:DH kr savings": 118427,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 138.3,
    "out:Total Energy Use Post PV": 138.3,
    "out:Primary Energy": 128.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.83,
    "out:CO2 Operational/m2": 46.53,
    "out:Total CO2/m2": 59.36,
    "out:Total CO2 (tons)": 305.63,
    "out:Klimatpaverkan": 16.02,
    "out:Initial Cost/MSEK": 4.764,
    "out:Running cost/(Apt SEK y)": 38434,
    "out:Running Cost over RSP/MSEK": 16.22,
    "out:LCP/MSEK": -1.48,
    "out:ROI% old": 9.85,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 139023,
    "out:DH kr savings": 139023,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 146.3,
    "out:Total Energy Use Post PV": 142.29,
    "out:Primary Energy": 127.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.61,
    "out:CO2 Operational/m2": 36.98,
    "out:Total CO2/m2": 56.59,
    "out:Total CO2 (tons)": 291.36,
    "out:Klimatpaverkan": 1.75,
    "out:Initial Cost/MSEK": 5.071,
    "out:Running cost/(Apt SEK y)": 39633,
    "out:Running Cost over RSP/MSEK": 16.739,
    "out:LCP/MSEK": -2.306,
    "out:ROI% old": 7.79,
    "out:Payback discounted": 16,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 7,
    "out:Return/kSEK/y": 355,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157489,
    "out:DH kr savings": 97831,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 142.3,
    "out:Total Energy Use Post PV": 138.29,
    "out:Primary Energy": 123.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.61,
    "out:CO2 Operational/m2": 35.54,
    "out:Total CO2/m2": 55.15,
    "out:Total CO2 (tons)": 283.95,
    "out:Klimatpaverkan": -5.66,
    "out:Initial Cost/MSEK": 5.2,
    "out:Running cost/(Apt SEK y)": 38481,
    "out:Running Cost over RSP/MSEK": 16.253,
    "out:LCP/MSEK": -1.949,
    "out:ROI% old": 8.93,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 417,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118427,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178085,
    "out:DH kr savings": 118427,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 142.3,
    "out:Total Energy Use Post PV": 138.29,
    "out:Primary Energy": 125.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.89,
    "out:CO2 Operational/m2": 35.54,
    "out:Total CO2/m2": 55.43,
    "out:Total CO2 (tons)": 285.4,
    "out:Klimatpaverkan": -4.21,
    "out:Initial Cost/MSEK": 5.247,
    "out:Running cost/(Apt SEK y)": 38481,
    "out:Running Cost over RSP/MSEK": 16.253,
    "out:LCP/MSEK": -1.995,
    "out:ROI% old": 8.85,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 417,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118427,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178085,
    "out:DH kr savings": 118427,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 138.3,
    "out:Total Energy Use Post PV": 134.29,
    "out:Primary Energy": 121.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.89,
    "out:CO2 Operational/m2": 34.1,
    "out:Total CO2/m2": 53.99,
    "out:Total CO2 (tons)": 277.98,
    "out:Klimatpaverkan": -11.63,
    "out:Initial Cost/MSEK": 5.376,
    "out:Running cost/(Apt SEK y)": 37330,
    "out:Running Cost over RSP/MSEK": 15.766,
    "out:LCP/MSEK": -1.638,
    "out:ROI% old": 9.93,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 23.1,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198681,
    "out:DH kr savings": 139023,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 141.3,
    "out:Total Energy Use Post PV": 141.3,
    "out:Primary Energy": 131.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.55,
    "out:CO2 Operational/m2": 47.61,
    "out:Total CO2/m2": 60.16,
    "out:Total CO2 (tons)": 309.74,
    "out:Klimatpaverkan": 20.13,
    "out:Initial Cost/MSEK": 4.602,
    "out:Running cost/(Apt SEK y)": 39298,
    "out:Running Cost over RSP/MSEK": 16.585,
    "out:LCP/MSEK": -1.683,
    "out:ROI% old": 9.06,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 121,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 8,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 123576,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123576,
    "out:DH kr savings": 123576,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 136.3,
    "out:Total Energy Use Post PV": 136.3,
    "out:Primary Energy": 127.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.55,
    "out:CO2 Operational/m2": 45.81,
    "out:Total CO2/m2": 58.36,
    "out:Total CO2 (tons)": 300.48,
    "out:Klimatpaverkan": 10.87,
    "out:Initial Cost/MSEK": 4.731,
    "out:Running cost/(Apt SEK y)": 37858,
    "out:Running Cost over RSP/MSEK": 15.977,
    "out:LCP/MSEK": -1.203,
    "out:ROI% old": 10.65,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 451,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149321,
    "out:DH kr savings": 149321,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 137.3,
    "out:Total Energy Use Post PV": 137.3,
    "out:Primary Energy": 129.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.83,
    "out:CO2 Operational/m2": 46.17,
    "out:Total CO2/m2": 59,
    "out:Total CO2 (tons)": 303.78,
    "out:Klimatpaverkan": 14.17,
    "out:Initial Cost/MSEK": 4.778,
    "out:Running cost/(Apt SEK y)": 38146,
    "out:Running Cost over RSP/MSEK": 16.099,
    "out:LCP/MSEK": -1.372,
    "out:ROI% old": 10.18,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 435,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 144172,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144172,
    "out:DH kr savings": 144172,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 133.3,
    "out:Total Energy Use Post PV": 133.3,
    "out:Primary Energy": 125.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.83,
    "out:CO2 Operational/m2": 44.73,
    "out:Total CO2/m2": 57.56,
    "out:Total CO2 (tons)": 296.37,
    "out:Klimatpaverkan": 6.76,
    "out:Initial Cost/MSEK": 4.906,
    "out:Running cost/(Apt SEK y)": 36995,
    "out:Running Cost over RSP/MSEK": 15.612,
    "out:LCP/MSEK": -1.014,
    "out:ROI% old": 11.33,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 498,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 164768,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164768,
    "out:DH kr savings": 164768,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 141.3,
    "out:Total Energy Use Post PV": 137.29,
    "out:Primary Energy": 124.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.61,
    "out:CO2 Operational/m2": 35.18,
    "out:Total CO2/m2": 54.79,
    "out:Total CO2 (tons)": 282.09,
    "out:Klimatpaverkan": -7.52,
    "out:Initial Cost/MSEK": 5.214,
    "out:Running cost/(Apt SEK y)": 38194,
    "out:Running Cost over RSP/MSEK": 16.131,
    "out:LCP/MSEK": -1.841,
    "out:ROI% old": 9.24,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 123576,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183234,
    "out:DH kr savings": 123576,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 136.3,
    "out:Total Energy Use Post PV": 132.29,
    "out:Primary Energy": 120.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.61,
    "out:CO2 Operational/m2": 33.38,
    "out:Total CO2/m2": 52.99,
    "out:Total CO2 (tons)": 272.83,
    "out:Klimatpaverkan": -16.78,
    "out:Initial Cost/MSEK": 5.343,
    "out:Running cost/(Apt SEK y)": 36754,
    "out:Running Cost over RSP/MSEK": 15.523,
    "out:LCP/MSEK": -1.361,
    "out:ROI% old": 10.65,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 208979,
    "out:DH kr savings": 149321,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 137.3,
    "out:Total Energy Use Post PV": 133.29,
    "out:Primary Energy": 122.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.89,
    "out:CO2 Operational/m2": 33.74,
    "out:Total CO2/m2": 53.63,
    "out:Total CO2 (tons)": 276.13,
    "out:Klimatpaverkan": -13.48,
    "out:Initial Cost/MSEK": 5.39,
    "out:Running cost/(Apt SEK y)": 37042,
    "out:Running Cost over RSP/MSEK": 15.644,
    "out:LCP/MSEK": -1.53,
    "out:ROI% old": 10.23,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 495,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 144172,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 203830,
    "out:DH kr savings": 144172,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 133.3,
    "out:Total Energy Use Post PV": 129.29,
    "out:Primary Energy": 118.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.89,
    "out:CO2 Operational/m2": 32.3,
    "out:Total CO2/m2": 52.19,
    "out:Total CO2 (tons)": 268.72,
    "out:Klimatpaverkan": -20.89,
    "out:Initial Cost/MSEK": 5.518,
    "out:Running cost/(Apt SEK y)": 35890,
    "out:Running Cost over RSP/MSEK": 15.158,
    "out:LCP/MSEK": -1.172,
    "out:ROI% old": 11.25,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 164768,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 224426,
    "out:DH kr savings": 164768,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 141.69,
    "out:Total Energy Use Post PV": 141.69,
    "out:Primary Energy": 137.7,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.89,
    "out:CO2 Operational/m2": 46.52,
    "out:Total CO2/m2": 61.41,
    "out:Total CO2 (tons)": 316.21,
    "out:Klimatpaverkan": 26.6,
    "out:Initial Cost/MSEK": 5.256,
    "out:Running cost/(Apt SEK y)": 38887,
    "out:Running Cost over RSP/MSEK": 16.406,
    "out:LCP/MSEK": -2.158,
    "out:ROI% old": 8.42,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 8,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93786,
    "out:DH kr savings": 149321,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 136.69,
    "out:Total Energy Use Post PV": 136.69,
    "out:Primary Energy": 133.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.89,
    "out:CO2 Operational/m2": 44.72,
    "out:Total CO2/m2": 59.61,
    "out:Total CO2 (tons)": 306.94,
    "out:Klimatpaverkan": 17.33,
    "out:Initial Cost/MSEK": 5.385,
    "out:Running cost/(Apt SEK y)": 37447,
    "out:Running Cost over RSP/MSEK": 15.798,
    "out:LCP/MSEK": -1.679,
    "out:ROI% old": 9.83,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 473,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119531,
    "out:DH kr savings": 175066,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 137.69,
    "out:Total Energy Use Post PV": 137.69,
    "out:Primary Energy": 135.07,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.18,
    "out:CO2 Operational/m2": 45.08,
    "out:Total CO2/m2": 60.25,
    "out:Total CO2 (tons)": 310.24,
    "out:Klimatpaverkan": 20.63,
    "out:Initial Cost/MSEK": 5.432,
    "out:Running cost/(Apt SEK y)": 37735,
    "out:Running Cost over RSP/MSEK": 15.92,
    "out:LCP/MSEK": -1.847,
    "out:ROI% old": 9.43,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 8,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 169917,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114382,
    "out:DH kr savings": 169917,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 132.69,
    "out:Total Energy Use Post PV": 132.69,
    "out:Primary Energy": 131.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.18,
    "out:CO2 Operational/m2": 43.28,
    "out:Total CO2/m2": 58.45,
    "out:Total CO2 (tons)": 300.97,
    "out:Klimatpaverkan": 11.36,
    "out:Initial Cost/MSEK": 5.561,
    "out:Running cost/(Apt SEK y)": 36295,
    "out:Running Cost over RSP/MSEK": 15.312,
    "out:LCP/MSEK": -1.368,
    "out:ROI% old": 10.77,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140127,
    "out:DH kr savings": 195662,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 141.69,
    "out:Total Energy Use Post PV": 136.84,
    "out:Primary Energy": 128.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.95,
    "out:CO2 Operational/m2": 38.29,
    "out:Total CO2/m2": 60.24,
    "out:Total CO2 (tons)": 310.16,
    "out:Klimatpaverkan": 20.55,
    "out:Initial Cost/MSEK": 5.868,
    "out:Running cost/(Apt SEK y)": 37742,
    "out:Running Cost over RSP/MSEK": 15.936,
    "out:LCP/MSEK": -2.3,
    "out:ROI% old": 8.69,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155613,
    "out:DH kr savings": 149321,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 136.69,
    "out:Total Energy Use Post PV": 131.84,
    "out:Primary Energy": 125.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.95,
    "out:CO2 Operational/m2": 36.49,
    "out:Total CO2/m2": 58.44,
    "out:Total CO2 (tons)": 300.9,
    "out:Klimatpaverkan": 11.29,
    "out:Initial Cost/MSEK": 5.997,
    "out:Running cost/(Apt SEK y)": 36302,
    "out:Running Cost over RSP/MSEK": 15.328,
    "out:LCP/MSEK": -1.82,
    "out:ROI% old": 9.95,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181358,
    "out:DH kr savings": 175066,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 137.69,
    "out:Total Energy Use Post PV": 132.84,
    "out:Primary Energy": 126.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.23,
    "out:CO2 Operational/m2": 36.85,
    "out:Total CO2/m2": 59.08,
    "out:Total CO2 (tons)": 304.2,
    "out:Klimatpaverkan": 14.59,
    "out:Initial Cost/MSEK": 6.044,
    "out:Running cost/(Apt SEK y)": 36590,
    "out:Running Cost over RSP/MSEK": 15.449,
    "out:LCP/MSEK": -1.989,
    "out:ROI% old": 9.58,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 519,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 169917,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176209,
    "out:DH kr savings": 169917,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 132.69,
    "out:Total Energy Use Post PV": 127.84,
    "out:Primary Energy": 122.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.23,
    "out:CO2 Operational/m2": 35.05,
    "out:Total CO2/m2": 57.28,
    "out:Total CO2 (tons)": 294.93,
    "out:Klimatpaverkan": 5.32,
    "out:Initial Cost/MSEK": 6.173,
    "out:Running cost/(Apt SEK y)": 35150,
    "out:Running Cost over RSP/MSEK": 14.841,
    "out:LCP/MSEK": -1.509,
    "out:ROI% old": 10.79,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 597,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201954,
    "out:DH kr savings": 195662,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 135.69,
    "out:Primary Energy": 134.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.89,
    "out:CO2 Operational/m2": 44.36,
    "out:Total CO2/m2": 59.25,
    "out:Total CO2 (tons)": 305.09,
    "out:Klimatpaverkan": 15.48,
    "out:Initial Cost/MSEK": 5.399,
    "out:Running cost/(Apt SEK y)": 37159,
    "out:Running Cost over RSP/MSEK": 15.677,
    "out:LCP/MSEK": -1.571,
    "out:ROI% old": 10.13,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 489,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124680,
    "out:DH kr savings": 180215,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 131.69,
    "out:Total Energy Use Post PV": 131.69,
    "out:Primary Energy": 130.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.89,
    "out:CO2 Operational/m2": 42.92,
    "out:Total CO2/m2": 57.81,
    "out:Total CO2 (tons)": 297.67,
    "out:Klimatpaverkan": 8.06,
    "out:Initial Cost/MSEK": 5.527,
    "out:Running cost/(Apt SEK y)": 36007,
    "out:Running Cost over RSP/MSEK": 15.19,
    "out:LCP/MSEK": -1.213,
    "out:ROI% old": 11.15,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145276,
    "out:DH kr savings": 200811,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 132.69,
    "out:Total Energy Use Post PV": 132.69,
    "out:Primary Energy": 132.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.18,
    "out:CO2 Operational/m2": 43.28,
    "out:Total CO2/m2": 58.45,
    "out:Total CO2 (tons)": 300.97,
    "out:Klimatpaverkan": 11.36,
    "out:Initial Cost/MSEK": 5.574,
    "out:Running cost/(Apt SEK y)": 36295,
    "out:Running Cost over RSP/MSEK": 15.312,
    "out:LCP/MSEK": -1.382,
    "out:ROI% old": 10.74,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140127,
    "out:DH kr savings": 195662,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 128.69,
    "out:Total Energy Use Post PV": 128.69,
    "out:Primary Energy": 128.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.18,
    "out:CO2 Operational/m2": 41.84,
    "out:Total CO2/m2": 57.01,
    "out:Total CO2 (tons)": 293.56,
    "out:Klimatpaverkan": 3.95,
    "out:Initial Cost/MSEK": 5.703,
    "out:Running cost/(Apt SEK y)": 35143,
    "out:Running Cost over RSP/MSEK": 14.825,
    "out:LCP/MSEK": -1.024,
    "out:ROI% old": 11.72,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 598,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160723,
    "out:DH kr savings": 216258,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 130.84,
    "out:Primary Energy": 125.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.95,
    "out:CO2 Operational/m2": 36.13,
    "out:Total CO2/m2": 58.08,
    "out:Total CO2 (tons)": 299.04,
    "out:Klimatpaverkan": 9.43,
    "out:Initial Cost/MSEK": 6.011,
    "out:Running cost/(Apt SEK y)": 36014,
    "out:Running Cost over RSP/MSEK": 15.206,
    "out:LCP/MSEK": -1.712,
    "out:ROI% old": 10.22,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186507,
    "out:DH kr savings": 180215,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 131.69,
    "out:Total Energy Use Post PV": 126.84,
    "out:Primary Energy": 121.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.95,
    "out:CO2 Operational/m2": 34.69,
    "out:Total CO2/m2": 56.64,
    "out:Total CO2 (tons)": 291.63,
    "out:Klimatpaverkan": 2.02,
    "out:Initial Cost/MSEK": 6.14,
    "out:Running cost/(Apt SEK y)": 34862,
    "out:Running Cost over RSP/MSEK": 14.719,
    "out:LCP/MSEK": -1.355,
    "out:ROI% old": 11.13,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 613,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207103,
    "out:DH kr savings": 200811,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 132.69,
    "out:Total Energy Use Post PV": 127.84,
    "out:Primary Energy": 123.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.23,
    "out:CO2 Operational/m2": 35.05,
    "out:Total CO2/m2": 57.28,
    "out:Total CO2 (tons)": 294.93,
    "out:Klimatpaverkan": 5.32,
    "out:Initial Cost/MSEK": 6.186,
    "out:Running cost/(Apt SEK y)": 35150,
    "out:Running Cost over RSP/MSEK": 14.841,
    "out:LCP/MSEK": -1.523,
    "out:ROI% old": 10.77,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 597,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201954,
    "out:DH kr savings": 195662,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 128.69,
    "out:Total Energy Use Post PV": 123.84,
    "out:Primary Energy": 119.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.23,
    "out:CO2 Operational/m2": 33.61,
    "out:Total CO2/m2": 55.84,
    "out:Total CO2 (tons)": 287.52,
    "out:Klimatpaverkan": -2.09,
    "out:Initial Cost/MSEK": 6.315,
    "out:Running cost/(Apt SEK y)": 33998,
    "out:Running Cost over RSP/MSEK": 14.355,
    "out:LCP/MSEK": -1.165,
    "out:ROI% old": 11.65,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 659,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222550,
    "out:DH kr savings": 216258,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.89,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 154.3,
    "out:Total Energy Use Post PV": 154.3,
    "out:Primary Energy": 142.02,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.42,
    "out:CO2 Operational/m2": 52.29,
    "out:Total CO2/m2": 53.7,
    "out:Total CO2 (tons)": 276.51,
    "out:Klimatpaverkan": -13.1,
    "out:Initial Cost/MSEK": 0.199,
    "out:Running cost/(Apt SEK y)": 43042,
    "out:Running Cost over RSP/MSEK": 18.166,
    "out:LCP/MSEK": 1.139,
    "out:ROI% old": 95.94,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 7.1,
    "out:Op energy cost/MSEK": 133,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 86,
    "out:Return/kSEK/y": 171,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 28,
    "out:Payback Time": 3.571429,
    "out:DH kWh savings": 56639,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 56639,
    "out:DH kr savings": 56639,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 581283,
    "out:% savings (space heating)": 8.6,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.89,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.03,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 149.3,
    "out:Total Energy Use Post PV": 149.3,
    "out:Primary Energy": 137.77,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.42,
    "out:CO2 Operational/m2": 50.49,
    "out:Total CO2/m2": 51.9,
    "out:Total CO2 (tons)": 267.24,
    "out:Klimatpaverkan": -22.37,
    "out:Initial Cost/MSEK": 0.328,
    "out:Running cost/(Apt SEK y)": 41602,
    "out:Running Cost over RSP/MSEK": 17.558,
    "out:LCP/MSEK": 1.618,
    "out:ROI% old": 84.77,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 10.7,
    "out:Op energy cost/MSEK": 128,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 76,
    "out:Return/kSEK/y": 249,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 25,
    "out:Payback Time": 4,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 82384,
    "out:DH kr savings": 82384,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 556265,
    "out:% savings (space heating)": 12.54,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.03,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.89,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 150.3,
    "out:Total Energy Use Post PV": 150.3,
    "out:Primary Energy": 139.4,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.7,
    "out:CO2 Operational/m2": 50.85,
    "out:Total CO2/m2": 52.54,
    "out:Total CO2 (tons)": 270.55,
    "out:Klimatpaverkan": -19.06,
    "out:Initial Cost/MSEK": 0.375,
    "out:Running cost/(Apt SEK y)": 41890,
    "out:Running Cost over RSP/MSEK": 17.68,
    "out:LCP/MSEK": 1.45,
    "out:ROI% old": 69.53,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 129,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 62,
    "out:Return/kSEK/y": 233,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 21,
    "out:Payback Time": 4.761905,
    "out:DH kWh savings": 77235,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77235,
    "out:DH kr savings": 77235,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 581283,
    "out:% savings (space heating)": 8.6,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.89,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.03,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 145.3,
    "out:Primary Energy": 135.14,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.7,
    "out:CO2 Operational/m2": 49.05,
    "out:Total CO2/m2": 50.74,
    "out:Total CO2 (tons)": 261.28,
    "out:Klimatpaverkan": -28.33,
    "out:Initial Cost/MSEK": 0.504,
    "out:Running cost/(Apt SEK y)": 40450,
    "out:Running Cost over RSP/MSEK": 17.072,
    "out:LCP/MSEK": 1.929,
    "out:ROI% old": 69.01,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 62,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 20,
    "out:Payback Time": 5,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102980,
    "out:DH kr savings": 102980,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 556265,
    "out:% savings (space heating)": 12.54,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.03,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.89,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 154.3,
    "out:Total Energy Use Post PV": 150.29,
    "out:Primary Energy": 134.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.48,
    "out:CO2 Operational/m2": 39.86,
    "out:Total CO2/m2": 48.33,
    "out:Total CO2 (tons)": 248.86,
    "out:Klimatpaverkan": -40.75,
    "out:Initial Cost/MSEK": 0.811,
    "out:Running cost/(Apt SEK y)": 41937,
    "out:Running Cost over RSP/MSEK": 17.712,
    "out:LCP/MSEK": 0.981,
    "out:ROI% old": 31.56,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 130,
    "out:El price/MSEK": 78,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 28,
    "out:Return/kSEK/y": 231,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 56639,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116297,
    "out:DH kr savings": 56639,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 581283,
    "out:% savings (space heating)": 8.6,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.89,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.03,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 149.3,
    "out:Total Energy Use Post PV": 145.29,
    "out:Primary Energy": 130.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.48,
    "out:CO2 Operational/m2": 38.06,
    "out:Total CO2/m2": 46.53,
    "out:Total CO2 (tons)": 239.6,
    "out:Klimatpaverkan": -50.01,
    "out:Initial Cost/MSEK": 0.94,
    "out:Running cost/(Apt SEK y)": 40497,
    "out:Running Cost over RSP/MSEK": 17.104,
    "out:LCP/MSEK": 1.46,
    "out:ROI% old": 36.48,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 33,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142042,
    "out:DH kr savings": 82384,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 556265,
    "out:% savings (space heating)": 12.54,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.03,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.89,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 150.3,
    "out:Total Energy Use Post PV": 146.29,
    "out:Primary Energy": 132.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.76,
    "out:CO2 Operational/m2": 38.42,
    "out:Total CO2/m2": 47.17,
    "out:Total CO2 (tons)": 242.9,
    "out:Klimatpaverkan": -46.71,
    "out:Initial Cost/MSEK": 0.987,
    "out:Running cost/(Apt SEK y)": 40785,
    "out:Running Cost over RSP/MSEK": 17.226,
    "out:LCP/MSEK": 1.292,
    "out:ROI% old": 32.98,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 30,
    "out:Return/kSEK/y": 293,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 77235,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136893,
    "out:DH kr savings": 77235,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 581283,
    "out:% savings (space heating)": 8.6,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.89,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.03,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 141.29,
    "out:Primary Energy": 127.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.76,
    "out:CO2 Operational/m2": 36.62,
    "out:Total CO2/m2": 45.37,
    "out:Total CO2 (tons)": 233.63,
    "out:Klimatpaverkan": -55.98,
    "out:Initial Cost/MSEK": 1.116,
    "out:Running cost/(Apt SEK y)": 39345,
    "out:Running Cost over RSP/MSEK": 16.618,
    "out:LCP/MSEK": 1.771,
    "out:ROI% old": 36.97,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 33,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162638,
    "out:DH kr savings": 102980,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 556265,
    "out:% savings (space heating)": 12.54,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.03,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.89,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 149.3,
    "out:Total Energy Use Post PV": 149.3,
    "out:Primary Energy": 138.52,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.42,
    "out:CO2 Operational/m2": 50.49,
    "out:Total CO2/m2": 51.9,
    "out:Total CO2 (tons)": 267.24,
    "out:Klimatpaverkan": -22.37,
    "out:Initial Cost/MSEK": 0.342,
    "out:Running cost/(Apt SEK y)": 41602,
    "out:Running Cost over RSP/MSEK": 17.558,
    "out:LCP/MSEK": 1.604,
    "out:ROI% old": 81.35,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 10.7,
    "out:Op energy cost/MSEK": 128,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 73,
    "out:Return/kSEK/y": 249,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 24,
    "out:Payback Time": 4.166667,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82384,
    "out:DH kr savings": 82384,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 581283,
    "out:% savings (space heating)": 8.6,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.89,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.03,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 144.3,
    "out:Total Energy Use Post PV": 144.3,
    "out:Primary Energy": 134.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.42,
    "out:CO2 Operational/m2": 48.69,
    "out:Total CO2/m2": 50.1,
    "out:Total CO2 (tons)": 257.98,
    "out:Klimatpaverkan": -31.63,
    "out:Initial Cost/MSEK": 0.47,
    "out:Running cost/(Apt SEK y)": 40162,
    "out:Running Cost over RSP/MSEK": 16.95,
    "out:LCP/MSEK": 2.084,
    "out:ROI% old": 77.56,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 14.6,
    "out:Op energy cost/MSEK": 124,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 69,
    "out:Return/kSEK/y": 327,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 23,
    "out:Payback Time": 4.347826,
    "out:DH kWh savings": 108129,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 108129,
    "out:DH kr savings": 108129,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 556265,
    "out:% savings (space heating)": 12.54,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.03,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.89,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 145.3,
    "out:Primary Energy": 136.42,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.7,
    "out:CO2 Operational/m2": 49.05,
    "out:Total CO2/m2": 50.74,
    "out:Total CO2 (tons)": 261.28,
    "out:Klimatpaverkan": -28.33,
    "out:Initial Cost/MSEK": 0.517,
    "out:Running cost/(Apt SEK y)": 40450,
    "out:Running Cost over RSP/MSEK": 17.072,
    "out:LCP/MSEK": 1.915,
    "out:ROI% old": 67.17,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 60,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 20,
    "out:Payback Time": 5,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102980,
    "out:DH kr savings": 102980,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 581283,
    "out:% savings (space heating)": 8.6,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.89,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.03,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 140.3,
    "out:Primary Energy": 132.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.7,
    "out:CO2 Operational/m2": 47.25,
    "out:Total CO2/m2": 48.94,
    "out:Total CO2 (tons)": 252.01,
    "out:Klimatpaverkan": -37.6,
    "out:Initial Cost/MSEK": 0.646,
    "out:Running cost/(Apt SEK y)": 39010,
    "out:Running Cost over RSP/MSEK": 16.464,
    "out:LCP/MSEK": 2.395,
    "out:ROI% old": 67.23,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 60,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 20,
    "out:Payback Time": 5,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128725,
    "out:DH kr savings": 128725,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 556265,
    "out:% savings (space heating)": 12.54,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.03,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.89,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 149.3,
    "out:Total Energy Use Post PV": 145.29,
    "out:Primary Energy": 131.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.48,
    "out:CO2 Operational/m2": 38.06,
    "out:Total CO2/m2": 46.53,
    "out:Total CO2 (tons)": 239.6,
    "out:Klimatpaverkan": -50.01,
    "out:Initial Cost/MSEK": 0.954,
    "out:Running cost/(Apt SEK y)": 40497,
    "out:Running Cost over RSP/MSEK": 17.104,
    "out:LCP/MSEK": 1.446,
    "out:ROI% old": 35.95,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 32,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142042,
    "out:DH kr savings": 82384,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 581283,
    "out:% savings (space heating)": 8.6,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.89,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.03,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 144.3,
    "out:Total Energy Use Post PV": 140.29,
    "out:Primary Energy": 127.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.48,
    "out:CO2 Operational/m2": 36.26,
    "out:Total CO2/m2": 44.73,
    "out:Total CO2 (tons)": 230.33,
    "out:Klimatpaverkan": -59.28,
    "out:Initial Cost/MSEK": 1.082,
    "out:Running cost/(Apt SEK y)": 39057,
    "out:Running Cost over RSP/MSEK": 16.496,
    "out:LCP/MSEK": 1.926,
    "out:ROI% old": 39.7,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 121,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 36,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 108129,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167787,
    "out:DH kr savings": 108129,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 556265,
    "out:% savings (space heating)": 12.54,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.03,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.89,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 141.29,
    "out:Primary Energy": 129.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.76,
    "out:CO2 Operational/m2": 36.62,
    "out:Total CO2/m2": 45.37,
    "out:Total CO2 (tons)": 233.63,
    "out:Klimatpaverkan": -55.98,
    "out:Initial Cost/MSEK": 1.129,
    "out:Running cost/(Apt SEK y)": 39345,
    "out:Running Cost over RSP/MSEK": 16.618,
    "out:LCP/MSEK": 1.757,
    "out:ROI% old": 36.51,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 33,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162638,
    "out:DH kr savings": 102980,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 581283,
    "out:% savings (space heating)": 8.6,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.89,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.03,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 136.29,
    "out:Primary Energy": 124.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.76,
    "out:CO2 Operational/m2": 34.82,
    "out:Total CO2/m2": 43.57,
    "out:Total CO2 (tons)": 224.36,
    "out:Klimatpaverkan": -65.25,
    "out:Initial Cost/MSEK": 1.258,
    "out:Running cost/(Apt SEK y)": 37906,
    "out:Running Cost over RSP/MSEK": 16.009,
    "out:LCP/MSEK": 2.237,
    "out:ROI% old": 39.68,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 36,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188383,
    "out:DH kr savings": 128725,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 556265,
    "out:% savings (space heating)": 12.54,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.03,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.89,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 149.69,
    "out:Total Energy Use Post PV": 149.69,
    "out:Primary Energy": 144.58,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.76,
    "out:CO2 Operational/m2": 49.4,
    "out:Total CO2/m2": 53.16,
    "out:Total CO2 (tons)": 273.71,
    "out:Klimatpaverkan": -15.9,
    "out:Initial Cost/MSEK": 0.996,
    "out:Running cost/(Apt SEK y)": 41191,
    "out:Running Cost over RSP/MSEK": 17.379,
    "out:LCP/MSEK": 1.129,
    "out:ROI% old": 30.48,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 127,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 27,
    "out:Return/kSEK/y": 271,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 108129,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 52594,
    "out:DH kr savings": 108129,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 581283,
    "out:% savings (space heating)": 8.6,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.89,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.03,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 144.69,
    "out:Total Energy Use Post PV": 144.69,
    "out:Primary Energy": 140.33,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.76,
    "out:CO2 Operational/m2": 47.6,
    "out:Total CO2/m2": 51.36,
    "out:Total CO2 (tons)": 264.44,
    "out:Klimatpaverkan": -25.17,
    "out:Initial Cost/MSEK": 1.125,
    "out:Running cost/(Apt SEK y)": 39751,
    "out:Running Cost over RSP/MSEK": 16.771,
    "out:LCP/MSEK": 1.608,
    "out:ROI% old": 34.71,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 31,
    "out:Return/kSEK/y": 349,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 133874,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 78339,
    "out:DH kr savings": 133874,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 556265,
    "out:% savings (space heating)": 12.54,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.03,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.89,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 145.69,
    "out:Total Energy Use Post PV": 145.69,
    "out:Primary Energy": 141.96,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.04,
    "out:CO2 Operational/m2": 47.96,
    "out:Total CO2/m2": 52,
    "out:Total CO2 (tons)": 267.74,
    "out:Klimatpaverkan": -21.87,
    "out:Initial Cost/MSEK": 1.172,
    "out:Running cost/(Apt SEK y)": 40039,
    "out:Running Cost over RSP/MSEK": 16.893,
    "out:LCP/MSEK": 1.44,
    "out:ROI% old": 31.84,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 28,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 73190,
    "out:DH kr savings": 128725,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 581283,
    "out:% savings (space heating)": 8.6,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.89,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.03,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 140.69,
    "out:Total Energy Use Post PV": 140.69,
    "out:Primary Energy": 137.71,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.04,
    "out:CO2 Operational/m2": 46.16,
    "out:Total CO2/m2": 50.2,
    "out:Total CO2 (tons)": 258.48,
    "out:Klimatpaverkan": -31.13,
    "out:Initial Cost/MSEK": 1.3,
    "out:Running cost/(Apt SEK y)": 38599,
    "out:Running Cost over RSP/MSEK": 16.285,
    "out:LCP/MSEK": 1.919,
    "out:ROI% old": 35.37,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 32,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 98935,
    "out:DH kr savings": 154470,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 556265,
    "out:% savings (space heating)": 12.54,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.03,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.89,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 149.69,
    "out:Total Energy Use Post PV": 144.84,
    "out:Primary Energy": 135.84,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.82,
    "out:CO2 Operational/m2": 41.16,
    "out:Total CO2/m2": 51.98,
    "out:Total CO2 (tons)": 267.66,
    "out:Klimatpaverkan": -21.95,
    "out:Initial Cost/MSEK": 1.608,
    "out:Running cost/(Apt SEK y)": 40046,
    "out:Running Cost over RSP/MSEK": 16.909,
    "out:LCP/MSEK": 0.988,
    "out:ROI% old": 23.06,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 124,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 108129,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114421,
    "out:DH kr savings": 108129,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 581283,
    "out:% savings (space heating)": 8.6,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.89,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.03,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 144.69,
    "out:Total Energy Use Post PV": 139.84,
    "out:Primary Energy": 131.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.82,
    "out:CO2 Operational/m2": 39.36,
    "out:Total CO2/m2": 50.18,
    "out:Total CO2 (tons)": 258.4,
    "out:Klimatpaverkan": -31.21,
    "out:Initial Cost/MSEK": 1.737,
    "out:Running cost/(Apt SEK y)": 38606,
    "out:Running Cost over RSP/MSEK": 16.301,
    "out:LCP/MSEK": 1.467,
    "out:ROI% old": 26.35,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 133874,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140166,
    "out:DH kr savings": 133874,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 556265,
    "out:% savings (space heating)": 12.54,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.03,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.89,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 145.69,
    "out:Total Energy Use Post PV": 140.84,
    "out:Primary Energy": 133.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.1,
    "out:CO2 Operational/m2": 39.72,
    "out:Total CO2/m2": 50.83,
    "out:Total CO2 (tons)": 261.7,
    "out:Klimatpaverkan": -27.91,
    "out:Initial Cost/MSEK": 1.784,
    "out:Running cost/(Apt SEK y)": 38894,
    "out:Running Cost over RSP/MSEK": 16.422,
    "out:LCP/MSEK": 1.298,
    "out:ROI% old": 24.69,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135017,
    "out:DH kr savings": 128725,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 581283,
    "out:% savings (space heating)": 8.6,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.89,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.03,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 140.69,
    "out:Total Energy Use Post PV": 135.84,
    "out:Primary Energy": 128.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.1,
    "out:CO2 Operational/m2": 37.93,
    "out:Total CO2/m2": 49.03,
    "out:Total CO2 (tons)": 252.43,
    "out:Klimatpaverkan": -37.18,
    "out:Initial Cost/MSEK": 1.912,
    "out:Running cost/(Apt SEK y)": 37454,
    "out:Running Cost over RSP/MSEK": 15.814,
    "out:LCP/MSEK": 1.778,
    "out:ROI% old": 27.57,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 25,
    "out:Return/kSEK/y": 473,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160762,
    "out:DH kr savings": 154470,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 556265,
    "out:% savings (space heating)": 12.54,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.03,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.89,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 143.69,
    "out:Total Energy Use Post PV": 143.69,
    "out:Primary Energy": 141.08,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.76,
    "out:CO2 Operational/m2": 47.24,
    "out:Total CO2/m2": 51,
    "out:Total CO2 (tons)": 262.59,
    "out:Klimatpaverkan": -27.02,
    "out:Initial Cost/MSEK": 1.139,
    "out:Running cost/(Apt SEK y)": 39463,
    "out:Running Cost over RSP/MSEK": 16.65,
    "out:LCP/MSEK": 1.716,
    "out:ROI% old": 35.82,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 14.6,
    "out:Op energy cost/MSEK": 121,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 32,
    "out:Return/kSEK/y": 364,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83488,
    "out:DH kr savings": 139023,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 581283,
    "out:% savings (space heating)": 8.6,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.89,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.03,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 139.69,
    "out:Total Energy Use Post PV": 139.69,
    "out:Primary Energy": 136.83,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.76,
    "out:CO2 Operational/m2": 45.8,
    "out:Total CO2/m2": 49.56,
    "out:Total CO2 (tons)": 255.17,
    "out:Klimatpaverkan": -34.44,
    "out:Initial Cost/MSEK": 1.267,
    "out:Running cost/(Apt SEK y)": 38311,
    "out:Running Cost over RSP/MSEK": 16.163,
    "out:LCP/MSEK": 2.074,
    "out:ROI% old": 37.66,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 34,
    "out:Return/kSEK/y": 427,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104084,
    "out:DH kr savings": 159619,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 556265,
    "out:% savings (space heating)": 12.54,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.03,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.89,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 140.69,
    "out:Total Energy Use Post PV": 140.69,
    "out:Primary Energy": 138.98,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.04,
    "out:CO2 Operational/m2": 46.16,
    "out:Total CO2/m2": 50.2,
    "out:Total CO2 (tons)": 258.48,
    "out:Klimatpaverkan": -31.13,
    "out:Initial Cost/MSEK": 1.314,
    "out:Running cost/(Apt SEK y)": 38599,
    "out:Running Cost over RSP/MSEK": 16.285,
    "out:LCP/MSEK": 1.905,
    "out:ROI% old": 35,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 31,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98935,
    "out:DH kr savings": 154470,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 581283,
    "out:% savings (space heating)": 8.6,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.89,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.03,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 135.69,
    "out:Primary Energy": 134.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.04,
    "out:CO2 Operational/m2": 44.36,
    "out:Total CO2/m2": 48.4,
    "out:Total CO2 (tons)": 249.21,
    "out:Klimatpaverkan": -40.4,
    "out:Initial Cost/MSEK": 1.443,
    "out:Running cost/(Apt SEK y)": 37159,
    "out:Running Cost over RSP/MSEK": 15.677,
    "out:LCP/MSEK": 2.385,
    "out:ROI% old": 37.9,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 34,
    "out:Return/kSEK/y": 489,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124680,
    "out:DH kr savings": 180215,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 556265,
    "out:% savings (space heating)": 12.54,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.03,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.89,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 143.69,
    "out:Total Energy Use Post PV": 138.84,
    "out:Primary Energy": 132.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.82,
    "out:CO2 Operational/m2": 39.01,
    "out:Total CO2/m2": 49.82,
    "out:Total CO2 (tons)": 256.54,
    "out:Klimatpaverkan": -33.07,
    "out:Initial Cost/MSEK": 1.751,
    "out:Running cost/(Apt SEK y)": 38318,
    "out:Running Cost over RSP/MSEK": 16.179,
    "out:LCP/MSEK": 1.575,
    "out:ROI% old": 27.14,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 18.7,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 426,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145315,
    "out:DH kr savings": 139023,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 581283,
    "out:% savings (space heating)": 8.6,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.89,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.03,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 139.69,
    "out:Total Energy Use Post PV": 134.84,
    "out:Primary Energy": 128.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.82,
    "out:CO2 Operational/m2": 37.57,
    "out:Total CO2/m2": 48.38,
    "out:Total CO2 (tons)": 249.13,
    "out:Klimatpaverkan": -40.48,
    "out:Initial Cost/MSEK": 1.879,
    "out:Running cost/(Apt SEK y)": 37166,
    "out:Running Cost over RSP/MSEK": 15.692,
    "out:LCP/MSEK": 1.933,
    "out:ROI% old": 28.98,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 26,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165911,
    "out:DH kr savings": 159619,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 556265,
    "out:% savings (space heating)": 12.54,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.03,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.89,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 140.69,
    "out:Total Energy Use Post PV": 135.84,
    "out:Primary Energy": 130.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.1,
    "out:CO2 Operational/m2": 37.93,
    "out:Total CO2/m2": 49.03,
    "out:Total CO2 (tons)": 252.43,
    "out:Klimatpaverkan": -37.18,
    "out:Initial Cost/MSEK": 1.926,
    "out:Running cost/(Apt SEK y)": 37454,
    "out:Running Cost over RSP/MSEK": 15.814,
    "out:LCP/MSEK": 1.764,
    "out:ROI% old": 27.37,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 25,
    "out:Return/kSEK/y": 473,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160762,
    "out:DH kr savings": 154470,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 581283,
    "out:% savings (space heating)": 8.6,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.89,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.03,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 130.84,
    "out:Primary Energy": 125.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.1,
    "out:CO2 Operational/m2": 36.13,
    "out:Total CO2/m2": 47.23,
    "out:Total CO2 (tons)": 243.16,
    "out:Klimatpaverkan": -46.45,
    "out:Initial Cost/MSEK": 2.055,
    "out:Running cost/(Apt SEK y)": 36014,
    "out:Running Cost over RSP/MSEK": 15.206,
    "out:LCP/MSEK": 2.243,
    "out:ROI% old": 29.88,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 27,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186507,
    "out:DH kr savings": 180215,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 556265,
    "out:% savings (space heating)": 12.54,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.03,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.45,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 140.3,
    "out:Primary Energy": 129.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.41,
    "out:CO2 Operational/m2": 47.25,
    "out:Total CO2/m2": 51.65,
    "out:Total CO2 (tons)": 265.95,
    "out:Klimatpaverkan": -23.66,
    "out:Initial Cost/MSEK": 1.492,
    "out:Running cost/(Apt SEK y)": 39010,
    "out:Running Cost over RSP/MSEK": 16.464,
    "out:LCP/MSEK": 1.548,
    "out:ROI% old": 29.11,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 26,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128725,
    "out:DH kr savings": 128725,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506940,
    "out:% savings (space heating)": 20.29,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.45,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.25,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 135.3,
    "out:Primary Energy": 125.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.41,
    "out:CO2 Operational/m2": 45.45,
    "out:Total CO2/m2": 49.85,
    "out:Total CO2 (tons)": 256.68,
    "out:Klimatpaverkan": -32.93,
    "out:Initial Cost/MSEK": 1.621,
    "out:Running cost/(Apt SEK y)": 37571,
    "out:Running Cost over RSP/MSEK": 15.855,
    "out:LCP/MSEK": 2.028,
    "out:ROI% old": 32.16,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 29,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154470,
    "out:DH kr savings": 154470,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 485301,
    "out:% savings (space heating)": 23.69,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.25,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.45,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 135.3,
    "out:Primary Energy": 126.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.69,
    "out:CO2 Operational/m2": 45.45,
    "out:Total CO2/m2": 50.13,
    "out:Total CO2 (tons)": 258.13,
    "out:Klimatpaverkan": -31.48,
    "out:Initial Cost/MSEK": 1.668,
    "out:Running cost/(Apt SEK y)": 37571,
    "out:Running Cost over RSP/MSEK": 15.855,
    "out:LCP/MSEK": 1.981,
    "out:ROI% old": 31.25,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 28,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154470,
    "out:DH kr savings": 154470,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506940,
    "out:% savings (space heating)": 20.29,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.45,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.25,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 131.3,
    "out:Primary Energy": 123.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.69,
    "out:CO2 Operational/m2": 44.01,
    "out:Total CO2/m2": 48.69,
    "out:Total CO2 (tons)": 250.72,
    "out:Klimatpaverkan": -38.89,
    "out:Initial Cost/MSEK": 1.797,
    "out:Running cost/(Apt SEK y)": 36419,
    "out:Running Cost over RSP/MSEK": 15.369,
    "out:LCP/MSEK": 2.339,
    "out:ROI% old": 32.88,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 29,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175066,
    "out:DH kr savings": 175066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 485301,
    "out:% savings (space heating)": 23.69,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.25,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.45,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 136.29,
    "out:Primary Energy": 122.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.46,
    "out:CO2 Operational/m2": 34.82,
    "out:Total CO2/m2": 46.28,
    "out:Total CO2 (tons)": 238.3,
    "out:Klimatpaverkan": -51.31,
    "out:Initial Cost/MSEK": 2.104,
    "out:Running cost/(Apt SEK y)": 37906,
    "out:Running Cost over RSP/MSEK": 16.009,
    "out:LCP/MSEK": 1.39,
    "out:ROI% old": 23.73,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188383,
    "out:DH kr savings": 128725,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506940,
    "out:% savings (space heating)": 20.29,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.45,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.25,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 131.29,
    "out:Primary Energy": 118.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.46,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 44.48,
    "out:Total CO2 (tons)": 229.03,
    "out:Klimatpaverkan": -60.58,
    "out:Initial Cost/MSEK": 2.233,
    "out:Running cost/(Apt SEK y)": 36466,
    "out:Running Cost over RSP/MSEK": 15.401,
    "out:LCP/MSEK": 1.87,
    "out:ROI% old": 26.25,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214128,
    "out:DH kr savings": 154470,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 485301,
    "out:% savings (space heating)": 23.69,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.25,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.45,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 131.29,
    "out:Primary Energy": 119.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.75,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 44.76,
    "out:Total CO2 (tons)": 230.48,
    "out:Klimatpaverkan": -59.13,
    "out:Initial Cost/MSEK": 2.28,
    "out:Running cost/(Apt SEK y)": 36466,
    "out:Running Cost over RSP/MSEK": 15.401,
    "out:LCP/MSEK": 1.823,
    "out:ROI% old": 25.71,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 23,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214128,
    "out:DH kr savings": 154470,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506940,
    "out:% savings (space heating)": 20.29,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.45,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.25,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 127.29,
    "out:Primary Energy": 115.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.75,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 43.32,
    "out:Total CO2 (tons)": 223.07,
    "out:Klimatpaverkan": -66.54,
    "out:Initial Cost/MSEK": 2.409,
    "out:Running cost/(Apt SEK y)": 35314,
    "out:Running Cost over RSP/MSEK": 14.915,
    "out:LCP/MSEK": 2.181,
    "out:ROI% old": 27.22,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234724,
    "out:DH kr savings": 175066,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 485301,
    "out:% savings (space heating)": 23.69,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.25,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.45,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 134.3,
    "out:Total Energy Use Post PV": 134.3,
    "out:Primary Energy": 125.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.41,
    "out:CO2 Operational/m2": 45.09,
    "out:Total CO2/m2": 49.49,
    "out:Total CO2 (tons)": 254.83,
    "out:Klimatpaverkan": -34.78,
    "out:Initial Cost/MSEK": 1.635,
    "out:Running cost/(Apt SEK y)": 37283,
    "out:Running Cost over RSP/MSEK": 15.734,
    "out:LCP/MSEK": 2.136,
    "out:ROI% old": 32.95,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 23.1,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 29,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159619,
    "out:DH kr savings": 159619,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506940,
    "out:% savings (space heating)": 20.29,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.45,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.25,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 130.3,
    "out:Total Energy Use Post PV": 130.3,
    "out:Primary Energy": 122.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.41,
    "out:CO2 Operational/m2": 43.65,
    "out:Total CO2/m2": 48.05,
    "out:Total CO2 (tons)": 247.41,
    "out:Klimatpaverkan": -42.2,
    "out:Initial Cost/MSEK": 1.764,
    "out:Running cost/(Apt SEK y)": 36131,
    "out:Running Cost over RSP/MSEK": 15.247,
    "out:LCP/MSEK": 2.493,
    "out:ROI% old": 34.48,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 31,
    "out:Return/kSEK/y": 544,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180215,
    "out:DH kr savings": 180215,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 485301,
    "out:% savings (space heating)": 23.69,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.25,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.45,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 131.3,
    "out:Primary Energy": 123.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.69,
    "out:CO2 Operational/m2": 44.01,
    "out:Total CO2/m2": 48.69,
    "out:Total CO2 (tons)": 250.72,
    "out:Klimatpaverkan": -38.89,
    "out:Initial Cost/MSEK": 1.811,
    "out:Running cost/(Apt SEK y)": 36419,
    "out:Running Cost over RSP/MSEK": 15.369,
    "out:LCP/MSEK": 2.325,
    "out:ROI% old": 32.63,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 29,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175066,
    "out:DH kr savings": 175066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506940,
    "out:% savings (space heating)": 20.29,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.45,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.25,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 127.3,
    "out:Total Energy Use Post PV": 127.3,
    "out:Primary Energy": 120.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.69,
    "out:CO2 Operational/m2": 42.57,
    "out:Total CO2/m2": 47.25,
    "out:Total CO2 (tons)": 243.3,
    "out:Klimatpaverkan": -46.31,
    "out:Initial Cost/MSEK": 1.939,
    "out:Running cost/(Apt SEK y)": 35267,
    "out:Running Cost over RSP/MSEK": 14.882,
    "out:LCP/MSEK": 2.683,
    "out:ROI% old": 34.05,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 30,
    "out:Return/kSEK/y": 591,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195662,
    "out:DH kr savings": 195662,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 485301,
    "out:% savings (space heating)": 23.69,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.25,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.45,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 134.3,
    "out:Total Energy Use Post PV": 130.29,
    "out:Primary Energy": 118.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.46,
    "out:CO2 Operational/m2": 32.66,
    "out:Total CO2/m2": 44.12,
    "out:Total CO2 (tons)": 227.18,
    "out:Klimatpaverkan": -62.43,
    "out:Initial Cost/MSEK": 2.247,
    "out:Running cost/(Apt SEK y)": 36178,
    "out:Running Cost over RSP/MSEK": 15.28,
    "out:LCP/MSEK": 1.978,
    "out:ROI% old": 26.86,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 542,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 219277,
    "out:DH kr savings": 159619,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506940,
    "out:% savings (space heating)": 20.29,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.45,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.25,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 130.3,
    "out:Total Energy Use Post PV": 126.29,
    "out:Primary Energy": 114.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.46,
    "out:CO2 Operational/m2": 31.22,
    "out:Total CO2/m2": 42.68,
    "out:Total CO2 (tons)": 219.77,
    "out:Klimatpaverkan": -69.84,
    "out:Initial Cost/MSEK": 2.376,
    "out:Running cost/(Apt SEK y)": 35026,
    "out:Running Cost over RSP/MSEK": 14.793,
    "out:LCP/MSEK": 2.335,
    "out:ROI% old": 28.33,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 25,
    "out:Return/kSEK/y": 604,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 239873,
    "out:DH kr savings": 180215,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 485301,
    "out:% savings (space heating)": 23.69,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.25,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.45,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 127.29,
    "out:Primary Energy": 116.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.75,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 43.32,
    "out:Total CO2 (tons)": 223.07,
    "out:Klimatpaverkan": -66.54,
    "out:Initial Cost/MSEK": 2.423,
    "out:Running cost/(Apt SEK y)": 35314,
    "out:Running Cost over RSP/MSEK": 14.915,
    "out:LCP/MSEK": 2.167,
    "out:ROI% old": 27.06,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 234724,
    "out:DH kr savings": 175066,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506940,
    "out:% savings (space heating)": 20.29,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.45,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.25,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 127.3,
    "out:Total Energy Use Post PV": 123.29,
    "out:Primary Energy": 112.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.75,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 41.88,
    "out:Total CO2 (tons)": 215.66,
    "out:Klimatpaverkan": -73.95,
    "out:Initial Cost/MSEK": 2.551,
    "out:Running cost/(Apt SEK y)": 34162,
    "out:Running Cost over RSP/MSEK": 14.428,
    "out:LCP/MSEK": 2.525,
    "out:ROI% old": 28.42,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 34.1,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 25,
    "out:Return/kSEK/y": 651,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 255320,
    "out:DH kr savings": 195662,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 485301,
    "out:% savings (space heating)": 23.69,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.25,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.45,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 134.69,
    "out:Total Energy Use Post PV": 134.69,
    "out:Primary Energy": 131.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.75,
    "out:CO2 Operational/m2": 44,
    "out:Total CO2/m2": 50.75,
    "out:Total CO2 (tons)": 261.29,
    "out:Klimatpaverkan": -28.32,
    "out:Initial Cost/MSEK": 2.289,
    "out:Running cost/(Apt SEK y)": 36871,
    "out:Running Cost over RSP/MSEK": 15.555,
    "out:LCP/MSEK": 1.66,
    "out:ROI% old": 24.65,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 22,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 129829,
    "out:DH kr savings": 185364,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506940,
    "out:% savings (space heating)": 20.29,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.45,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.25,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 130.69,
    "out:Primary Energy": 128.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.75,
    "out:CO2 Operational/m2": 42.56,
    "out:Total CO2/m2": 49.31,
    "out:Total CO2 (tons)": 253.88,
    "out:Klimatpaverkan": -35.73,
    "out:Initial Cost/MSEK": 2.418,
    "out:Running cost/(Apt SEK y)": 35719,
    "out:Running Cost over RSP/MSEK": 15.068,
    "out:LCP/MSEK": 2.018,
    "out:ROI% old": 26.21,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 23,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 150425,
    "out:DH kr savings": 205960,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 485301,
    "out:% savings (space heating)": 23.69,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.25,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.45,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 130.69,
    "out:Primary Energy": 129.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.03,
    "out:CO2 Operational/m2": 42.56,
    "out:Total CO2/m2": 49.59,
    "out:Total CO2 (tons)": 255.33,
    "out:Klimatpaverkan": -34.28,
    "out:Initial Cost/MSEK": 2.465,
    "out:Running cost/(Apt SEK y)": 35719,
    "out:Running Cost over RSP/MSEK": 15.068,
    "out:LCP/MSEK": 1.971,
    "out:ROI% old": 25.71,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 23,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150425,
    "out:DH kr savings": 205960,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506940,
    "out:% savings (space heating)": 20.29,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.45,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.25,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 126.69,
    "out:Primary Energy": 125.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.03,
    "out:CO2 Operational/m2": 41.12,
    "out:Total CO2/m2": 48.15,
    "out:Total CO2 (tons)": 247.91,
    "out:Klimatpaverkan": -41.7,
    "out:Initial Cost/MSEK": 2.594,
    "out:Running cost/(Apt SEK y)": 34567,
    "out:Running Cost over RSP/MSEK": 14.582,
    "out:LCP/MSEK": 2.329,
    "out:ROI% old": 27.11,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 24,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171021,
    "out:DH kr savings": 226556,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 485301,
    "out:% savings (space heating)": 23.69,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.25,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.45,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 134.69,
    "out:Total Energy Use Post PV": 129.84,
    "out:Primary Energy": 123.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.81,
    "out:CO2 Operational/m2": 35.77,
    "out:Total CO2/m2": 49.57,
    "out:Total CO2 (tons)": 255.25,
    "out:Klimatpaverkan": -34.36,
    "out:Initial Cost/MSEK": 2.901,
    "out:Running cost/(Apt SEK y)": 35726,
    "out:Running Cost over RSP/MSEK": 15.084,
    "out:LCP/MSEK": 1.519,
    "out:ROI% old": 21.76,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191656,
    "out:DH kr savings": 185364,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506940,
    "out:% savings (space heating)": 20.29,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.45,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.25,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 125.84,
    "out:Primary Energy": 119.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.81,
    "out:CO2 Operational/m2": 34.33,
    "out:Total CO2/m2": 48.13,
    "out:Total CO2 (tons)": 247.83,
    "out:Klimatpaverkan": -41.78,
    "out:Initial Cost/MSEK": 3.03,
    "out:Running cost/(Apt SEK y)": 34574,
    "out:Running Cost over RSP/MSEK": 14.598,
    "out:LCP/MSEK": 1.877,
    "out:ROI% old": 23.13,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212252,
    "out:DH kr savings": 205960,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 485301,
    "out:% savings (space heating)": 23.69,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.25,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.45,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 125.84,
    "out:Primary Energy": 120.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.09,
    "out:CO2 Operational/m2": 34.33,
    "out:Total CO2/m2": 48.41,
    "out:Total CO2 (tons)": 249.28,
    "out:Klimatpaverkan": -40.33,
    "out:Initial Cost/MSEK": 3.077,
    "out:Running cost/(Apt SEK y)": 34574,
    "out:Running Cost over RSP/MSEK": 14.598,
    "out:LCP/MSEK": 1.83,
    "out:ROI% old": 22.78,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212252,
    "out:DH kr savings": 205960,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506940,
    "out:% savings (space heating)": 20.29,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.45,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.25,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 121.84,
    "out:Primary Energy": 116.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.09,
    "out:CO2 Operational/m2": 32.89,
    "out:Total CO2/m2": 46.97,
    "out:Total CO2 (tons)": 241.87,
    "out:Klimatpaverkan": -47.74,
    "out:Initial Cost/MSEK": 3.206,
    "out:Running cost/(Apt SEK y)": 33423,
    "out:Running Cost over RSP/MSEK": 14.111,
    "out:LCP/MSEK": 2.187,
    "out:ROI% old": 24.03,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 232848,
    "out:DH kr savings": 226556,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 485301,
    "out:% savings (space heating)": 23.69,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.25,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.45,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 129.69,
    "out:Total Energy Use Post PV": 129.69,
    "out:Primary Energy": 128.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.75,
    "out:CO2 Operational/m2": 42.2,
    "out:Total CO2/m2": 48.95,
    "out:Total CO2 (tons)": 252.02,
    "out:Klimatpaverkan": -37.59,
    "out:Initial Cost/MSEK": 2.432,
    "out:Running cost/(Apt SEK y)": 35431,
    "out:Running Cost over RSP/MSEK": 14.947,
    "out:LCP/MSEK": 2.126,
    "out:ROI% old": 26.77,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 24,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155574,
    "out:DH kr savings": 211109,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506940,
    "out:% savings (space heating)": 20.29,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.45,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.25,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 125.69,
    "out:Primary Energy": 124.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.75,
    "out:CO2 Operational/m2": 40.76,
    "out:Total CO2/m2": 47.51,
    "out:Total CO2 (tons)": 244.61,
    "out:Klimatpaverkan": -45,
    "out:Initial Cost/MSEK": 2.56,
    "out:Running cost/(Apt SEK y)": 34280,
    "out:Running Cost over RSP/MSEK": 14.46,
    "out:LCP/MSEK": 2.484,
    "out:ROI% old": 28.14,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 25,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176170,
    "out:DH kr savings": 231705,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 485301,
    "out:% savings (space heating)": 23.69,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.25,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.45,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 126.69,
    "out:Primary Energy": 126.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.03,
    "out:CO2 Operational/m2": 41.12,
    "out:Total CO2/m2": 48.15,
    "out:Total CO2 (tons)": 247.91,
    "out:Klimatpaverkan": -41.7,
    "out:Initial Cost/MSEK": 2.607,
    "out:Running cost/(Apt SEK y)": 34567,
    "out:Running Cost over RSP/MSEK": 14.582,
    "out:LCP/MSEK": 2.315,
    "out:ROI% old": 26.97,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 24,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171021,
    "out:DH kr savings": 226556,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506940,
    "out:% savings (space heating)": 20.29,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.45,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.25,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 121.69,
    "out:Primary Energy": 122.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.03,
    "out:CO2 Operational/m2": 39.32,
    "out:Total CO2/m2": 46.35,
    "out:Total CO2 (tons)": 238.65,
    "out:Klimatpaverkan": -50.96,
    "out:Initial Cost/MSEK": 2.736,
    "out:Running cost/(Apt SEK y)": 33128,
    "out:Running Cost over RSP/MSEK": 13.974,
    "out:LCP/MSEK": 2.794,
    "out:ROI% old": 28.88,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 26,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 196766,
    "out:DH kr savings": 252301,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 485301,
    "out:% savings (space heating)": 23.69,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.25,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.45,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 129.69,
    "out:Total Energy Use Post PV": 124.84,
    "out:Primary Energy": 119.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.81,
    "out:CO2 Operational/m2": 33.97,
    "out:Total CO2/m2": 47.77,
    "out:Total CO2 (tons)": 245.98,
    "out:Klimatpaverkan": -43.63,
    "out:Initial Cost/MSEK": 3.044,
    "out:Running cost/(Apt SEK y)": 34286,
    "out:Running Cost over RSP/MSEK": 14.476,
    "out:LCP/MSEK": 1.984,
    "out:ROI% old": 23.6,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217401,
    "out:DH kr savings": 211109,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506940,
    "out:% savings (space heating)": 20.29,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.45,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.25,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 120.84,
    "out:Primary Energy": 116.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.81,
    "out:CO2 Operational/m2": 32.53,
    "out:Total CO2/m2": 46.33,
    "out:Total CO2 (tons)": 238.57,
    "out:Klimatpaverkan": -51.04,
    "out:Initial Cost/MSEK": 3.172,
    "out:Running cost/(Apt SEK y)": 33135,
    "out:Running Cost over RSP/MSEK": 13.99,
    "out:LCP/MSEK": 2.342,
    "out:ROI% old": 24.83,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 237997,
    "out:DH kr savings": 231705,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 485301,
    "out:% savings (space heating)": 23.69,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.25,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.45,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 121.84,
    "out:Primary Energy": 117.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.09,
    "out:CO2 Operational/m2": 32.89,
    "out:Total CO2/m2": 46.97,
    "out:Total CO2 (tons)": 241.87,
    "out:Klimatpaverkan": -47.74,
    "out:Initial Cost/MSEK": 3.219,
    "out:Running cost/(Apt SEK y)": 33423,
    "out:Running Cost over RSP/MSEK": 14.111,
    "out:LCP/MSEK": 2.174,
    "out:ROI% old": 23.93,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 232848,
    "out:DH kr savings": 226556,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506940,
    "out:% savings (space heating)": 20.29,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.45,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.25,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 116.84,
    "out:Primary Energy": 113.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.09,
    "out:CO2 Operational/m2": 31.09,
    "out:Total CO2/m2": 45.17,
    "out:Total CO2 (tons)": 232.6,
    "out:Klimatpaverkan": -57.01,
    "out:Initial Cost/MSEK": 3.348,
    "out:Running cost/(Apt SEK y)": 31983,
    "out:Running Cost over RSP/MSEK": 13.503,
    "out:LCP/MSEK": 2.653,
    "out:ROI% old": 25.61,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 23,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258593,
    "out:DH kr savings": 252301,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 485301,
    "out:% savings (space heating)": 23.69,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.25,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.99,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 135.3,
    "out:Primary Energy": 125.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.97,
    "out:CO2 Operational/m2": 45.45,
    "out:Total CO2/m2": 59.41,
    "out:Total CO2 (tons)": 305.92,
    "out:Klimatpaverkan": 16.31,
    "out:Initial Cost/MSEK": 4.659,
    "out:Running cost/(Apt SEK y)": 37571,
    "out:Running Cost over RSP/MSEK": 15.855,
    "out:LCP/MSEK": -1.01,
    "out:ROI% old": 11.19,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154470,
    "out:DH kr savings": 154470,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483949,
    "out:% savings (space heating)": 23.91,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.99,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 131.3,
    "out:Primary Energy": 121.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.97,
    "out:CO2 Operational/m2": 44.01,
    "out:Total CO2/m2": 57.97,
    "out:Total CO2 (tons)": 298.5,
    "out:Klimatpaverkan": 8.89,
    "out:Initial Cost/MSEK": 4.787,
    "out:Running cost/(Apt SEK y)": 36419,
    "out:Running Cost over RSP/MSEK": 15.369,
    "out:LCP/MSEK": -0.652,
    "out:ROI% old": 12.34,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175066,
    "out:DH kr savings": 175066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 463356,
    "out:% savings (space heating)": 27.14,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.99,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.99,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 131.3,
    "out:Primary Energy": 122.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.25,
    "out:CO2 Operational/m2": 44.01,
    "out:Total CO2/m2": 58.25,
    "out:Total CO2 (tons)": 299.95,
    "out:Klimatpaverkan": 10.34,
    "out:Initial Cost/MSEK": 4.834,
    "out:Running cost/(Apt SEK y)": 36419,
    "out:Running Cost over RSP/MSEK": 15.369,
    "out:LCP/MSEK": -0.699,
    "out:ROI% old": 12.22,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175066,
    "out:DH kr savings": 175066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483949,
    "out:% savings (space heating)": 23.91,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.99,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 127.3,
    "out:Total Energy Use Post PV": 127.3,
    "out:Primary Energy": 119.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.25,
    "out:CO2 Operational/m2": 42.57,
    "out:Total CO2/m2": 56.81,
    "out:Total CO2 (tons)": 292.54,
    "out:Klimatpaverkan": 2.93,
    "out:Initial Cost/MSEK": 4.963,
    "out:Running cost/(Apt SEK y)": 35267,
    "out:Running Cost over RSP/MSEK": 14.882,
    "out:LCP/MSEK": -0.341,
    "out:ROI% old": 13.3,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 591,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195662,
    "out:DH kr savings": 195662,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 463356,
    "out:% savings (space heating)": 27.14,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.99,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.99,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 131.29,
    "out:Primary Energy": 118.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.03,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 54.04,
    "out:Total CO2 (tons)": 278.27,
    "out:Klimatpaverkan": -11.34,
    "out:Initial Cost/MSEK": 5.271,
    "out:Running cost/(Apt SEK y)": 36466,
    "out:Running Cost over RSP/MSEK": 15.401,
    "out:LCP/MSEK": -1.168,
    "out:ROI% old": 11.12,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214128,
    "out:DH kr savings": 154470,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483949,
    "out:% savings (space heating)": 23.91,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.99,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 127.29,
    "out:Primary Energy": 114.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.03,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 52.6,
    "out:Total CO2 (tons)": 270.85,
    "out:Klimatpaverkan": -18.76,
    "out:Initial Cost/MSEK": 5.399,
    "out:Running cost/(Apt SEK y)": 35314,
    "out:Running Cost over RSP/MSEK": 14.915,
    "out:LCP/MSEK": -0.81,
    "out:ROI% old": 12.14,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234724,
    "out:DH kr savings": 175066,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 463356,
    "out:% savings (space heating)": 27.14,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.99,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.99,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 127.29,
    "out:Primary Energy": 115.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.31,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 52.88,
    "out:Total CO2 (tons)": 272.3,
    "out:Klimatpaverkan": -17.31,
    "out:Initial Cost/MSEK": 5.446,
    "out:Running cost/(Apt SEK y)": 35314,
    "out:Running Cost over RSP/MSEK": 14.915,
    "out:LCP/MSEK": -0.857,
    "out:ROI% old": 12.04,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 234724,
    "out:DH kr savings": 175066,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483949,
    "out:% savings (space heating)": 23.91,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.99,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 127.3,
    "out:Total Energy Use Post PV": 123.29,
    "out:Primary Energy": 112.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.31,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 51.45,
    "out:Total CO2 (tons)": 264.89,
    "out:Klimatpaverkan": -24.72,
    "out:Initial Cost/MSEK": 5.575,
    "out:Running cost/(Apt SEK y)": 34162,
    "out:Running Cost over RSP/MSEK": 14.428,
    "out:LCP/MSEK": -0.499,
    "out:ROI% old": 13.01,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 34.1,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 651,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 255320,
    "out:DH kr savings": 195662,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 463356,
    "out:% savings (space heating)": 27.14,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.99,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.99,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 130.3,
    "out:Total Energy Use Post PV": 130.3,
    "out:Primary Energy": 121.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.97,
    "out:CO2 Operational/m2": 43.65,
    "out:Total CO2/m2": 57.61,
    "out:Total CO2 (tons)": 296.65,
    "out:Klimatpaverkan": 7.04,
    "out:Initial Cost/MSEK": 4.801,
    "out:Running cost/(Apt SEK y)": 36131,
    "out:Running Cost over RSP/MSEK": 15.247,
    "out:LCP/MSEK": -0.544,
    "out:ROI% old": 12.67,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 544,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180215,
    "out:DH kr savings": 180215,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483949,
    "out:% savings (space heating)": 23.91,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.99,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 126.3,
    "out:Primary Energy": 118.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.97,
    "out:CO2 Operational/m2": 42.21,
    "out:Total CO2/m2": 56.17,
    "out:Total CO2 (tons)": 289.24,
    "out:Klimatpaverkan": -0.37,
    "out:Initial Cost/MSEK": 4.93,
    "out:Running cost/(Apt SEK y)": 34979,
    "out:Running Cost over RSP/MSEK": 14.761,
    "out:LCP/MSEK": -0.186,
    "out:ROI% old": 13.75,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200811,
    "out:DH kr savings": 200811,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 463356,
    "out:% savings (space heating)": 27.14,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.99,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.99,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 126.3,
    "out:Primary Energy": 119.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.25,
    "out:CO2 Operational/m2": 42.21,
    "out:Total CO2/m2": 56.45,
    "out:Total CO2 (tons)": 290.69,
    "out:Klimatpaverkan": 1.08,
    "out:Initial Cost/MSEK": 4.977,
    "out:Running cost/(Apt SEK y)": 34979,
    "out:Running Cost over RSP/MSEK": 14.761,
    "out:LCP/MSEK": -0.233,
    "out:ROI% old": 13.62,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 200811,
    "out:DH kr savings": 200811,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483949,
    "out:% savings (space heating)": 23.91,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.99,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 122.3,
    "out:Total Energy Use Post PV": 122.3,
    "out:Primary Energy": 116.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.25,
    "out:CO2 Operational/m2": 40.77,
    "out:Total CO2/m2": 55.01,
    "out:Total CO2 (tons)": 283.27,
    "out:Klimatpaverkan": -6.34,
    "out:Initial Cost/MSEK": 5.106,
    "out:Running cost/(Apt SEK y)": 33827,
    "out:Running Cost over RSP/MSEK": 14.274,
    "out:LCP/MSEK": 0.124,
    "out:ROI% old": 14.63,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 669,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221407,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221407,
    "out:DH kr savings": 221407,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 463356,
    "out:% savings (space heating)": 27.14,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.99,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00001200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.99,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 130.3,
    "out:Total Energy Use Post PV": 126.29,
    "out:Primary Energy": 114.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.03,
    "out:CO2 Operational/m2": 31.22,
    "out:Total CO2/m2": 52.24,
    "out:Total CO2 (tons)": 269,
    "out:Klimatpaverkan": -20.61,
    "out:Initial Cost/MSEK": 5.413,
    "out:Running cost/(Apt SEK y)": 35026,
    "out:Running Cost over RSP/MSEK": 14.793,
    "out:LCP/MSEK": -0.702,
    "out:ROI% old": 12.43,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 604,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 239873,
    "out:DH kr savings": 180215,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483949,
    "out:% savings (space heating)": 23.91,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.99,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 122.29,
    "out:Primary Energy": 111.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.03,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 50.8,
    "out:Total CO2 (tons)": 261.59,
    "out:Klimatpaverkan": -28.02,
    "out:Initial Cost/MSEK": 5.542,
    "out:Running cost/(Apt SEK y)": 33874,
    "out:Running Cost over RSP/MSEK": 14.307,
    "out:LCP/MSEK": -0.344,
    "out:ROI% old": 13.4,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 666,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260469,
    "out:DH kr savings": 200811,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 463356,
    "out:% savings (space heating)": 27.14,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.99,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.99,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 122.29,
    "out:Primary Energy": 112.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.31,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 51.09,
    "out:Total CO2 (tons)": 263.04,
    "out:Klimatpaverkan": -26.57,
    "out:Initial Cost/MSEK": 5.589,
    "out:Running cost/(Apt SEK y)": 33874,
    "out:Running Cost over RSP/MSEK": 14.307,
    "out:LCP/MSEK": -0.391,
    "out:ROI% old": 13.29,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 666,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 260469,
    "out:DH kr savings": 200811,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483949,
    "out:% savings (space heating)": 23.91,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.99,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 122.3,
    "out:Total Energy Use Post PV": 118.29,
    "out:Primary Energy": 109.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.31,
    "out:CO2 Operational/m2": 28.34,
    "out:Total CO2/m2": 49.65,
    "out:Total CO2 (tons)": 255.62,
    "out:Klimatpaverkan": -33.99,
    "out:Initial Cost/MSEK": 5.718,
    "out:Running cost/(Apt SEK y)": 32722,
    "out:Running Cost over RSP/MSEK": 13.82,
    "out:LCP/MSEK": -0.033,
    "out:ROI% old": 14.2,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 728,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 221407,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281065,
    "out:DH kr savings": 221407,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 463356,
    "out:% savings (space heating)": 27.14,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.99,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00001201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.99,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 130.69,
    "out:Primary Energy": 128.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.31,
    "out:CO2 Operational/m2": 42.56,
    "out:Total CO2/m2": 58.87,
    "out:Total CO2 (tons)": 303.11,
    "out:Klimatpaverkan": 13.5,
    "out:Initial Cost/MSEK": 5.455,
    "out:Running cost/(Apt SEK y)": 35719,
    "out:Running Cost over RSP/MSEK": 15.068,
    "out:LCP/MSEK": -1.02,
    "out:ROI% old": 11.62,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150425,
    "out:DH kr savings": 205960,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483949,
    "out:% savings (space heating)": 23.91,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.99,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 126.69,
    "out:Primary Energy": 124.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.31,
    "out:CO2 Operational/m2": 41.12,
    "out:Total CO2/m2": 57.43,
    "out:Total CO2 (tons)": 295.7,
    "out:Klimatpaverkan": 6.09,
    "out:Initial Cost/MSEK": 5.584,
    "out:Running cost/(Apt SEK y)": 34567,
    "out:Running Cost over RSP/MSEK": 14.582,
    "out:LCP/MSEK": -0.662,
    "out:ROI% old": 12.59,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171021,
    "out:DH kr savings": 226556,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 463356,
    "out:% savings (space heating)": 27.14,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.99,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.99,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 126.69,
    "out:Primary Energy": 125.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.59,
    "out:CO2 Operational/m2": 41.12,
    "out:Total CO2/m2": 57.71,
    "out:Total CO2 (tons)": 297.15,
    "out:Klimatpaverkan": 7.54,
    "out:Initial Cost/MSEK": 5.631,
    "out:Running cost/(Apt SEK y)": 34567,
    "out:Running Cost over RSP/MSEK": 14.582,
    "out:LCP/MSEK": -0.709,
    "out:ROI% old": 12.49,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171021,
    "out:DH kr savings": 226556,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483949,
    "out:% savings (space heating)": 23.91,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.99,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 122.69,
    "out:Total Energy Use Post PV": 122.69,
    "out:Primary Energy": 121.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.59,
    "out:CO2 Operational/m2": 39.68,
    "out:Total CO2/m2": 56.27,
    "out:Total CO2 (tons)": 289.73,
    "out:Klimatpaverkan": 0.12,
    "out:Initial Cost/MSEK": 5.76,
    "out:Running cost/(Apt SEK y)": 33416,
    "out:Running Cost over RSP/MSEK": 14.095,
    "out:LCP/MSEK": -0.351,
    "out:ROI% old": 13.42,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 34.1,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 691,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191617,
    "out:DH kr savings": 247152,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 463356,
    "out:% savings (space heating)": 27.14,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.99,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.99,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 125.84,
    "out:Primary Energy": 119.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.37,
    "out:CO2 Operational/m2": 34.33,
    "out:Total CO2/m2": 57.69,
    "out:Total CO2 (tons)": 297.07,
    "out:Klimatpaverkan": 7.46,
    "out:Initial Cost/MSEK": 6.067,
    "out:Running cost/(Apt SEK y)": 34574,
    "out:Running Cost over RSP/MSEK": 14.598,
    "out:LCP/MSEK": -1.161,
    "out:ROI% old": 11.55,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212252,
    "out:DH kr savings": 205960,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483949,
    "out:% savings (space heating)": 23.91,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.99,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 121.84,
    "out:Primary Energy": 115.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.37,
    "out:CO2 Operational/m2": 32.89,
    "out:Total CO2/m2": 56.25,
    "out:Total CO2 (tons)": 289.66,
    "out:Klimatpaverkan": 0.05,
    "out:Initial Cost/MSEK": 6.196,
    "out:Running cost/(Apt SEK y)": 33423,
    "out:Running Cost over RSP/MSEK": 14.111,
    "out:LCP/MSEK": -0.803,
    "out:ROI% old": 12.43,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 232848,
    "out:DH kr savings": 226556,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 463356,
    "out:% savings (space heating)": 27.14,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.99,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.99,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 121.84,
    "out:Primary Energy": 116.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.65,
    "out:CO2 Operational/m2": 32.89,
    "out:Total CO2/m2": 56.54,
    "out:Total CO2 (tons)": 291.11,
    "out:Klimatpaverkan": 1.5,
    "out:Initial Cost/MSEK": 6.243,
    "out:Running cost/(Apt SEK y)": 33423,
    "out:Running Cost over RSP/MSEK": 14.111,
    "out:LCP/MSEK": -0.85,
    "out:ROI% old": 12.34,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 232848,
    "out:DH kr savings": 226556,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483949,
    "out:% savings (space heating)": 23.91,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.99,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 122.69,
    "out:Total Energy Use Post PV": 117.84,
    "out:Primary Energy": 113.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.65,
    "out:CO2 Operational/m2": 31.45,
    "out:Total CO2/m2": 55.1,
    "out:Total CO2 (tons)": 283.69,
    "out:Klimatpaverkan": -5.92,
    "out:Initial Cost/MSEK": 6.372,
    "out:Running cost/(Apt SEK y)": 32271,
    "out:Running Cost over RSP/MSEK": 13.625,
    "out:LCP/MSEK": -0.492,
    "out:ROI% old": 13.18,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 753,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 253444,
    "out:DH kr savings": 247152,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 463356,
    "out:% savings (space heating)": 27.14,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.99,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.99,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 124.69,
    "out:Primary Energy": 124.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.31,
    "out:CO2 Operational/m2": 40.4,
    "out:Total CO2/m2": 56.71,
    "out:Total CO2 (tons)": 291.99,
    "out:Klimatpaverkan": 2.38,
    "out:Initial Cost/MSEK": 5.598,
    "out:Running cost/(Apt SEK y)": 33992,
    "out:Running Cost over RSP/MSEK": 14.339,
    "out:LCP/MSEK": -0.432,
    "out:ROI% old": 13.18,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181319,
    "out:DH kr savings": 236854,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483949,
    "out:% savings (space heating)": 23.91,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.99,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 120.69,
    "out:Primary Energy": 121.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.31,
    "out:CO2 Operational/m2": 38.96,
    "out:Total CO2/m2": 55.27,
    "out:Total CO2 (tons)": 284.58,
    "out:Klimatpaverkan": -5.03,
    "out:Initial Cost/MSEK": 5.727,
    "out:Running cost/(Apt SEK y)": 32840,
    "out:Running Cost over RSP/MSEK": 13.852,
    "out:LCP/MSEK": -0.075,
    "out:ROI% old": 14.1,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201915,
    "out:DH kr savings": 257450,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 463356,
    "out:% savings (space heating)": 27.14,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.99,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.99,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 121.69,
    "out:Primary Energy": 122.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.59,
    "out:CO2 Operational/m2": 39.32,
    "out:Total CO2/m2": 55.91,
    "out:Total CO2 (tons)": 287.88,
    "out:Klimatpaverkan": -1.73,
    "out:Initial Cost/MSEK": 5.774,
    "out:Running cost/(Apt SEK y)": 33128,
    "out:Running Cost over RSP/MSEK": 13.974,
    "out:LCP/MSEK": -0.243,
    "out:ROI% old": 13.68,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196766,
    "out:DH kr savings": 252301,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483949,
    "out:% savings (space heating)": 23.91,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.99,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 117.69,
    "out:Total Energy Use Post PV": 117.69,
    "out:Primary Energy": 118.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.59,
    "out:CO2 Operational/m2": 37.88,
    "out:Total CO2/m2": 54.47,
    "out:Total CO2 (tons)": 280.47,
    "out:Klimatpaverkan": -9.14,
    "out:Initial Cost/MSEK": 5.902,
    "out:Running cost/(Apt SEK y)": 31976,
    "out:Running Cost over RSP/MSEK": 13.487,
    "out:LCP/MSEK": 0.115,
    "out:ROI% old": 14.56,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 769,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217362,
    "out:DH kr savings": 272897,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 463356,
    "out:% savings (space heating)": 27.14,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.99,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00001201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.99,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 119.84,
    "out:Primary Energy": 115.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.37,
    "out:CO2 Operational/m2": 32.17,
    "out:Total CO2/m2": 55.53,
    "out:Total CO2 (tons)": 285.95,
    "out:Klimatpaverkan": -3.66,
    "out:Initial Cost/MSEK": 6.21,
    "out:Running cost/(Apt SEK y)": 32847,
    "out:Running Cost over RSP/MSEK": 13.868,
    "out:LCP/MSEK": -0.574,
    "out:ROI% old": 12.97,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 243146,
    "out:DH kr savings": 236854,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483949,
    "out:% savings (space heating)": 23.91,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.99,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 115.84,
    "out:Primary Energy": 112.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.37,
    "out:CO2 Operational/m2": 30.73,
    "out:Total CO2/m2": 54.09,
    "out:Total CO2 (tons)": 278.53,
    "out:Klimatpaverkan": -11.08,
    "out:Initial Cost/MSEK": 6.339,
    "out:Running cost/(Apt SEK y)": 31695,
    "out:Running Cost over RSP/MSEK": 13.381,
    "out:LCP/MSEK": -0.216,
    "out:ROI% old": 13.8,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 263742,
    "out:DH kr savings": 257450,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 463356,
    "out:% savings (space heating)": 27.14,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.99,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.99,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 116.84,
    "out:Primary Energy": 113.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.65,
    "out:CO2 Operational/m2": 31.09,
    "out:Total CO2/m2": 54.74,
    "out:Total CO2 (tons)": 281.84,
    "out:Klimatpaverkan": -7.77,
    "out:Initial Cost/MSEK": 6.386,
    "out:Running cost/(Apt SEK y)": 31983,
    "out:Running Cost over RSP/MSEK": 13.503,
    "out:LCP/MSEK": -0.385,
    "out:ROI% old": 13.43,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 258593,
    "out:DH kr savings": 252301,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483949,
    "out:% savings (space heating)": 23.91,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.99,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00001201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.99,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 117.69,
    "out:Total Energy Use Post PV": 112.84,
    "out:Primary Energy": 110.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.65,
    "out:CO2 Operational/m2": 29.65,
    "out:Total CO2/m2": 53.3,
    "out:Total CO2 (tons)": 274.42,
    "out:Klimatpaverkan": -15.19,
    "out:Initial Cost/MSEK": 6.514,
    "out:Running cost/(Apt SEK y)": 30831,
    "out:Running Cost over RSP/MSEK": 13.017,
    "out:LCP/MSEK": -0.027,
    "out:ROI% old": 14.23,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 830,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279189,
    "out:DH kr savings": 272897,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 463356,
    "out:% savings (space heating)": 27.14,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.99,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.31,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 153.3,
    "out:Total Energy Use Post PV": 153.3,
    "out:Primary Energy": 141.51,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.74,
    "out:CO2 Operational/m2": 51.93,
    "out:Total CO2/m2": 53.67,
    "out:Total CO2 (tons)": 276.34,
    "out:Klimatpaverkan": -13.27,
    "out:Initial Cost/MSEK": 0.254,
    "out:Running cost/(Apt SEK y)": 42754,
    "out:Running Cost over RSP/MSEK": 18.045,
    "out:LCP/MSEK": 1.205,
    "out:ROI% old": 81.97,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 7.8,
    "out:Op energy cost/MSEK": 132,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 73,
    "out:Return/kSEK/y": 187,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 24,
    "out:Payback Time": 4.166667,
    "out:DH kWh savings": 61788,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 61788,
    "out:DH kr savings": 61788,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578267,
    "out:% savings (space heating)": 9.08,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.31,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.47,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 149.3,
    "out:Total Energy Use Post PV": 149.3,
    "out:Primary Energy": 137.28,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.74,
    "out:CO2 Operational/m2": 50.49,
    "out:Total CO2/m2": 52.23,
    "out:Total CO2 (tons)": 268.93,
    "out:Klimatpaverkan": -20.68,
    "out:Initial Cost/MSEK": 0.383,
    "out:Running cost/(Apt SEK y)": 41602,
    "out:Running Cost over RSP/MSEK": 17.558,
    "out:LCP/MSEK": 1.563,
    "out:ROI% old": 72.57,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 10.7,
    "out:Op energy cost/MSEK": 128,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 65,
    "out:Return/kSEK/y": 249,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 22,
    "out:Payback Time": 4.545455,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 82384,
    "out:DH kr savings": 82384,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 553387,
    "out:% savings (space heating)": 12.99,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.47,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.31,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 149.3,
    "out:Total Energy Use Post PV": 149.3,
    "out:Primary Energy": 138.88,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.03,
    "out:CO2 Operational/m2": 50.49,
    "out:Total CO2/m2": 52.51,
    "out:Total CO2 (tons)": 270.38,
    "out:Klimatpaverkan": -19.23,
    "out:Initial Cost/MSEK": 0.43,
    "out:Running cost/(Apt SEK y)": 41602,
    "out:Running Cost over RSP/MSEK": 17.558,
    "out:LCP/MSEK": 1.516,
    "out:ROI% old": 64.65,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 10.7,
    "out:Op energy cost/MSEK": 128,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 58,
    "out:Return/kSEK/y": 249,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82384,
    "out:DH kr savings": 82384,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578267,
    "out:% savings (space heating)": 9.08,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.31,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.47,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 145.3,
    "out:Primary Energy": 134.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.03,
    "out:CO2 Operational/m2": 49.05,
    "out:Total CO2/m2": 51.07,
    "out:Total CO2 (tons)": 262.96,
    "out:Klimatpaverkan": -26.65,
    "out:Initial Cost/MSEK": 0.559,
    "out:Running cost/(Apt SEK y)": 40450,
    "out:Running Cost over RSP/MSEK": 17.072,
    "out:LCP/MSEK": 1.874,
    "out:ROI% old": 62.19,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 56,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102980,
    "out:DH kr savings": 102980,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 553387,
    "out:% savings (space heating)": 12.99,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.47,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.31,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 153.3,
    "out:Total Energy Use Post PV": 149.29,
    "out:Primary Energy": 134.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.8,
    "out:CO2 Operational/m2": 39.5,
    "out:Total CO2/m2": 48.3,
    "out:Total CO2 (tons)": 248.69,
    "out:Klimatpaverkan": -40.92,
    "out:Initial Cost/MSEK": 0.866,
    "out:Running cost/(Apt SEK y)": 41649,
    "out:Running Cost over RSP/MSEK": 17.591,
    "out:LCP/MSEK": 1.047,
    "out:ROI% old": 31.55,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 10.7,
    "out:Op energy cost/MSEK": 129,
    "out:El price/MSEK": 78,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 28,
    "out:Return/kSEK/y": 246,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 61788,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121446,
    "out:DH kr savings": 61788,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578267,
    "out:% savings (space heating)": 9.08,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.31,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.47,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 149.3,
    "out:Total Energy Use Post PV": 145.29,
    "out:Primary Energy": 130.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.8,
    "out:CO2 Operational/m2": 38.06,
    "out:Total CO2/m2": 46.86,
    "out:Total CO2 (tons)": 241.28,
    "out:Klimatpaverkan": -48.33,
    "out:Initial Cost/MSEK": 0.995,
    "out:Running cost/(Apt SEK y)": 40497,
    "out:Running Cost over RSP/MSEK": 17.104,
    "out:LCP/MSEK": 1.405,
    "out:ROI% old": 34.46,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 31,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142042,
    "out:DH kr savings": 82384,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 553387,
    "out:% savings (space heating)": 12.99,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.47,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.31,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 149.3,
    "out:Total Energy Use Post PV": 145.29,
    "out:Primary Energy": 131.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.08,
    "out:CO2 Operational/m2": 38.06,
    "out:Total CO2/m2": 47.14,
    "out:Total CO2 (tons)": 242.73,
    "out:Klimatpaverkan": -46.88,
    "out:Initial Cost/MSEK": 1.042,
    "out:Running cost/(Apt SEK y)": 40497,
    "out:Running Cost over RSP/MSEK": 17.104,
    "out:LCP/MSEK": 1.358,
    "out:ROI% old": 32.91,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 30,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142042,
    "out:DH kr savings": 82384,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578267,
    "out:% savings (space heating)": 9.08,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.31,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.47,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 141.29,
    "out:Primary Energy": 127.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.08,
    "out:CO2 Operational/m2": 36.62,
    "out:Total CO2/m2": 45.7,
    "out:Total CO2 (tons)": 235.31,
    "out:Klimatpaverkan": -54.3,
    "out:Initial Cost/MSEK": 1.171,
    "out:Running cost/(Apt SEK y)": 39345,
    "out:Running Cost over RSP/MSEK": 16.618,
    "out:LCP/MSEK": 1.716,
    "out:ROI% old": 35.22,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 32,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162638,
    "out:DH kr savings": 102980,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 553387,
    "out:% savings (space heating)": 12.99,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.47,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.31,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 148.3,
    "out:Total Energy Use Post PV": 148.3,
    "out:Primary Energy": 138.01,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.74,
    "out:CO2 Operational/m2": 50.13,
    "out:Total CO2/m2": 51.87,
    "out:Total CO2 (tons)": 267.07,
    "out:Klimatpaverkan": -22.54,
    "out:Initial Cost/MSEK": 0.397,
    "out:Running cost/(Apt SEK y)": 41314,
    "out:Running Cost over RSP/MSEK": 17.437,
    "out:LCP/MSEK": 1.671,
    "out:ROI% old": 74.42,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 11.5,
    "out:Op energy cost/MSEK": 127,
    "out:El price/MSEK": 78,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 67,
    "out:Return/kSEK/y": 264,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 22,
    "out:Payback Time": 4.545455,
    "out:DH kWh savings": 87533,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 87533,
    "out:DH kr savings": 87533,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578267,
    "out:% savings (space heating)": 9.08,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.31,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.47,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 143.3,
    "out:Total Energy Use Post PV": 143.3,
    "out:Primary Energy": 133.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.74,
    "out:CO2 Operational/m2": 48.33,
    "out:Total CO2/m2": 50.07,
    "out:Total CO2 (tons)": 257.81,
    "out:Klimatpaverkan": -31.8,
    "out:Initial Cost/MSEK": 0.526,
    "out:Running cost/(Apt SEK y)": 39874,
    "out:Running Cost over RSP/MSEK": 16.828,
    "out:LCP/MSEK": 2.15,
    "out:ROI% old": 72.72,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 15.4,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 65,
    "out:Return/kSEK/y": 342,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 22,
    "out:Payback Time": 4.545455,
    "out:DH kWh savings": 113278,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113278,
    "out:DH kr savings": 113278,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 553387,
    "out:% savings (space heating)": 12.99,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.47,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.31,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 145.3,
    "out:Primary Energy": 135.91,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.03,
    "out:CO2 Operational/m2": 49.05,
    "out:Total CO2/m2": 51.07,
    "out:Total CO2 (tons)": 262.96,
    "out:Klimatpaverkan": -26.65,
    "out:Initial Cost/MSEK": 0.573,
    "out:Running cost/(Apt SEK y)": 40450,
    "out:Running Cost over RSP/MSEK": 17.072,
    "out:LCP/MSEK": 1.86,
    "out:ROI% old": 60.7,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 54,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102980,
    "out:DH kr savings": 102980,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578267,
    "out:% savings (space heating)": 9.08,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.31,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.47,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 140.3,
    "out:Primary Energy": 131.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.03,
    "out:CO2 Operational/m2": 47.25,
    "out:Total CO2/m2": 49.27,
    "out:Total CO2 (tons)": 253.7,
    "out:Klimatpaverkan": -35.91,
    "out:Initial Cost/MSEK": 0.701,
    "out:Running cost/(Apt SEK y)": 39010,
    "out:Running Cost over RSP/MSEK": 16.464,
    "out:LCP/MSEK": 2.339,
    "out:ROI% old": 61.94,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 55,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128725,
    "out:DH kr savings": 128725,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 553387,
    "out:% savings (space heating)": 12.99,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.47,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.31,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 148.3,
    "out:Total Energy Use Post PV": 144.29,
    "out:Primary Energy": 130.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.8,
    "out:CO2 Operational/m2": 37.7,
    "out:Total CO2/m2": 46.5,
    "out:Total CO2 (tons)": 239.43,
    "out:Klimatpaverkan": -50.18,
    "out:Initial Cost/MSEK": 1.009,
    "out:Running cost/(Apt SEK y)": 40209,
    "out:Running Cost over RSP/MSEK": 16.982,
    "out:LCP/MSEK": 1.513,
    "out:ROI% old": 35.71,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 14.6,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 32,
    "out:Return/kSEK/y": 324,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 87533,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 147191,
    "out:DH kr savings": 87533,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578267,
    "out:% savings (space heating)": 9.08,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.31,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.47,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 143.3,
    "out:Total Energy Use Post PV": 139.29,
    "out:Primary Energy": 126.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.8,
    "out:CO2 Operational/m2": 35.9,
    "out:Total CO2/m2": 44.7,
    "out:Total CO2 (tons)": 230.16,
    "out:Klimatpaverkan": -59.45,
    "out:Initial Cost/MSEK": 1.138,
    "out:Running cost/(Apt SEK y)": 38769,
    "out:Running Cost over RSP/MSEK": 16.374,
    "out:LCP/MSEK": 1.992,
    "out:ROI% old": 39.3,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 18.7,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 35,
    "out:Return/kSEK/y": 402,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 113278,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172936,
    "out:DH kr savings": 113278,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 553387,
    "out:% savings (space heating)": 12.99,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.47,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.31,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 141.29,
    "out:Primary Energy": 128.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.08,
    "out:CO2 Operational/m2": 36.62,
    "out:Total CO2/m2": 45.7,
    "out:Total CO2 (tons)": 235.31,
    "out:Klimatpaverkan": -54.3,
    "out:Initial Cost/MSEK": 1.185,
    "out:Running cost/(Apt SEK y)": 39345,
    "out:Running Cost over RSP/MSEK": 16.618,
    "out:LCP/MSEK": 1.702,
    "out:ROI% old": 34.81,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 31,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162638,
    "out:DH kr savings": 102980,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578267,
    "out:% savings (space heating)": 9.08,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.31,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.47,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 136.29,
    "out:Primary Energy": 124.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.08,
    "out:CO2 Operational/m2": 34.82,
    "out:Total CO2/m2": 43.9,
    "out:Total CO2 (tons)": 226.05,
    "out:Klimatpaverkan": -63.56,
    "out:Initial Cost/MSEK": 1.313,
    "out:Running cost/(Apt SEK y)": 37906,
    "out:Running Cost over RSP/MSEK": 16.009,
    "out:LCP/MSEK": 2.182,
    "out:ROI% old": 38.02,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 34,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188383,
    "out:DH kr savings": 128725,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 553387,
    "out:% savings (space heating)": 12.99,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.47,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.31,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 148.69,
    "out:Total Energy Use Post PV": 148.69,
    "out:Primary Energy": 144.07,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.09,
    "out:CO2 Operational/m2": 49.04,
    "out:Total CO2/m2": 53.12,
    "out:Total CO2 (tons)": 273.54,
    "out:Klimatpaverkan": -16.07,
    "out:Initial Cost/MSEK": 1.051,
    "out:Running cost/(Apt SEK y)": 40903,
    "out:Running Cost over RSP/MSEK": 17.258,
    "out:LCP/MSEK": 1.195,
    "out:ROI% old": 30.53,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 10.7,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 78,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 27,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 113278,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 57743,
    "out:DH kr savings": 113278,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578267,
    "out:% savings (space heating)": 9.08,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.31,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.47,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 143.69,
    "out:Total Energy Use Post PV": 143.69,
    "out:Primary Energy": 139.84,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.09,
    "out:CO2 Operational/m2": 47.24,
    "out:Total CO2/m2": 51.32,
    "out:Total CO2 (tons)": 264.27,
    "out:Klimatpaverkan": -25.34,
    "out:Initial Cost/MSEK": 1.18,
    "out:Running cost/(Apt SEK y)": 39463,
    "out:Running Cost over RSP/MSEK": 16.65,
    "out:LCP/MSEK": 1.675,
    "out:ROI% old": 34.56,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 14.6,
    "out:Op energy cost/MSEK": 121,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 31,
    "out:Return/kSEK/y": 364,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83488,
    "out:DH kr savings": 139023,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 553387,
    "out:% savings (space heating)": 12.99,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.47,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.31,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 144.69,
    "out:Total Energy Use Post PV": 144.69,
    "out:Primary Energy": 141.44,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.37,
    "out:CO2 Operational/m2": 47.6,
    "out:Total CO2/m2": 51.97,
    "out:Total CO2 (tons)": 267.57,
    "out:Klimatpaverkan": -22.04,
    "out:Initial Cost/MSEK": 1.227,
    "out:Running cost/(Apt SEK y)": 39751,
    "out:Running Cost over RSP/MSEK": 16.771,
    "out:LCP/MSEK": 1.506,
    "out:ROI% old": 31.82,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 28,
    "out:Return/kSEK/y": 349,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 133874,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 78339,
    "out:DH kr savings": 133874,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578267,
    "out:% savings (space heating)": 9.08,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.31,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.47,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 139.69,
    "out:Total Energy Use Post PV": 139.69,
    "out:Primary Energy": 137.22,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.37,
    "out:CO2 Operational/m2": 45.8,
    "out:Total CO2/m2": 50.17,
    "out:Total CO2 (tons)": 258.31,
    "out:Klimatpaverkan": -31.3,
    "out:Initial Cost/MSEK": 1.356,
    "out:Running cost/(Apt SEK y)": 38311,
    "out:Running Cost over RSP/MSEK": 16.163,
    "out:LCP/MSEK": 1.986,
    "out:ROI% old": 35.21,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 31,
    "out:Return/kSEK/y": 427,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104084,
    "out:DH kr savings": 159619,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 553387,
    "out:% savings (space heating)": 12.99,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.47,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.31,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 148.69,
    "out:Total Energy Use Post PV": 143.84,
    "out:Primary Energy": 135.33,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.15,
    "out:CO2 Operational/m2": 40.8,
    "out:Total CO2/m2": 51.95,
    "out:Total CO2 (tons)": 267.49,
    "out:Klimatpaverkan": -22.12,
    "out:Initial Cost/MSEK": 1.663,
    "out:Running cost/(Apt SEK y)": 39758,
    "out:Running Cost over RSP/MSEK": 16.787,
    "out:LCP/MSEK": 1.054,
    "out:ROI% old": 23.34,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 14.6,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 348,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 113278,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 119570,
    "out:DH kr savings": 113278,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578267,
    "out:% savings (space heating)": 9.08,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.31,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.47,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 143.69,
    "out:Total Energy Use Post PV": 138.84,
    "out:Primary Energy": 131.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.15,
    "out:CO2 Operational/m2": 39.01,
    "out:Total CO2/m2": 50.15,
    "out:Total CO2 (tons)": 258.23,
    "out:Klimatpaverkan": -31.38,
    "out:Initial Cost/MSEK": 1.792,
    "out:Running cost/(Apt SEK y)": 38318,
    "out:Running Cost over RSP/MSEK": 16.179,
    "out:LCP/MSEK": 1.533,
    "out:ROI% old": 26.51,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 18.7,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 426,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145315,
    "out:DH kr savings": 139023,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 553387,
    "out:% savings (space heating)": 12.99,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.47,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.31,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 144.69,
    "out:Total Energy Use Post PV": 139.84,
    "out:Primary Energy": 132.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.43,
    "out:CO2 Operational/m2": 39.36,
    "out:Total CO2/m2": 50.79,
    "out:Total CO2 (tons)": 261.53,
    "out:Klimatpaverkan": -28.08,
    "out:Initial Cost/MSEK": 1.839,
    "out:Running cost/(Apt SEK y)": 38606,
    "out:Running Cost over RSP/MSEK": 16.301,
    "out:LCP/MSEK": 1.365,
    "out:ROI% old": 24.89,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 133874,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140166,
    "out:DH kr savings": 133874,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578267,
    "out:% savings (space heating)": 9.08,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.31,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.47,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 139.69,
    "out:Total Energy Use Post PV": 134.84,
    "out:Primary Energy": 128.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.43,
    "out:CO2 Operational/m2": 37.57,
    "out:Total CO2/m2": 48.99,
    "out:Total CO2 (tons)": 252.26,
    "out:Klimatpaverkan": -37.35,
    "out:Initial Cost/MSEK": 1.968,
    "out:Running cost/(Apt SEK y)": 37166,
    "out:Running Cost over RSP/MSEK": 15.692,
    "out:LCP/MSEK": 1.844,
    "out:ROI% old": 27.68,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 25,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165911,
    "out:DH kr savings": 159619,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 553387,
    "out:% savings (space heating)": 12.99,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.47,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.31,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 143.69,
    "out:Total Energy Use Post PV": 143.69,
    "out:Primary Energy": 140.57,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.09,
    "out:CO2 Operational/m2": 47.24,
    "out:Total CO2/m2": 51.32,
    "out:Total CO2 (tons)": 264.27,
    "out:Klimatpaverkan": -25.34,
    "out:Initial Cost/MSEK": 1.194,
    "out:Running cost/(Apt SEK y)": 39463,
    "out:Running Cost over RSP/MSEK": 16.65,
    "out:LCP/MSEK": 1.661,
    "out:ROI% old": 34.16,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 14.6,
    "out:Op energy cost/MSEK": 121,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 31,
    "out:Return/kSEK/y": 364,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83488,
    "out:DH kr savings": 139023,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578267,
    "out:% savings (space heating)": 9.08,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.31,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.47,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 138.69,
    "out:Total Energy Use Post PV": 138.69,
    "out:Primary Energy": 136.34,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.09,
    "out:CO2 Operational/m2": 45.44,
    "out:Total CO2/m2": 49.52,
    "out:Total CO2 (tons)": 255,
    "out:Klimatpaverkan": -34.61,
    "out:Initial Cost/MSEK": 1.322,
    "out:Running cost/(Apt SEK y)": 38023,
    "out:Running Cost over RSP/MSEK": 16.041,
    "out:LCP/MSEK": 2.14,
    "out:ROI% old": 37.41,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 18.7,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 33,
    "out:Return/kSEK/y": 442,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 164768,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109233,
    "out:DH kr savings": 164768,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 553387,
    "out:% savings (space heating)": 12.99,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.47,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.31,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 140.69,
    "out:Total Energy Use Post PV": 140.69,
    "out:Primary Energy": 138.47,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.37,
    "out:CO2 Operational/m2": 46.16,
    "out:Total CO2/m2": 50.53,
    "out:Total CO2 (tons)": 260.16,
    "out:Klimatpaverkan": -29.45,
    "out:Initial Cost/MSEK": 1.369,
    "out:Running cost/(Apt SEK y)": 38599,
    "out:Running Cost over RSP/MSEK": 16.285,
    "out:LCP/MSEK": 1.85,
    "out:ROI% old": 33.59,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 30,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98935,
    "out:DH kr savings": 154470,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578267,
    "out:% savings (space heating)": 9.08,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.31,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.47,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 135.69,
    "out:Primary Energy": 134.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.37,
    "out:CO2 Operational/m2": 44.36,
    "out:Total CO2/m2": 48.73,
    "out:Total CO2 (tons)": 250.89,
    "out:Klimatpaverkan": -38.72,
    "out:Initial Cost/MSEK": 1.498,
    "out:Running cost/(Apt SEK y)": 37159,
    "out:Running Cost over RSP/MSEK": 15.677,
    "out:LCP/MSEK": 2.33,
    "out:ROI% old": 36.5,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 33,
    "out:Return/kSEK/y": 489,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124680,
    "out:DH kr savings": 180215,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 553387,
    "out:% savings (space heating)": 12.99,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.47,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.31,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 143.69,
    "out:Total Energy Use Post PV": 138.84,
    "out:Primary Energy": 131.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.15,
    "out:CO2 Operational/m2": 39.01,
    "out:Total CO2/m2": 50.15,
    "out:Total CO2 (tons)": 258.23,
    "out:Klimatpaverkan": -31.38,
    "out:Initial Cost/MSEK": 1.806,
    "out:Running cost/(Apt SEK y)": 38318,
    "out:Running Cost over RSP/MSEK": 16.179,
    "out:LCP/MSEK": 1.52,
    "out:ROI% old": 26.31,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 18.7,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 426,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145315,
    "out:DH kr savings": 139023,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578267,
    "out:% savings (space heating)": 9.08,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.31,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.47,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 138.69,
    "out:Total Energy Use Post PV": 133.84,
    "out:Primary Energy": 127.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.15,
    "out:CO2 Operational/m2": 37.21,
    "out:Total CO2/m2": 48.35,
    "out:Total CO2 (tons)": 248.96,
    "out:Klimatpaverkan": -40.65,
    "out:Initial Cost/MSEK": 1.935,
    "out:Running cost/(Apt SEK y)": 36878,
    "out:Running Cost over RSP/MSEK": 15.571,
    "out:LCP/MSEK": 1.999,
    "out:ROI% old": 29.05,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 23.1,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 26,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 164768,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171060,
    "out:DH kr savings": 164768,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 553387,
    "out:% savings (space heating)": 12.99,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.47,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.31,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 140.69,
    "out:Total Energy Use Post PV": 135.84,
    "out:Primary Energy": 129.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.43,
    "out:CO2 Operational/m2": 37.93,
    "out:Total CO2/m2": 49.35,
    "out:Total CO2 (tons)": 254.12,
    "out:Klimatpaverkan": -35.49,
    "out:Initial Cost/MSEK": 1.981,
    "out:Running cost/(Apt SEK y)": 37454,
    "out:Running Cost over RSP/MSEK": 15.814,
    "out:LCP/MSEK": 1.709,
    "out:ROI% old": 26.61,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 473,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160762,
    "out:DH kr savings": 154470,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578267,
    "out:% savings (space heating)": 9.08,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.31,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.47,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 130.84,
    "out:Primary Energy": 125.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.43,
    "out:CO2 Operational/m2": 36.13,
    "out:Total CO2/m2": 47.55,
    "out:Total CO2 (tons)": 244.85,
    "out:Klimatpaverkan": -44.76,
    "out:Initial Cost/MSEK": 2.11,
    "out:Running cost/(Apt SEK y)": 36014,
    "out:Running Cost over RSP/MSEK": 15.206,
    "out:LCP/MSEK": 2.188,
    "out:ROI% old": 29.1,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 26,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186507,
    "out:DH kr savings": 180215,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 553387,
    "out:% savings (space heating)": 12.99,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.47,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.85,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 139.3,
    "out:Total Energy Use Post PV": 139.3,
    "out:Primary Energy": 128.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.73,
    "out:CO2 Operational/m2": 46.89,
    "out:Total CO2/m2": 51.62,
    "out:Total CO2 (tons)": 265.78,
    "out:Klimatpaverkan": -23.83,
    "out:Initial Cost/MSEK": 1.548,
    "out:Running cost/(Apt SEK y)": 38722,
    "out:Running Cost over RSP/MSEK": 16.342,
    "out:LCP/MSEK": 1.615,
    "out:ROI% old": 29.19,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 18.7,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 26,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 133874,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133874,
    "out:DH kr savings": 133874,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 503834,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.85,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.68,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 135.3,
    "out:Primary Energy": 125.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.73,
    "out:CO2 Operational/m2": 45.45,
    "out:Total CO2/m2": 50.18,
    "out:Total CO2 (tons)": 258.37,
    "out:Klimatpaverkan": -31.24,
    "out:Initial Cost/MSEK": 1.676,
    "out:Running cost/(Apt SEK y)": 37571,
    "out:Running Cost over RSP/MSEK": 15.855,
    "out:LCP/MSEK": 1.973,
    "out:ROI% old": 31.1,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 28,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154470,
    "out:DH kr savings": 154470,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 482336,
    "out:% savings (space heating)": 24.16,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.68,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.85,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 135.3,
    "out:Primary Energy": 126.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.01,
    "out:CO2 Operational/m2": 45.45,
    "out:Total CO2/m2": 50.46,
    "out:Total CO2 (tons)": 259.82,
    "out:Klimatpaverkan": -29.79,
    "out:Initial Cost/MSEK": 1.723,
    "out:Running cost/(Apt SEK y)": 37571,
    "out:Running Cost over RSP/MSEK": 15.855,
    "out:LCP/MSEK": 1.926,
    "out:ROI% old": 30.25,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 27,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154470,
    "out:DH kr savings": 154470,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 503834,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.85,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.68,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 131.3,
    "out:Primary Energy": 122.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.01,
    "out:CO2 Operational/m2": 44.01,
    "out:Total CO2/m2": 49.02,
    "out:Total CO2 (tons)": 252.4,
    "out:Klimatpaverkan": -37.21,
    "out:Initial Cost/MSEK": 1.852,
    "out:Running cost/(Apt SEK y)": 36419,
    "out:Running Cost over RSP/MSEK": 15.369,
    "out:LCP/MSEK": 2.283,
    "out:ROI% old": 31.9,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 29,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175066,
    "out:DH kr savings": 175066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 482336,
    "out:% savings (space heating)": 24.16,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.68,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.85,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 139.3,
    "out:Total Energy Use Post PV": 135.29,
    "out:Primary Energy": 121.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.79,
    "out:CO2 Operational/m2": 34.46,
    "out:Total CO2/m2": 46.25,
    "out:Total CO2 (tons)": 238.13,
    "out:Klimatpaverkan": -51.48,
    "out:Initial Cost/MSEK": 2.16,
    "out:Running cost/(Apt SEK y)": 37618,
    "out:Running Cost over RSP/MSEK": 15.888,
    "out:LCP/MSEK": 1.457,
    "out:ROI% old": 23.92,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 464,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 133874,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 193532,
    "out:DH kr savings": 133874,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 503834,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.85,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.68,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 131.29,
    "out:Primary Energy": 117.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.79,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 44.81,
    "out:Total CO2 (tons)": 230.72,
    "out:Klimatpaverkan": -58.89,
    "out:Initial Cost/MSEK": 2.288,
    "out:Running cost/(Apt SEK y)": 36466,
    "out:Running Cost over RSP/MSEK": 15.401,
    "out:LCP/MSEK": 1.815,
    "out:ROI% old": 25.62,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 23,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214128,
    "out:DH kr savings": 154470,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 482336,
    "out:% savings (space heating)": 24.16,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.68,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.85,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 131.29,
    "out:Primary Energy": 119.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.07,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 45.09,
    "out:Total CO2 (tons)": 232.17,
    "out:Klimatpaverkan": -57.44,
    "out:Initial Cost/MSEK": 2.335,
    "out:Running cost/(Apt SEK y)": 36466,
    "out:Running Cost over RSP/MSEK": 15.401,
    "out:LCP/MSEK": 1.768,
    "out:ROI% old": 25.1,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 23,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214128,
    "out:DH kr savings": 154470,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 503834,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.85,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.68,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 127.29,
    "out:Primary Energy": 115.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.07,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 43.65,
    "out:Total CO2 (tons)": 224.75,
    "out:Klimatpaverkan": -64.86,
    "out:Initial Cost/MSEK": 2.464,
    "out:Running cost/(Apt SEK y)": 35314,
    "out:Running Cost over RSP/MSEK": 14.915,
    "out:LCP/MSEK": 2.126,
    "out:ROI% old": 26.61,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234724,
    "out:DH kr savings": 175066,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 482336,
    "out:% savings (space heating)": 24.16,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.68,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.85,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 134.3,
    "out:Total Energy Use Post PV": 134.3,
    "out:Primary Energy": 125.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.73,
    "out:CO2 Operational/m2": 45.09,
    "out:Total CO2/m2": 49.82,
    "out:Total CO2 (tons)": 256.51,
    "out:Klimatpaverkan": -33.1,
    "out:Initial Cost/MSEK": 1.69,
    "out:Running cost/(Apt SEK y)": 37283,
    "out:Running Cost over RSP/MSEK": 15.734,
    "out:LCP/MSEK": 2.08,
    "out:ROI% old": 31.87,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 23.1,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 29,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159619,
    "out:DH kr savings": 159619,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 503834,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.85,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.68,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 129.3,
    "out:Primary Energy": 121.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.73,
    "out:CO2 Operational/m2": 43.29,
    "out:Total CO2/m2": 48.02,
    "out:Total CO2 (tons)": 247.24,
    "out:Klimatpaverkan": -42.37,
    "out:Initial Cost/MSEK": 1.819,
    "out:Running cost/(Apt SEK y)": 35843,
    "out:Running Cost over RSP/MSEK": 15.126,
    "out:LCP/MSEK": 2.56,
    "out:ROI% old": 34.39,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 31,
    "out:Return/kSEK/y": 560,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 185364,
    "out:DH kr savings": 185364,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 482336,
    "out:% savings (space heating)": 24.16,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.68,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.85,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 130.3,
    "out:Total Energy Use Post PV": 130.3,
    "out:Primary Energy": 123.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.01,
    "out:CO2 Operational/m2": 43.65,
    "out:Total CO2/m2": 48.66,
    "out:Total CO2 (tons)": 250.55,
    "out:Klimatpaverkan": -39.06,
    "out:Initial Cost/MSEK": 1.866,
    "out:Running cost/(Apt SEK y)": 36131,
    "out:Running Cost over RSP/MSEK": 15.247,
    "out:LCP/MSEK": 2.391,
    "out:ROI% old": 32.6,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 29,
    "out:Return/kSEK/y": 544,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180215,
    "out:DH kr savings": 180215,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 503834,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.85,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.68,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 126.3,
    "out:Primary Energy": 119.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.01,
    "out:CO2 Operational/m2": 42.21,
    "out:Total CO2/m2": 47.22,
    "out:Total CO2 (tons)": 243.13,
    "out:Klimatpaverkan": -46.48,
    "out:Initial Cost/MSEK": 1.994,
    "out:Running cost/(Apt SEK y)": 34979,
    "out:Running Cost over RSP/MSEK": 14.761,
    "out:LCP/MSEK": 2.749,
    "out:ROI% old": 33.98,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 30,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200811,
    "out:DH kr savings": 200811,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 482336,
    "out:% savings (space heating)": 24.16,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.68,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.85,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 134.3,
    "out:Total Energy Use Post PV": 130.29,
    "out:Primary Energy": 118.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.79,
    "out:CO2 Operational/m2": 32.66,
    "out:Total CO2/m2": 44.45,
    "out:Total CO2 (tons)": 228.86,
    "out:Klimatpaverkan": -60.75,
    "out:Initial Cost/MSEK": 2.302,
    "out:Running cost/(Apt SEK y)": 36178,
    "out:Running Cost over RSP/MSEK": 15.28,
    "out:LCP/MSEK": 1.923,
    "out:ROI% old": 26.22,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 542,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 219277,
    "out:DH kr savings": 159619,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 503834,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.85,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.68,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 125.29,
    "out:Primary Energy": 114.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.79,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 42.65,
    "out:Total CO2 (tons)": 219.6,
    "out:Klimatpaverkan": -70.01,
    "out:Initial Cost/MSEK": 2.431,
    "out:Running cost/(Apt SEK y)": 34738,
    "out:Running Cost over RSP/MSEK": 14.671,
    "out:LCP/MSEK": 2.402,
    "out:ROI% old": 28.4,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 25,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 245022,
    "out:DH kr savings": 185364,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 482336,
    "out:% savings (space heating)": 24.16,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.68,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.85,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 130.3,
    "out:Total Energy Use Post PV": 126.29,
    "out:Primary Energy": 116.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.07,
    "out:CO2 Operational/m2": 31.22,
    "out:Total CO2/m2": 43.29,
    "out:Total CO2 (tons)": 222.9,
    "out:Klimatpaverkan": -66.71,
    "out:Initial Cost/MSEK": 2.478,
    "out:Running cost/(Apt SEK y)": 35026,
    "out:Running Cost over RSP/MSEK": 14.793,
    "out:LCP/MSEK": 2.233,
    "out:ROI% old": 27.16,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 604,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 239873,
    "out:DH kr savings": 180215,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 503834,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.85,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.68,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 122.29,
    "out:Primary Energy": 112.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.07,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 41.85,
    "out:Total CO2 (tons)": 215.49,
    "out:Klimatpaverkan": -74.12,
    "out:Initial Cost/MSEK": 2.606,
    "out:Running cost/(Apt SEK y)": 33874,
    "out:Running Cost over RSP/MSEK": 14.307,
    "out:LCP/MSEK": 2.591,
    "out:ROI% old": 28.49,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 26,
    "out:Return/kSEK/y": 666,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260469,
    "out:DH kr savings": 200811,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 482336,
    "out:% savings (space heating)": 24.16,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.68,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.85,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 134.69,
    "out:Total Energy Use Post PV": 134.69,
    "out:Primary Energy": 131.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.08,
    "out:CO2 Operational/m2": 44,
    "out:Total CO2/m2": 51.07,
    "out:Total CO2 (tons)": 262.97,
    "out:Klimatpaverkan": -26.64,
    "out:Initial Cost/MSEK": 2.344,
    "out:Running cost/(Apt SEK y)": 36871,
    "out:Running Cost over RSP/MSEK": 15.555,
    "out:LCP/MSEK": 1.605,
    "out:ROI% old": 24.07,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 22,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 129829,
    "out:DH kr savings": 185364,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 503834,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.85,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.68,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 130.69,
    "out:Primary Energy": 127.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.08,
    "out:CO2 Operational/m2": 42.56,
    "out:Total CO2/m2": 49.63,
    "out:Total CO2 (tons)": 255.56,
    "out:Klimatpaverkan": -34.05,
    "out:Initial Cost/MSEK": 2.473,
    "out:Running cost/(Apt SEK y)": 35719,
    "out:Running Cost over RSP/MSEK": 15.068,
    "out:LCP/MSEK": 1.963,
    "out:ROI% old": 25.62,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 23,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 150425,
    "out:DH kr savings": 205960,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 482336,
    "out:% savings (space heating)": 24.16,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.68,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.85,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 130.69,
    "out:Primary Energy": 128.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.36,
    "out:CO2 Operational/m2": 42.56,
    "out:Total CO2/m2": 49.91,
    "out:Total CO2 (tons)": 257.01,
    "out:Klimatpaverkan": -32.6,
    "out:Initial Cost/MSEK": 2.52,
    "out:Running cost/(Apt SEK y)": 35719,
    "out:Running Cost over RSP/MSEK": 15.068,
    "out:LCP/MSEK": 1.916,
    "out:ROI% old": 25.15,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 22,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150425,
    "out:DH kr savings": 205960,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 503834,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.85,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.68,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 126.69,
    "out:Primary Energy": 125.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.36,
    "out:CO2 Operational/m2": 41.12,
    "out:Total CO2/m2": 48.47,
    "out:Total CO2 (tons)": 249.6,
    "out:Klimatpaverkan": -40.01,
    "out:Initial Cost/MSEK": 2.649,
    "out:Running cost/(Apt SEK y)": 34567,
    "out:Running Cost over RSP/MSEK": 14.582,
    "out:LCP/MSEK": 2.274,
    "out:ROI% old": 26.55,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 24,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171021,
    "out:DH kr savings": 226556,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 482336,
    "out:% savings (space heating)": 24.16,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.68,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.85,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 134.69,
    "out:Total Energy Use Post PV": 129.84,
    "out:Primary Energy": 122.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.13,
    "out:CO2 Operational/m2": 35.77,
    "out:Total CO2/m2": 49.9,
    "out:Total CO2 (tons)": 256.93,
    "out:Klimatpaverkan": -32.68,
    "out:Initial Cost/MSEK": 2.956,
    "out:Running cost/(Apt SEK y)": 35726,
    "out:Running Cost over RSP/MSEK": 15.084,
    "out:LCP/MSEK": 1.464,
    "out:ROI% old": 21.36,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191656,
    "out:DH kr savings": 185364,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 503834,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.85,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.68,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 125.84,
    "out:Primary Energy": 119.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.13,
    "out:CO2 Operational/m2": 34.33,
    "out:Total CO2/m2": 48.46,
    "out:Total CO2 (tons)": 249.52,
    "out:Klimatpaverkan": -40.09,
    "out:Initial Cost/MSEK": 3.085,
    "out:Running cost/(Apt SEK y)": 34574,
    "out:Running Cost over RSP/MSEK": 14.598,
    "out:LCP/MSEK": 1.821,
    "out:ROI% old": 22.72,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212252,
    "out:DH kr savings": 205960,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 482336,
    "out:% savings (space heating)": 24.16,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.68,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.85,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 125.84,
    "out:Primary Energy": 120.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.42,
    "out:CO2 Operational/m2": 34.33,
    "out:Total CO2/m2": 48.74,
    "out:Total CO2 (tons)": 250.97,
    "out:Klimatpaverkan": -38.64,
    "out:Initial Cost/MSEK": 3.132,
    "out:Running cost/(Apt SEK y)": 34574,
    "out:Running Cost over RSP/MSEK": 14.598,
    "out:LCP/MSEK": 1.774,
    "out:ROI% old": 22.38,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212252,
    "out:DH kr savings": 205960,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 503834,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.85,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.68,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 121.84,
    "out:Primary Energy": 116.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.42,
    "out:CO2 Operational/m2": 32.89,
    "out:Total CO2/m2": 47.3,
    "out:Total CO2 (tons)": 243.55,
    "out:Klimatpaverkan": -46.06,
    "out:Initial Cost/MSEK": 3.261,
    "out:Running cost/(Apt SEK y)": 33423,
    "out:Running Cost over RSP/MSEK": 14.111,
    "out:LCP/MSEK": 2.132,
    "out:ROI% old": 23.63,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 232848,
    "out:DH kr savings": 226556,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 482336,
    "out:% savings (space heating)": 24.16,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.68,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.85,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 128.69,
    "out:Total Energy Use Post PV": 128.69,
    "out:Primary Energy": 127.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.08,
    "out:CO2 Operational/m2": 41.84,
    "out:Total CO2/m2": 48.91,
    "out:Total CO2 (tons)": 251.85,
    "out:Klimatpaverkan": -37.76,
    "out:Initial Cost/MSEK": 2.487,
    "out:Running cost/(Apt SEK y)": 35143,
    "out:Running Cost over RSP/MSEK": 14.825,
    "out:LCP/MSEK": 2.192,
    "out:ROI% old": 26.88,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 24,
    "out:Return/kSEK/y": 598,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160723,
    "out:DH kr savings": 216258,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 503834,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.85,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.68,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 124.69,
    "out:Primary Energy": 124.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.08,
    "out:CO2 Operational/m2": 40.4,
    "out:Total CO2/m2": 47.47,
    "out:Total CO2 (tons)": 244.44,
    "out:Klimatpaverkan": -45.17,
    "out:Initial Cost/MSEK": 2.616,
    "out:Running cost/(Apt SEK y)": 33992,
    "out:Running Cost over RSP/MSEK": 14.339,
    "out:LCP/MSEK": 2.55,
    "out:ROI% old": 28.21,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 25,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181319,
    "out:DH kr savings": 236854,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 482336,
    "out:% savings (space heating)": 24.16,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.68,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.85,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 125.69,
    "out:Primary Energy": 125.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.36,
    "out:CO2 Operational/m2": 40.76,
    "out:Total CO2/m2": 48.11,
    "out:Total CO2 (tons)": 247.74,
    "out:Klimatpaverkan": -41.87,
    "out:Initial Cost/MSEK": 2.663,
    "out:Running cost/(Apt SEK y)": 34280,
    "out:Running Cost over RSP/MSEK": 14.46,
    "out:LCP/MSEK": 2.381,
    "out:ROI% old": 27.06,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 24,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176170,
    "out:DH kr savings": 231705,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 503834,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.85,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.68,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 121.69,
    "out:Primary Energy": 122.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.36,
    "out:CO2 Operational/m2": 39.32,
    "out:Total CO2/m2": 46.67,
    "out:Total CO2 (tons)": 240.33,
    "out:Klimatpaverkan": -49.28,
    "out:Initial Cost/MSEK": 2.791,
    "out:Running cost/(Apt SEK y)": 33128,
    "out:Running Cost over RSP/MSEK": 13.974,
    "out:LCP/MSEK": 2.739,
    "out:ROI% old": 28.31,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 25,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 196766,
    "out:DH kr savings": 252301,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 482336,
    "out:% savings (space heating)": 24.16,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.68,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.85,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 128.69,
    "out:Total Energy Use Post PV": 123.84,
    "out:Primary Energy": 119.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.13,
    "out:CO2 Operational/m2": 33.61,
    "out:Total CO2/m2": 47.74,
    "out:Total CO2 (tons)": 245.81,
    "out:Klimatpaverkan": -43.8,
    "out:Initial Cost/MSEK": 3.099,
    "out:Running cost/(Apt SEK y)": 33998,
    "out:Running Cost over RSP/MSEK": 14.355,
    "out:LCP/MSEK": 2.051,
    "out:ROI% old": 23.74,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 659,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 222550,
    "out:DH kr savings": 216258,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 503834,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.85,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.68,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 119.84,
    "out:Primary Energy": 115.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.13,
    "out:CO2 Operational/m2": 32.17,
    "out:Total CO2/m2": 46.3,
    "out:Total CO2 (tons)": 238.4,
    "out:Klimatpaverkan": -51.21,
    "out:Initial Cost/MSEK": 3.228,
    "out:Running cost/(Apt SEK y)": 32847,
    "out:Running Cost over RSP/MSEK": 13.868,
    "out:LCP/MSEK": 2.409,
    "out:ROI% old": 24.95,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243146,
    "out:DH kr savings": 236854,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 482336,
    "out:% savings (space heating)": 24.16,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.68,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.85,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 120.84,
    "out:Primary Energy": 117.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.42,
    "out:CO2 Operational/m2": 32.53,
    "out:Total CO2/m2": 46.94,
    "out:Total CO2 (tons)": 241.7,
    "out:Klimatpaverkan": -47.91,
    "out:Initial Cost/MSEK": 3.275,
    "out:Running cost/(Apt SEK y)": 33135,
    "out:Running Cost over RSP/MSEK": 13.99,
    "out:LCP/MSEK": 2.24,
    "out:ROI% old": 24.06,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 237997,
    "out:DH kr savings": 231705,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 503834,
    "out:% savings (space heating)": 20.78,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.85,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.68,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 116.84,
    "out:Primary Energy": 113.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.42,
    "out:CO2 Operational/m2": 31.09,
    "out:Total CO2/m2": 45.5,
    "out:Total CO2 (tons)": 234.29,
    "out:Klimatpaverkan": -55.32,
    "out:Initial Cost/MSEK": 3.403,
    "out:Running cost/(Apt SEK y)": 31983,
    "out:Running Cost over RSP/MSEK": 13.503,
    "out:LCP/MSEK": 2.598,
    "out:ROI% old": 25.19,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.88,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 23,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258593,
    "out:DH kr savings": 252301,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 482336,
    "out:% savings (space heating)": 24.16,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.68,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.38,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 134.3,
    "out:Total Energy Use Post PV": 134.3,
    "out:Primary Energy": 124.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.29,
    "out:CO2 Operational/m2": 45.09,
    "out:Total CO2/m2": 59.38,
    "out:Total CO2 (tons)": 305.75,
    "out:Klimatpaverkan": 16.14,
    "out:Initial Cost/MSEK": 4.714,
    "out:Running cost/(Apt SEK y)": 37283,
    "out:Running Cost over RSP/MSEK": 15.734,
    "out:LCP/MSEK": -0.943,
    "out:ROI% old": 11.43,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 23.1,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159619,
    "out:DH kr savings": 159619,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 480807,
    "out:% savings (space heating)": 24.4,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.38,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.41,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 131.3,
    "out:Primary Energy": 121.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.29,
    "out:CO2 Operational/m2": 44.01,
    "out:Total CO2/m2": 58.3,
    "out:Total CO2 (tons)": 300.19,
    "out:Klimatpaverkan": 10.58,
    "out:Initial Cost/MSEK": 4.843,
    "out:Running cost/(Apt SEK y)": 36419,
    "out:Running Cost over RSP/MSEK": 15.369,
    "out:LCP/MSEK": -0.707,
    "out:ROI% old": 12.2,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175066,
    "out:DH kr savings": 175066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 460356,
    "out:% savings (space heating)": 27.62,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.41,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.38,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 130.3,
    "out:Total Energy Use Post PV": 130.3,
    "out:Primary Energy": 122.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.58,
    "out:CO2 Operational/m2": 43.65,
    "out:Total CO2/m2": 58.22,
    "out:Total CO2 (tons)": 299.78,
    "out:Klimatpaverkan": 10.17,
    "out:Initial Cost/MSEK": 4.889,
    "out:Running cost/(Apt SEK y)": 36131,
    "out:Running Cost over RSP/MSEK": 15.247,
    "out:LCP/MSEK": -0.632,
    "out:ROI% old": 12.44,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 544,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180215,
    "out:DH kr savings": 180215,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 480807,
    "out:% savings (space heating)": 24.4,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.38,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.41,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 126.3,
    "out:Primary Energy": 118.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.58,
    "out:CO2 Operational/m2": 42.21,
    "out:Total CO2/m2": 56.78,
    "out:Total CO2 (tons)": 292.37,
    "out:Klimatpaverkan": 2.76,
    "out:Initial Cost/MSEK": 5.018,
    "out:Running cost/(Apt SEK y)": 34979,
    "out:Running Cost over RSP/MSEK": 14.761,
    "out:LCP/MSEK": -0.275,
    "out:ROI% old": 13.5,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200811,
    "out:DH kr savings": 200811,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 460356,
    "out:% savings (space heating)": 27.62,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.41,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.38,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 134.3,
    "out:Total Energy Use Post PV": 130.29,
    "out:Primary Energy": 117.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.35,
    "out:CO2 Operational/m2": 32.66,
    "out:Total CO2/m2": 54.01,
    "out:Total CO2 (tons)": 278.1,
    "out:Klimatpaverkan": -11.51,
    "out:Initial Cost/MSEK": 5.326,
    "out:Running cost/(Apt SEK y)": 36178,
    "out:Running Cost over RSP/MSEK": 15.28,
    "out:LCP/MSEK": -1.101,
    "out:ROI% old": 11.33,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 542,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 219277,
    "out:DH kr savings": 159619,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 480807,
    "out:% savings (space heating)": 24.4,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.38,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.41,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 127.29,
    "out:Primary Energy": 114.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.35,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 52.93,
    "out:Total CO2 (tons)": 272.54,
    "out:Klimatpaverkan": -17.07,
    "out:Initial Cost/MSEK": 5.455,
    "out:Running cost/(Apt SEK y)": 35314,
    "out:Running Cost over RSP/MSEK": 14.915,
    "out:LCP/MSEK": -0.865,
    "out:ROI% old": 12.02,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234724,
    "out:DH kr savings": 175066,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 460356,
    "out:% savings (space heating)": 27.62,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.41,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.38,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 130.3,
    "out:Total Energy Use Post PV": 126.29,
    "out:Primary Energy": 115.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.63,
    "out:CO2 Operational/m2": 31.22,
    "out:Total CO2/m2": 52.85,
    "out:Total CO2 (tons)": 272.13,
    "out:Klimatpaverkan": -17.48,
    "out:Initial Cost/MSEK": 5.501,
    "out:Running cost/(Apt SEK y)": 35026,
    "out:Running Cost over RSP/MSEK": 14.793,
    "out:LCP/MSEK": -0.79,
    "out:ROI% old": 12.23,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 604,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 239873,
    "out:DH kr savings": 180215,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 480807,
    "out:% savings (space heating)": 24.4,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.38,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.41,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 122.29,
    "out:Primary Energy": 111.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.63,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 51.41,
    "out:Total CO2 (tons)": 264.72,
    "out:Klimatpaverkan": -24.89,
    "out:Initial Cost/MSEK": 5.63,
    "out:Running cost/(Apt SEK y)": 33874,
    "out:Running Cost over RSP/MSEK": 14.307,
    "out:LCP/MSEK": -0.432,
    "out:ROI% old": 13.19,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 666,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260469,
    "out:DH kr savings": 200811,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 460356,
    "out:% savings (space heating)": 27.62,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.41,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.38,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 129.3,
    "out:Primary Energy": 121.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.29,
    "out:CO2 Operational/m2": 43.29,
    "out:Total CO2/m2": 57.58,
    "out:Total CO2 (tons)": 296.48,
    "out:Klimatpaverkan": 6.87,
    "out:Initial Cost/MSEK": 4.856,
    "out:Running cost/(Apt SEK y)": 35843,
    "out:Running Cost over RSP/MSEK": 15.126,
    "out:LCP/MSEK": -0.478,
    "out:ROI% old": 12.88,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 560,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185364,
    "out:DH kr savings": 185364,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 480807,
    "out:% savings (space heating)": 24.4,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.38,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.41,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 125.3,
    "out:Primary Energy": 117.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.29,
    "out:CO2 Operational/m2": 41.85,
    "out:Total CO2/m2": 56.14,
    "out:Total CO2 (tons)": 289.07,
    "out:Klimatpaverkan": -0.54,
    "out:Initial Cost/MSEK": 4.985,
    "out:Running cost/(Apt SEK y)": 34691,
    "out:Running Cost over RSP/MSEK": 14.639,
    "out:LCP/MSEK": -0.12,
    "out:ROI% old": 13.94,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205960,
    "out:DH kr savings": 205960,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 460356,
    "out:% savings (space heating)": 27.62,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.41,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.38,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 126.3,
    "out:Primary Energy": 119.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.58,
    "out:CO2 Operational/m2": 42.21,
    "out:Total CO2/m2": 56.78,
    "out:Total CO2 (tons)": 292.37,
    "out:Klimatpaverkan": 2.76,
    "out:Initial Cost/MSEK": 5.032,
    "out:Running cost/(Apt SEK y)": 34979,
    "out:Running Cost over RSP/MSEK": 14.761,
    "out:LCP/MSEK": -0.288,
    "out:ROI% old": 13.47,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 200811,
    "out:DH kr savings": 200811,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 480807,
    "out:% savings (space heating)": 24.4,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.38,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.41,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 122.3,
    "out:Total Energy Use Post PV": 122.3,
    "out:Primary Energy": 115.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.58,
    "out:CO2 Operational/m2": 40.77,
    "out:Total CO2/m2": 55.34,
    "out:Total CO2 (tons)": 284.96,
    "out:Klimatpaverkan": -4.65,
    "out:Initial Cost/MSEK": 5.161,
    "out:Running cost/(Apt SEK y)": 33827,
    "out:Running Cost over RSP/MSEK": 14.274,
    "out:LCP/MSEK": 0.069,
    "out:ROI% old": 14.48,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 669,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221407,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221407,
    "out:DH kr savings": 221407,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 460356,
    "out:% savings (space heating)": 27.62,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.41,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00002200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.38,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 125.29,
    "out:Primary Energy": 114.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.35,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 52.21,
    "out:Total CO2 (tons)": 268.83,
    "out:Klimatpaverkan": -20.78,
    "out:Initial Cost/MSEK": 5.468,
    "out:Running cost/(Apt SEK y)": 34738,
    "out:Running Cost over RSP/MSEK": 14.671,
    "out:LCP/MSEK": -0.636,
    "out:ROI% old": 12.63,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245022,
    "out:DH kr savings": 185364,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 480807,
    "out:% savings (space heating)": 24.4,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.38,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.41,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 121.29,
    "out:Primary Energy": 110.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.35,
    "out:CO2 Operational/m2": 29.42,
    "out:Total CO2/m2": 50.77,
    "out:Total CO2 (tons)": 261.42,
    "out:Klimatpaverkan": -28.19,
    "out:Initial Cost/MSEK": 5.597,
    "out:Running cost/(Apt SEK y)": 33586,
    "out:Running Cost over RSP/MSEK": 14.185,
    "out:LCP/MSEK": -0.278,
    "out:ROI% old": 13.58,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 265618,
    "out:DH kr savings": 205960,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 460356,
    "out:% savings (space heating)": 27.62,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.41,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.38,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 122.29,
    "out:Primary Energy": 112.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.63,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 51.41,
    "out:Total CO2 (tons)": 264.72,
    "out:Klimatpaverkan": -24.89,
    "out:Initial Cost/MSEK": 5.644,
    "out:Running cost/(Apt SEK y)": 33874,
    "out:Running Cost over RSP/MSEK": 14.307,
    "out:LCP/MSEK": -0.446,
    "out:ROI% old": 13.16,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 666,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 260469,
    "out:DH kr savings": 200811,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 480807,
    "out:% savings (space heating)": 24.4,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.38,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.41,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 122.3,
    "out:Total Energy Use Post PV": 118.29,
    "out:Primary Energy": 108.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.63,
    "out:CO2 Operational/m2": 28.34,
    "out:Total CO2/m2": 49.97,
    "out:Total CO2 (tons)": 257.31,
    "out:Klimatpaverkan": -32.3,
    "out:Initial Cost/MSEK": 5.773,
    "out:Running cost/(Apt SEK y)": 32722,
    "out:Running Cost over RSP/MSEK": 13.82,
    "out:LCP/MSEK": -0.088,
    "out:ROI% old": 14.07,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 728,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 221407,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281065,
    "out:DH kr savings": 221407,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 460356,
    "out:% savings (space heating)": 27.62,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.41,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00002201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.38,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 129.69,
    "out:Total Energy Use Post PV": 129.69,
    "out:Primary Energy": 127.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.64,
    "out:CO2 Operational/m2": 42.2,
    "out:Total CO2/m2": 58.84,
    "out:Total CO2 (tons)": 302.94,
    "out:Klimatpaverkan": 13.33,
    "out:Initial Cost/MSEK": 5.511,
    "out:Running cost/(Apt SEK y)": 35431,
    "out:Running Cost over RSP/MSEK": 14.947,
    "out:LCP/MSEK": -0.953,
    "out:ROI% old": 11.81,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155574,
    "out:DH kr savings": 211109,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 480807,
    "out:% savings (space heating)": 24.4,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.38,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.41,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 125.69,
    "out:Primary Energy": 124.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.64,
    "out:CO2 Operational/m2": 40.76,
    "out:Total CO2/m2": 57.4,
    "out:Total CO2 (tons)": 295.53,
    "out:Klimatpaverkan": 5.92,
    "out:Initial Cost/MSEK": 5.639,
    "out:Running cost/(Apt SEK y)": 34280,
    "out:Running Cost over RSP/MSEK": 14.46,
    "out:LCP/MSEK": -0.595,
    "out:ROI% old": 12.78,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176170,
    "out:DH kr savings": 231705,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 460356,
    "out:% savings (space heating)": 27.62,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.41,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.38,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 125.69,
    "out:Primary Energy": 124.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.92,
    "out:CO2 Operational/m2": 40.76,
    "out:Total CO2/m2": 57.68,
    "out:Total CO2 (tons)": 296.98,
    "out:Klimatpaverkan": 7.37,
    "out:Initial Cost/MSEK": 5.686,
    "out:Running cost/(Apt SEK y)": 34280,
    "out:Running Cost over RSP/MSEK": 14.46,
    "out:LCP/MSEK": -0.642,
    "out:ROI% old": 12.67,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176170,
    "out:DH kr savings": 231705,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 480807,
    "out:% savings (space heating)": 24.4,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.38,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.41,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 121.69,
    "out:Primary Energy": 121.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.92,
    "out:CO2 Operational/m2": 39.32,
    "out:Total CO2/m2": 56.24,
    "out:Total CO2 (tons)": 289.56,
    "out:Klimatpaverkan": -0.05,
    "out:Initial Cost/MSEK": 5.815,
    "out:Running cost/(Apt SEK y)": 33128,
    "out:Running Cost over RSP/MSEK": 13.974,
    "out:LCP/MSEK": -0.284,
    "out:ROI% old": 13.59,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 196766,
    "out:DH kr savings": 252301,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 460356,
    "out:% savings (space heating)": 27.62,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.41,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.38,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 129.69,
    "out:Total Energy Use Post PV": 124.84,
    "out:Primary Energy": 118.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.7,
    "out:CO2 Operational/m2": 33.97,
    "out:Total CO2/m2": 57.66,
    "out:Total CO2 (tons)": 296.9,
    "out:Klimatpaverkan": 7.29,
    "out:Initial Cost/MSEK": 6.123,
    "out:Running cost/(Apt SEK y)": 34286,
    "out:Running Cost over RSP/MSEK": 14.476,
    "out:LCP/MSEK": -1.095,
    "out:ROI% old": 11.73,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217401,
    "out:DH kr savings": 211109,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 480807,
    "out:% savings (space heating)": 24.4,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.38,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.41,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 120.84,
    "out:Primary Energy": 115.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.7,
    "out:CO2 Operational/m2": 32.53,
    "out:Total CO2/m2": 56.22,
    "out:Total CO2 (tons)": 289.48,
    "out:Klimatpaverkan": -0.13,
    "out:Initial Cost/MSEK": 6.251,
    "out:Running cost/(Apt SEK y)": 33135,
    "out:Running Cost over RSP/MSEK": 13.99,
    "out:LCP/MSEK": -0.737,
    "out:ROI% old": 12.6,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 237997,
    "out:DH kr savings": 231705,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 460356,
    "out:% savings (space heating)": 27.62,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.41,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.38,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 120.84,
    "out:Primary Energy": 116.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.98,
    "out:CO2 Operational/m2": 32.53,
    "out:Total CO2/m2": 56.5,
    "out:Total CO2 (tons)": 290.94,
    "out:Klimatpaverkan": 1.33,
    "out:Initial Cost/MSEK": 6.298,
    "out:Running cost/(Apt SEK y)": 33135,
    "out:Running Cost over RSP/MSEK": 13.99,
    "out:LCP/MSEK": -0.784,
    "out:ROI% old": 12.51,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 237997,
    "out:DH kr savings": 231705,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 480807,
    "out:% savings (space heating)": 24.4,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.38,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.41,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 116.84,
    "out:Primary Energy": 112.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.98,
    "out:CO2 Operational/m2": 31.09,
    "out:Total CO2/m2": 55.06,
    "out:Total CO2 (tons)": 283.52,
    "out:Klimatpaverkan": -6.09,
    "out:Initial Cost/MSEK": 6.427,
    "out:Running cost/(Apt SEK y)": 31983,
    "out:Running Cost over RSP/MSEK": 13.503,
    "out:LCP/MSEK": -0.426,
    "out:ROI% old": 13.34,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258593,
    "out:DH kr savings": 252301,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 460356,
    "out:% savings (space heating)": 27.62,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.41,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.38,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 124.69,
    "out:Primary Energy": 124.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.64,
    "out:CO2 Operational/m2": 40.4,
    "out:Total CO2/m2": 57.04,
    "out:Total CO2 (tons)": 293.67,
    "out:Klimatpaverkan": 4.06,
    "out:Initial Cost/MSEK": 5.653,
    "out:Running cost/(Apt SEK y)": 33992,
    "out:Running Cost over RSP/MSEK": 14.339,
    "out:LCP/MSEK": -0.488,
    "out:ROI% old": 13.05,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181319,
    "out:DH kr savings": 236854,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 480807,
    "out:% savings (space heating)": 24.4,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.38,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.41,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 120.69,
    "out:Primary Energy": 120.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.64,
    "out:CO2 Operational/m2": 38.96,
    "out:Total CO2/m2": 55.6,
    "out:Total CO2 (tons)": 286.26,
    "out:Klimatpaverkan": -3.35,
    "out:Initial Cost/MSEK": 5.782,
    "out:Running cost/(Apt SEK y)": 32840,
    "out:Running Cost over RSP/MSEK": 13.852,
    "out:LCP/MSEK": -0.13,
    "out:ROI% old": 13.97,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201915,
    "out:DH kr savings": 257450,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 460356,
    "out:% savings (space heating)": 27.62,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.41,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.38,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 121.69,
    "out:Primary Energy": 121.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.92,
    "out:CO2 Operational/m2": 39.32,
    "out:Total CO2/m2": 56.24,
    "out:Total CO2 (tons)": 289.56,
    "out:Klimatpaverkan": -0.05,
    "out:Initial Cost/MSEK": 5.829,
    "out:Running cost/(Apt SEK y)": 33128,
    "out:Running Cost over RSP/MSEK": 13.974,
    "out:LCP/MSEK": -0.298,
    "out:ROI% old": 13.55,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196766,
    "out:DH kr savings": 252301,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 480807,
    "out:% savings (space heating)": 24.4,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.38,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.41,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 117.69,
    "out:Total Energy Use Post PV": 117.69,
    "out:Primary Energy": 118.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.92,
    "out:CO2 Operational/m2": 37.88,
    "out:Total CO2/m2": 54.8,
    "out:Total CO2 (tons)": 282.15,
    "out:Klimatpaverkan": -7.46,
    "out:Initial Cost/MSEK": 5.957,
    "out:Running cost/(Apt SEK y)": 31976,
    "out:Running Cost over RSP/MSEK": 13.487,
    "out:LCP/MSEK": 0.06,
    "out:ROI% old": 14.43,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 769,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217362,
    "out:DH kr savings": 272897,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 460356,
    "out:% savings (space heating)": 27.62,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.41,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00002201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.38,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 119.84,
    "out:Primary Energy": 115.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.7,
    "out:CO2 Operational/m2": 32.17,
    "out:Total CO2/m2": 55.86,
    "out:Total CO2 (tons)": 287.63,
    "out:Klimatpaverkan": -1.98,
    "out:Initial Cost/MSEK": 6.265,
    "out:Running cost/(Apt SEK y)": 32847,
    "out:Running Cost over RSP/MSEK": 13.868,
    "out:LCP/MSEK": -0.629,
    "out:ROI% old": 12.85,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 243146,
    "out:DH kr savings": 236854,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 480807,
    "out:% savings (space heating)": 24.4,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.38,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.41,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 115.84,
    "out:Primary Energy": 111.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.7,
    "out:CO2 Operational/m2": 30.73,
    "out:Total CO2/m2": 54.42,
    "out:Total CO2 (tons)": 280.22,
    "out:Klimatpaverkan": -9.39,
    "out:Initial Cost/MSEK": 6.394,
    "out:Running cost/(Apt SEK y)": 31695,
    "out:Running Cost over RSP/MSEK": 13.381,
    "out:LCP/MSEK": -0.271,
    "out:ROI% old": 13.68,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 263742,
    "out:DH kr savings": 257450,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 460356,
    "out:% savings (space heating)": 27.62,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.41,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.38,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 116.84,
    "out:Primary Energy": 113.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.98,
    "out:CO2 Operational/m2": 31.09,
    "out:Total CO2/m2": 55.06,
    "out:Total CO2 (tons)": 283.52,
    "out:Klimatpaverkan": -6.09,
    "out:Initial Cost/MSEK": 6.441,
    "out:Running cost/(Apt SEK y)": 31983,
    "out:Running Cost over RSP/MSEK": 13.503,
    "out:LCP/MSEK": -0.44,
    "out:ROI% old": 13.31,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 258593,
    "out:DH kr savings": 252301,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 480807,
    "out:% savings (space heating)": 24.4,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.38,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00002201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.41,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 117.69,
    "out:Total Energy Use Post PV": 112.84,
    "out:Primary Energy": 109.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.98,
    "out:CO2 Operational/m2": 29.65,
    "out:Total CO2/m2": 53.62,
    "out:Total CO2 (tons)": 276.11,
    "out:Klimatpaverkan": -13.5,
    "out:Initial Cost/MSEK": 6.57,
    "out:Running cost/(Apt SEK y)": 30831,
    "out:Running Cost over RSP/MSEK": 13.017,
    "out:LCP/MSEK": -0.082,
    "out:ROI% old": 14.11,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 830,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279189,
    "out:DH kr savings": 272897,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 460356,
    "out:% savings (space heating)": 27.62,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.41,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 165.3,
    "out:Total Energy Use Post PV": 165.3,
    "out:Primary Energy": 151.32,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 56.25,
    "out:Total CO2/m2": 56.25,
    "out:Total CO2 (tons)": 289.61,
    "out:Klimatpaverkan": 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 46209,
    "out:Running Cost over RSP/MSEK": 19.504,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 0,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 143,
    "out:El price/MSEK": 87,
    "out:Heating price/MSEK": 48,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 7,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 159.3,
    "out:Total Energy Use Post PV": 159.3,
    "out:Primary Energy": 146.64,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 54.09,
    "out:Total CO2/m2": 54.09,
    "out:Total CO2 (tons)": 278.49,
    "out:Klimatpaverkan": -11.12,
    "out:Initial Cost/MSEK": 0.129,
    "out:Running cost/(Apt SEK y)": 44482,
    "out:Running Cost over RSP/MSEK": 18.774,
    "out:LCP/MSEK": 0.601,
    "out:ROI% old": 80.99,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 3.8,
    "out:Op energy cost/MSEK": 137,
    "out:El price/MSEK": 84,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 72,
    "out:Return/kSEK/y": 93,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 24,
    "out:Payback Time": 4.166667,
    "out:DH kWh savings": 30894,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 30894,
    "out:DH kr savings": 30894,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 161.3,
    "out:Total Energy Use Post PV": 161.3,
    "out:Primary Energy": 148.69,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.28,
    "out:CO2 Operational/m2": 54.81,
    "out:Total CO2/m2": 55.09,
    "out:Total CO2 (tons)": 283.64,
    "out:Klimatpaverkan": -5.97,
    "out:Initial Cost/MSEK": 0.176,
    "out:Running cost/(Apt SEK y)": 45058,
    "out:Running Cost over RSP/MSEK": 19.018,
    "out:LCP/MSEK": 0.311,
    "out:ROI% old": 39.57,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 2.5,
    "out:Op energy cost/MSEK": 139,
    "out:El price/MSEK": 85,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 35,
    "out:Return/kSEK/y": 62,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 20596,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 20596,
    "out:DH kr savings": 20596,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 155.3,
    "out:Total Energy Use Post PV": 155.3,
    "out:Primary Energy": 144.02,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.28,
    "out:CO2 Operational/m2": 52.65,
    "out:Total CO2/m2": 52.93,
    "out:Total CO2 (tons)": 272.52,
    "out:Klimatpaverkan": -17.09,
    "out:Initial Cost/MSEK": 0.304,
    "out:Running cost/(Apt SEK y)": 43330,
    "out:Running Cost over RSP/MSEK": 18.288,
    "out:LCP/MSEK": 0.912,
    "out:ROI% old": 57.09,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 134,
    "out:El price/MSEK": 82,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 51,
    "out:Return/kSEK/y": 156,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 17,
    "out:Payback Time": 5.882353,
    "out:DH kWh savings": 51490,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 51490,
    "out:DH kr savings": 51490,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 165.3,
    "out:Total Energy Use Post PV": 161.29,
    "out:Primary Energy": 144.1,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.06,
    "out:CO2 Operational/m2": 43.82,
    "out:Total CO2/m2": 50.88,
    "out:Total CO2 (tons)": 261.96,
    "out:Klimatpaverkan": -27.65,
    "out:Initial Cost/MSEK": 0.612,
    "out:Running cost/(Apt SEK y)": 45105,
    "out:Running Cost over RSP/MSEK": 19.05,
    "out:LCP/MSEK": -0.158,
    "out:ROI% old": 10.6,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 2.5,
    "out:Op energy cost/MSEK": 140,
    "out:El price/MSEK": 84,
    "out:Heating price/MSEK": 48,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 60,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 59658,
    "out:DH kr savings": 0,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 159.3,
    "out:Total Energy Use Post PV": 155.29,
    "out:Primary Energy": 139.42,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.06,
    "out:CO2 Operational/m2": 41.66,
    "out:Total CO2/m2": 48.72,
    "out:Total CO2 (tons)": 250.84,
    "out:Klimatpaverkan": -38.77,
    "out:Initial Cost/MSEK": 0.741,
    "out:Running cost/(Apt SEK y)": 43377,
    "out:Running Cost over RSP/MSEK": 18.32,
    "out:LCP/MSEK": 0.443,
    "out:ROI% old": 22.83,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 134,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 153,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 30894,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 90552,
    "out:DH kr savings": 30894,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 161.3,
    "out:Total Energy Use Post PV": 157.29,
    "out:Primary Energy": 141.47,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.34,
    "out:CO2 Operational/m2": 42.38,
    "out:Total CO2/m2": 49.72,
    "out:Total CO2 (tons)": 255.99,
    "out:Klimatpaverkan": -33.62,
    "out:Initial Cost/MSEK": 0.788,
    "out:Running cost/(Apt SEK y)": 43953,
    "out:Running Cost over RSP/MSEK": 18.564,
    "out:LCP/MSEK": 0.153,
    "out:ROI% old": 17.06,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 5.1,
    "out:Op energy cost/MSEK": 136,
    "out:El price/MSEK": 82,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 122,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 20596,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 80254,
    "out:DH kr savings": 20596,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 155.3,
    "out:Total Energy Use Post PV": 151.29,
    "out:Primary Energy": 136.8,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.34,
    "out:CO2 Operational/m2": 40.22,
    "out:Total CO2/m2": 47.56,
    "out:Total CO2 (tons)": 244.87,
    "out:Klimatpaverkan": -44.74,
    "out:Initial Cost/MSEK": 0.916,
    "out:Running cost/(Apt SEK y)": 42225,
    "out:Running Cost over RSP/MSEK": 17.834,
    "out:LCP/MSEK": 0.754,
    "out:ROI% old": 26.04,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 9.3,
    "out:Op energy cost/MSEK": 131,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 23,
    "out:Return/kSEK/y": 215,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 51490,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111148,
    "out:DH kr savings": 51490,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 159.3,
    "out:Total Energy Use Post PV": 159.3,
    "out:Primary Energy": 147.82,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 54.09,
    "out:Total CO2/m2": 54.09,
    "out:Total CO2 (tons)": 278.49,
    "out:Klimatpaverkan": -11.12,
    "out:Initial Cost/MSEK": 0.143,
    "out:Running cost/(Apt SEK y)": 44482,
    "out:Running Cost over RSP/MSEK": 18.774,
    "out:LCP/MSEK": 0.587,
    "out:ROI% old": 73.15,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 3.8,
    "out:Op energy cost/MSEK": 137,
    "out:El price/MSEK": 84,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 65,
    "out:Return/kSEK/y": 93,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 22,
    "out:Payback Time": 4.545455,
    "out:DH kWh savings": 30894,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 30894,
    "out:DH kr savings": 30894,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 154.3,
    "out:Total Energy Use Post PV": 154.3,
    "out:Primary Energy": 143.14,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 52.29,
    "out:Total CO2/m2": 52.29,
    "out:Total CO2 (tons)": 269.22,
    "out:Klimatpaverkan": -20.39,
    "out:Initial Cost/MSEK": 0.271,
    "out:Running cost/(Apt SEK y)": 43042,
    "out:Running Cost over RSP/MSEK": 18.166,
    "out:LCP/MSEK": 1.067,
    "out:ROI% old": 70.46,
    "out:Payback discounted": 2,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 7.1,
    "out:Op energy cost/MSEK": 133,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 63,
    "out:Return/kSEK/y": 171,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 21,
    "out:Payback Time": 4.761905,
    "out:DH kWh savings": 56639,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 56639,
    "out:DH kr savings": 56639,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 156.3,
    "out:Total Energy Use Post PV": 156.3,
    "out:Primary Energy": 145.72,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.28,
    "out:CO2 Operational/m2": 53.01,
    "out:Total CO2/m2": 53.29,
    "out:Total CO2 (tons)": 274.38,
    "out:Klimatpaverkan": -15.23,
    "out:Initial Cost/MSEK": 0.318,
    "out:Running cost/(Apt SEK y)": 43618,
    "out:Running Cost over RSP/MSEK": 18.41,
    "out:LCP/MSEK": 0.776,
    "out:ROI% old": 49.15,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 5.8,
    "out:Op energy cost/MSEK": 134,
    "out:El price/MSEK": 82,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 44,
    "out:Return/kSEK/y": 140,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 46341,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 46341,
    "out:DH kr savings": 46341,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 151.3,
    "out:Total Energy Use Post PV": 151.3,
    "out:Primary Energy": 141.04,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.28,
    "out:CO2 Operational/m2": 51.21,
    "out:Total CO2/m2": 51.49,
    "out:Total CO2 (tons)": 265.11,
    "out:Klimatpaverkan": -24.5,
    "out:Initial Cost/MSEK": 0.447,
    "out:Running cost/(Apt SEK y)": 42178,
    "out:Running Cost over RSP/MSEK": 17.801,
    "out:LCP/MSEK": 1.256,
    "out:ROI% old": 54.43,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 9.3,
    "out:Op energy cost/MSEK": 130,
    "out:El price/MSEK": 80,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 49,
    "out:Return/kSEK/y": 218,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 72086,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 72086,
    "out:DH kr savings": 72086,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 159.3,
    "out:Total Energy Use Post PV": 155.29,
    "out:Primary Energy": 140.6,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.06,
    "out:CO2 Operational/m2": 41.66,
    "out:Total CO2/m2": 48.72,
    "out:Total CO2 (tons)": 250.84,
    "out:Klimatpaverkan": -38.77,
    "out:Initial Cost/MSEK": 0.755,
    "out:Running cost/(Apt SEK y)": 43377,
    "out:Running Cost over RSP/MSEK": 18.32,
    "out:LCP/MSEK": 0.429,
    "out:ROI% old": 22.41,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 134,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 46,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 153,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 30894,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 90552,
    "out:DH kr savings": 30894,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 154.3,
    "out:Total Energy Use Post PV": 150.29,
    "out:Primary Energy": 135.92,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.06,
    "out:CO2 Operational/m2": 39.86,
    "out:Total CO2/m2": 46.92,
    "out:Total CO2 (tons)": 241.57,
    "out:Klimatpaverkan": -48.04,
    "out:Initial Cost/MSEK": 0.883,
    "out:Running cost/(Apt SEK y)": 41937,
    "out:Running Cost over RSP/MSEK": 17.712,
    "out:LCP/MSEK": 0.909,
    "out:ROI% old": 28.98,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 130,
    "out:El price/MSEK": 78,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 26,
    "out:Return/kSEK/y": 231,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 56639,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116297,
    "out:DH kr savings": 56639,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 156.3,
    "out:Total Energy Use Post PV": 152.29,
    "out:Primary Energy": 138.5,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.34,
    "out:CO2 Operational/m2": 40.58,
    "out:Total CO2/m2": 47.92,
    "out:Total CO2 (tons)": 246.73,
    "out:Klimatpaverkan": -42.88,
    "out:Initial Cost/MSEK": 0.93,
    "out:Running cost/(Apt SEK y)": 42513,
    "out:Running Cost over RSP/MSEK": 17.955,
    "out:LCP/MSEK": 0.619,
    "out:ROI% old": 23.79,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 8.6,
    "out:Op energy cost/MSEK": 132,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 45,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 200,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 46341,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105999,
    "out:DH kr savings": 46341,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 151.3,
    "out:Total Energy Use Post PV": 147.29,
    "out:Primary Energy": 133.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.34,
    "out:CO2 Operational/m2": 38.78,
    "out:Total CO2/m2": 46.12,
    "out:Total CO2 (tons)": 237.46,
    "out:Klimatpaverkan": -52.15,
    "out:Initial Cost/MSEK": 1.059,
    "out:Running cost/(Apt SEK y)": 41073,
    "out:Running Cost over RSP/MSEK": 17.347,
    "out:LCP/MSEK": 1.098,
    "out:ROI% old": 29.1,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 12.2,
    "out:Op energy cost/MSEK": 127,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 26,
    "out:Return/kSEK/y": 277,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 72086,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131744,
    "out:DH kr savings": 72086,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 159.69,
    "out:Total Energy Use Post PV": 159.69,
    "out:Primary Energy": 153.88,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.34,
    "out:CO2 Operational/m2": 53,
    "out:Total CO2/m2": 55.34,
    "out:Total CO2 (tons)": 284.95,
    "out:Klimatpaverkan": -4.66,
    "out:Initial Cost/MSEK": 0.797,
    "out:Running cost/(Apt SEK y)": 44070,
    "out:Running Cost over RSP/MSEK": 18.596,
    "out:LCP/MSEK": 0.112,
    "out:ROI% old": 16.29,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 3.1,
    "out:Op energy cost/MSEK": 136,
    "out:El price/MSEK": 84,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 116,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": ">100",
    "out:DH kWh savings": 56639,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 1104,
    "out:DH kr savings": 56639,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 154.69,
    "out:Total Energy Use Post PV": 154.69,
    "out:Primary Energy": 149.2,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.34,
    "out:CO2 Operational/m2": 51.2,
    "out:Total CO2/m2": 53.54,
    "out:Total CO2 (tons)": 275.68,
    "out:Klimatpaverkan": -13.93,
    "out:Initial Cost/MSEK": 0.926,
    "out:Running cost/(Apt SEK y)": 42630,
    "out:Running Cost over RSP/MSEK": 17.988,
    "out:LCP/MSEK": 0.591,
    "out:ROI% old": 23.41,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 131,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 193,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 26849,
    "out:DH kr savings": 82384,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 155.69,
    "out:Total Energy Use Post PV": 155.69,
    "out:Primary Energy": 151.25,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.62,
    "out:CO2 Operational/m2": 51.56,
    "out:Total CO2/m2": 54.18,
    "out:Total CO2 (tons)": 278.98,
    "out:Klimatpaverkan": -10.63,
    "out:Initial Cost/MSEK": 0.972,
    "out:Running cost/(Apt SEK y)": 42918,
    "out:Running Cost over RSP/MSEK": 18.109,
    "out:LCP/MSEK": 0.423,
    "out:ROI% old": 20.49,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 5.8,
    "out:Op energy cost/MSEK": 132,
    "out:El price/MSEK": 82,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 18,
    "out:Return/kSEK/y": 178,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 77235,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 21700,
    "out:DH kr savings": 77235,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 150.69,
    "out:Total Energy Use Post PV": 150.69,
    "out:Primary Energy": 146.58,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.62,
    "out:CO2 Operational/m2": 49.76,
    "out:Total CO2/m2": 52.38,
    "out:Total CO2 (tons)": 269.72,
    "out:Klimatpaverkan": -19.89,
    "out:Initial Cost/MSEK": 1.101,
    "out:Running cost/(Apt SEK y)": 41479,
    "out:Running Cost over RSP/MSEK": 17.501,
    "out:LCP/MSEK": 0.902,
    "out:ROI% old": 25.99,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 9.3,
    "out:Op energy cost/MSEK": 127,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 23,
    "out:Return/kSEK/y": 255,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 47445,
    "out:DH kr savings": 102980,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 159.69,
    "out:Total Energy Use Post PV": 154.84,
    "out:Primary Energy": 145.14,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.4,
    "out:CO2 Operational/m2": 44.76,
    "out:Total CO2/m2": 54.17,
    "out:Total CO2 (tons)": 278.91,
    "out:Klimatpaverkan": -10.7,
    "out:Initial Cost/MSEK": 1.409,
    "out:Running cost/(Apt SEK y)": 42925,
    "out:Running Cost over RSP/MSEK": 18.125,
    "out:LCP/MSEK": -0.03,
    "out:ROI% old": 13.99,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 133,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 177,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 56639,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62931,
    "out:DH kr savings": 56639,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 154.69,
    "out:Total Energy Use Post PV": 149.84,
    "out:Primary Energy": 140.47,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.4,
    "out:CO2 Operational/m2": 42.96,
    "out:Total CO2/m2": 52.37,
    "out:Total CO2 (tons)": 269.64,
    "out:Klimatpaverkan": -19.97,
    "out:Initial Cost/MSEK": 1.538,
    "out:Running cost/(Apt SEK y)": 41485,
    "out:Running Cost over RSP/MSEK": 17.517,
    "out:LCP/MSEK": 0.45,
    "out:ROI% old": 18.47,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 128,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 255,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88676,
    "out:DH kr savings": 82384,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 155.69,
    "out:Total Energy Use Post PV": 150.84,
    "out:Primary Energy": 142.52,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.68,
    "out:CO2 Operational/m2": 43.32,
    "out:Total CO2/m2": 53.01,
    "out:Total CO2 (tons)": 272.94,
    "out:Klimatpaverkan": -16.67,
    "out:Initial Cost/MSEK": 1.585,
    "out:Running cost/(Apt SEK y)": 41773,
    "out:Running Cost over RSP/MSEK": 17.638,
    "out:LCP/MSEK": 0.281,
    "out:ROI% old": 16.82,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 9.3,
    "out:Op energy cost/MSEK": 129,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 240,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 77235,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83527,
    "out:DH kr savings": 77235,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 150.69,
    "out:Total Energy Use Post PV": 145.84,
    "out:Primary Energy": 137.84,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.68,
    "out:CO2 Operational/m2": 41.52,
    "out:Total CO2/m2": 51.21,
    "out:Total CO2 (tons)": 263.67,
    "out:Klimatpaverkan": -25.94,
    "out:Initial Cost/MSEK": 1.713,
    "out:Running cost/(Apt SEK y)": 40334,
    "out:Running Cost over RSP/MSEK": 17.03,
    "out:LCP/MSEK": 0.761,
    "out:ROI% old": 20.63,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 317,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109272,
    "out:DH kr savings": 102980,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 154.69,
    "out:Total Energy Use Post PV": 154.69,
    "out:Primary Energy": 150.38,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.34,
    "out:CO2 Operational/m2": 51.2,
    "out:Total CO2/m2": 53.54,
    "out:Total CO2 (tons)": 275.68,
    "out:Klimatpaverkan": -13.93,
    "out:Initial Cost/MSEK": 0.939,
    "out:Running cost/(Apt SEK y)": 42630,
    "out:Running Cost over RSP/MSEK": 17.988,
    "out:LCP/MSEK": 0.577,
    "out:ROI% old": 23.07,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 131,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 193,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 26849,
    "out:DH kr savings": 82384,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 149.69,
    "out:Total Energy Use Post PV": 149.69,
    "out:Primary Energy": 145.7,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.34,
    "out:CO2 Operational/m2": 49.4,
    "out:Total CO2/m2": 51.74,
    "out:Total CO2 (tons)": 266.41,
    "out:Klimatpaverkan": -23.2,
    "out:Initial Cost/MSEK": 1.068,
    "out:Running cost/(Apt SEK y)": 41191,
    "out:Running Cost over RSP/MSEK": 17.379,
    "out:LCP/MSEK": 1.057,
    "out:ROI% old": 28.42,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 127,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 25,
    "out:Return/kSEK/y": 271,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 108129,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 52594,
    "out:DH kr savings": 108129,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 151.69,
    "out:Total Energy Use Post PV": 151.69,
    "out:Primary Energy": 148.28,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.62,
    "out:CO2 Operational/m2": 50.12,
    "out:Total CO2/m2": 52.74,
    "out:Total CO2 (tons)": 271.57,
    "out:Klimatpaverkan": -18.04,
    "out:Initial Cost/MSEK": 1.115,
    "out:Running cost/(Apt SEK y)": 41767,
    "out:Running Cost over RSP/MSEK": 17.623,
    "out:LCP/MSEK": 0.767,
    "out:ROI% old": 24.11,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 8.6,
    "out:Op energy cost/MSEK": 128,
    "out:El price/MSEK": 80,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 22,
    "out:Return/kSEK/y": 240,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 42296,
    "out:DH kr savings": 97831,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 145.69,
    "out:Total Energy Use Post PV": 145.69,
    "out:Primary Energy": 143.6,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.62,
    "out:CO2 Operational/m2": 47.96,
    "out:Total CO2/m2": 50.58,
    "out:Total CO2 (tons)": 260.45,
    "out:Klimatpaverkan": -29.16,
    "out:Initial Cost/MSEK": 1.244,
    "out:Running cost/(Apt SEK y)": 40039,
    "out:Running Cost over RSP/MSEK": 16.893,
    "out:LCP/MSEK": 1.368,
    "out:ROI% old": 30,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 27,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 73190,
    "out:DH kr savings": 128725,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 154.69,
    "out:Total Energy Use Post PV": 149.84,
    "out:Primary Energy": 141.64,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.4,
    "out:CO2 Operational/m2": 42.96,
    "out:Total CO2/m2": 52.37,
    "out:Total CO2 (tons)": 269.64,
    "out:Klimatpaverkan": -19.97,
    "out:Initial Cost/MSEK": 1.551,
    "out:Running cost/(Apt SEK y)": 41485,
    "out:Running Cost over RSP/MSEK": 17.517,
    "out:LCP/MSEK": 0.436,
    "out:ROI% old": 18.3,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 128,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 255,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88676,
    "out:DH kr savings": 82384,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 149.69,
    "out:Total Energy Use Post PV": 144.84,
    "out:Primary Energy": 136.97,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.4,
    "out:CO2 Operational/m2": 41.16,
    "out:Total CO2/m2": 50.57,
    "out:Total CO2 (tons)": 260.37,
    "out:Klimatpaverkan": -29.24,
    "out:Initial Cost/MSEK": 1.68,
    "out:Running cost/(Apt SEK y)": 40046,
    "out:Running Cost over RSP/MSEK": 16.909,
    "out:LCP/MSEK": 0.915,
    "out:ROI% old": 22.07,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 124,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 108129,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114421,
    "out:DH kr savings": 108129,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.52,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 151.69,
    "out:Total Energy Use Post PV": 146.84,
    "out:Primary Energy": 139.54,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.68,
    "out:CO2 Operational/m2": 41.88,
    "out:Total CO2/m2": 51.57,
    "out:Total CO2 (tons)": 265.53,
    "out:Klimatpaverkan": -24.08,
    "out:Initial Cost/MSEK": 1.727,
    "out:Running cost/(Apt SEK y)": 40622,
    "out:Running Cost over RSP/MSEK": 17.152,
    "out:LCP/MSEK": 0.625,
    "out:ROI% old": 19.46,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 12.2,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 302,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 104123,
    "out:DH kr savings": 97831,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 635989,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 123.52,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.18,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 145.69,
    "out:Total Energy Use Post PV": 140.84,
    "out:Primary Energy": 134.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.68,
    "out:CO2 Operational/m2": 39.72,
    "out:Total CO2/m2": 49.41,
    "out:Total CO2 (tons)": 254.41,
    "out:Klimatpaverkan": -35.2,
    "out:Initial Cost/MSEK": 1.856,
    "out:Running cost/(Apt SEK y)": 38894,
    "out:Running Cost over RSP/MSEK": 16.422,
    "out:LCP/MSEK": 1.226,
    "out:ROI% old": 23.73,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.32,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135017,
    "out:DH kr savings": 128725,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 608485,
    "out:% savings (space heating)": 4.32,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 118.18,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 150.3,
    "out:Total Energy Use Post PV": 150.3,
    "out:Primary Energy": 138.95,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.99,
    "out:CO2 Operational/m2": 50.85,
    "out:Total CO2/m2": 53.83,
    "out:Total CO2 (tons)": 277.19,
    "out:Klimatpaverkan": -12.42,
    "out:Initial Cost/MSEK": 1.293,
    "out:Running cost/(Apt SEK y)": 41890,
    "out:Running Cost over RSP/MSEK": 17.68,
    "out:LCP/MSEK": 0.531,
    "out:ROI% old": 20.16,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 129,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 18,
    "out:Return/kSEK/y": 233,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 77235,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77235,
    "out:DH kr savings": 77235,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 146.3,
    "out:Total Energy Use Post PV": 146.3,
    "out:Primary Energy": 134.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.99,
    "out:CO2 Operational/m2": 49.41,
    "out:Total CO2/m2": 52.39,
    "out:Total CO2 (tons)": 269.78,
    "out:Klimatpaverkan": -19.83,
    "out:Initial Cost/MSEK": 1.422,
    "out:Running cost/(Apt SEK y)": 40738,
    "out:Running Cost over RSP/MSEK": 17.193,
    "out:LCP/MSEK": 0.889,
    "out:ROI% old": 23.22,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 21,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 97831,
    "out:DH kr savings": 97831,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 146.3,
    "out:Total Energy Use Post PV": 146.3,
    "out:Primary Energy": 136.32,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.27,
    "out:CO2 Operational/m2": 49.41,
    "out:Total CO2/m2": 52.68,
    "out:Total CO2 (tons)": 271.23,
    "out:Klimatpaverkan": -18.38,
    "out:Initial Cost/MSEK": 1.469,
    "out:Running cost/(Apt SEK y)": 40738,
    "out:Running Cost over RSP/MSEK": 17.193,
    "out:LCP/MSEK": 0.842,
    "out:ROI% old": 22.48,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 20,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 97831,
    "out:DH kr savings": 97831,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 142.3,
    "out:Total Energy Use Post PV": 142.3,
    "out:Primary Energy": 132.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.27,
    "out:CO2 Operational/m2": 47.97,
    "out:Total CO2/m2": 51.24,
    "out:Total CO2 (tons)": 263.81,
    "out:Klimatpaverkan": -25.8,
    "out:Initial Cost/MSEK": 1.598,
    "out:Running cost/(Apt SEK y)": 39586,
    "out:Running Cost over RSP/MSEK": 16.707,
    "out:LCP/MSEK": 1.2,
    "out:ROI% old": 25.02,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 22,
    "out:Return/kSEK/y": 358,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 118427,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118427,
    "out:DH kr savings": 118427,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 150.3,
    "out:Total Energy Use Post PV": 146.29,
    "out:Primary Energy": 131.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.05,
    "out:CO2 Operational/m2": 38.42,
    "out:Total CO2/m2": 48.46,
    "out:Total CO2 (tons)": 249.54,
    "out:Klimatpaverkan": -40.07,
    "out:Initial Cost/MSEK": 1.905,
    "out:Running cost/(Apt SEK y)": 40785,
    "out:Running Cost over RSP/MSEK": 17.226,
    "out:LCP/MSEK": 0.373,
    "out:ROI% old": 17.09,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 293,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 77235,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136893,
    "out:DH kr savings": 77235,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 146.3,
    "out:Total Energy Use Post PV": 142.29,
    "out:Primary Energy": 127.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.05,
    "out:CO2 Operational/m2": 36.98,
    "out:Total CO2/m2": 47.02,
    "out:Total CO2 (tons)": 242.13,
    "out:Klimatpaverkan": -47.48,
    "out:Initial Cost/MSEK": 2.034,
    "out:Running cost/(Apt SEK y)": 39633,
    "out:Running Cost over RSP/MSEK": 16.739,
    "out:LCP/MSEK": 0.731,
    "out:ROI% old": 19.42,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 355,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157489,
    "out:DH kr savings": 97831,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 146.3,
    "out:Total Energy Use Post PV": 142.29,
    "out:Primary Energy": 129.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.33,
    "out:CO2 Operational/m2": 36.98,
    "out:Total CO2/m2": 47.31,
    "out:Total CO2 (tons)": 243.58,
    "out:Klimatpaverkan": -46.03,
    "out:Initial Cost/MSEK": 2.081,
    "out:Running cost/(Apt SEK y)": 39633,
    "out:Running Cost over RSP/MSEK": 16.739,
    "out:LCP/MSEK": 0.684,
    "out:ROI% old": 18.98,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 355,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157489,
    "out:DH kr savings": 97831,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 142.3,
    "out:Total Energy Use Post PV": 138.29,
    "out:Primary Energy": 124.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.33,
    "out:CO2 Operational/m2": 35.54,
    "out:Total CO2/m2": 45.87,
    "out:Total CO2 (tons)": 236.16,
    "out:Klimatpaverkan": -53.45,
    "out:Initial Cost/MSEK": 2.21,
    "out:Running cost/(Apt SEK y)": 38481,
    "out:Running Cost over RSP/MSEK": 16.253,
    "out:LCP/MSEK": 1.042,
    "out:ROI% old": 21.02,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 417,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 118427,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178085,
    "out:DH kr savings": 118427,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 145.3,
    "out:Primary Energy": 135.45,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.99,
    "out:CO2 Operational/m2": 49.05,
    "out:Total CO2/m2": 52.03,
    "out:Total CO2 (tons)": 267.92,
    "out:Klimatpaverkan": -21.69,
    "out:Initial Cost/MSEK": 1.436,
    "out:Running cost/(Apt SEK y)": 40450,
    "out:Running Cost over RSP/MSEK": 17.072,
    "out:LCP/MSEK": 0.997,
    "out:ROI% old": 24.21,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 22,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102980,
    "out:DH kr savings": 102980,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 140.3,
    "out:Primary Energy": 131.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.99,
    "out:CO2 Operational/m2": 47.25,
    "out:Total CO2/m2": 50.23,
    "out:Total CO2 (tons)": 258.66,
    "out:Klimatpaverkan": -30.95,
    "out:Initial Cost/MSEK": 1.564,
    "out:Running cost/(Apt SEK y)": 39010,
    "out:Running Cost over RSP/MSEK": 16.464,
    "out:LCP/MSEK": 1.476,
    "out:ROI% old": 27.77,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 25,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128725,
    "out:DH kr savings": 128725,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 142.3,
    "out:Total Energy Use Post PV": 142.3,
    "out:Primary Energy": 133.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.27,
    "out:CO2 Operational/m2": 47.97,
    "out:Total CO2/m2": 51.24,
    "out:Total CO2 (tons)": 263.81,
    "out:Klimatpaverkan": -25.8,
    "out:Initial Cost/MSEK": 1.611,
    "out:Running cost/(Apt SEK y)": 39586,
    "out:Running Cost over RSP/MSEK": 16.707,
    "out:LCP/MSEK": 1.186,
    "out:ROI% old": 24.8,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 22,
    "out:Return/kSEK/y": 358,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 118427,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118427,
    "out:DH kr savings": 118427,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 137.3,
    "out:Total Energy Use Post PV": 137.3,
    "out:Primary Energy": 129.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.27,
    "out:CO2 Operational/m2": 46.17,
    "out:Total CO2/m2": 49.44,
    "out:Total CO2 (tons)": 254.55,
    "out:Klimatpaverkan": -35.06,
    "out:Initial Cost/MSEK": 1.74,
    "out:Running cost/(Apt SEK y)": 38146,
    "out:Running Cost over RSP/MSEK": 16.099,
    "out:LCP/MSEK": 1.666,
    "out:ROI% old": 27.96,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 25,
    "out:Return/kSEK/y": 435,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 144172,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 144172,
    "out:DH kr savings": 144172,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 141.29,
    "out:Primary Energy": 128.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.05,
    "out:CO2 Operational/m2": 36.62,
    "out:Total CO2/m2": 46.66,
    "out:Total CO2 (tons)": 240.27,
    "out:Klimatpaverkan": -49.34,
    "out:Initial Cost/MSEK": 2.048,
    "out:Running cost/(Apt SEK y)": 39345,
    "out:Running Cost over RSP/MSEK": 16.618,
    "out:LCP/MSEK": 0.839,
    "out:ROI% old": 20.14,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 18,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162638,
    "out:DH kr savings": 102980,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 136.29,
    "out:Primary Energy": 124.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.05,
    "out:CO2 Operational/m2": 34.82,
    "out:Total CO2/m2": 44.86,
    "out:Total CO2 (tons)": 231.01,
    "out:Klimatpaverkan": -58.6,
    "out:Initial Cost/MSEK": 2.176,
    "out:Running cost/(Apt SEK y)": 37906,
    "out:Running Cost over RSP/MSEK": 16.009,
    "out:LCP/MSEK": 1.318,
    "out:ROI% old": 22.94,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188383,
    "out:DH kr savings": 128725,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 142.3,
    "out:Total Energy Use Post PV": 138.29,
    "out:Primary Energy": 126.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.33,
    "out:CO2 Operational/m2": 35.54,
    "out:Total CO2/m2": 45.87,
    "out:Total CO2 (tons)": 236.16,
    "out:Klimatpaverkan": -53.45,
    "out:Initial Cost/MSEK": 2.223,
    "out:Running cost/(Apt SEK y)": 38481,
    "out:Running Cost over RSP/MSEK": 16.253,
    "out:LCP/MSEK": 1.028,
    "out:ROI% old": 20.89,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 417,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 118427,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178085,
    "out:DH kr savings": 118427,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 137.3,
    "out:Total Energy Use Post PV": 133.29,
    "out:Primary Energy": 122.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.33,
    "out:CO2 Operational/m2": 33.74,
    "out:Total CO2/m2": 44.07,
    "out:Total CO2 (tons)": 226.9,
    "out:Klimatpaverkan": -62.71,
    "out:Initial Cost/MSEK": 2.352,
    "out:Running cost/(Apt SEK y)": 37042,
    "out:Running Cost over RSP/MSEK": 15.644,
    "out:LCP/MSEK": 1.508,
    "out:ROI% old": 23.44,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 495,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 144172,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 203830,
    "out:DH kr savings": 144172,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 145.69,
    "out:Total Energy Use Post PV": 145.69,
    "out:Primary Energy": 141.51,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.33,
    "out:CO2 Operational/m2": 47.96,
    "out:Total CO2/m2": 53.29,
    "out:Total CO2 (tons)": 274.39,
    "out:Klimatpaverkan": -15.22,
    "out:Initial Cost/MSEK": 2.09,
    "out:Running cost/(Apt SEK y)": 40039,
    "out:Running Cost over RSP/MSEK": 16.893,
    "out:LCP/MSEK": 0.521,
    "out:ROI% old": 17.85,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 16,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 73190,
    "out:DH kr savings": 128725,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 141.69,
    "out:Total Energy Use Post PV": 141.69,
    "out:Primary Energy": 137.4,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.33,
    "out:CO2 Operational/m2": 46.52,
    "out:Total CO2/m2": 51.85,
    "out:Total CO2 (tons)": 266.97,
    "out:Klimatpaverkan": -22.64,
    "out:Initial Cost/MSEK": 2.219,
    "out:Running cost/(Apt SEK y)": 38887,
    "out:Running Cost over RSP/MSEK": 16.406,
    "out:LCP/MSEK": 0.879,
    "out:ROI% old": 19.95,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 18,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93786,
    "out:DH kr savings": 149321,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 141.69,
    "out:Total Energy Use Post PV": 141.69,
    "out:Primary Energy": 138.88,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.61,
    "out:CO2 Operational/m2": 46.52,
    "out:Total CO2/m2": 52.13,
    "out:Total CO2 (tons)": 268.42,
    "out:Klimatpaverkan": -21.19,
    "out:Initial Cost/MSEK": 2.266,
    "out:Running cost/(Apt SEK y)": 38887,
    "out:Running Cost over RSP/MSEK": 16.406,
    "out:LCP/MSEK": 0.832,
    "out:ROI% old": 19.53,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 17,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93786,
    "out:DH kr savings": 149321,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 137.69,
    "out:Total Energy Use Post PV": 137.69,
    "out:Primary Energy": 134.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.61,
    "out:CO2 Operational/m2": 45.08,
    "out:Total CO2/m2": 50.69,
    "out:Total CO2 (tons)": 261.01,
    "out:Klimatpaverkan": -28.6,
    "out:Initial Cost/MSEK": 2.394,
    "out:Running cost/(Apt SEK y)": 37735,
    "out:Running Cost over RSP/MSEK": 15.92,
    "out:LCP/MSEK": 1.19,
    "out:ROI% old": 21.39,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 19,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 169917,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114382,
    "out:DH kr savings": 169917,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 145.69,
    "out:Total Energy Use Post PV": 140.84,
    "out:Primary Energy": 132.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.39,
    "out:CO2 Operational/m2": 39.72,
    "out:Total CO2/m2": 52.12,
    "out:Total CO2 (tons)": 268.34,
    "out:Klimatpaverkan": -21.27,
    "out:Initial Cost/MSEK": 2.702,
    "out:Running cost/(Apt SEK y)": 38894,
    "out:Running Cost over RSP/MSEK": 16.422,
    "out:LCP/MSEK": 0.38,
    "out:ROI% old": 16.3,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135017,
    "out:DH kr savings": 128725,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 141.69,
    "out:Total Energy Use Post PV": 136.84,
    "out:Primary Energy": 128.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.39,
    "out:CO2 Operational/m2": 38.29,
    "out:Total CO2/m2": 50.68,
    "out:Total CO2 (tons)": 260.93,
    "out:Klimatpaverkan": -28.68,
    "out:Initial Cost/MSEK": 2.831,
    "out:Running cost/(Apt SEK y)": 37742,
    "out:Running Cost over RSP/MSEK": 15.936,
    "out:LCP/MSEK": 0.738,
    "out:ROI% old": 18.01,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 155613,
    "out:DH kr savings": 149321,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 141.69,
    "out:Total Energy Use Post PV": 136.84,
    "out:Primary Energy": 130.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.67,
    "out:CO2 Operational/m2": 38.29,
    "out:Total CO2/m2": 50.96,
    "out:Total CO2 (tons)": 262.38,
    "out:Klimatpaverkan": -27.23,
    "out:Initial Cost/MSEK": 2.878,
    "out:Running cost/(Apt SEK y)": 37742,
    "out:Running Cost over RSP/MSEK": 15.936,
    "out:LCP/MSEK": 0.691,
    "out:ROI% old": 17.72,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155613,
    "out:DH kr savings": 149321,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 137.69,
    "out:Total Energy Use Post PV": 132.84,
    "out:Primary Energy": 126.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.67,
    "out:CO2 Operational/m2": 36.85,
    "out:Total CO2/m2": 49.52,
    "out:Total CO2 (tons)": 254.96,
    "out:Klimatpaverkan": -34.65,
    "out:Initial Cost/MSEK": 3.006,
    "out:Running cost/(Apt SEK y)": 36590,
    "out:Running Cost over RSP/MSEK": 15.449,
    "out:LCP/MSEK": 1.049,
    "out:ROI% old": 19.27,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 519,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 169917,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176209,
    "out:DH kr savings": 169917,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 140.69,
    "out:Total Energy Use Post PV": 140.69,
    "out:Primary Energy": 138.01,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.33,
    "out:CO2 Operational/m2": 46.16,
    "out:Total CO2/m2": 51.49,
    "out:Total CO2 (tons)": 265.12,
    "out:Klimatpaverkan": -24.49,
    "out:Initial Cost/MSEK": 2.232,
    "out:Running cost/(Apt SEK y)": 38599,
    "out:Running Cost over RSP/MSEK": 16.285,
    "out:LCP/MSEK": 0.987,
    "out:ROI% old": 20.6,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 18,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98935,
    "out:DH kr savings": 154470,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 135.69,
    "out:Primary Energy": 133.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.33,
    "out:CO2 Operational/m2": 44.36,
    "out:Total CO2/m2": 49.69,
    "out:Total CO2 (tons)": 255.85,
    "out:Klimatpaverkan": -33.76,
    "out:Initial Cost/MSEK": 2.361,
    "out:Running cost/(Apt SEK y)": 37159,
    "out:Running Cost over RSP/MSEK": 15.677,
    "out:LCP/MSEK": 1.467,
    "out:ROI% old": 23.16,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 489,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124680,
    "out:DH kr savings": 180215,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 137.69,
    "out:Total Energy Use Post PV": 137.69,
    "out:Primary Energy": 135.91,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.61,
    "out:CO2 Operational/m2": 45.08,
    "out:Total CO2/m2": 50.69,
    "out:Total CO2 (tons)": 261.01,
    "out:Klimatpaverkan": -28.6,
    "out:Initial Cost/MSEK": 2.408,
    "out:Running cost/(Apt SEK y)": 37735,
    "out:Running Cost over RSP/MSEK": 15.92,
    "out:LCP/MSEK": 1.176,
    "out:ROI% old": 21.26,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 19,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 169917,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114382,
    "out:DH kr savings": 169917,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 132.69,
    "out:Total Energy Use Post PV": 132.69,
    "out:Primary Energy": 131.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.61,
    "out:CO2 Operational/m2": 43.28,
    "out:Total CO2/m2": 48.89,
    "out:Total CO2 (tons)": 251.74,
    "out:Klimatpaverkan": -37.87,
    "out:Initial Cost/MSEK": 2.537,
    "out:Running cost/(Apt SEK y)": 36295,
    "out:Running Cost over RSP/MSEK": 15.312,
    "out:LCP/MSEK": 1.656,
    "out:ROI% old": 23.61,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140127,
    "out:DH kr savings": 195662,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 140.69,
    "out:Total Energy Use Post PV": 135.84,
    "out:Primary Energy": 129.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.39,
    "out:CO2 Operational/m2": 37.93,
    "out:Total CO2/m2": 50.32,
    "out:Total CO2 (tons)": 259.08,
    "out:Klimatpaverkan": -30.53,
    "out:Initial Cost/MSEK": 2.845,
    "out:Running cost/(Apt SEK y)": 37454,
    "out:Running Cost over RSP/MSEK": 15.814,
    "out:LCP/MSEK": 0.846,
    "out:ROI% old": 18.53,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 473,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160762,
    "out:DH kr savings": 154470,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 130.84,
    "out:Primary Energy": 125.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.39,
    "out:CO2 Operational/m2": 36.13,
    "out:Total CO2/m2": 48.52,
    "out:Total CO2 (tons)": 249.81,
    "out:Klimatpaverkan": -39.8,
    "out:Initial Cost/MSEK": 2.973,
    "out:Running cost/(Apt SEK y)": 36014,
    "out:Running Cost over RSP/MSEK": 15.206,
    "out:LCP/MSEK": 1.325,
    "out:ROI% old": 20.65,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186507,
    "out:DH kr savings": 180215,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.38,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 137.69,
    "out:Total Energy Use Post PV": 132.84,
    "out:Primary Energy": 127.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.67,
    "out:CO2 Operational/m2": 36.85,
    "out:Total CO2/m2": 49.52,
    "out:Total CO2 (tons)": 254.96,
    "out:Klimatpaverkan": -34.65,
    "out:Initial Cost/MSEK": 3.02,
    "out:Running cost/(Apt SEK y)": 36590,
    "out:Running Cost over RSP/MSEK": 15.449,
    "out:LCP/MSEK": 1.035,
    "out:ROI% old": 19.18,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 519,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 169917,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176209,
    "out:DH kr savings": 169917,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 563201,
    "out:% savings (space heating)": 11.44,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.38,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.68,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 132.69,
    "out:Total Energy Use Post PV": 127.84,
    "out:Primary Energy": 123.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.67,
    "out:CO2 Operational/m2": 35.05,
    "out:Total CO2/m2": 47.72,
    "out:Total CO2 (tons)": 245.7,
    "out:Klimatpaverkan": -43.91,
    "out:Initial Cost/MSEK": 3.149,
    "out:Running cost/(Apt SEK y)": 35150,
    "out:Running Cost over RSP/MSEK": 14.841,
    "out:LCP/MSEK": 1.514,
    "out:ROI% old": 21.16,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.06,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 597,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201954,
    "out:DH kr savings": 195662,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 539005,
    "out:% savings (space heating)": 15.25,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.68,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 146.3,
    "out:Total Energy Use Post PV": 146.3,
    "out:Primary Energy": 135.14,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.55,
    "out:CO2 Operational/m2": 49.41,
    "out:Total CO2/m2": 61.96,
    "out:Total CO2 (tons)": 319.01,
    "out:Klimatpaverkan": 29.4,
    "out:Initial Cost/MSEK": 4.459,
    "out:Running cost/(Apt SEK y)": 40738,
    "out:Running Cost over RSP/MSEK": 17.193,
    "out:LCP/MSEK": -2.148,
    "out:ROI% old": 7.4,
    "out:Payback discounted": 16,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 7,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 97831,
    "out:DH kr savings": 97831,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 142.3,
    "out:Total Energy Use Post PV": 142.3,
    "out:Primary Energy": 131.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.55,
    "out:CO2 Operational/m2": 47.97,
    "out:Total CO2/m2": 60.52,
    "out:Total CO2 (tons)": 311.6,
    "out:Klimatpaverkan": 21.99,
    "out:Initial Cost/MSEK": 4.588,
    "out:Running cost/(Apt SEK y)": 39586,
    "out:Running Cost over RSP/MSEK": 16.707,
    "out:LCP/MSEK": -1.791,
    "out:ROI% old": 8.71,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 8,
    "out:Return/kSEK/y": 358,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118427,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118427,
    "out:DH kr savings": 118427,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 142.3,
    "out:Total Energy Use Post PV": 142.3,
    "out:Primary Energy": 132.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.83,
    "out:CO2 Operational/m2": 47.97,
    "out:Total CO2/m2": 60.8,
    "out:Total CO2 (tons)": 313.05,
    "out:Klimatpaverkan": 23.44,
    "out:Initial Cost/MSEK": 4.635,
    "out:Running cost/(Apt SEK y)": 39586,
    "out:Running Cost over RSP/MSEK": 16.707,
    "out:LCP/MSEK": -1.838,
    "out:ROI% old": 8.62,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 8,
    "out:Return/kSEK/y": 358,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118427,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118427,
    "out:DH kr savings": 118427,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 138.3,
    "out:Total Energy Use Post PV": 138.3,
    "out:Primary Energy": 128.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.83,
    "out:CO2 Operational/m2": 46.53,
    "out:Total CO2/m2": 59.36,
    "out:Total CO2 (tons)": 305.63,
    "out:Klimatpaverkan": 16.02,
    "out:Initial Cost/MSEK": 4.764,
    "out:Running cost/(Apt SEK y)": 38434,
    "out:Running Cost over RSP/MSEK": 16.22,
    "out:LCP/MSEK": -1.48,
    "out:ROI% old": 9.85,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 139023,
    "out:DH kr savings": 139023,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 146.3,
    "out:Total Energy Use Post PV": 142.29,
    "out:Primary Energy": 127.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.61,
    "out:CO2 Operational/m2": 36.98,
    "out:Total CO2/m2": 56.59,
    "out:Total CO2 (tons)": 291.36,
    "out:Klimatpaverkan": 1.75,
    "out:Initial Cost/MSEK": 5.071,
    "out:Running cost/(Apt SEK y)": 39633,
    "out:Running Cost over RSP/MSEK": 16.739,
    "out:LCP/MSEK": -2.306,
    "out:ROI% old": 7.79,
    "out:Payback discounted": 16,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 7,
    "out:Return/kSEK/y": 355,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157489,
    "out:DH kr savings": 97831,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 142.3,
    "out:Total Energy Use Post PV": 138.29,
    "out:Primary Energy": 123.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.61,
    "out:CO2 Operational/m2": 35.54,
    "out:Total CO2/m2": 55.15,
    "out:Total CO2 (tons)": 283.95,
    "out:Klimatpaverkan": -5.66,
    "out:Initial Cost/MSEK": 5.2,
    "out:Running cost/(Apt SEK y)": 38481,
    "out:Running Cost over RSP/MSEK": 16.253,
    "out:LCP/MSEK": -1.949,
    "out:ROI% old": 8.93,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 417,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118427,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178085,
    "out:DH kr savings": 118427,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 142.3,
    "out:Total Energy Use Post PV": 138.29,
    "out:Primary Energy": 125.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.89,
    "out:CO2 Operational/m2": 35.54,
    "out:Total CO2/m2": 55.43,
    "out:Total CO2 (tons)": 285.4,
    "out:Klimatpaverkan": -4.21,
    "out:Initial Cost/MSEK": 5.247,
    "out:Running cost/(Apt SEK y)": 38481,
    "out:Running Cost over RSP/MSEK": 16.253,
    "out:LCP/MSEK": -1.995,
    "out:ROI% old": 8.85,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 417,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 118427,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178085,
    "out:DH kr savings": 118427,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 138.3,
    "out:Total Energy Use Post PV": 134.29,
    "out:Primary Energy": 121.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.89,
    "out:CO2 Operational/m2": 34.1,
    "out:Total CO2/m2": 53.99,
    "out:Total CO2 (tons)": 277.98,
    "out:Klimatpaverkan": -11.63,
    "out:Initial Cost/MSEK": 5.376,
    "out:Running cost/(Apt SEK y)": 37330,
    "out:Running Cost over RSP/MSEK": 15.766,
    "out:LCP/MSEK": -1.638,
    "out:ROI% old": 9.93,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 23.1,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198681,
    "out:DH kr savings": 139023,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 141.3,
    "out:Total Energy Use Post PV": 141.3,
    "out:Primary Energy": 131.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.55,
    "out:CO2 Operational/m2": 47.61,
    "out:Total CO2/m2": 60.16,
    "out:Total CO2 (tons)": 309.74,
    "out:Klimatpaverkan": 20.13,
    "out:Initial Cost/MSEK": 4.602,
    "out:Running cost/(Apt SEK y)": 39298,
    "out:Running Cost over RSP/MSEK": 16.585,
    "out:LCP/MSEK": -1.683,
    "out:ROI% old": 9.06,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 121,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 8,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 123576,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123576,
    "out:DH kr savings": 123576,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 136.3,
    "out:Total Energy Use Post PV": 136.3,
    "out:Primary Energy": 127.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.55,
    "out:CO2 Operational/m2": 45.81,
    "out:Total CO2/m2": 58.36,
    "out:Total CO2 (tons)": 300.48,
    "out:Klimatpaverkan": 10.87,
    "out:Initial Cost/MSEK": 4.731,
    "out:Running cost/(Apt SEK y)": 37858,
    "out:Running Cost over RSP/MSEK": 15.977,
    "out:LCP/MSEK": -1.203,
    "out:ROI% old": 10.65,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 451,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149321,
    "out:DH kr savings": 149321,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 137.3,
    "out:Total Energy Use Post PV": 137.3,
    "out:Primary Energy": 129.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.83,
    "out:CO2 Operational/m2": 46.17,
    "out:Total CO2/m2": 59,
    "out:Total CO2 (tons)": 303.78,
    "out:Klimatpaverkan": 14.17,
    "out:Initial Cost/MSEK": 4.778,
    "out:Running cost/(Apt SEK y)": 38146,
    "out:Running Cost over RSP/MSEK": 16.099,
    "out:LCP/MSEK": -1.372,
    "out:ROI% old": 10.18,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 435,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 144172,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144172,
    "out:DH kr savings": 144172,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 133.3,
    "out:Total Energy Use Post PV": 133.3,
    "out:Primary Energy": 125.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.83,
    "out:CO2 Operational/m2": 44.73,
    "out:Total CO2/m2": 57.56,
    "out:Total CO2 (tons)": 296.37,
    "out:Klimatpaverkan": 6.76,
    "out:Initial Cost/MSEK": 4.906,
    "out:Running cost/(Apt SEK y)": 36995,
    "out:Running Cost over RSP/MSEK": 15.612,
    "out:LCP/MSEK": -1.014,
    "out:ROI% old": 11.33,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 498,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 164768,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164768,
    "out:DH kr savings": 164768,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00010200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 141.3,
    "out:Total Energy Use Post PV": 137.29,
    "out:Primary Energy": 124.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.61,
    "out:CO2 Operational/m2": 35.18,
    "out:Total CO2/m2": 54.79,
    "out:Total CO2 (tons)": 282.09,
    "out:Klimatpaverkan": -7.52,
    "out:Initial Cost/MSEK": 5.214,
    "out:Running cost/(Apt SEK y)": 38194,
    "out:Running Cost over RSP/MSEK": 16.131,
    "out:LCP/MSEK": -1.841,
    "out:ROI% old": 9.24,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 123576,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183234,
    "out:DH kr savings": 123576,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 136.3,
    "out:Total Energy Use Post PV": 132.29,
    "out:Primary Energy": 120.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.61,
    "out:CO2 Operational/m2": 33.38,
    "out:Total CO2/m2": 52.99,
    "out:Total CO2 (tons)": 272.83,
    "out:Klimatpaverkan": -16.78,
    "out:Initial Cost/MSEK": 5.343,
    "out:Running cost/(Apt SEK y)": 36754,
    "out:Running Cost over RSP/MSEK": 15.523,
    "out:LCP/MSEK": -1.361,
    "out:ROI% old": 10.65,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 208979,
    "out:DH kr savings": 149321,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 137.3,
    "out:Total Energy Use Post PV": 133.29,
    "out:Primary Energy": 122.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.89,
    "out:CO2 Operational/m2": 33.74,
    "out:Total CO2/m2": 53.63,
    "out:Total CO2 (tons)": 276.13,
    "out:Klimatpaverkan": -13.48,
    "out:Initial Cost/MSEK": 5.39,
    "out:Running cost/(Apt SEK y)": 37042,
    "out:Running Cost over RSP/MSEK": 15.644,
    "out:LCP/MSEK": -1.53,
    "out:ROI% old": 10.23,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 495,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 144172,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 203830,
    "out:DH kr savings": 144172,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 133.3,
    "out:Total Energy Use Post PV": 129.29,
    "out:Primary Energy": 118.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.89,
    "out:CO2 Operational/m2": 32.3,
    "out:Total CO2/m2": 52.19,
    "out:Total CO2 (tons)": 268.72,
    "out:Klimatpaverkan": -20.89,
    "out:Initial Cost/MSEK": 5.518,
    "out:Running cost/(Apt SEK y)": 35890,
    "out:Running Cost over RSP/MSEK": 15.158,
    "out:LCP/MSEK": -1.172,
    "out:ROI% old": 11.25,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 164768,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 224426,
    "out:DH kr savings": 164768,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00010201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 141.69,
    "out:Total Energy Use Post PV": 141.69,
    "out:Primary Energy": 137.7,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.89,
    "out:CO2 Operational/m2": 46.52,
    "out:Total CO2/m2": 61.41,
    "out:Total CO2 (tons)": 316.21,
    "out:Klimatpaverkan": 26.6,
    "out:Initial Cost/MSEK": 5.256,
    "out:Running cost/(Apt SEK y)": 38887,
    "out:Running Cost over RSP/MSEK": 16.406,
    "out:LCP/MSEK": -2.158,
    "out:ROI% old": 8.42,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 8,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93786,
    "out:DH kr savings": 149321,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 136.69,
    "out:Total Energy Use Post PV": 136.69,
    "out:Primary Energy": 133.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.89,
    "out:CO2 Operational/m2": 44.72,
    "out:Total CO2/m2": 59.61,
    "out:Total CO2 (tons)": 306.94,
    "out:Klimatpaverkan": 17.33,
    "out:Initial Cost/MSEK": 5.385,
    "out:Running cost/(Apt SEK y)": 37447,
    "out:Running Cost over RSP/MSEK": 15.798,
    "out:LCP/MSEK": -1.679,
    "out:ROI% old": 9.83,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 473,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119531,
    "out:DH kr savings": 175066,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 137.69,
    "out:Total Energy Use Post PV": 137.69,
    "out:Primary Energy": 135.07,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.18,
    "out:CO2 Operational/m2": 45.08,
    "out:Total CO2/m2": 60.25,
    "out:Total CO2 (tons)": 310.24,
    "out:Klimatpaverkan": 20.63,
    "out:Initial Cost/MSEK": 5.432,
    "out:Running cost/(Apt SEK y)": 37735,
    "out:Running Cost over RSP/MSEK": 15.92,
    "out:LCP/MSEK": -1.847,
    "out:ROI% old": 9.43,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 8,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 169917,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114382,
    "out:DH kr savings": 169917,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 132.69,
    "out:Total Energy Use Post PV": 132.69,
    "out:Primary Energy": 131.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.18,
    "out:CO2 Operational/m2": 43.28,
    "out:Total CO2/m2": 58.45,
    "out:Total CO2 (tons)": 300.97,
    "out:Klimatpaverkan": 11.36,
    "out:Initial Cost/MSEK": 5.561,
    "out:Running cost/(Apt SEK y)": 36295,
    "out:Running Cost over RSP/MSEK": 15.312,
    "out:LCP/MSEK": -1.368,
    "out:ROI% old": 10.77,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140127,
    "out:DH kr savings": 195662,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 141.69,
    "out:Total Energy Use Post PV": 136.84,
    "out:Primary Energy": 128.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.95,
    "out:CO2 Operational/m2": 38.29,
    "out:Total CO2/m2": 60.24,
    "out:Total CO2 (tons)": 310.16,
    "out:Klimatpaverkan": 20.55,
    "out:Initial Cost/MSEK": 5.868,
    "out:Running cost/(Apt SEK y)": 37742,
    "out:Running Cost over RSP/MSEK": 15.936,
    "out:LCP/MSEK": -2.3,
    "out:ROI% old": 8.69,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155613,
    "out:DH kr savings": 149321,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 136.69,
    "out:Total Energy Use Post PV": 131.84,
    "out:Primary Energy": 125.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.95,
    "out:CO2 Operational/m2": 36.49,
    "out:Total CO2/m2": 58.44,
    "out:Total CO2 (tons)": 300.9,
    "out:Klimatpaverkan": 11.29,
    "out:Initial Cost/MSEK": 5.997,
    "out:Running cost/(Apt SEK y)": 36302,
    "out:Running Cost over RSP/MSEK": 15.328,
    "out:LCP/MSEK": -1.82,
    "out:ROI% old": 9.95,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181358,
    "out:DH kr savings": 175066,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 137.69,
    "out:Total Energy Use Post PV": 132.84,
    "out:Primary Energy": 126.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.23,
    "out:CO2 Operational/m2": 36.85,
    "out:Total CO2/m2": 59.08,
    "out:Total CO2 (tons)": 304.2,
    "out:Klimatpaverkan": 14.59,
    "out:Initial Cost/MSEK": 6.044,
    "out:Running cost/(Apt SEK y)": 36590,
    "out:Running Cost over RSP/MSEK": 15.449,
    "out:LCP/MSEK": -1.989,
    "out:ROI% old": 9.58,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 519,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 169917,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176209,
    "out:DH kr savings": 169917,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 132.69,
    "out:Total Energy Use Post PV": 127.84,
    "out:Primary Energy": 122.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.23,
    "out:CO2 Operational/m2": 35.05,
    "out:Total CO2/m2": 57.28,
    "out:Total CO2 (tons)": 294.93,
    "out:Klimatpaverkan": 5.32,
    "out:Initial Cost/MSEK": 6.173,
    "out:Running cost/(Apt SEK y)": 35150,
    "out:Running Cost over RSP/MSEK": 14.841,
    "out:LCP/MSEK": -1.509,
    "out:ROI% old": 10.79,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 597,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201954,
    "out:DH kr savings": 195662,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 135.69,
    "out:Primary Energy": 134.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.89,
    "out:CO2 Operational/m2": 44.36,
    "out:Total CO2/m2": 59.25,
    "out:Total CO2 (tons)": 305.09,
    "out:Klimatpaverkan": 15.48,
    "out:Initial Cost/MSEK": 5.399,
    "out:Running cost/(Apt SEK y)": 37159,
    "out:Running Cost over RSP/MSEK": 15.677,
    "out:LCP/MSEK": -1.571,
    "out:ROI% old": 10.13,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 489,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124680,
    "out:DH kr savings": 180215,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 131.69,
    "out:Total Energy Use Post PV": 131.69,
    "out:Primary Energy": 130.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.89,
    "out:CO2 Operational/m2": 42.92,
    "out:Total CO2/m2": 57.81,
    "out:Total CO2 (tons)": 297.67,
    "out:Klimatpaverkan": 8.06,
    "out:Initial Cost/MSEK": 5.527,
    "out:Running cost/(Apt SEK y)": 36007,
    "out:Running Cost over RSP/MSEK": 15.19,
    "out:LCP/MSEK": -1.213,
    "out:ROI% old": 11.15,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145276,
    "out:DH kr savings": 200811,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 132.69,
    "out:Total Energy Use Post PV": 132.69,
    "out:Primary Energy": 132.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.18,
    "out:CO2 Operational/m2": 43.28,
    "out:Total CO2/m2": 58.45,
    "out:Total CO2 (tons)": 300.97,
    "out:Klimatpaverkan": 11.36,
    "out:Initial Cost/MSEK": 5.574,
    "out:Running cost/(Apt SEK y)": 36295,
    "out:Running Cost over RSP/MSEK": 15.312,
    "out:LCP/MSEK": -1.382,
    "out:ROI% old": 10.74,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140127,
    "out:DH kr savings": 195662,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 128.69,
    "out:Total Energy Use Post PV": 128.69,
    "out:Primary Energy": 128.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.18,
    "out:CO2 Operational/m2": 41.84,
    "out:Total CO2/m2": 57.01,
    "out:Total CO2 (tons)": 293.56,
    "out:Klimatpaverkan": 3.95,
    "out:Initial Cost/MSEK": 5.703,
    "out:Running cost/(Apt SEK y)": 35143,
    "out:Running Cost over RSP/MSEK": 14.825,
    "out:LCP/MSEK": -1.024,
    "out:ROI% old": 11.72,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 598,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160723,
    "out:DH kr savings": 216258,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00010201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 130.84,
    "out:Primary Energy": 125.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.95,
    "out:CO2 Operational/m2": 36.13,
    "out:Total CO2/m2": 58.08,
    "out:Total CO2 (tons)": 299.04,
    "out:Klimatpaverkan": 9.43,
    "out:Initial Cost/MSEK": 6.011,
    "out:Running cost/(Apt SEK y)": 36014,
    "out:Running Cost over RSP/MSEK": 15.206,
    "out:LCP/MSEK": -1.712,
    "out:ROI% old": 10.22,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186507,
    "out:DH kr savings": 180215,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 131.69,
    "out:Total Energy Use Post PV": 126.84,
    "out:Primary Energy": 121.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.95,
    "out:CO2 Operational/m2": 34.69,
    "out:Total CO2/m2": 56.64,
    "out:Total CO2 (tons)": 291.63,
    "out:Klimatpaverkan": 2.02,
    "out:Initial Cost/MSEK": 6.14,
    "out:Running cost/(Apt SEK y)": 34862,
    "out:Running Cost over RSP/MSEK": 14.719,
    "out:LCP/MSEK": -1.355,
    "out:ROI% old": 11.13,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 613,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207103,
    "out:DH kr savings": 200811,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.03,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 132.69,
    "out:Total Energy Use Post PV": 127.84,
    "out:Primary Energy": 123.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.23,
    "out:CO2 Operational/m2": 35.05,
    "out:Total CO2/m2": 57.28,
    "out:Total CO2 (tons)": 294.93,
    "out:Klimatpaverkan": 5.32,
    "out:Initial Cost/MSEK": 6.186,
    "out:Running cost/(Apt SEK y)": 35150,
    "out:Running Cost over RSP/MSEK": 14.841,
    "out:LCP/MSEK": -1.523,
    "out:ROI% old": 10.77,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 597,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201954,
    "out:DH kr savings": 195662,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 540783,
    "out:% savings (space heating)": 14.97,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.03,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00010201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.53,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 128.69,
    "out:Total Energy Use Post PV": 123.84,
    "out:Primary Energy": 119.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.23,
    "out:CO2 Operational/m2": 33.61,
    "out:Total CO2/m2": 55.84,
    "out:Total CO2 (tons)": 287.52,
    "out:Klimatpaverkan": -2.09,
    "out:Initial Cost/MSEK": 6.315,
    "out:Running cost/(Apt SEK y)": 33998,
    "out:Running Cost over RSP/MSEK": 14.355,
    "out:LCP/MSEK": -1.165,
    "out:ROI% old": 11.65,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.97,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 659,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222550,
    "out:DH kr savings": 216258,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 517606,
    "out:% savings (space heating)": 18.61,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.53,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.07,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 154.3,
    "out:Total Energy Use Post PV": 154.3,
    "out:Primary Energy": 142.18,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1,
    "out:CO2 Operational/m2": 52.29,
    "out:Total CO2/m2": 53.28,
    "out:Total CO2 (tons)": 274.36,
    "out:Klimatpaverkan": -15.25,
    "out:Initial Cost/MSEK": 0.398,
    "out:Running cost/(Apt SEK y)": 43042,
    "out:Running Cost over RSP/MSEK": 18.166,
    "out:LCP/MSEK": 0.94,
    "out:ROI% old": 48.02,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 7.1,
    "out:Op energy cost/MSEK": 133,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 43,
    "out:Return/kSEK/y": 171,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 56639,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 56639,
    "out:DH kr savings": 56639,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 582213,
    "out:% savings (space heating)": 8.46,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.07,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.21,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 149.3,
    "out:Total Energy Use Post PV": 149.3,
    "out:Primary Energy": 137.92,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1,
    "out:CO2 Operational/m2": 50.49,
    "out:Total CO2/m2": 51.48,
    "out:Total CO2 (tons)": 265.1,
    "out:Klimatpaverkan": -24.51,
    "out:Initial Cost/MSEK": 0.527,
    "out:Running cost/(Apt SEK y)": 41602,
    "out:Running Cost over RSP/MSEK": 17.558,
    "out:LCP/MSEK": 1.419,
    "out:ROI% old": 52.78,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 10.7,
    "out:Op energy cost/MSEK": 128,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 47,
    "out:Return/kSEK/y": 249,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 82384,
    "out:DH kr savings": 82384,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 557153,
    "out:% savings (space heating)": 12.4,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.21,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.07,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 150.3,
    "out:Total Energy Use Post PV": 150.3,
    "out:Primary Energy": 139.55,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.28,
    "out:CO2 Operational/m2": 50.85,
    "out:Total CO2/m2": 52.13,
    "out:Total CO2 (tons)": 268.4,
    "out:Klimatpaverkan": -21.21,
    "out:Initial Cost/MSEK": 0.574,
    "out:Running cost/(Apt SEK y)": 41890,
    "out:Running Cost over RSP/MSEK": 17.68,
    "out:LCP/MSEK": 1.251,
    "out:ROI% old": 45.43,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 129,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 41,
    "out:Return/kSEK/y": 233,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 77235,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77235,
    "out:DH kr savings": 77235,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 582213,
    "out:% savings (space heating)": 8.46,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.07,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.21,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 145.3,
    "out:Primary Energy": 135.3,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.28,
    "out:CO2 Operational/m2": 49.05,
    "out:Total CO2/m2": 50.33,
    "out:Total CO2 (tons)": 259.13,
    "out:Klimatpaverkan": -30.48,
    "out:Initial Cost/MSEK": 0.702,
    "out:Running cost/(Apt SEK y)": 40450,
    "out:Running Cost over RSP/MSEK": 17.072,
    "out:LCP/MSEK": 1.73,
    "out:ROI% old": 49.48,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 44,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102980,
    "out:DH kr savings": 102980,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 557153,
    "out:% savings (space heating)": 12.4,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.21,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.07,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 154.3,
    "out:Total Energy Use Post PV": 150.29,
    "out:Primary Energy": 134.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.06,
    "out:CO2 Operational/m2": 39.86,
    "out:Total CO2/m2": 47.91,
    "out:Total CO2 (tons)": 246.71,
    "out:Klimatpaverkan": -42.9,
    "out:Initial Cost/MSEK": 1.01,
    "out:Running cost/(Apt SEK y)": 41937,
    "out:Running Cost over RSP/MSEK": 17.712,
    "out:LCP/MSEK": 0.782,
    "out:ROI% old": 25.35,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 130,
    "out:El price/MSEK": 78,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 23,
    "out:Return/kSEK/y": 231,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 56639,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116297,
    "out:DH kr savings": 56639,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 582213,
    "out:% savings (space heating)": 8.46,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.07,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.21,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 149.3,
    "out:Total Energy Use Post PV": 145.29,
    "out:Primary Energy": 130.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.06,
    "out:CO2 Operational/m2": 38.06,
    "out:Total CO2/m2": 46.11,
    "out:Total CO2 (tons)": 237.45,
    "out:Klimatpaverkan": -52.16,
    "out:Initial Cost/MSEK": 1.139,
    "out:Running cost/(Apt SEK y)": 40497,
    "out:Running Cost over RSP/MSEK": 17.104,
    "out:LCP/MSEK": 1.261,
    "out:ROI% old": 30.11,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 27,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142042,
    "out:DH kr savings": 82384,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 557153,
    "out:% savings (space heating)": 12.4,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.21,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.07,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 150.3,
    "out:Total Energy Use Post PV": 146.29,
    "out:Primary Energy": 132.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.34,
    "out:CO2 Operational/m2": 38.42,
    "out:Total CO2/m2": 46.76,
    "out:Total CO2 (tons)": 240.75,
    "out:Klimatpaverkan": -48.86,
    "out:Initial Cost/MSEK": 1.186,
    "out:Running cost/(Apt SEK y)": 40785,
    "out:Running Cost over RSP/MSEK": 17.226,
    "out:LCP/MSEK": 1.093,
    "out:ROI% old": 27.45,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 25,
    "out:Return/kSEK/y": 293,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 77235,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136893,
    "out:DH kr savings": 77235,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 582213,
    "out:% savings (space heating)": 8.46,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.07,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.21,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 141.29,
    "out:Primary Energy": 128.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.34,
    "out:CO2 Operational/m2": 36.62,
    "out:Total CO2/m2": 44.96,
    "out:Total CO2 (tons)": 231.48,
    "out:Klimatpaverkan": -58.13,
    "out:Initial Cost/MSEK": 1.314,
    "out:Running cost/(Apt SEK y)": 39345,
    "out:Running Cost over RSP/MSEK": 16.618,
    "out:LCP/MSEK": 1.572,
    "out:ROI% old": 31.38,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 28,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162638,
    "out:DH kr savings": 102980,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 557153,
    "out:% savings (space heating)": 12.4,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.21,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.07,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 149.3,
    "out:Total Energy Use Post PV": 149.3,
    "out:Primary Energy": 138.68,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1,
    "out:CO2 Operational/m2": 50.49,
    "out:Total CO2/m2": 51.48,
    "out:Total CO2 (tons)": 265.1,
    "out:Klimatpaverkan": -24.51,
    "out:Initial Cost/MSEK": 0.541,
    "out:Running cost/(Apt SEK y)": 41602,
    "out:Running Cost over RSP/MSEK": 17.558,
    "out:LCP/MSEK": 1.406,
    "out:ROI% old": 51.43,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 10.7,
    "out:Op energy cost/MSEK": 128,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 46,
    "out:Return/kSEK/y": 249,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82384,
    "out:DH kr savings": 82384,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 582213,
    "out:% savings (space heating)": 8.46,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.07,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.21,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 144.3,
    "out:Total Energy Use Post PV": 144.3,
    "out:Primary Energy": 134.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1,
    "out:CO2 Operational/m2": 48.69,
    "out:Total CO2/m2": 49.68,
    "out:Total CO2 (tons)": 255.83,
    "out:Klimatpaverkan": -33.78,
    "out:Initial Cost/MSEK": 0.669,
    "out:Running cost/(Apt SEK y)": 40162,
    "out:Running Cost over RSP/MSEK": 16.95,
    "out:LCP/MSEK": 1.885,
    "out:ROI% old": 54.52,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 14.6,
    "out:Op energy cost/MSEK": 124,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 49,
    "out:Return/kSEK/y": 327,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 108129,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 108129,
    "out:DH kr savings": 108129,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 557153,
    "out:% savings (space heating)": 12.4,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.21,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.07,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 146.3,
    "out:Total Energy Use Post PV": 146.3,
    "out:Primary Energy": 136.58,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.28,
    "out:CO2 Operational/m2": 49.41,
    "out:Total CO2/m2": 50.69,
    "out:Total CO2 (tons)": 260.98,
    "out:Klimatpaverkan": -28.63,
    "out:Initial Cost/MSEK": 0.716,
    "out:Running cost/(Apt SEK y)": 40738,
    "out:Running Cost over RSP/MSEK": 17.193,
    "out:LCP/MSEK": 1.595,
    "out:ROI% old": 46.1,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 41,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 97831,
    "out:DH kr savings": 97831,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 582213,
    "out:% savings (space heating)": 8.46,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.07,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.21,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 141.3,
    "out:Total Energy Use Post PV": 141.3,
    "out:Primary Energy": 132.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.28,
    "out:CO2 Operational/m2": 47.61,
    "out:Total CO2/m2": 48.89,
    "out:Total CO2 (tons)": 251.72,
    "out:Klimatpaverkan": -37.89,
    "out:Initial Cost/MSEK": 0.845,
    "out:Running cost/(Apt SEK y)": 39298,
    "out:Running Cost over RSP/MSEK": 16.585,
    "out:LCP/MSEK": 2.074,
    "out:ROI% old": 49.36,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 121,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 44,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 123576,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123576,
    "out:DH kr savings": 123576,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 557153,
    "out:% savings (space heating)": 12.4,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.21,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.07,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 149.3,
    "out:Total Energy Use Post PV": 145.29,
    "out:Primary Energy": 131.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.06,
    "out:CO2 Operational/m2": 38.06,
    "out:Total CO2/m2": 46.11,
    "out:Total CO2 (tons)": 237.45,
    "out:Klimatpaverkan": -52.16,
    "out:Initial Cost/MSEK": 1.153,
    "out:Running cost/(Apt SEK y)": 40497,
    "out:Running Cost over RSP/MSEK": 17.104,
    "out:LCP/MSEK": 1.248,
    "out:ROI% old": 29.75,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 27,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142042,
    "out:DH kr savings": 82384,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 582213,
    "out:% savings (space heating)": 8.46,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.07,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.21,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 144.3,
    "out:Total Energy Use Post PV": 140.29,
    "out:Primary Energy": 127.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.06,
    "out:CO2 Operational/m2": 36.26,
    "out:Total CO2/m2": 44.32,
    "out:Total CO2 (tons)": 228.18,
    "out:Klimatpaverkan": -61.43,
    "out:Initial Cost/MSEK": 1.281,
    "out:Running cost/(Apt SEK y)": 39057,
    "out:Running Cost over RSP/MSEK": 16.496,
    "out:LCP/MSEK": 1.727,
    "out:ROI% old": 33.54,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 121,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 30,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 108129,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167787,
    "out:DH kr savings": 108129,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 557153,
    "out:% savings (space heating)": 12.4,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.21,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.07,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 146.3,
    "out:Total Energy Use Post PV": 142.29,
    "out:Primary Energy": 129.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.34,
    "out:CO2 Operational/m2": 36.98,
    "out:Total CO2/m2": 45.32,
    "out:Total CO2 (tons)": 233.34,
    "out:Klimatpaverkan": -56.27,
    "out:Initial Cost/MSEK": 1.328,
    "out:Running cost/(Apt SEK y)": 39633,
    "out:Running Cost over RSP/MSEK": 16.739,
    "out:LCP/MSEK": 1.437,
    "out:ROI% old": 29.74,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 27,
    "out:Return/kSEK/y": 355,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 97831,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157489,
    "out:DH kr savings": 97831,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 582213,
    "out:% savings (space heating)": 8.46,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.07,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.21,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 141.3,
    "out:Total Energy Use Post PV": 137.29,
    "out:Primary Energy": 125.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.34,
    "out:CO2 Operational/m2": 35.18,
    "out:Total CO2/m2": 43.52,
    "out:Total CO2 (tons)": 224.07,
    "out:Klimatpaverkan": -65.54,
    "out:Initial Cost/MSEK": 1.457,
    "out:Running cost/(Apt SEK y)": 38194,
    "out:Running Cost over RSP/MSEK": 16.131,
    "out:LCP/MSEK": 1.916,
    "out:ROI% old": 33.08,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 20.4,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 30,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 123576,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 183234,
    "out:DH kr savings": 123576,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 557153,
    "out:% savings (space heating)": 12.4,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.21,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.07,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 149.69,
    "out:Total Energy Use Post PV": 149.69,
    "out:Primary Energy": 144.74,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.34,
    "out:CO2 Operational/m2": 49.4,
    "out:Total CO2/m2": 52.74,
    "out:Total CO2 (tons)": 271.56,
    "out:Klimatpaverkan": -18.05,
    "out:Initial Cost/MSEK": 1.195,
    "out:Running cost/(Apt SEK y)": 41191,
    "out:Running Cost over RSP/MSEK": 17.379,
    "out:LCP/MSEK": 0.93,
    "out:ROI% old": 25.41,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 127,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 23,
    "out:Return/kSEK/y": 271,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 108129,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 52594,
    "out:DH kr savings": 108129,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 582213,
    "out:% savings (space heating)": 8.46,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.07,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.21,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 144.69,
    "out:Total Energy Use Post PV": 144.69,
    "out:Primary Energy": 140.48,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.34,
    "out:CO2 Operational/m2": 47.6,
    "out:Total CO2/m2": 50.94,
    "out:Total CO2 (tons)": 262.29,
    "out:Klimatpaverkan": -27.32,
    "out:Initial Cost/MSEK": 1.324,
    "out:Running cost/(Apt SEK y)": 39751,
    "out:Running Cost over RSP/MSEK": 16.771,
    "out:LCP/MSEK": 1.409,
    "out:ROI% old": 29.5,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 26,
    "out:Return/kSEK/y": 349,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 133874,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 78339,
    "out:DH kr savings": 133874,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 557153,
    "out:% savings (space heating)": 12.4,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.21,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.07,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 145.69,
    "out:Total Energy Use Post PV": 145.69,
    "out:Primary Energy": 142.12,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.62,
    "out:CO2 Operational/m2": 47.96,
    "out:Total CO2/m2": 51.58,
    "out:Total CO2 (tons)": 265.59,
    "out:Klimatpaverkan": -24.02,
    "out:Initial Cost/MSEK": 1.371,
    "out:Running cost/(Apt SEK y)": 40039,
    "out:Running Cost over RSP/MSEK": 16.893,
    "out:LCP/MSEK": 1.241,
    "out:ROI% old": 27.22,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 13,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 77,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 24,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 73190,
    "out:DH kr savings": 128725,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 582213,
    "out:% savings (space heating)": 8.46,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.07,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.21,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 140.69,
    "out:Total Energy Use Post PV": 140.69,
    "out:Primary Energy": 137.86,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.62,
    "out:CO2 Operational/m2": 46.16,
    "out:Total CO2/m2": 49.78,
    "out:Total CO2 (tons)": 256.33,
    "out:Klimatpaverkan": -33.28,
    "out:Initial Cost/MSEK": 1.499,
    "out:Running cost/(Apt SEK y)": 38599,
    "out:Running Cost over RSP/MSEK": 16.285,
    "out:LCP/MSEK": 1.72,
    "out:ROI% old": 30.68,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 27,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 98935,
    "out:DH kr savings": 154470,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 557153,
    "out:% savings (space heating)": 12.4,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.21,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.07,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 149.69,
    "out:Total Energy Use Post PV": 144.84,
    "out:Primary Energy": 136,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.4,
    "out:CO2 Operational/m2": 41.16,
    "out:Total CO2/m2": 51.57,
    "out:Total CO2 (tons)": 265.51,
    "out:Klimatpaverkan": -24.1,
    "out:Initial Cost/MSEK": 1.807,
    "out:Running cost/(Apt SEK y)": 40046,
    "out:Running Cost over RSP/MSEK": 16.909,
    "out:LCP/MSEK": 0.789,
    "out:ROI% old": 20.52,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 124,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 18,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 108129,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114421,
    "out:DH kr savings": 108129,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 582213,
    "out:% savings (space heating)": 8.46,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.07,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.21,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 144.69,
    "out:Total Energy Use Post PV": 139.84,
    "out:Primary Energy": 131.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.4,
    "out:CO2 Operational/m2": 39.36,
    "out:Total CO2/m2": 49.77,
    "out:Total CO2 (tons)": 256.25,
    "out:Klimatpaverkan": -33.36,
    "out:Initial Cost/MSEK": 1.936,
    "out:Running cost/(Apt SEK y)": 38606,
    "out:Running Cost over RSP/MSEK": 16.301,
    "out:LCP/MSEK": 1.268,
    "out:ROI% old": 23.65,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 133874,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140166,
    "out:DH kr savings": 133874,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 557153,
    "out:% savings (space heating)": 12.4,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.21,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.07,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 145.69,
    "out:Total Energy Use Post PV": 140.84,
    "out:Primary Energy": 133.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.68,
    "out:CO2 Operational/m2": 39.72,
    "out:Total CO2/m2": 50.41,
    "out:Total CO2 (tons)": 259.55,
    "out:Klimatpaverkan": -30.06,
    "out:Initial Cost/MSEK": 1.983,
    "out:Running cost/(Apt SEK y)": 38894,
    "out:Running Cost over RSP/MSEK": 16.422,
    "out:LCP/MSEK": 1.1,
    "out:ROI% old": 22.21,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135017,
    "out:DH kr savings": 128725,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 582213,
    "out:% savings (space heating)": 8.46,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.07,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.21,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 140.69,
    "out:Total Energy Use Post PV": 135.84,
    "out:Primary Energy": 129.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.68,
    "out:CO2 Operational/m2": 37.93,
    "out:Total CO2/m2": 48.61,
    "out:Total CO2 (tons)": 250.28,
    "out:Klimatpaverkan": -39.33,
    "out:Initial Cost/MSEK": 2.111,
    "out:Running cost/(Apt SEK y)": 37454,
    "out:Running Cost over RSP/MSEK": 15.814,
    "out:LCP/MSEK": 1.579,
    "out:ROI% old": 24.97,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 473,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160762,
    "out:DH kr savings": 154470,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 557153,
    "out:% savings (space heating)": 12.4,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.21,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.07,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 144.69,
    "out:Total Energy Use Post PV": 144.69,
    "out:Primary Energy": 141.24,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.34,
    "out:CO2 Operational/m2": 47.6,
    "out:Total CO2/m2": 50.94,
    "out:Total CO2 (tons)": 262.29,
    "out:Klimatpaverkan": -27.32,
    "out:Initial Cost/MSEK": 1.337,
    "out:Running cost/(Apt SEK y)": 39751,
    "out:Running Cost over RSP/MSEK": 16.771,
    "out:LCP/MSEK": 1.396,
    "out:ROI% old": 29.19,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 26,
    "out:Return/kSEK/y": 349,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 133874,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 78339,
    "out:DH kr savings": 133874,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 582213,
    "out:% savings (space heating)": 8.46,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.07,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.21,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 139.69,
    "out:Total Energy Use Post PV": 139.69,
    "out:Primary Energy": 136.98,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.34,
    "out:CO2 Operational/m2": 45.8,
    "out:Total CO2/m2": 49.14,
    "out:Total CO2 (tons)": 253.02,
    "out:Klimatpaverkan": -36.59,
    "out:Initial Cost/MSEK": 1.466,
    "out:Running cost/(Apt SEK y)": 38311,
    "out:Running Cost over RSP/MSEK": 16.163,
    "out:LCP/MSEK": 1.875,
    "out:ROI% old": 32.56,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 29,
    "out:Return/kSEK/y": 427,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104084,
    "out:DH kr savings": 159619,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 557153,
    "out:% savings (space heating)": 12.4,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.21,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.07,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 140.69,
    "out:Total Energy Use Post PV": 140.69,
    "out:Primary Energy": 139.14,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.62,
    "out:CO2 Operational/m2": 46.16,
    "out:Total CO2/m2": 49.78,
    "out:Total CO2 (tons)": 256.33,
    "out:Klimatpaverkan": -33.28,
    "out:Initial Cost/MSEK": 1.513,
    "out:Running cost/(Apt SEK y)": 38599,
    "out:Running Cost over RSP/MSEK": 16.285,
    "out:LCP/MSEK": 1.706,
    "out:ROI% old": 30.4,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 27,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98935,
    "out:DH kr savings": 154470,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 582213,
    "out:% savings (space heating)": 8.46,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.07,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.21,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 135.69,
    "out:Primary Energy": 134.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.62,
    "out:CO2 Operational/m2": 44.36,
    "out:Total CO2/m2": 47.98,
    "out:Total CO2 (tons)": 247.06,
    "out:Klimatpaverkan": -42.55,
    "out:Initial Cost/MSEK": 1.642,
    "out:Running cost/(Apt SEK y)": 37159,
    "out:Running Cost over RSP/MSEK": 15.677,
    "out:LCP/MSEK": 2.186,
    "out:ROI% old": 33.31,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 30,
    "out:Return/kSEK/y": 489,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124680,
    "out:DH kr savings": 180215,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 557153,
    "out:% savings (space heating)": 12.4,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.21,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.07,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 144.69,
    "out:Total Energy Use Post PV": 139.84,
    "out:Primary Energy": 132.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.4,
    "out:CO2 Operational/m2": 39.36,
    "out:Total CO2/m2": 49.77,
    "out:Total CO2 (tons)": 256.25,
    "out:Klimatpaverkan": -33.36,
    "out:Initial Cost/MSEK": 1.949,
    "out:Running cost/(Apt SEK y)": 38606,
    "out:Running Cost over RSP/MSEK": 16.301,
    "out:LCP/MSEK": 1.254,
    "out:ROI% old": 23.48,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 133874,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140166,
    "out:DH kr savings": 133874,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 582213,
    "out:% savings (space heating)": 8.46,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.07,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.21,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 139.69,
    "out:Total Energy Use Post PV": 134.84,
    "out:Primary Energy": 128.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.4,
    "out:CO2 Operational/m2": 37.57,
    "out:Total CO2/m2": 47.97,
    "out:Total CO2 (tons)": 246.98,
    "out:Klimatpaverkan": -42.63,
    "out:Initial Cost/MSEK": 2.078,
    "out:Running cost/(Apt SEK y)": 37166,
    "out:Running Cost over RSP/MSEK": 15.692,
    "out:LCP/MSEK": 1.734,
    "out:ROI% old": 26.2,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 23,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165911,
    "out:DH kr savings": 159619,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 557153,
    "out:% savings (space heating)": 12.4,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.21,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.07,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 140.69,
    "out:Total Energy Use Post PV": 135.84,
    "out:Primary Energy": 130.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.68,
    "out:CO2 Operational/m2": 37.93,
    "out:Total CO2/m2": 48.61,
    "out:Total CO2 (tons)": 250.28,
    "out:Klimatpaverkan": -39.33,
    "out:Initial Cost/MSEK": 2.125,
    "out:Running cost/(Apt SEK y)": 37454,
    "out:Running Cost over RSP/MSEK": 15.814,
    "out:LCP/MSEK": 1.565,
    "out:ROI% old": 24.81,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 473,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160762,
    "out:DH kr savings": 154470,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 582213,
    "out:% savings (space heating)": 8.46,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.07,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.21,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 130.84,
    "out:Primary Energy": 126.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.68,
    "out:CO2 Operational/m2": 36.13,
    "out:Total CO2/m2": 46.81,
    "out:Total CO2 (tons)": 241.02,
    "out:Klimatpaverkan": -48.59,
    "out:Initial Cost/MSEK": 2.254,
    "out:Running cost/(Apt SEK y)": 36014,
    "out:Running Cost over RSP/MSEK": 15.206,
    "out:LCP/MSEK": 2.045,
    "out:ROI% old": 27.25,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.16,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186507,
    "out:DH kr savings": 180215,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 557153,
    "out:% savings (space heating)": 12.4,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.21,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.64,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 140.3,
    "out:Primary Energy": 129.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.99,
    "out:CO2 Operational/m2": 47.25,
    "out:Total CO2/m2": 51.23,
    "out:Total CO2 (tons)": 263.8,
    "out:Klimatpaverkan": -25.81,
    "out:Initial Cost/MSEK": 1.691,
    "out:Running cost/(Apt SEK y)": 39010,
    "out:Running Cost over RSP/MSEK": 16.464,
    "out:LCP/MSEK": 1.35,
    "out:ROI% old": 25.69,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 23,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128725,
    "out:DH kr savings": 128725,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 507901,
    "out:% savings (space heating)": 20.14,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.64,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.43,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 136.3,
    "out:Total Energy Use Post PV": 136.3,
    "out:Primary Energy": 125.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.99,
    "out:CO2 Operational/m2": 45.81,
    "out:Total CO2/m2": 49.79,
    "out:Total CO2 (tons)": 256.39,
    "out:Klimatpaverkan": -33.22,
    "out:Initial Cost/MSEK": 1.82,
    "out:Running cost/(Apt SEK y)": 37858,
    "out:Running Cost over RSP/MSEK": 15.977,
    "out:LCP/MSEK": 1.707,
    "out:ROI% old": 27.69,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 25,
    "out:Return/kSEK/y": 451,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149321,
    "out:DH kr savings": 149321,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 486218,
    "out:% savings (space heating)": 23.55,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.43,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.64,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 136.3,
    "out:Total Energy Use Post PV": 136.3,
    "out:Primary Energy": 126.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.27,
    "out:CO2 Operational/m2": 45.81,
    "out:Total CO2/m2": 50.08,
    "out:Total CO2 (tons)": 257.84,
    "out:Klimatpaverkan": -31.77,
    "out:Initial Cost/MSEK": 1.867,
    "out:Running cost/(Apt SEK y)": 37858,
    "out:Running Cost over RSP/MSEK": 15.977,
    "out:LCP/MSEK": 1.66,
    "out:ROI% old": 26.99,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 72,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 24,
    "out:Return/kSEK/y": 451,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149321,
    "out:DH kr savings": 149321,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 507901,
    "out:% savings (space heating)": 20.14,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.64,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.43,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 131.3,
    "out:Primary Energy": 123.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.27,
    "out:CO2 Operational/m2": 44.01,
    "out:Total CO2/m2": 48.28,
    "out:Total CO2 (tons)": 248.57,
    "out:Klimatpaverkan": -41.04,
    "out:Initial Cost/MSEK": 1.996,
    "out:Running cost/(Apt SEK y)": 36419,
    "out:Running Cost over RSP/MSEK": 15.369,
    "out:LCP/MSEK": 2.14,
    "out:ROI% old": 29.6,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 26,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175066,
    "out:DH kr savings": 175066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 486218,
    "out:% savings (space heating)": 23.55,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.43,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.64,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 136.29,
    "out:Primary Energy": 122.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.05,
    "out:CO2 Operational/m2": 34.82,
    "out:Total CO2/m2": 45.86,
    "out:Total CO2 (tons)": 236.15,
    "out:Klimatpaverkan": -53.46,
    "out:Initial Cost/MSEK": 2.303,
    "out:Running cost/(Apt SEK y)": 37906,
    "out:Running Cost over RSP/MSEK": 16.009,
    "out:LCP/MSEK": 1.192,
    "out:ROI% old": 21.68,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188383,
    "out:DH kr savings": 128725,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 507901,
    "out:% savings (space heating)": 20.14,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.64,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.43,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 136.3,
    "out:Total Energy Use Post PV": 132.29,
    "out:Primary Energy": 118.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.05,
    "out:CO2 Operational/m2": 33.38,
    "out:Total CO2/m2": 44.42,
    "out:Total CO2 (tons)": 228.74,
    "out:Klimatpaverkan": -60.87,
    "out:Initial Cost/MSEK": 2.432,
    "out:Running cost/(Apt SEK y)": 36754,
    "out:Running Cost over RSP/MSEK": 15.523,
    "out:LCP/MSEK": 1.549,
    "out:ROI% old": 23.39,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 208979,
    "out:DH kr savings": 149321,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 486218,
    "out:% savings (space heating)": 23.55,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.43,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.64,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 136.3,
    "out:Total Energy Use Post PV": 132.29,
    "out:Primary Energy": 119.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.33,
    "out:CO2 Operational/m2": 33.38,
    "out:Total CO2/m2": 44.71,
    "out:Total CO2 (tons)": 230.19,
    "out:Klimatpaverkan": -59.42,
    "out:Initial Cost/MSEK": 2.479,
    "out:Running cost/(Apt SEK y)": 36754,
    "out:Running Cost over RSP/MSEK": 15.523,
    "out:LCP/MSEK": 1.503,
    "out:ROI% old": 22.95,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 149321,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 208979,
    "out:DH kr savings": 149321,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 507901,
    "out:% savings (space heating)": 20.14,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.64,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.43,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 127.29,
    "out:Primary Energy": 116.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.33,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 42.91,
    "out:Total CO2 (tons)": 220.92,
    "out:Klimatpaverkan": -68.69,
    "out:Initial Cost/MSEK": 2.608,
    "out:Running cost/(Apt SEK y)": 35314,
    "out:Running Cost over RSP/MSEK": 14.915,
    "out:LCP/MSEK": 1.982,
    "out:ROI% old": 25.14,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 23,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234724,
    "out:DH kr savings": 175066,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 486218,
    "out:% savings (space heating)": 23.55,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.43,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.64,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 134.3,
    "out:Total Energy Use Post PV": 134.3,
    "out:Primary Energy": 126.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.99,
    "out:CO2 Operational/m2": 45.09,
    "out:Total CO2/m2": 49.07,
    "out:Total CO2 (tons)": 252.68,
    "out:Klimatpaverkan": -36.93,
    "out:Initial Cost/MSEK": 1.834,
    "out:Running cost/(Apt SEK y)": 37283,
    "out:Running Cost over RSP/MSEK": 15.734,
    "out:LCP/MSEK": 1.937,
    "out:ROI% old": 29.38,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 23.1,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 26,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159619,
    "out:DH kr savings": 159619,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 507901,
    "out:% savings (space heating)": 20.14,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.64,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.43,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 130.3,
    "out:Total Energy Use Post PV": 130.3,
    "out:Primary Energy": 122.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.99,
    "out:CO2 Operational/m2": 43.65,
    "out:Total CO2/m2": 47.63,
    "out:Total CO2 (tons)": 245.27,
    "out:Klimatpaverkan": -44.34,
    "out:Initial Cost/MSEK": 1.962,
    "out:Running cost/(Apt SEK y)": 36131,
    "out:Running Cost over RSP/MSEK": 15.247,
    "out:LCP/MSEK": 2.295,
    "out:ROI% old": 30.99,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 28,
    "out:Return/kSEK/y": 544,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180215,
    "out:DH kr savings": 180215,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 486218,
    "out:% savings (space heating)": 23.55,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.43,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.64,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 131.3,
    "out:Primary Energy": 123.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.27,
    "out:CO2 Operational/m2": 44.01,
    "out:Total CO2/m2": 48.28,
    "out:Total CO2 (tons)": 248.57,
    "out:Klimatpaverkan": -41.04,
    "out:Initial Cost/MSEK": 2.009,
    "out:Running cost/(Apt SEK y)": 36419,
    "out:Running Cost over RSP/MSEK": 15.369,
    "out:LCP/MSEK": 2.126,
    "out:ROI% old": 29.4,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 26,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175066,
    "out:DH kr savings": 175066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 507901,
    "out:% savings (space heating)": 20.14,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.64,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.43,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 127.3,
    "out:Total Energy Use Post PV": 127.3,
    "out:Primary Energy": 120.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.27,
    "out:CO2 Operational/m2": 42.57,
    "out:Total CO2/m2": 46.84,
    "out:Total CO2 (tons)": 241.15,
    "out:Klimatpaverkan": -48.46,
    "out:Initial Cost/MSEK": 2.138,
    "out:Running cost/(Apt SEK y)": 35267,
    "out:Running Cost over RSP/MSEK": 14.882,
    "out:LCP/MSEK": 2.484,
    "out:ROI% old": 30.88,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 28,
    "out:Return/kSEK/y": 591,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195662,
    "out:DH kr savings": 195662,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 486218,
    "out:% savings (space heating)": 23.55,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.43,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.64,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 134.3,
    "out:Total Energy Use Post PV": 130.29,
    "out:Primary Energy": 118.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.05,
    "out:CO2 Operational/m2": 32.66,
    "out:Total CO2/m2": 43.7,
    "out:Total CO2 (tons)": 225.03,
    "out:Klimatpaverkan": -64.58,
    "out:Initial Cost/MSEK": 2.446,
    "out:Running cost/(Apt SEK y)": 36178,
    "out:Running Cost over RSP/MSEK": 15.28,
    "out:LCP/MSEK": 1.779,
    "out:ROI% old": 24.68,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 542,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 219277,
    "out:DH kr savings": 159619,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 507901,
    "out:% savings (space heating)": 20.14,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.64,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.43,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 130.3,
    "out:Total Energy Use Post PV": 126.29,
    "out:Primary Energy": 115.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.05,
    "out:CO2 Operational/m2": 31.22,
    "out:Total CO2/m2": 42.26,
    "out:Total CO2 (tons)": 217.62,
    "out:Klimatpaverkan": -71.99,
    "out:Initial Cost/MSEK": 2.574,
    "out:Running cost/(Apt SEK y)": 35026,
    "out:Running Cost over RSP/MSEK": 14.793,
    "out:LCP/MSEK": 2.137,
    "out:ROI% old": 26.14,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 23,
    "out:Return/kSEK/y": 604,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 239873,
    "out:DH kr savings": 180215,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 486218,
    "out:% savings (space heating)": 23.55,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.43,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.64,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 127.29,
    "out:Primary Energy": 116.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.33,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 42.91,
    "out:Total CO2 (tons)": 220.92,
    "out:Klimatpaverkan": -68.69,
    "out:Initial Cost/MSEK": 2.621,
    "out:Running cost/(Apt SEK y)": 35314,
    "out:Running Cost over RSP/MSEK": 14.915,
    "out:LCP/MSEK": 1.968,
    "out:ROI% old": 25.01,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 234724,
    "out:DH kr savings": 175066,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 507901,
    "out:% savings (space heating)": 20.14,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.64,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.43,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 127.3,
    "out:Total Energy Use Post PV": 123.29,
    "out:Primary Energy": 113.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.33,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 41.47,
    "out:Total CO2 (tons)": 213.51,
    "out:Klimatpaverkan": -76.1,
    "out:Initial Cost/MSEK": 2.75,
    "out:Running cost/(Apt SEK y)": 34162,
    "out:Running Cost over RSP/MSEK": 14.428,
    "out:LCP/MSEK": 2.326,
    "out:ROI% old": 26.37,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 34.1,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 651,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 255320,
    "out:DH kr savings": 195662,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 486218,
    "out:% savings (space heating)": 23.55,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.43,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.64,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 135.69,
    "out:Primary Energy": 132.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.33,
    "out:CO2 Operational/m2": 44.36,
    "out:Total CO2/m2": 50.69,
    "out:Total CO2 (tons)": 261,
    "out:Klimatpaverkan": -28.61,
    "out:Initial Cost/MSEK": 2.488,
    "out:Running cost/(Apt SEK y)": 37159,
    "out:Running Cost over RSP/MSEK": 15.677,
    "out:LCP/MSEK": 1.34,
    "out:ROI% old": 21.98,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 20,
    "out:Return/kSEK/y": 489,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124680,
    "out:DH kr savings": 180215,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 507901,
    "out:% savings (space heating)": 20.14,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.64,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.43,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 130.69,
    "out:Primary Energy": 128.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.33,
    "out:CO2 Operational/m2": 42.56,
    "out:Total CO2/m2": 48.89,
    "out:Total CO2 (tons)": 251.73,
    "out:Klimatpaverkan": -37.88,
    "out:Initial Cost/MSEK": 2.617,
    "out:Running cost/(Apt SEK y)": 35719,
    "out:Running Cost over RSP/MSEK": 15.068,
    "out:LCP/MSEK": 1.819,
    "out:ROI% old": 24.22,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 22,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 150425,
    "out:DH kr savings": 205960,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 486218,
    "out:% savings (space heating)": 23.55,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.43,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.64,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 130.69,
    "out:Primary Energy": 129.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.61,
    "out:CO2 Operational/m2": 42.56,
    "out:Total CO2/m2": 49.17,
    "out:Total CO2 (tons)": 253.18,
    "out:Klimatpaverkan": -36.43,
    "out:Initial Cost/MSEK": 2.664,
    "out:Running cost/(Apt SEK y)": 35719,
    "out:Running Cost over RSP/MSEK": 15.068,
    "out:LCP/MSEK": 1.772,
    "out:ROI% old": 23.79,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150425,
    "out:DH kr savings": 205960,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 507901,
    "out:% savings (space heating)": 20.14,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.64,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.43,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 126.69,
    "out:Primary Energy": 125.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.61,
    "out:CO2 Operational/m2": 41.12,
    "out:Total CO2/m2": 47.73,
    "out:Total CO2 (tons)": 245.76,
    "out:Klimatpaverkan": -43.85,
    "out:Initial Cost/MSEK": 2.792,
    "out:Running cost/(Apt SEK y)": 34567,
    "out:Running Cost over RSP/MSEK": 14.582,
    "out:LCP/MSEK": 2.13,
    "out:ROI% old": 25.18,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 23,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171021,
    "out:DH kr savings": 226556,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 486218,
    "out:% savings (space heating)": 23.55,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.43,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.64,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 130.84,
    "out:Primary Energy": 123.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.39,
    "out:CO2 Operational/m2": 36.13,
    "out:Total CO2/m2": 49.51,
    "out:Total CO2 (tons)": 254.95,
    "out:Klimatpaverkan": -34.66,
    "out:Initial Cost/MSEK": 3.1,
    "out:Running cost/(Apt SEK y)": 36014,
    "out:Running Cost over RSP/MSEK": 15.206,
    "out:LCP/MSEK": 1.198,
    "out:ROI% old": 19.81,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 18,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186507,
    "out:DH kr savings": 180215,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 507901,
    "out:% savings (space heating)": 20.14,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.64,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.43,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 125.84,
    "out:Primary Energy": 119.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.39,
    "out:CO2 Operational/m2": 34.33,
    "out:Total CO2/m2": 47.72,
    "out:Total CO2 (tons)": 245.68,
    "out:Klimatpaverkan": -43.93,
    "out:Initial Cost/MSEK": 3.229,
    "out:Running cost/(Apt SEK y)": 34574,
    "out:Running Cost over RSP/MSEK": 14.598,
    "out:LCP/MSEK": 1.678,
    "out:ROI% old": 21.71,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212252,
    "out:DH kr savings": 205960,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 486218,
    "out:% savings (space heating)": 23.55,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.43,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.64,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 125.84,
    "out:Primary Energy": 120.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.67,
    "out:CO2 Operational/m2": 34.33,
    "out:Total CO2/m2": 48,
    "out:Total CO2 (tons)": 247.14,
    "out:Klimatpaverkan": -42.47,
    "out:Initial Cost/MSEK": 3.276,
    "out:Running cost/(Apt SEK y)": 34574,
    "out:Running Cost over RSP/MSEK": 14.598,
    "out:LCP/MSEK": 1.631,
    "out:ROI% old": 21.4,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212252,
    "out:DH kr savings": 205960,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 507901,
    "out:% savings (space heating)": 20.14,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.64,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.43,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 121.84,
    "out:Primary Energy": 117.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.67,
    "out:CO2 Operational/m2": 32.89,
    "out:Total CO2/m2": 46.56,
    "out:Total CO2 (tons)": 239.72,
    "out:Klimatpaverkan": -49.89,
    "out:Initial Cost/MSEK": 3.404,
    "out:Running cost/(Apt SEK y)": 33423,
    "out:Running Cost over RSP/MSEK": 14.111,
    "out:LCP/MSEK": 1.989,
    "out:ROI% old": 22.63,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 232848,
    "out:DH kr savings": 226556,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 486218,
    "out:% savings (space heating)": 23.55,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.43,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.64,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 129.69,
    "out:Total Energy Use Post PV": 129.69,
    "out:Primary Energy": 128.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.33,
    "out:CO2 Operational/m2": 42.2,
    "out:Total CO2/m2": 48.53,
    "out:Total CO2 (tons)": 249.87,
    "out:Klimatpaverkan": -39.74,
    "out:Initial Cost/MSEK": 2.631,
    "out:Running cost/(Apt SEK y)": 35431,
    "out:Running Cost over RSP/MSEK": 14.947,
    "out:LCP/MSEK": 1.927,
    "out:ROI% old": 24.75,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 22,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155574,
    "out:DH kr savings": 211109,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 507901,
    "out:% savings (space heating)": 20.14,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.64,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.43,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 125.69,
    "out:Primary Energy": 124.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.33,
    "out:CO2 Operational/m2": 40.76,
    "out:Total CO2/m2": 47.09,
    "out:Total CO2 (tons)": 242.46,
    "out:Klimatpaverkan": -47.15,
    "out:Initial Cost/MSEK": 2.759,
    "out:Running cost/(Apt SEK y)": 34280,
    "out:Running Cost over RSP/MSEK": 14.46,
    "out:LCP/MSEK": 2.285,
    "out:ROI% old": 26.12,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 23,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176170,
    "out:DH kr savings": 231705,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 486218,
    "out:% savings (space heating)": 23.55,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.43,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.64,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 126.69,
    "out:Primary Energy": 126.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.61,
    "out:CO2 Operational/m2": 41.12,
    "out:Total CO2/m2": 47.73,
    "out:Total CO2 (tons)": 245.76,
    "out:Klimatpaverkan": -43.85,
    "out:Initial Cost/MSEK": 2.806,
    "out:Running cost/(Apt SEK y)": 34567,
    "out:Running Cost over RSP/MSEK": 14.582,
    "out:LCP/MSEK": 2.116,
    "out:ROI% old": 25.06,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 22,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171021,
    "out:DH kr savings": 226556,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 507901,
    "out:% savings (space heating)": 20.14,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.64,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.43,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 122.69,
    "out:Total Energy Use Post PV": 122.69,
    "out:Primary Energy": 122.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.61,
    "out:CO2 Operational/m2": 39.68,
    "out:Total CO2/m2": 46.29,
    "out:Total CO2 (tons)": 238.35,
    "out:Klimatpaverkan": -51.26,
    "out:Initial Cost/MSEK": 2.935,
    "out:Running cost/(Apt SEK y)": 33416,
    "out:Running Cost over RSP/MSEK": 14.095,
    "out:LCP/MSEK": 2.474,
    "out:ROI% old": 26.33,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 34.1,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 24,
    "out:Return/kSEK/y": 691,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191617,
    "out:DH kr savings": 247152,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 486218,
    "out:% savings (space heating)": 23.55,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.43,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.64,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 129.69,
    "out:Total Energy Use Post PV": 124.84,
    "out:Primary Energy": 119.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.39,
    "out:CO2 Operational/m2": 33.97,
    "out:Total CO2/m2": 47.36,
    "out:Total CO2 (tons)": 243.83,
    "out:Klimatpaverkan": -45.78,
    "out:Initial Cost/MSEK": 3.243,
    "out:Running cost/(Apt SEK y)": 34286,
    "out:Running Cost over RSP/MSEK": 14.476,
    "out:LCP/MSEK": 1.786,
    "out:ROI% old": 22.15,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217401,
    "out:DH kr savings": 211109,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 507901,
    "out:% savings (space heating)": 20.14,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.64,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.43,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 120.84,
    "out:Primary Energy": 116.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.39,
    "out:CO2 Operational/m2": 32.53,
    "out:Total CO2/m2": 45.92,
    "out:Total CO2 (tons)": 236.42,
    "out:Klimatpaverkan": -53.19,
    "out:Initial Cost/MSEK": 3.371,
    "out:Running cost/(Apt SEK y)": 33135,
    "out:Running Cost over RSP/MSEK": 13.99,
    "out:LCP/MSEK": 2.143,
    "out:ROI% old": 23.37,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 237997,
    "out:DH kr savings": 231705,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 486218,
    "out:% savings (space heating)": 23.55,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.43,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.64,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 121.84,
    "out:Primary Energy": 117.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.67,
    "out:CO2 Operational/m2": 32.89,
    "out:Total CO2/m2": 46.56,
    "out:Total CO2 (tons)": 239.72,
    "out:Klimatpaverkan": -49.89,
    "out:Initial Cost/MSEK": 3.418,
    "out:Running cost/(Apt SEK y)": 33423,
    "out:Running Cost over RSP/MSEK": 14.111,
    "out:LCP/MSEK": 1.975,
    "out:ROI% old": 22.54,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 232848,
    "out:DH kr savings": 226556,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 507901,
    "out:% savings (space heating)": 20.14,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.64,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.43,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 122.69,
    "out:Total Energy Use Post PV": 117.84,
    "out:Primary Energy": 114.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.67,
    "out:CO2 Operational/m2": 31.45,
    "out:Total CO2/m2": 45.12,
    "out:Total CO2 (tons)": 232.31,
    "out:Klimatpaverkan": -57.3,
    "out:Initial Cost/MSEK": 3.547,
    "out:Running cost/(Apt SEK y)": 32271,
    "out:Running Cost over RSP/MSEK": 13.625,
    "out:LCP/MSEK": 2.333,
    "out:ROI% old": 23.68,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 753,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 253444,
    "out:DH kr savings": 247152,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 486218,
    "out:% savings (space heating)": 23.55,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.43,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.18,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 135.3,
    "out:Primary Energy": 125.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.55,
    "out:CO2 Operational/m2": 45.45,
    "out:Total CO2/m2": 59,
    "out:Total CO2 (tons)": 303.77,
    "out:Klimatpaverkan": 14.16,
    "out:Initial Cost/MSEK": 4.857,
    "out:Running cost/(Apt SEK y)": 37571,
    "out:Running Cost over RSP/MSEK": 15.855,
    "out:LCP/MSEK": -1.209,
    "out:ROI% old": 10.73,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154470,
    "out:DH kr savings": 154470,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 484922,
    "out:% savings (space heating)": 23.75,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.17,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 131.3,
    "out:Primary Energy": 122.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.55,
    "out:CO2 Operational/m2": 44.01,
    "out:Total CO2/m2": 57.56,
    "out:Total CO2 (tons)": 296.35,
    "out:Klimatpaverkan": 6.74,
    "out:Initial Cost/MSEK": 4.986,
    "out:Running cost/(Apt SEK y)": 36419,
    "out:Running Cost over RSP/MSEK": 15.369,
    "out:LCP/MSEK": -0.851,
    "out:ROI% old": 11.85,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175066,
    "out:DH kr savings": 175066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 464285,
    "out:% savings (space heating)": 27,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.17,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.18,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 131.3,
    "out:Primary Energy": 123.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.83,
    "out:CO2 Operational/m2": 44.01,
    "out:Total CO2/m2": 57.84,
    "out:Total CO2 (tons)": 297.8,
    "out:Klimatpaverkan": 8.19,
    "out:Initial Cost/MSEK": 5.033,
    "out:Running cost/(Apt SEK y)": 36419,
    "out:Running Cost over RSP/MSEK": 15.369,
    "out:LCP/MSEK": -0.898,
    "out:ROI% old": 11.74,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175066,
    "out:DH kr savings": 175066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 484922,
    "out:% savings (space heating)": 23.75,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.17,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 127.3,
    "out:Total Energy Use Post PV": 127.3,
    "out:Primary Energy": 119.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.83,
    "out:CO2 Operational/m2": 42.57,
    "out:Total CO2/m2": 56.4,
    "out:Total CO2 (tons)": 290.39,
    "out:Klimatpaverkan": 0.78,
    "out:Initial Cost/MSEK": 5.162,
    "out:Running cost/(Apt SEK y)": 35267,
    "out:Running Cost over RSP/MSEK": 14.882,
    "out:LCP/MSEK": -0.54,
    "out:ROI% old": 12.79,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 591,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195662,
    "out:DH kr savings": 195662,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 464285,
    "out:% savings (space heating)": 27,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.17,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.18,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 131.29,
    "out:Primary Energy": 118.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.61,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 53.63,
    "out:Total CO2 (tons)": 276.12,
    "out:Klimatpaverkan": -13.49,
    "out:Initial Cost/MSEK": 5.469,
    "out:Running cost/(Apt SEK y)": 36466,
    "out:Running Cost over RSP/MSEK": 15.401,
    "out:LCP/MSEK": -1.366,
    "out:ROI% old": 10.72,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214128,
    "out:DH kr savings": 154470,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 484922,
    "out:% savings (space heating)": 23.75,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.17,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 127.29,
    "out:Primary Energy": 114.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.61,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 52.19,
    "out:Total CO2 (tons)": 268.7,
    "out:Klimatpaverkan": -20.91,
    "out:Initial Cost/MSEK": 5.598,
    "out:Running cost/(Apt SEK y)": 35314,
    "out:Running Cost over RSP/MSEK": 14.915,
    "out:LCP/MSEK": -1.009,
    "out:ROI% old": 11.71,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234724,
    "out:DH kr savings": 175066,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 464285,
    "out:% savings (space heating)": 27,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.17,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.18,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 127.29,
    "out:Primary Energy": 115.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.89,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 52.47,
    "out:Total CO2 (tons)": 270.15,
    "out:Klimatpaverkan": -19.46,
    "out:Initial Cost/MSEK": 5.645,
    "out:Running cost/(Apt SEK y)": 35314,
    "out:Running Cost over RSP/MSEK": 14.915,
    "out:LCP/MSEK": -1.056,
    "out:ROI% old": 11.61,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 234724,
    "out:DH kr savings": 175066,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 484922,
    "out:% savings (space heating)": 23.75,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.17,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 127.3,
    "out:Total Energy Use Post PV": 123.29,
    "out:Primary Energy": 112.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.89,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 51.03,
    "out:Total CO2 (tons)": 262.74,
    "out:Klimatpaverkan": -26.87,
    "out:Initial Cost/MSEK": 5.774,
    "out:Running cost/(Apt SEK y)": 34162,
    "out:Running Cost over RSP/MSEK": 14.428,
    "out:LCP/MSEK": -0.698,
    "out:ROI% old": 12.56,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 34.1,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 651,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 255320,
    "out:DH kr savings": 195662,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 464285,
    "out:% savings (space heating)": 27,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.17,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.18,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 130.3,
    "out:Total Energy Use Post PV": 130.3,
    "out:Primary Energy": 122.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.55,
    "out:CO2 Operational/m2": 43.65,
    "out:Total CO2/m2": 57.2,
    "out:Total CO2 (tons)": 294.5,
    "out:Klimatpaverkan": 4.89,
    "out:Initial Cost/MSEK": 5,
    "out:Running cost/(Apt SEK y)": 36131,
    "out:Running Cost over RSP/MSEK": 15.247,
    "out:LCP/MSEK": -0.743,
    "out:ROI% old": 12.16,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 544,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180215,
    "out:DH kr savings": 180215,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 484922,
    "out:% savings (space heating)": 23.75,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.17,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 126.3,
    "out:Primary Energy": 118.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.55,
    "out:CO2 Operational/m2": 42.21,
    "out:Total CO2/m2": 55.76,
    "out:Total CO2 (tons)": 287.09,
    "out:Klimatpaverkan": -2.52,
    "out:Initial Cost/MSEK": 5.129,
    "out:Running cost/(Apt SEK y)": 34979,
    "out:Running Cost over RSP/MSEK": 14.761,
    "out:LCP/MSEK": -0.385,
    "out:ROI% old": 13.21,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200811,
    "out:DH kr savings": 200811,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 464285,
    "out:% savings (space heating)": 27,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.17,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.18,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 127.3,
    "out:Total Energy Use Post PV": 127.3,
    "out:Primary Energy": 120.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.83,
    "out:CO2 Operational/m2": 42.57,
    "out:Total CO2/m2": 56.4,
    "out:Total CO2 (tons)": 290.39,
    "out:Klimatpaverkan": 0.78,
    "out:Initial Cost/MSEK": 5.176,
    "out:Running cost/(Apt SEK y)": 35267,
    "out:Running Cost over RSP/MSEK": 14.882,
    "out:LCP/MSEK": -0.554,
    "out:ROI% old": 12.76,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 591,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195662,
    "out:DH kr savings": 195662,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 484922,
    "out:% savings (space heating)": 23.75,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.17,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 123.3,
    "out:Total Energy Use Post PV": 123.3,
    "out:Primary Energy": 116.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.83,
    "out:CO2 Operational/m2": 41.13,
    "out:Total CO2/m2": 54.96,
    "out:Total CO2 (tons)": 282.98,
    "out:Klimatpaverkan": -6.63,
    "out:Initial Cost/MSEK": 5.304,
    "out:Running cost/(Apt SEK y)": 34115,
    "out:Running Cost over RSP/MSEK": 14.396,
    "out:LCP/MSEK": -0.196,
    "out:ROI% old": 13.76,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 34.1,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 653,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 216258,
    "out:DH kr savings": 216258,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 464285,
    "out:% savings (space heating)": 27,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.17,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00011200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.18,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 130.3,
    "out:Total Energy Use Post PV": 126.29,
    "out:Primary Energy": 114.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.61,
    "out:CO2 Operational/m2": 31.22,
    "out:Total CO2/m2": 51.83,
    "out:Total CO2 (tons)": 266.85,
    "out:Klimatpaverkan": -22.76,
    "out:Initial Cost/MSEK": 5.612,
    "out:Running cost/(Apt SEK y)": 35026,
    "out:Running Cost over RSP/MSEK": 14.793,
    "out:LCP/MSEK": -0.901,
    "out:ROI% old": 11.99,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 604,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 239873,
    "out:DH kr savings": 180215,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 484922,
    "out:% savings (space heating)": 23.75,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.17,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 122.29,
    "out:Primary Energy": 111.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.61,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 50.39,
    "out:Total CO2 (tons)": 259.44,
    "out:Klimatpaverkan": -30.17,
    "out:Initial Cost/MSEK": 5.741,
    "out:Running cost/(Apt SEK y)": 33874,
    "out:Running Cost over RSP/MSEK": 14.307,
    "out:LCP/MSEK": -0.543,
    "out:ROI% old": 12.93,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 666,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260469,
    "out:DH kr savings": 200811,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 464285,
    "out:% savings (space heating)": 27,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.17,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.18,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 127.3,
    "out:Total Energy Use Post PV": 123.29,
    "out:Primary Energy": 112.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.89,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 51.03,
    "out:Total CO2 (tons)": 262.74,
    "out:Klimatpaverkan": -26.87,
    "out:Initial Cost/MSEK": 5.788,
    "out:Running cost/(Apt SEK y)": 34162,
    "out:Running Cost over RSP/MSEK": 14.428,
    "out:LCP/MSEK": -0.712,
    "out:ROI% old": 12.53,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 34.1,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 651,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 255320,
    "out:DH kr savings": 195662,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 484922,
    "out:% savings (space heating)": 23.75,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.17,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 123.3,
    "out:Total Energy Use Post PV": 119.29,
    "out:Primary Energy": 109.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.89,
    "out:CO2 Operational/m2": 28.7,
    "out:Total CO2/m2": 49.59,
    "out:Total CO2 (tons)": 255.33,
    "out:Klimatpaverkan": -34.28,
    "out:Initial Cost/MSEK": 5.916,
    "out:Running cost/(Apt SEK y)": 33010,
    "out:Running Cost over RSP/MSEK": 13.942,
    "out:LCP/MSEK": -0.354,
    "out:ROI% old": 13.43,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 38.7,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 713,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 275916,
    "out:DH kr savings": 216258,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 464285,
    "out:% savings (space heating)": 27,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.17,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00011201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.18,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 130.69,
    "out:Primary Energy": 128.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.89,
    "out:CO2 Operational/m2": 42.56,
    "out:Total CO2/m2": 58.45,
    "out:Total CO2 (tons)": 300.96,
    "out:Klimatpaverkan": 11.35,
    "out:Initial Cost/MSEK": 5.654,
    "out:Running cost/(Apt SEK y)": 35719,
    "out:Running Cost over RSP/MSEK": 15.068,
    "out:LCP/MSEK": -1.218,
    "out:ROI% old": 11.21,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150425,
    "out:DH kr savings": 205960,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 484922,
    "out:% savings (space heating)": 23.75,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.17,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 126.69,
    "out:Primary Energy": 124.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.89,
    "out:CO2 Operational/m2": 41.12,
    "out:Total CO2/m2": 57.01,
    "out:Total CO2 (tons)": 293.55,
    "out:Klimatpaverkan": 3.94,
    "out:Initial Cost/MSEK": 5.783,
    "out:Running cost/(Apt SEK y)": 34567,
    "out:Running Cost over RSP/MSEK": 14.582,
    "out:LCP/MSEK": -0.861,
    "out:ROI% old": 12.16,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171021,
    "out:DH kr savings": 226556,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 464285,
    "out:% savings (space heating)": 27,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.17,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.18,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 126.69,
    "out:Primary Energy": 125.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.17,
    "out:CO2 Operational/m2": 41.12,
    "out:Total CO2/m2": 57.29,
    "out:Total CO2 (tons)": 295,
    "out:Klimatpaverkan": 5.39,
    "out:Initial Cost/MSEK": 5.83,
    "out:Running cost/(Apt SEK y)": 34567,
    "out:Running Cost over RSP/MSEK": 14.582,
    "out:LCP/MSEK": -0.907,
    "out:ROI% old": 12.06,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171021,
    "out:DH kr savings": 226556,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 484922,
    "out:% savings (space heating)": 23.75,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.17,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 122.69,
    "out:Total Energy Use Post PV": 122.69,
    "out:Primary Energy": 122.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.17,
    "out:CO2 Operational/m2": 39.68,
    "out:Total CO2/m2": 55.85,
    "out:Total CO2 (tons)": 287.58,
    "out:Klimatpaverkan": -2.03,
    "out:Initial Cost/MSEK": 5.959,
    "out:Running cost/(Apt SEK y)": 33416,
    "out:Running Cost over RSP/MSEK": 14.095,
    "out:LCP/MSEK": -0.55,
    "out:ROI% old": 12.97,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 34.1,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 691,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191617,
    "out:DH kr savings": 247152,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 464285,
    "out:% savings (space heating)": 27,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.17,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.18,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 125.84,
    "out:Primary Energy": 119.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.95,
    "out:CO2 Operational/m2": 34.33,
    "out:Total CO2/m2": 57.28,
    "out:Total CO2 (tons)": 294.92,
    "out:Klimatpaverkan": 5.31,
    "out:Initial Cost/MSEK": 6.266,
    "out:Running cost/(Apt SEK y)": 34574,
    "out:Running Cost over RSP/MSEK": 14.598,
    "out:LCP/MSEK": -1.36,
    "out:ROI% old": 11.19,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212252,
    "out:DH kr savings": 205960,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 484922,
    "out:% savings (space heating)": 23.75,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.17,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 121.84,
    "out:Primary Energy": 115.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.95,
    "out:CO2 Operational/m2": 32.89,
    "out:Total CO2/m2": 55.84,
    "out:Total CO2 (tons)": 287.51,
    "out:Klimatpaverkan": -2.1,
    "out:Initial Cost/MSEK": 6.395,
    "out:Running cost/(Apt SEK y)": 33423,
    "out:Running Cost over RSP/MSEK": 14.111,
    "out:LCP/MSEK": -1.002,
    "out:ROI% old": 12.05,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 232848,
    "out:DH kr savings": 226556,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 464285,
    "out:% savings (space heating)": 27,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.17,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.18,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 121.84,
    "out:Primary Energy": 116.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.23,
    "out:CO2 Operational/m2": 32.89,
    "out:Total CO2/m2": 56.12,
    "out:Total CO2 (tons)": 288.96,
    "out:Klimatpaverkan": -0.65,
    "out:Initial Cost/MSEK": 6.442,
    "out:Running cost/(Apt SEK y)": 33423,
    "out:Running Cost over RSP/MSEK": 14.111,
    "out:LCP/MSEK": -1.049,
    "out:ROI% old": 11.96,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 232848,
    "out:DH kr savings": 226556,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 484922,
    "out:% savings (space heating)": 23.75,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.17,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 122.69,
    "out:Total Energy Use Post PV": 117.84,
    "out:Primary Energy": 113.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.23,
    "out:CO2 Operational/m2": 31.45,
    "out:Total CO2/m2": 54.68,
    "out:Total CO2 (tons)": 281.54,
    "out:Klimatpaverkan": -8.07,
    "out:Initial Cost/MSEK": 6.571,
    "out:Running cost/(Apt SEK y)": 32271,
    "out:Running Cost over RSP/MSEK": 13.625,
    "out:LCP/MSEK": -0.691,
    "out:ROI% old": 12.78,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 753,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 253444,
    "out:DH kr savings": 247152,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 464285,
    "out:% savings (space heating)": 27,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.17,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.18,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 125.69,
    "out:Primary Energy": 124.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.89,
    "out:CO2 Operational/m2": 40.76,
    "out:Total CO2/m2": 56.65,
    "out:Total CO2 (tons)": 291.69,
    "out:Klimatpaverkan": 2.08,
    "out:Initial Cost/MSEK": 5.797,
    "out:Running cost/(Apt SEK y)": 34280,
    "out:Running Cost over RSP/MSEK": 14.46,
    "out:LCP/MSEK": -0.753,
    "out:ROI% old": 12.43,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176170,
    "out:DH kr savings": 231705,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 484922,
    "out:% savings (space heating)": 23.75,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.17,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 121.69,
    "out:Primary Energy": 121.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.89,
    "out:CO2 Operational/m2": 39.32,
    "out:Total CO2/m2": 55.21,
    "out:Total CO2 (tons)": 284.28,
    "out:Klimatpaverkan": -5.33,
    "out:Initial Cost/MSEK": 5.925,
    "out:Running cost/(Apt SEK y)": 33128,
    "out:Running Cost over RSP/MSEK": 13.974,
    "out:LCP/MSEK": -0.395,
    "out:ROI% old": 13.33,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 196766,
    "out:DH kr savings": 252301,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 464285,
    "out:% savings (space heating)": 27,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.17,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.18,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 121.69,
    "out:Primary Energy": 122.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.17,
    "out:CO2 Operational/m2": 39.32,
    "out:Total CO2/m2": 55.49,
    "out:Total CO2 (tons)": 285.73,
    "out:Klimatpaverkan": -3.88,
    "out:Initial Cost/MSEK": 5.972,
    "out:Running cost/(Apt SEK y)": 33128,
    "out:Running Cost over RSP/MSEK": 13.974,
    "out:LCP/MSEK": -0.442,
    "out:ROI% old": 13.23,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196766,
    "out:DH kr savings": 252301,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 484922,
    "out:% savings (space heating)": 23.75,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.17,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 117.69,
    "out:Total Energy Use Post PV": 117.69,
    "out:Primary Energy": 119.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.17,
    "out:CO2 Operational/m2": 37.88,
    "out:Total CO2/m2": 54.05,
    "out:Total CO2 (tons)": 278.32,
    "out:Klimatpaverkan": -11.29,
    "out:Initial Cost/MSEK": 6.101,
    "out:Running cost/(Apt SEK y)": 31976,
    "out:Running Cost over RSP/MSEK": 13.487,
    "out:LCP/MSEK": -0.084,
    "out:ROI% old": 14.09,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 769,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217362,
    "out:DH kr savings": 272897,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 464285,
    "out:% savings (space heating)": 27,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.17,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00011201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.18,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 120.84,
    "out:Primary Energy": 115.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.95,
    "out:CO2 Operational/m2": 32.53,
    "out:Total CO2/m2": 55.48,
    "out:Total CO2 (tons)": 285.65,
    "out:Klimatpaverkan": -3.96,
    "out:Initial Cost/MSEK": 6.409,
    "out:Running cost/(Apt SEK y)": 33135,
    "out:Running Cost over RSP/MSEK": 13.99,
    "out:LCP/MSEK": -0.894,
    "out:ROI% old": 12.29,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 237997,
    "out:DH kr savings": 231705,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 484922,
    "out:% savings (space heating)": 23.75,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.17,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 116.84,
    "out:Primary Energy": 112.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.95,
    "out:CO2 Operational/m2": 31.09,
    "out:Total CO2/m2": 54.04,
    "out:Total CO2 (tons)": 278.24,
    "out:Klimatpaverkan": -11.37,
    "out:Initial Cost/MSEK": 6.538,
    "out:Running cost/(Apt SEK y)": 31983,
    "out:Running Cost over RSP/MSEK": 13.503,
    "out:LCP/MSEK": -0.536,
    "out:ROI% old": 13.11,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258593,
    "out:DH kr savings": 252301,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 464285,
    "out:% savings (space heating)": 27,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.17,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.18,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 116.84,
    "out:Primary Energy": 113.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.23,
    "out:CO2 Operational/m2": 31.09,
    "out:Total CO2/m2": 54.32,
    "out:Total CO2 (tons)": 279.69,
    "out:Klimatpaverkan": -9.92,
    "out:Initial Cost/MSEK": 6.584,
    "out:Running cost/(Apt SEK y)": 31983,
    "out:Running Cost over RSP/MSEK": 13.503,
    "out:LCP/MSEK": -0.583,
    "out:ROI% old": 13.02,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 258593,
    "out:DH kr savings": 252301,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 484922,
    "out:% savings (space heating)": 23.75,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.18,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00011201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.17,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 117.69,
    "out:Total Energy Use Post PV": 112.84,
    "out:Primary Energy": 110.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.23,
    "out:CO2 Operational/m2": 29.65,
    "out:Total CO2/m2": 52.88,
    "out:Total CO2 (tons)": 272.27,
    "out:Klimatpaverkan": -17.34,
    "out:Initial Cost/MSEK": 6.713,
    "out:Running cost/(Apt SEK y)": 30831,
    "out:Running Cost over RSP/MSEK": 13.017,
    "out:LCP/MSEK": -0.226,
    "out:ROI% old": 13.81,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.81,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 830,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279189,
    "out:DH kr savings": 272897,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 464285,
    "out:% savings (space heating)": 27,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.17,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.44,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 154.3,
    "out:Total Energy Use Post PV": 154.3,
    "out:Primary Energy": 141.63,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.12,
    "out:CO2 Operational/m2": 52.29,
    "out:Total CO2/m2": 53.4,
    "out:Total CO2 (tons)": 274.97,
    "out:Klimatpaverkan": -14.64,
    "out:Initial Cost/MSEK": 0.425,
    "out:Running cost/(Apt SEK y)": 43042,
    "out:Running Cost over RSP/MSEK": 18.166,
    "out:LCP/MSEK": 0.913,
    "out:ROI% old": 45.01,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 7.1,
    "out:Op energy cost/MSEK": 133,
    "out:El price/MSEK": 81,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 40,
    "out:Return/kSEK/y": 171,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 56639,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 56639,
    "out:DH kr savings": 56639,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578962,
    "out:% savings (space heating)": 8.97,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.44,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.6,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 149.3,
    "out:Total Energy Use Post PV": 149.3,
    "out:Primary Energy": 137.39,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.12,
    "out:CO2 Operational/m2": 50.49,
    "out:Total CO2/m2": 51.6,
    "out:Total CO2 (tons)": 265.7,
    "out:Klimatpaverkan": -23.91,
    "out:Initial Cost/MSEK": 0.553,
    "out:Running cost/(Apt SEK y)": 41602,
    "out:Running Cost over RSP/MSEK": 17.558,
    "out:LCP/MSEK": 1.393,
    "out:ROI% old": 50.24,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 10.7,
    "out:Op energy cost/MSEK": 128,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 45,
    "out:Return/kSEK/y": 249,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 82384,
    "out:DH kr savings": 82384,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 554050,
    "out:% savings (space heating)": 12.88,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.6,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.44,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 149.3,
    "out:Total Energy Use Post PV": 149.3,
    "out:Primary Energy": 139,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.4,
    "out:CO2 Operational/m2": 50.49,
    "out:Total CO2/m2": 51.88,
    "out:Total CO2 (tons)": 267.15,
    "out:Klimatpaverkan": -22.46,
    "out:Initial Cost/MSEK": 0.6,
    "out:Running cost/(Apt SEK y)": 41602,
    "out:Running Cost over RSP/MSEK": 17.558,
    "out:LCP/MSEK": 1.346,
    "out:ROI% old": 46.31,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 10.7,
    "out:Op energy cost/MSEK": 128,
    "out:El price/MSEK": 79,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 41,
    "out:Return/kSEK/y": 249,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82384,
    "out:DH kr savings": 82384,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578962,
    "out:% savings (space heating)": 8.97,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.44,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.6,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 145.3,
    "out:Primary Energy": 134.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.4,
    "out:CO2 Operational/m2": 49.05,
    "out:Total CO2/m2": 50.44,
    "out:Total CO2 (tons)": 259.74,
    "out:Klimatpaverkan": -29.87,
    "out:Initial Cost/MSEK": 0.729,
    "out:Running cost/(Apt SEK y)": 40450,
    "out:Running Cost over RSP/MSEK": 17.072,
    "out:LCP/MSEK": 1.704,
    "out:ROI% old": 47.67,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 43,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102980,
    "out:DH kr savings": 102980,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 554050,
    "out:% savings (space heating)": 12.88,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.6,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.44,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 154.3,
    "out:Total Energy Use Post PV": 150.29,
    "out:Primary Energy": 134.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.18,
    "out:CO2 Operational/m2": 39.86,
    "out:Total CO2/m2": 48.03,
    "out:Total CO2 (tons)": 247.32,
    "out:Klimatpaverkan": -42.29,
    "out:Initial Cost/MSEK": 1.037,
    "out:Running cost/(Apt SEK y)": 41937,
    "out:Running Cost over RSP/MSEK": 17.712,
    "out:LCP/MSEK": 0.755,
    "out:ROI% old": 24.69,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 130,
    "out:El price/MSEK": 78,
    "out:Heating price/MSEK": 44,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 231,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 56639,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116297,
    "out:DH kr savings": 56639,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578962,
    "out:% savings (space heating)": 8.97,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.44,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.6,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 149.3,
    "out:Total Energy Use Post PV": 145.29,
    "out:Primary Energy": 130.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.18,
    "out:CO2 Operational/m2": 38.06,
    "out:Total CO2/m2": 46.23,
    "out:Total CO2 (tons)": 238.05,
    "out:Klimatpaverkan": -51.56,
    "out:Initial Cost/MSEK": 1.165,
    "out:Running cost/(Apt SEK y)": 40497,
    "out:Running Cost over RSP/MSEK": 17.104,
    "out:LCP/MSEK": 1.235,
    "out:ROI% old": 29.42,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 26,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142042,
    "out:DH kr savings": 82384,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 554050,
    "out:% savings (space heating)": 12.88,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.6,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.44,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 149.3,
    "out:Total Energy Use Post PV": 145.29,
    "out:Primary Energy": 131.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.46,
    "out:CO2 Operational/m2": 38.06,
    "out:Total CO2/m2": 46.51,
    "out:Total CO2 (tons)": 239.5,
    "out:Klimatpaverkan": -50.11,
    "out:Initial Cost/MSEK": 1.212,
    "out:Running cost/(Apt SEK y)": 40497,
    "out:Running Cost over RSP/MSEK": 17.104,
    "out:LCP/MSEK": 1.188,
    "out:ROI% old": 28.28,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 43,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 25,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 82384,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142042,
    "out:DH kr savings": 82384,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578962,
    "out:% savings (space heating)": 8.97,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.44,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.6,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 141.29,
    "out:Primary Energy": 127.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.46,
    "out:CO2 Operational/m2": 36.62,
    "out:Total CO2/m2": 45.07,
    "out:Total CO2 (tons)": 232.09,
    "out:Klimatpaverkan": -57.52,
    "out:Initial Cost/MSEK": 1.341,
    "out:Running cost/(Apt SEK y)": 39345,
    "out:Running Cost over RSP/MSEK": 16.618,
    "out:LCP/MSEK": 1.546,
    "out:ROI% old": 30.75,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 28,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162638,
    "out:DH kr savings": 102980,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 554050,
    "out:% savings (space heating)": 12.88,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.6,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.44,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 148.3,
    "out:Total Energy Use Post PV": 148.3,
    "out:Primary Energy": 138.13,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.12,
    "out:CO2 Operational/m2": 50.13,
    "out:Total CO2/m2": 51.24,
    "out:Total CO2 (tons)": 263.85,
    "out:Klimatpaverkan": -25.76,
    "out:Initial Cost/MSEK": 0.567,
    "out:Running cost/(Apt SEK y)": 41314,
    "out:Running Cost over RSP/MSEK": 17.437,
    "out:LCP/MSEK": 1.5,
    "out:ROI% old": 52.08,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 11.5,
    "out:Op energy cost/MSEK": 127,
    "out:El price/MSEK": 78,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 47,
    "out:Return/kSEK/y": 264,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 87533,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 87533,
    "out:DH kr savings": 87533,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578962,
    "out:% savings (space heating)": 8.97,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.44,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.6,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 143.3,
    "out:Total Energy Use Post PV": 143.3,
    "out:Primary Energy": 133.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.12,
    "out:CO2 Operational/m2": 48.33,
    "out:Total CO2/m2": 49.44,
    "out:Total CO2 (tons)": 254.58,
    "out:Klimatpaverkan": -35.03,
    "out:Initial Cost/MSEK": 0.696,
    "out:Running cost/(Apt SEK y)": 39874,
    "out:Running Cost over RSP/MSEK": 16.828,
    "out:LCP/MSEK": 1.98,
    "out:ROI% old": 54.93,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 15.4,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 49,
    "out:Return/kSEK/y": 342,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 113278,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113278,
    "out:DH kr savings": 113278,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 554050,
    "out:% savings (space heating)": 12.88,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.6,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.44,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 145.3,
    "out:Primary Energy": 136.03,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.4,
    "out:CO2 Operational/m2": 49.05,
    "out:Total CO2/m2": 50.44,
    "out:Total CO2 (tons)": 259.74,
    "out:Klimatpaverkan": -29.87,
    "out:Initial Cost/MSEK": 0.743,
    "out:Running cost/(Apt SEK y)": 40450,
    "out:Running Cost over RSP/MSEK": 17.072,
    "out:LCP/MSEK": 1.69,
    "out:ROI% old": 46.78,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 42,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102980,
    "out:DH kr savings": 102980,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578962,
    "out:% savings (space heating)": 8.97,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.44,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.6,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 140.3,
    "out:Primary Energy": 131.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.4,
    "out:CO2 Operational/m2": 47.25,
    "out:Total CO2/m2": 48.64,
    "out:Total CO2 (tons)": 250.47,
    "out:Klimatpaverkan": -39.14,
    "out:Initial Cost/MSEK": 0.872,
    "out:Running cost/(Apt SEK y)": 39010,
    "out:Running Cost over RSP/MSEK": 16.464,
    "out:LCP/MSEK": 2.169,
    "out:ROI% old": 49.84,
    "out:Payback discounted": 3,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 45,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128725,
    "out:DH kr savings": 128725,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 554050,
    "out:% savings (space heating)": 12.88,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.6,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.44,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 148.3,
    "out:Total Energy Use Post PV": 144.29,
    "out:Primary Energy": 130.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.18,
    "out:CO2 Operational/m2": 37.7,
    "out:Total CO2/m2": 45.87,
    "out:Total CO2 (tons)": 236.2,
    "out:Klimatpaverkan": -53.41,
    "out:Initial Cost/MSEK": 1.179,
    "out:Running cost/(Apt SEK y)": 40209,
    "out:Running Cost over RSP/MSEK": 16.982,
    "out:LCP/MSEK": 1.343,
    "out:ROI% old": 30.55,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 14.6,
    "out:Op energy cost/MSEK": 125,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 42,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 27,
    "out:Return/kSEK/y": 324,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 87533,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 147191,
    "out:DH kr savings": 87533,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578962,
    "out:% savings (space heating)": 8.97,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.44,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.6,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 143.3,
    "out:Total Energy Use Post PV": 139.29,
    "out:Primary Energy": 126.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.18,
    "out:CO2 Operational/m2": 35.9,
    "out:Total CO2/m2": 44.07,
    "out:Total CO2 (tons)": 226.93,
    "out:Klimatpaverkan": -62.68,
    "out:Initial Cost/MSEK": 1.308,
    "out:Running cost/(Apt SEK y)": 38769,
    "out:Running Cost over RSP/MSEK": 16.374,
    "out:LCP/MSEK": 1.822,
    "out:ROI% old": 34.18,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 18.7,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 31,
    "out:Return/kSEK/y": 402,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 113278,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172936,
    "out:DH kr savings": 113278,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 554050,
    "out:% savings (space heating)": 12.88,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.6,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.44,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 145.3,
    "out:Total Energy Use Post PV": 141.29,
    "out:Primary Energy": 128.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.46,
    "out:CO2 Operational/m2": 36.62,
    "out:Total CO2/m2": 45.07,
    "out:Total CO2 (tons)": 232.09,
    "out:Klimatpaverkan": -57.52,
    "out:Initial Cost/MSEK": 1.355,
    "out:Running cost/(Apt SEK y)": 39345,
    "out:Running Cost over RSP/MSEK": 16.618,
    "out:LCP/MSEK": 1.532,
    "out:ROI% old": 30.44,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 27,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 102980,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162638,
    "out:DH kr savings": 102980,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578962,
    "out:% savings (space heating)": 8.97,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.44,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.6,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 136.29,
    "out:Primary Energy": 124.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.46,
    "out:CO2 Operational/m2": 34.82,
    "out:Total CO2/m2": 43.27,
    "out:Total CO2 (tons)": 222.82,
    "out:Klimatpaverkan": -66.79,
    "out:Initial Cost/MSEK": 1.484,
    "out:Running cost/(Apt SEK y)": 37906,
    "out:Running Cost over RSP/MSEK": 16.009,
    "out:LCP/MSEK": 2.011,
    "out:ROI% old": 33.65,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 30,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188383,
    "out:DH kr savings": 128725,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 554050,
    "out:% savings (space heating)": 12.88,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.6,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.44,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 148.69,
    "out:Total Energy Use Post PV": 148.69,
    "out:Primary Energy": 144.19,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.46,
    "out:CO2 Operational/m2": 49.04,
    "out:Total CO2/m2": 52.5,
    "out:Total CO2 (tons)": 270.31,
    "out:Klimatpaverkan": -19.3,
    "out:Initial Cost/MSEK": 1.221,
    "out:Running cost/(Apt SEK y)": 40903,
    "out:Running Cost over RSP/MSEK": 17.258,
    "out:LCP/MSEK": 1.025,
    "out:ROI% old": 26.27,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 10.7,
    "out:Op energy cost/MSEK": 126,
    "out:El price/MSEK": 78,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 23,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 113278,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 57743,
    "out:DH kr savings": 113278,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578962,
    "out:% savings (space heating)": 8.97,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.44,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.6,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 143.69,
    "out:Total Energy Use Post PV": 143.69,
    "out:Primary Energy": 139.95,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.46,
    "out:CO2 Operational/m2": 47.24,
    "out:Total CO2/m2": 50.7,
    "out:Total CO2 (tons)": 261.04,
    "out:Klimatpaverkan": -28.57,
    "out:Initial Cost/MSEK": 1.35,
    "out:Running cost/(Apt SEK y)": 39463,
    "out:Running Cost over RSP/MSEK": 16.65,
    "out:LCP/MSEK": 1.504,
    "out:ROI% old": 30.2,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 14.6,
    "out:Op energy cost/MSEK": 121,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 27,
    "out:Return/kSEK/y": 364,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83488,
    "out:DH kr savings": 139023,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 554050,
    "out:% savings (space heating)": 12.88,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.6,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.44,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 144.69,
    "out:Total Energy Use Post PV": 144.69,
    "out:Primary Energy": 141.56,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.74,
    "out:CO2 Operational/m2": 47.6,
    "out:Total CO2/m2": 51.34,
    "out:Total CO2 (tons)": 264.35,
    "out:Klimatpaverkan": -25.26,
    "out:Initial Cost/MSEK": 1.397,
    "out:Running cost/(Apt SEK y)": 39751,
    "out:Running Cost over RSP/MSEK": 16.771,
    "out:LCP/MSEK": 1.336,
    "out:ROI% old": 27.95,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.8,
    "out:Op energy cost/MSEK": 122,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 25,
    "out:Return/kSEK/y": 349,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 133874,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 78339,
    "out:DH kr savings": 133874,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578962,
    "out:% savings (space heating)": 8.97,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.44,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.6,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 139.69,
    "out:Total Energy Use Post PV": 139.69,
    "out:Primary Energy": 137.33,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.74,
    "out:CO2 Operational/m2": 45.8,
    "out:Total CO2/m2": 49.54,
    "out:Total CO2 (tons)": 255.08,
    "out:Klimatpaverkan": -34.53,
    "out:Initial Cost/MSEK": 1.526,
    "out:Running cost/(Apt SEK y)": 38311,
    "out:Running Cost over RSP/MSEK": 16.163,
    "out:LCP/MSEK": 1.815,
    "out:ROI% old": 31.28,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 28,
    "out:Return/kSEK/y": 427,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104084,
    "out:DH kr savings": 159619,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 554050,
    "out:% savings (space heating)": 12.88,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.6,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.44,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 148.69,
    "out:Total Energy Use Post PV": 143.84,
    "out:Primary Energy": 135.45,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.52,
    "out:CO2 Operational/m2": 40.8,
    "out:Total CO2/m2": 51.32,
    "out:Total CO2 (tons)": 264.27,
    "out:Klimatpaverkan": -25.34,
    "out:Initial Cost/MSEK": 1.834,
    "out:Running cost/(Apt SEK y)": 39758,
    "out:Running Cost over RSP/MSEK": 16.787,
    "out:LCP/MSEK": 0.884,
    "out:ROI% old": 21.17,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 14.6,
    "out:Op energy cost/MSEK": 123,
    "out:El price/MSEK": 75,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 348,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 113278,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 119570,
    "out:DH kr savings": 113278,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578962,
    "out:% savings (space heating)": 8.97,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.44,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.6,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 143.69,
    "out:Total Energy Use Post PV": 138.84,
    "out:Primary Energy": 131.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.52,
    "out:CO2 Operational/m2": 39.01,
    "out:Total CO2/m2": 49.52,
    "out:Total CO2 (tons)": 255,
    "out:Klimatpaverkan": -34.61,
    "out:Initial Cost/MSEK": 1.962,
    "out:Running cost/(Apt SEK y)": 38318,
    "out:Running Cost over RSP/MSEK": 16.179,
    "out:LCP/MSEK": 1.363,
    "out:ROI% old": 24.21,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 18.7,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 426,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145315,
    "out:DH kr savings": 139023,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 554050,
    "out:% savings (space heating)": 12.88,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.6,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.44,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 144.69,
    "out:Total Energy Use Post PV": 139.84,
    "out:Primary Energy": 132.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.8,
    "out:CO2 Operational/m2": 39.36,
    "out:Total CO2/m2": 50.17,
    "out:Total CO2 (tons)": 258.31,
    "out:Klimatpaverkan": -31.3,
    "out:Initial Cost/MSEK": 2.009,
    "out:Running cost/(Apt SEK y)": 38606,
    "out:Running Cost over RSP/MSEK": 16.301,
    "out:LCP/MSEK": 1.195,
    "out:ROI% old": 22.78,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 133874,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140166,
    "out:DH kr savings": 133874,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578962,
    "out:% savings (space heating)": 8.97,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.44,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.6,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 139.69,
    "out:Total Energy Use Post PV": 134.84,
    "out:Primary Energy": 128.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.8,
    "out:CO2 Operational/m2": 37.57,
    "out:Total CO2/m2": 48.37,
    "out:Total CO2 (tons)": 249.04,
    "out:Klimatpaverkan": -40.57,
    "out:Initial Cost/MSEK": 2.138,
    "out:Running cost/(Apt SEK y)": 37166,
    "out:Running Cost over RSP/MSEK": 15.692,
    "out:LCP/MSEK": 1.674,
    "out:ROI% old": 25.47,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 23,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165911,
    "out:DH kr savings": 159619,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 554050,
    "out:% savings (space heating)": 12.88,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.6,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.44,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 143.69,
    "out:Total Energy Use Post PV": 143.69,
    "out:Primary Energy": 140.69,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.46,
    "out:CO2 Operational/m2": 47.24,
    "out:Total CO2/m2": 50.7,
    "out:Total CO2 (tons)": 261.04,
    "out:Klimatpaverkan": -28.57,
    "out:Initial Cost/MSEK": 1.364,
    "out:Running cost/(Apt SEK y)": 39463,
    "out:Running Cost over RSP/MSEK": 16.65,
    "out:LCP/MSEK": 1.491,
    "out:ROI% old": 29.9,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 14.6,
    "out:Op energy cost/MSEK": 121,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 27,
    "out:Return/kSEK/y": 364,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83488,
    "out:DH kr savings": 139023,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578962,
    "out:% savings (space heating)": 8.97,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.44,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.6,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 138.69,
    "out:Total Energy Use Post PV": 138.69,
    "out:Primary Energy": 136.45,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.46,
    "out:CO2 Operational/m2": 45.44,
    "out:Total CO2/m2": 48.9,
    "out:Total CO2 (tons)": 251.78,
    "out:Klimatpaverkan": -37.83,
    "out:Initial Cost/MSEK": 1.493,
    "out:Running cost/(Apt SEK y)": 38023,
    "out:Running Cost over RSP/MSEK": 16.041,
    "out:LCP/MSEK": 1.97,
    "out:ROI% old": 33.14,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 18.7,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 30,
    "out:Return/kSEK/y": 442,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 164768,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109233,
    "out:DH kr savings": 164768,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 554050,
    "out:% savings (space heating)": 12.88,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.6,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.44,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 140.69,
    "out:Total Energy Use Post PV": 140.69,
    "out:Primary Energy": 138.59,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.74,
    "out:CO2 Operational/m2": 46.16,
    "out:Total CO2/m2": 49.9,
    "out:Total CO2 (tons)": 256.93,
    "out:Klimatpaverkan": -32.68,
    "out:Initial Cost/MSEK": 1.54,
    "out:Running cost/(Apt SEK y)": 38599,
    "out:Running Cost over RSP/MSEK": 16.285,
    "out:LCP/MSEK": 1.68,
    "out:ROI% old": 29.87,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 17,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 27,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98935,
    "out:DH kr savings": 154470,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578962,
    "out:% savings (space heating)": 8.97,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.44,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.6,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 135.69,
    "out:Primary Energy": 134.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.74,
    "out:CO2 Operational/m2": 44.36,
    "out:Total CO2/m2": 48.1,
    "out:Total CO2 (tons)": 247.67,
    "out:Klimatpaverkan": -41.94,
    "out:Initial Cost/MSEK": 1.668,
    "out:Running cost/(Apt SEK y)": 37159,
    "out:Running Cost over RSP/MSEK": 15.677,
    "out:LCP/MSEK": 2.159,
    "out:ROI% old": 32.77,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 29,
    "out:Return/kSEK/y": 489,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124680,
    "out:DH kr savings": 180215,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 554050,
    "out:% savings (space heating)": 12.88,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.6,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.44,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 143.69,
    "out:Total Energy Use Post PV": 138.84,
    "out:Primary Energy": 131.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.52,
    "out:CO2 Operational/m2": 39.01,
    "out:Total CO2/m2": 49.52,
    "out:Total CO2 (tons)": 255,
    "out:Klimatpaverkan": -34.61,
    "out:Initial Cost/MSEK": 1.976,
    "out:Running cost/(Apt SEK y)": 38318,
    "out:Running Cost over RSP/MSEK": 16.179,
    "out:LCP/MSEK": 1.349,
    "out:ROI% old": 24.04,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 18.7,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 426,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145315,
    "out:DH kr savings": 139023,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578962,
    "out:% savings (space heating)": 8.97,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.44,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.6,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 138.69,
    "out:Total Energy Use Post PV": 133.84,
    "out:Primary Energy": 127.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.52,
    "out:CO2 Operational/m2": 37.21,
    "out:Total CO2/m2": 47.72,
    "out:Total CO2 (tons)": 245.73,
    "out:Klimatpaverkan": -43.88,
    "out:Initial Cost/MSEK": 2.105,
    "out:Running cost/(Apt SEK y)": 36878,
    "out:Running Cost over RSP/MSEK": 15.571,
    "out:LCP/MSEK": 1.829,
    "out:ROI% old": 26.7,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 23.1,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 164768,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171060,
    "out:DH kr savings": 164768,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 554050,
    "out:% savings (space heating)": 12.88,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.6,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.44,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 140.69,
    "out:Total Energy Use Post PV": 135.84,
    "out:Primary Energy": 129.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.8,
    "out:CO2 Operational/m2": 37.93,
    "out:Total CO2/m2": 48.73,
    "out:Total CO2 (tons)": 250.89,
    "out:Klimatpaverkan": -38.72,
    "out:Initial Cost/MSEK": 2.152,
    "out:Running cost/(Apt SEK y)": 37454,
    "out:Running Cost over RSP/MSEK": 15.814,
    "out:LCP/MSEK": 1.539,
    "out:ROI% old": 24.5,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 473,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160762,
    "out:DH kr savings": 154470,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 578962,
    "out:% savings (space heating)": 8.97,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.44,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.6,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 130.84,
    "out:Primary Energy": 125.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.8,
    "out:CO2 Operational/m2": 36.13,
    "out:Total CO2/m2": 46.93,
    "out:Total CO2 (tons)": 241.62,
    "out:Klimatpaverkan": -47.99,
    "out:Initial Cost/MSEK": 2.28,
    "out:Running cost/(Apt SEK y)": 36014,
    "out:Running Cost over RSP/MSEK": 15.206,
    "out:LCP/MSEK": 2.018,
    "out:ROI% old": 26.93,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 1.15,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186507,
    "out:DH kr savings": 180215,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 554050,
    "out:% savings (space heating)": 12.88,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.6,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.99,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 139.3,
    "out:Total Energy Use Post PV": 139.3,
    "out:Primary Energy": 128.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.11,
    "out:CO2 Operational/m2": 46.89,
    "out:Total CO2/m2": 50.99,
    "out:Total CO2 (tons)": 262.56,
    "out:Klimatpaverkan": -27.05,
    "out:Initial Cost/MSEK": 1.718,
    "out:Running cost/(Apt SEK y)": 38722,
    "out:Running Cost over RSP/MSEK": 16.342,
    "out:LCP/MSEK": 1.445,
    "out:ROI% old": 26.3,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 18.7,
    "out:Op energy cost/MSEK": 119,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 24,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 133874,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133874,
    "out:DH kr savings": 133874,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 504552,
    "out:% savings (space heating)": 20.67,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.99,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.81,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 135.3,
    "out:Primary Energy": 125.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.11,
    "out:CO2 Operational/m2": 45.45,
    "out:Total CO2/m2": 49.55,
    "out:Total CO2 (tons)": 255.14,
    "out:Klimatpaverkan": -34.47,
    "out:Initial Cost/MSEK": 1.847,
    "out:Running cost/(Apt SEK y)": 37571,
    "out:Running Cost over RSP/MSEK": 15.855,
    "out:LCP/MSEK": 1.802,
    "out:ROI% old": 28.23,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 25,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154470,
    "out:DH kr savings": 154470,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483022,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.81,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.99,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 135.3,
    "out:Primary Energy": 126.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.39,
    "out:CO2 Operational/m2": 45.45,
    "out:Total CO2/m2": 49.83,
    "out:Total CO2 (tons)": 256.59,
    "out:Klimatpaverkan": -33.02,
    "out:Initial Cost/MSEK": 1.893,
    "out:Running cost/(Apt SEK y)": 37571,
    "out:Running Cost over RSP/MSEK": 15.855,
    "out:LCP/MSEK": 1.755,
    "out:ROI% old": 27.53,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 25,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154470,
    "out:DH kr savings": 154470,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 504552,
    "out:% savings (space heating)": 20.67,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.99,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.81,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 131.3,
    "out:Primary Energy": 122.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.39,
    "out:CO2 Operational/m2": 44.01,
    "out:Total CO2/m2": 48.39,
    "out:Total CO2 (tons)": 249.18,
    "out:Klimatpaverkan": -40.43,
    "out:Initial Cost/MSEK": 2.022,
    "out:Running cost/(Apt SEK y)": 36419,
    "out:Running Cost over RSP/MSEK": 15.369,
    "out:LCP/MSEK": 2.113,
    "out:ROI% old": 29.21,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 26,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175066,
    "out:DH kr savings": 175066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483022,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.81,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.99,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 139.3,
    "out:Total Energy Use Post PV": 135.29,
    "out:Primary Energy": 121.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.16,
    "out:CO2 Operational/m2": 34.46,
    "out:Total CO2/m2": 45.62,
    "out:Total CO2 (tons)": 234.91,
    "out:Klimatpaverkan": -54.7,
    "out:Initial Cost/MSEK": 2.33,
    "out:Running cost/(Apt SEK y)": 37618,
    "out:Running Cost over RSP/MSEK": 15.888,
    "out:LCP/MSEK": 1.287,
    "out:ROI% old": 22.17,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 464,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 133874,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 193532,
    "out:DH kr savings": 133874,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 504552,
    "out:% savings (space heating)": 20.67,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.99,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.81,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 131.29,
    "out:Primary Energy": 118.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.16,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 44.18,
    "out:Total CO2 (tons)": 227.49,
    "out:Klimatpaverkan": -62.12,
    "out:Initial Cost/MSEK": 2.459,
    "out:Running cost/(Apt SEK y)": 36466,
    "out:Running Cost over RSP/MSEK": 15.401,
    "out:LCP/MSEK": 1.644,
    "out:ROI% old": 23.84,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214128,
    "out:DH kr savings": 154470,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483022,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.81,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.99,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 131.29,
    "out:Primary Energy": 119.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.45,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 44.46,
    "out:Total CO2 (tons)": 228.94,
    "out:Klimatpaverkan": -60.67,
    "out:Initial Cost/MSEK": 2.506,
    "out:Running cost/(Apt SEK y)": 36466,
    "out:Running Cost over RSP/MSEK": 15.401,
    "out:LCP/MSEK": 1.597,
    "out:ROI% old": 23.39,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214128,
    "out:DH kr savings": 154470,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 504552,
    "out:% savings (space heating)": 20.67,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.99,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.81,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 127.29,
    "out:Primary Energy": 115.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.45,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 43.02,
    "out:Total CO2 (tons)": 221.53,
    "out:Klimatpaverkan": -68.08,
    "out:Initial Cost/MSEK": 2.634,
    "out:Running cost/(Apt SEK y)": 35314,
    "out:Running Cost over RSP/MSEK": 14.915,
    "out:LCP/MSEK": 1.955,
    "out:ROI% old": 24.89,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234724,
    "out:DH kr savings": 175066,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483022,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.81,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.99,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 134.3,
    "out:Total Energy Use Post PV": 134.3,
    "out:Primary Energy": 125.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.11,
    "out:CO2 Operational/m2": 45.09,
    "out:Total CO2/m2": 49.19,
    "out:Total CO2 (tons)": 253.29,
    "out:Klimatpaverkan": -36.32,
    "out:Initial Cost/MSEK": 1.86,
    "out:Running cost/(Apt SEK y)": 37283,
    "out:Running Cost over RSP/MSEK": 15.734,
    "out:LCP/MSEK": 1.91,
    "out:ROI% old": 28.95,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 23.1,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 26,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159619,
    "out:DH kr savings": 159619,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 504552,
    "out:% savings (space heating)": 20.67,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.99,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.81,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 129.3,
    "out:Primary Energy": 121.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.11,
    "out:CO2 Operational/m2": 43.29,
    "out:Total CO2/m2": 47.39,
    "out:Total CO2 (tons)": 244.02,
    "out:Klimatpaverkan": -45.59,
    "out:Initial Cost/MSEK": 1.989,
    "out:Running cost/(Apt SEK y)": 35843,
    "out:Running Cost over RSP/MSEK": 15.126,
    "out:LCP/MSEK": 2.39,
    "out:ROI% old": 31.45,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 28,
    "out:Return/kSEK/y": 560,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 185364,
    "out:DH kr savings": 185364,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483022,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.81,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.99,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 130.3,
    "out:Total Energy Use Post PV": 130.3,
    "out:Primary Energy": 123.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.39,
    "out:CO2 Operational/m2": 43.65,
    "out:Total CO2/m2": 48.03,
    "out:Total CO2 (tons)": 247.32,
    "out:Klimatpaverkan": -42.29,
    "out:Initial Cost/MSEK": 2.036,
    "out:Running cost/(Apt SEK y)": 36131,
    "out:Running Cost over RSP/MSEK": 15.247,
    "out:LCP/MSEK": 2.221,
    "out:ROI% old": 29.87,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 27,
    "out:Return/kSEK/y": 544,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180215,
    "out:DH kr savings": 180215,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 504552,
    "out:% savings (space heating)": 20.67,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.99,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.81,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 126.3,
    "out:Primary Energy": 119.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.39,
    "out:CO2 Operational/m2": 42.21,
    "out:Total CO2/m2": 46.59,
    "out:Total CO2 (tons)": 239.91,
    "out:Klimatpaverkan": -49.7,
    "out:Initial Cost/MSEK": 2.165,
    "out:Running cost/(Apt SEK y)": 34979,
    "out:Running Cost over RSP/MSEK": 14.761,
    "out:LCP/MSEK": 2.579,
    "out:ROI% old": 31.31,
    "out:Payback discounted": 4,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 28,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200811,
    "out:DH kr savings": 200811,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483022,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.81,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.99,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 134.3,
    "out:Total Energy Use Post PV": 130.29,
    "out:Primary Energy": 118.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.16,
    "out:CO2 Operational/m2": 32.66,
    "out:Total CO2/m2": 43.82,
    "out:Total CO2 (tons)": 225.64,
    "out:Klimatpaverkan": -63.97,
    "out:Initial Cost/MSEK": 2.472,
    "out:Running cost/(Apt SEK y)": 36178,
    "out:Running Cost over RSP/MSEK": 15.28,
    "out:LCP/MSEK": 1.752,
    "out:ROI% old": 24.41,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 542,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 219277,
    "out:DH kr savings": 159619,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 504552,
    "out:% savings (space heating)": 20.67,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.99,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.81,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 125.29,
    "out:Primary Energy": 114.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.16,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 42.02,
    "out:Total CO2 (tons)": 216.37,
    "out:Klimatpaverkan": -73.24,
    "out:Initial Cost/MSEK": 2.601,
    "out:Running cost/(Apt SEK y)": 34738,
    "out:Running Cost over RSP/MSEK": 14.671,
    "out:LCP/MSEK": 2.232,
    "out:ROI% old": 26.54,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 245022,
    "out:DH kr savings": 185364,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483022,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.81,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.99,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 130.3,
    "out:Total Energy Use Post PV": 126.29,
    "out:Primary Energy": 116.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.45,
    "out:CO2 Operational/m2": 31.22,
    "out:Total CO2/m2": 42.66,
    "out:Total CO2 (tons)": 219.67,
    "out:Klimatpaverkan": -69.94,
    "out:Initial Cost/MSEK": 2.648,
    "out:Running cost/(Apt SEK y)": 35026,
    "out:Running Cost over RSP/MSEK": 14.793,
    "out:LCP/MSEK": 2.063,
    "out:ROI% old": 25.42,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 23,
    "out:Return/kSEK/y": 604,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 239873,
    "out:DH kr savings": 180215,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 504552,
    "out:% savings (space heating)": 20.67,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.99,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.81,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 122.29,
    "out:Primary Energy": 112.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.45,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 41.22,
    "out:Total CO2 (tons)": 212.26,
    "out:Klimatpaverkan": -77.35,
    "out:Initial Cost/MSEK": 2.777,
    "out:Running cost/(Apt SEK y)": 33874,
    "out:Running Cost over RSP/MSEK": 14.307,
    "out:LCP/MSEK": 2.421,
    "out:ROI% old": 26.74,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 24,
    "out:Return/kSEK/y": 666,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260469,
    "out:DH kr savings": 200811,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483022,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.81,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.99,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 134.69,
    "out:Total Energy Use Post PV": 134.69,
    "out:Primary Energy": 131.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.45,
    "out:CO2 Operational/m2": 44,
    "out:Total CO2/m2": 50.45,
    "out:Total CO2 (tons)": 259.75,
    "out:Klimatpaverkan": -29.86,
    "out:Initial Cost/MSEK": 2.515,
    "out:Running cost/(Apt SEK y)": 36871,
    "out:Running Cost over RSP/MSEK": 15.555,
    "out:LCP/MSEK": 1.435,
    "out:ROI% old": 22.44,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 20,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 129829,
    "out:DH kr savings": 185364,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 504552,
    "out:% savings (space heating)": 20.67,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.99,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.81,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 130.69,
    "out:Primary Energy": 127.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.45,
    "out:CO2 Operational/m2": 42.56,
    "out:Total CO2/m2": 49.01,
    "out:Total CO2 (tons)": 252.34,
    "out:Klimatpaverkan": -37.27,
    "out:Initial Cost/MSEK": 2.643,
    "out:Running cost/(Apt SEK y)": 35719,
    "out:Running Cost over RSP/MSEK": 15.068,
    "out:LCP/MSEK": 1.793,
    "out:ROI% old": 23.97,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 150425,
    "out:DH kr savings": 205960,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483022,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.81,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.99,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 130.69,
    "out:Primary Energy": 128.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.73,
    "out:CO2 Operational/m2": 42.56,
    "out:Total CO2/m2": 49.29,
    "out:Total CO2 (tons)": 253.79,
    "out:Klimatpaverkan": -35.82,
    "out:Initial Cost/MSEK": 2.69,
    "out:Running cost/(Apt SEK y)": 35719,
    "out:Running Cost over RSP/MSEK": 15.068,
    "out:LCP/MSEK": 1.746,
    "out:ROI% old": 23.55,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150425,
    "out:DH kr savings": 205960,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 504552,
    "out:% savings (space heating)": 20.67,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.99,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.81,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 126.69,
    "out:Primary Energy": 125.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.73,
    "out:CO2 Operational/m2": 41.12,
    "out:Total CO2/m2": 47.85,
    "out:Total CO2 (tons)": 246.37,
    "out:Klimatpaverkan": -43.24,
    "out:Initial Cost/MSEK": 2.819,
    "out:Running cost/(Apt SEK y)": 34567,
    "out:Running Cost over RSP/MSEK": 14.582,
    "out:LCP/MSEK": 2.103,
    "out:ROI% old": 24.94,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 22,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171021,
    "out:DH kr savings": 226556,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483022,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.81,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.99,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 134.69,
    "out:Total Energy Use Post PV": 129.84,
    "out:Primary Energy": 122.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.51,
    "out:CO2 Operational/m2": 35.77,
    "out:Total CO2/m2": 49.27,
    "out:Total CO2 (tons)": 253.71,
    "out:Klimatpaverkan": -35.9,
    "out:Initial Cost/MSEK": 3.127,
    "out:Running cost/(Apt SEK y)": 35726,
    "out:Running Cost over RSP/MSEK": 15.084,
    "out:LCP/MSEK": 1.293,
    "out:ROI% old": 20.19,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 18,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191656,
    "out:DH kr savings": 185364,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 504552,
    "out:% savings (space heating)": 20.67,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.99,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.81,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 125.84,
    "out:Primary Energy": 119.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.51,
    "out:CO2 Operational/m2": 34.33,
    "out:Total CO2/m2": 47.83,
    "out:Total CO2 (tons)": 246.29,
    "out:Klimatpaverkan": -43.32,
    "out:Initial Cost/MSEK": 3.255,
    "out:Running cost/(Apt SEK y)": 34574,
    "out:Running Cost over RSP/MSEK": 14.598,
    "out:LCP/MSEK": 1.651,
    "out:ROI% old": 21.53,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212252,
    "out:DH kr savings": 205960,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483022,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.81,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.99,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 125.84,
    "out:Primary Energy": 120.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.79,
    "out:CO2 Operational/m2": 34.33,
    "out:Total CO2/m2": 48.11,
    "out:Total CO2 (tons)": 247.74,
    "out:Klimatpaverkan": -41.87,
    "out:Initial Cost/MSEK": 3.302,
    "out:Running cost/(Apt SEK y)": 34574,
    "out:Running Cost over RSP/MSEK": 14.598,
    "out:LCP/MSEK": 1.604,
    "out:ROI% old": 21.23,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212252,
    "out:DH kr savings": 205960,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 504552,
    "out:% savings (space heating)": 20.67,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.99,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.81,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 121.84,
    "out:Primary Energy": 116.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.79,
    "out:CO2 Operational/m2": 32.89,
    "out:Total CO2/m2": 46.67,
    "out:Total CO2 (tons)": 240.33,
    "out:Klimatpaverkan": -49.28,
    "out:Initial Cost/MSEK": 3.431,
    "out:Running cost/(Apt SEK y)": 33423,
    "out:Running Cost over RSP/MSEK": 14.111,
    "out:LCP/MSEK": 1.962,
    "out:ROI% old": 22.45,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 232848,
    "out:DH kr savings": 226556,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483022,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.81,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.99,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 128.69,
    "out:Total Energy Use Post PV": 128.69,
    "out:Primary Energy": 128.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.45,
    "out:CO2 Operational/m2": 41.84,
    "out:Total CO2/m2": 48.29,
    "out:Total CO2 (tons)": 248.63,
    "out:Klimatpaverkan": -40.98,
    "out:Initial Cost/MSEK": 2.657,
    "out:Running cost/(Apt SEK y)": 35143,
    "out:Running Cost over RSP/MSEK": 14.825,
    "out:LCP/MSEK": 2.022,
    "out:ROI% old": 25.16,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 22,
    "out:Return/kSEK/y": 598,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160723,
    "out:DH kr savings": 216258,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 504552,
    "out:% savings (space heating)": 20.67,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.99,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.81,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 124.69,
    "out:Primary Energy": 124.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.45,
    "out:CO2 Operational/m2": 40.4,
    "out:Total CO2/m2": 46.85,
    "out:Total CO2 (tons)": 241.21,
    "out:Klimatpaverkan": -48.4,
    "out:Initial Cost/MSEK": 2.786,
    "out:Running cost/(Apt SEK y)": 33992,
    "out:Running Cost over RSP/MSEK": 14.339,
    "out:LCP/MSEK": 2.38,
    "out:ROI% old": 26.49,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 24,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181319,
    "out:DH kr savings": 236854,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483022,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.81,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.99,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 125.69,
    "out:Primary Energy": 125.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.73,
    "out:CO2 Operational/m2": 40.76,
    "out:Total CO2/m2": 47.49,
    "out:Total CO2 (tons)": 244.52,
    "out:Klimatpaverkan": -45.09,
    "out:Initial Cost/MSEK": 2.833,
    "out:Running cost/(Apt SEK y)": 34280,
    "out:Running Cost over RSP/MSEK": 14.46,
    "out:LCP/MSEK": 2.211,
    "out:ROI% old": 25.44,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 23,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176170,
    "out:DH kr savings": 231705,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 504552,
    "out:% savings (space heating)": 20.67,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.99,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.81,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 121.69,
    "out:Primary Energy": 122.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.73,
    "out:CO2 Operational/m2": 39.32,
    "out:Total CO2/m2": 46.05,
    "out:Total CO2 (tons)": 237.1,
    "out:Klimatpaverkan": -52.51,
    "out:Initial Cost/MSEK": 2.962,
    "out:Running cost/(Apt SEK y)": 33128,
    "out:Running Cost over RSP/MSEK": 13.974,
    "out:LCP/MSEK": 2.569,
    "out:ROI% old": 26.68,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 24,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 196766,
    "out:DH kr savings": 252301,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483022,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.81,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.99,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 128.69,
    "out:Total Energy Use Post PV": 123.84,
    "out:Primary Energy": 119.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.51,
    "out:CO2 Operational/m2": 33.61,
    "out:Total CO2/m2": 47.11,
    "out:Total CO2 (tons)": 242.59,
    "out:Klimatpaverkan": -47.02,
    "out:Initial Cost/MSEK": 3.269,
    "out:Running cost/(Apt SEK y)": 33998,
    "out:Running Cost over RSP/MSEK": 14.355,
    "out:LCP/MSEK": 1.881,
    "out:ROI% old": 22.5,
    "out:Payback discounted": 6,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 659,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 222550,
    "out:DH kr savings": 216258,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 504552,
    "out:% savings (space heating)": 20.67,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.99,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.81,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 119.84,
    "out:Primary Energy": 115.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.51,
    "out:CO2 Operational/m2": 32.17,
    "out:Total CO2/m2": 45.67,
    "out:Total CO2 (tons)": 235.17,
    "out:Klimatpaverkan": -54.44,
    "out:Initial Cost/MSEK": 3.398,
    "out:Running cost/(Apt SEK y)": 32847,
    "out:Running Cost over RSP/MSEK": 13.868,
    "out:LCP/MSEK": 2.238,
    "out:ROI% old": 23.7,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243146,
    "out:DH kr savings": 236854,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483022,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.81,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.99,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 120.84,
    "out:Primary Energy": 117.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.79,
    "out:CO2 Operational/m2": 32.53,
    "out:Total CO2/m2": 46.31,
    "out:Total CO2 (tons)": 238.48,
    "out:Klimatpaverkan": -51.13,
    "out:Initial Cost/MSEK": 3.445,
    "out:Running cost/(Apt SEK y)": 33135,
    "out:Running Cost over RSP/MSEK": 13.99,
    "out:LCP/MSEK": 2.07,
    "out:ROI% old": 22.87,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 237997,
    "out:DH kr savings": 231705,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 504552,
    "out:% savings (space heating)": 20.67,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.99,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.81,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 116.84,
    "out:Primary Energy": 113.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.79,
    "out:CO2 Operational/m2": 31.09,
    "out:Total CO2/m2": 44.88,
    "out:Total CO2 (tons)": 231.06,
    "out:Klimatpaverkan": -58.55,
    "out:Initial Cost/MSEK": 3.574,
    "out:Running cost/(Apt SEK y)": 31983,
    "out:Running Cost over RSP/MSEK": 13.503,
    "out:LCP/MSEK": 2.428,
    "out:ROI% old": 23.99,
    "out:Payback discounted": 5,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258593,
    "out:DH kr savings": 252301,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 483022,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.81,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.52,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 135.3,
    "out:Primary Energy": 125.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.67,
    "out:CO2 Operational/m2": 45.45,
    "out:Total CO2/m2": 59.11,
    "out:Total CO2 (tons)": 304.38,
    "out:Klimatpaverkan": 14.77,
    "out:Initial Cost/MSEK": 4.884,
    "out:Running cost/(Apt SEK y)": 37571,
    "out:Running Cost over RSP/MSEK": 15.855,
    "out:LCP/MSEK": -1.235,
    "out:ROI% old": 10.67,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154470,
    "out:DH kr savings": 154470,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 481534,
    "out:% savings (space heating)": 24.29,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.52,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.54,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 131.3,
    "out:Primary Energy": 121.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.67,
    "out:CO2 Operational/m2": 44.01,
    "out:Total CO2/m2": 57.67,
    "out:Total CO2 (tons)": 296.96,
    "out:Klimatpaverkan": 7.35,
    "out:Initial Cost/MSEK": 5.013,
    "out:Running cost/(Apt SEK y)": 36419,
    "out:Running Cost over RSP/MSEK": 15.369,
    "out:LCP/MSEK": -0.877,
    "out:ROI% old": 11.79,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175066,
    "out:DH kr savings": 175066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 461051,
    "out:% savings (space heating)": 27.51,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.54,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.52,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 131.3,
    "out:Primary Energy": 122.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.95,
    "out:CO2 Operational/m2": 44.01,
    "out:Total CO2/m2": 57.96,
    "out:Total CO2 (tons)": 298.41,
    "out:Klimatpaverkan": 8.8,
    "out:Initial Cost/MSEK": 5.06,
    "out:Running cost/(Apt SEK y)": 36419,
    "out:Running Cost over RSP/MSEK": 15.369,
    "out:LCP/MSEK": -0.924,
    "out:ROI% old": 11.68,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175066,
    "out:DH kr savings": 175066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 481534,
    "out:% savings (space heating)": 24.29,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.52,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.54,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 127.3,
    "out:Total Energy Use Post PV": 127.3,
    "out:Primary Energy": 118.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.95,
    "out:CO2 Operational/m2": 42.57,
    "out:Total CO2/m2": 56.52,
    "out:Total CO2 (tons)": 291,
    "out:Klimatpaverkan": 1.39,
    "out:Initial Cost/MSEK": 5.188,
    "out:Running cost/(Apt SEK y)": 35267,
    "out:Running Cost over RSP/MSEK": 14.882,
    "out:LCP/MSEK": -0.567,
    "out:ROI% old": 12.73,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 591,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195662,
    "out:DH kr savings": 195662,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 461051,
    "out:% savings (space heating)": 27.51,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.54,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.52,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 131.29,
    "out:Primary Energy": 117.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.73,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 53.74,
    "out:Total CO2 (tons)": 276.73,
    "out:Klimatpaverkan": -12.88,
    "out:Initial Cost/MSEK": 5.496,
    "out:Running cost/(Apt SEK y)": 36466,
    "out:Running Cost over RSP/MSEK": 15.401,
    "out:LCP/MSEK": -1.393,
    "out:ROI% old": 10.66,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214128,
    "out:DH kr savings": 154470,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 481534,
    "out:% savings (space heating)": 24.29,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.52,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.54,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 127.29,
    "out:Primary Energy": 114.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.73,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 52.3,
    "out:Total CO2 (tons)": 269.31,
    "out:Klimatpaverkan": -20.3,
    "out:Initial Cost/MSEK": 5.625,
    "out:Running cost/(Apt SEK y)": 35314,
    "out:Running Cost over RSP/MSEK": 14.915,
    "out:LCP/MSEK": -1.035,
    "out:ROI% old": 11.66,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234724,
    "out:DH kr savings": 175066,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 461051,
    "out:% savings (space heating)": 27.51,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.54,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.52,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 127.29,
    "out:Primary Energy": 115.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.01,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 52.59,
    "out:Total CO2 (tons)": 270.76,
    "out:Klimatpaverkan": -18.85,
    "out:Initial Cost/MSEK": 5.672,
    "out:Running cost/(Apt SEK y)": 35314,
    "out:Running Cost over RSP/MSEK": 14.915,
    "out:LCP/MSEK": -1.082,
    "out:ROI% old": 11.56,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 234724,
    "out:DH kr savings": 175066,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 481534,
    "out:% savings (space heating)": 24.29,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.52,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.54,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 127.3,
    "out:Total Energy Use Post PV": 123.29,
    "out:Primary Energy": 111.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.01,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 51.15,
    "out:Total CO2 (tons)": 263.35,
    "out:Klimatpaverkan": -26.26,
    "out:Initial Cost/MSEK": 5.8,
    "out:Running cost/(Apt SEK y)": 34162,
    "out:Running Cost over RSP/MSEK": 14.428,
    "out:LCP/MSEK": -0.724,
    "out:ROI% old": 12.5,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 34.1,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 651,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 255320,
    "out:DH kr savings": 195662,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 461051,
    "out:% savings (space heating)": 27.51,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.54,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.52,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 129.3,
    "out:Primary Energy": 121.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.67,
    "out:CO2 Operational/m2": 43.29,
    "out:Total CO2/m2": 56.95,
    "out:Total CO2 (tons)": 293.26,
    "out:Klimatpaverkan": 3.65,
    "out:Initial Cost/MSEK": 5.027,
    "out:Running cost/(Apt SEK y)": 35843,
    "out:Running Cost over RSP/MSEK": 15.126,
    "out:LCP/MSEK": -0.648,
    "out:ROI% old": 12.44,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 560,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185364,
    "out:DH kr savings": 185364,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 481534,
    "out:% savings (space heating)": 24.29,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.52,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.54,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 125.3,
    "out:Primary Energy": 118.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.67,
    "out:CO2 Operational/m2": 41.85,
    "out:Total CO2/m2": 55.51,
    "out:Total CO2 (tons)": 285.84,
    "out:Klimatpaverkan": -3.77,
    "out:Initial Cost/MSEK": 5.155,
    "out:Running cost/(Apt SEK y)": 34691,
    "out:Running Cost over RSP/MSEK": 14.639,
    "out:LCP/MSEK": -0.29,
    "out:ROI% old": 13.48,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205960,
    "out:DH kr savings": 205960,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 461051,
    "out:% savings (space heating)": 27.51,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.54,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.52,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 126.3,
    "out:Primary Energy": 119.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.95,
    "out:CO2 Operational/m2": 42.21,
    "out:Total CO2/m2": 56.16,
    "out:Total CO2 (tons)": 289.14,
    "out:Klimatpaverkan": -0.47,
    "out:Initial Cost/MSEK": 5.202,
    "out:Running cost/(Apt SEK y)": 34979,
    "out:Running Cost over RSP/MSEK": 14.761,
    "out:LCP/MSEK": -0.459,
    "out:ROI% old": 13.03,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 200811,
    "out:DH kr savings": 200811,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 481534,
    "out:% savings (space heating)": 24.29,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.52,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.54,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 122.3,
    "out:Total Energy Use Post PV": 122.3,
    "out:Primary Energy": 115.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.95,
    "out:CO2 Operational/m2": 40.77,
    "out:Total CO2/m2": 54.72,
    "out:Total CO2 (tons)": 281.73,
    "out:Klimatpaverkan": -7.88,
    "out:Initial Cost/MSEK": 5.331,
    "out:Running cost/(Apt SEK y)": 33827,
    "out:Running Cost over RSP/MSEK": 14.274,
    "out:LCP/MSEK": -0.101,
    "out:ROI% old": 14.02,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 669,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221407,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221407,
    "out:DH kr savings": 221407,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 461051,
    "out:% savings (space heating)": 27.51,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.54,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z00012200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.52,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 125.29,
    "out:Primary Energy": 114.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.73,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 51.58,
    "out:Total CO2 (tons)": 265.61,
    "out:Klimatpaverkan": -24,
    "out:Initial Cost/MSEK": 5.639,
    "out:Running cost/(Apt SEK y)": 34738,
    "out:Running Cost over RSP/MSEK": 14.671,
    "out:LCP/MSEK": -0.806,
    "out:ROI% old": 12.24,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245022,
    "out:DH kr savings": 185364,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 481534,
    "out:% savings (space heating)": 24.29,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.52,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.54,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 121.29,
    "out:Primary Energy": 110.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.73,
    "out:CO2 Operational/m2": 29.42,
    "out:Total CO2/m2": 50.14,
    "out:Total CO2 (tons)": 258.19,
    "out:Klimatpaverkan": -31.42,
    "out:Initial Cost/MSEK": 5.767,
    "out:Running cost/(Apt SEK y)": 33586,
    "out:Running Cost over RSP/MSEK": 14.185,
    "out:LCP/MSEK": -0.448,
    "out:ROI% old": 13.18,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 265618,
    "out:DH kr savings": 205960,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 461051,
    "out:% savings (space heating)": 27.51,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.54,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.52,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 122.29,
    "out:Primary Energy": 112.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.01,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 50.79,
    "out:Total CO2 (tons)": 261.5,
    "out:Klimatpaverkan": -28.11,
    "out:Initial Cost/MSEK": 5.814,
    "out:Running cost/(Apt SEK y)": 33874,
    "out:Running Cost over RSP/MSEK": 14.307,
    "out:LCP/MSEK": -0.617,
    "out:ROI% old": 12.77,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 666,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 260469,
    "out:DH kr savings": 200811,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 481534,
    "out:% savings (space heating)": 24.29,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.52,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.54,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 122.3,
    "out:Total Energy Use Post PV": 118.29,
    "out:Primary Energy": 108.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.01,
    "out:CO2 Operational/m2": 28.34,
    "out:Total CO2/m2": 49.35,
    "out:Total CO2 (tons)": 254.08,
    "out:Klimatpaverkan": -35.53,
    "out:Initial Cost/MSEK": 5.943,
    "out:Running cost/(Apt SEK y)": 32722,
    "out:Running Cost over RSP/MSEK": 13.82,
    "out:LCP/MSEK": -0.259,
    "out:ROI% old": 13.66,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 728,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 221407,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281065,
    "out:DH kr savings": 221407,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 461051,
    "out:% savings (space heating)": 27.51,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.54,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z00012201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.52,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 129.69,
    "out:Total Energy Use Post PV": 129.69,
    "out:Primary Energy": 127.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.01,
    "out:CO2 Operational/m2": 42.2,
    "out:Total CO2/m2": 58.21,
    "out:Total CO2 (tons)": 299.72,
    "out:Klimatpaverkan": 10.11,
    "out:Initial Cost/MSEK": 5.681,
    "out:Running cost/(Apt SEK y)": 35431,
    "out:Running Cost over RSP/MSEK": 14.947,
    "out:LCP/MSEK": -1.123,
    "out:ROI% old": 11.46,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155574,
    "out:DH kr savings": 211109,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 481534,
    "out:% savings (space heating)": 24.29,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.52,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.54,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 125.69,
    "out:Primary Energy": 124.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.01,
    "out:CO2 Operational/m2": 40.76,
    "out:Total CO2/m2": 56.77,
    "out:Total CO2 (tons)": 292.3,
    "out:Klimatpaverkan": 2.69,
    "out:Initial Cost/MSEK": 5.81,
    "out:Running cost/(Apt SEK y)": 34280,
    "out:Running Cost over RSP/MSEK": 14.46,
    "out:LCP/MSEK": -0.766,
    "out:ROI% old": 12.4,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176170,
    "out:DH kr savings": 231705,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 461051,
    "out:% savings (space heating)": 27.51,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.54,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.52,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 125.69,
    "out:Primary Energy": 125.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.29,
    "out:CO2 Operational/m2": 40.76,
    "out:Total CO2/m2": 57.05,
    "out:Total CO2 (tons)": 293.75,
    "out:Klimatpaverkan": 4.14,
    "out:Initial Cost/MSEK": 5.857,
    "out:Running cost/(Apt SEK y)": 34280,
    "out:Running Cost over RSP/MSEK": 14.46,
    "out:LCP/MSEK": -0.813,
    "out:ROI% old": 12.3,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176170,
    "out:DH kr savings": 231705,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 481534,
    "out:% savings (space heating)": 24.29,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.52,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.54,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 121.69,
    "out:Primary Energy": 121.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.29,
    "out:CO2 Operational/m2": 39.32,
    "out:Total CO2/m2": 55.61,
    "out:Total CO2 (tons)": 286.34,
    "out:Klimatpaverkan": -3.27,
    "out:Initial Cost/MSEK": 5.985,
    "out:Running cost/(Apt SEK y)": 33128,
    "out:Running Cost over RSP/MSEK": 13.974,
    "out:LCP/MSEK": -0.455,
    "out:ROI% old": 13.2,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 196766,
    "out:DH kr savings": 252301,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 461051,
    "out:% savings (space heating)": 27.51,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.54,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.52,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 129.69,
    "out:Total Energy Use Post PV": 124.84,
    "out:Primary Energy": 118.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.07,
    "out:CO2 Operational/m2": 33.97,
    "out:Total CO2/m2": 57.04,
    "out:Total CO2 (tons)": 293.67,
    "out:Klimatpaverkan": 4.06,
    "out:Initial Cost/MSEK": 6.293,
    "out:Running cost/(Apt SEK y)": 34286,
    "out:Running Cost over RSP/MSEK": 14.476,
    "out:LCP/MSEK": -1.265,
    "out:ROI% old": 11.41,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217401,
    "out:DH kr savings": 211109,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 481534,
    "out:% savings (space heating)": 24.29,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.52,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.54,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 120.84,
    "out:Primary Energy": 115.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.07,
    "out:CO2 Operational/m2": 32.53,
    "out:Total CO2/m2": 55.6,
    "out:Total CO2 (tons)": 286.26,
    "out:Klimatpaverkan": -3.35,
    "out:Initial Cost/MSEK": 6.422,
    "out:Running cost/(Apt SEK y)": 33135,
    "out:Running Cost over RSP/MSEK": 13.99,
    "out:LCP/MSEK": -0.907,
    "out:ROI% old": 12.27,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 237997,
    "out:DH kr savings": 231705,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 461051,
    "out:% savings (space heating)": 27.51,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.54,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.52,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 125.69,
    "out:Total Energy Use Post PV": 120.84,
    "out:Primary Energy": 116.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.35,
    "out:CO2 Operational/m2": 32.53,
    "out:Total CO2/m2": 55.88,
    "out:Total CO2 (tons)": 287.71,
    "out:Klimatpaverkan": -1.9,
    "out:Initial Cost/MSEK": 6.469,
    "out:Running cost/(Apt SEK y)": 33135,
    "out:Running Cost over RSP/MSEK": 13.99,
    "out:LCP/MSEK": -0.954,
    "out:ROI% old": 12.18,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 237997,
    "out:DH kr savings": 231705,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 481534,
    "out:% savings (space heating)": 24.29,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.52,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.54,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 116.84,
    "out:Primary Energy": 112.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.35,
    "out:CO2 Operational/m2": 31.09,
    "out:Total CO2/m2": 54.44,
    "out:Total CO2 (tons)": 280.3,
    "out:Klimatpaverkan": -9.31,
    "out:Initial Cost/MSEK": 6.597,
    "out:Running cost/(Apt SEK y)": 31983,
    "out:Running Cost over RSP/MSEK": 13.503,
    "out:LCP/MSEK": -0.596,
    "out:ROI% old": 12.99,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258593,
    "out:DH kr savings": 252301,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 461051,
    "out:% savings (space heating)": 27.51,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.54,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.52,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 124.69,
    "out:Primary Energy": 124.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.01,
    "out:CO2 Operational/m2": 40.4,
    "out:Total CO2/m2": 56.41,
    "out:Total CO2 (tons)": 290.45,
    "out:Klimatpaverkan": 0.84,
    "out:Initial Cost/MSEK": 5.823,
    "out:Running cost/(Apt SEK y)": 33992,
    "out:Running Cost over RSP/MSEK": 14.339,
    "out:LCP/MSEK": -0.658,
    "out:ROI% old": 12.67,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181319,
    "out:DH kr savings": 236854,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 481534,
    "out:% savings (space heating)": 24.29,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.52,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.54,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 120.69,
    "out:Primary Energy": 120.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.01,
    "out:CO2 Operational/m2": 38.96,
    "out:Total CO2/m2": 54.97,
    "out:Total CO2 (tons)": 283.04,
    "out:Klimatpaverkan": -6.57,
    "out:Initial Cost/MSEK": 5.952,
    "out:Running cost/(Apt SEK y)": 32840,
    "out:Running Cost over RSP/MSEK": 13.852,
    "out:LCP/MSEK": -0.3,
    "out:ROI% old": 13.57,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201915,
    "out:DH kr savings": 257450,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 461051,
    "out:% savings (space heating)": 27.51,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.54,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.52,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 121.69,
    "out:Primary Energy": 122.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.29,
    "out:CO2 Operational/m2": 39.32,
    "out:Total CO2/m2": 55.61,
    "out:Total CO2 (tons)": 286.34,
    "out:Klimatpaverkan": -3.27,
    "out:Initial Cost/MSEK": 5.999,
    "out:Running cost/(Apt SEK y)": 33128,
    "out:Running Cost over RSP/MSEK": 13.974,
    "out:LCP/MSEK": -0.469,
    "out:ROI% old": 13.17,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196766,
    "out:DH kr savings": 252301,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 481534,
    "out:% savings (space heating)": 24.29,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.52,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.54,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 117.69,
    "out:Total Energy Use Post PV": 117.69,
    "out:Primary Energy": 118.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.29,
    "out:CO2 Operational/m2": 37.88,
    "out:Total CO2/m2": 54.17,
    "out:Total CO2 (tons)": 278.93,
    "out:Klimatpaverkan": -10.68,
    "out:Initial Cost/MSEK": 6.128,
    "out:Running cost/(Apt SEK y)": 31976,
    "out:Running Cost over RSP/MSEK": 13.487,
    "out:LCP/MSEK": -0.111,
    "out:ROI% old": 14.03,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 769,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217362,
    "out:DH kr savings": 272897,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 461051,
    "out:% savings (space heating)": 27.51,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.54,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z00012201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.52,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 119.84,
    "out:Primary Energy": 115.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.07,
    "out:CO2 Operational/m2": 32.17,
    "out:Total CO2/m2": 55.24,
    "out:Total CO2 (tons)": 284.41,
    "out:Klimatpaverkan": -5.2,
    "out:Initial Cost/MSEK": 6.435,
    "out:Running cost/(Apt SEK y)": 32847,
    "out:Running Cost over RSP/MSEK": 13.868,
    "out:LCP/MSEK": -0.799,
    "out:ROI% old": 12.51,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 243146,
    "out:DH kr savings": 236854,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 481534,
    "out:% savings (space heating)": 24.29,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.52,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.54,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 115.84,
    "out:Primary Energy": 111.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.07,
    "out:CO2 Operational/m2": 30.73,
    "out:Total CO2/m2": 53.8,
    "out:Total CO2 (tons)": 276.99,
    "out:Klimatpaverkan": -12.62,
    "out:Initial Cost/MSEK": 6.564,
    "out:Running cost/(Apt SEK y)": 31695,
    "out:Running Cost over RSP/MSEK": 13.381,
    "out:LCP/MSEK": -0.441,
    "out:ROI% old": 13.33,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 263742,
    "out:DH kr savings": 257450,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 461051,
    "out:% savings (space heating)": 27.51,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.54,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.52,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 116.84,
    "out:Primary Energy": 113.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.35,
    "out:CO2 Operational/m2": 31.09,
    "out:Total CO2/m2": 54.44,
    "out:Total CO2 (tons)": 280.3,
    "out:Klimatpaverkan": -9.31,
    "out:Initial Cost/MSEK": 6.611,
    "out:Running cost/(Apt SEK y)": 31983,
    "out:Running Cost over RSP/MSEK": 13.503,
    "out:LCP/MSEK": -0.61,
    "out:ROI% old": 12.97,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 258593,
    "out:DH kr savings": 252301,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 481534,
    "out:% savings (space heating)": 24.29,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.52,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00012201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.54,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 117.69,
    "out:Total Energy Use Post PV": 112.84,
    "out:Primary Energy": 109.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.35,
    "out:CO2 Operational/m2": 29.65,
    "out:Total CO2/m2": 53,
    "out:Total CO2 (tons)": 272.88,
    "out:Klimatpaverkan": -16.73,
    "out:Initial Cost/MSEK": 6.74,
    "out:Running cost/(Apt SEK y)": 30831,
    "out:Running Cost over RSP/MSEK": 13.017,
    "out:LCP/MSEK": -0.252,
    "out:ROI% old": 13.75,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 830,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279189,
    "out:DH kr savings": 272897,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 461051,
    "out:% savings (space heating)": 27.51,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.54,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 144.3,
    "out:Total Energy Use Post PV": 144.3,
    "out:Primary Energy": 133.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.24,
    "out:CO2 Operational/m2": 48.69,
    "out:Total CO2/m2": 59.92,
    "out:Total CO2 (tons)": 308.55,
    "out:Klimatpaverkan": 18.94,
    "out:Initial Cost/MSEK": 4.126,
    "out:Running cost/(Apt SEK y)": 40162,
    "out:Running Cost over RSP/MSEK": 16.95,
    "out:LCP/MSEK": -1.572,
    "out:ROI% old": 8.84,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 14.6,
    "out:Op energy cost/MSEK": 124,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 8,
    "out:Return/kSEK/y": 327,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 108129,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108129,
    "out:DH kr savings": 108129,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 140.3,
    "out:Primary Energy": 129.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.24,
    "out:CO2 Operational/m2": 47.25,
    "out:Total CO2/m2": 58.48,
    "out:Total CO2 (tons)": 301.13,
    "out:Klimatpaverkan": 11.52,
    "out:Initial Cost/MSEK": 4.255,
    "out:Running cost/(Apt SEK y)": 39010,
    "out:Running Cost over RSP/MSEK": 16.464,
    "out:LCP/MSEK": -1.214,
    "out:ROI% old": 10.21,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128725,
    "out:DH kr savings": 128725,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 140.3,
    "out:Primary Energy": 130.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.52,
    "out:CO2 Operational/m2": 47.25,
    "out:Total CO2/m2": 58.77,
    "out:Total CO2 (tons)": 302.58,
    "out:Klimatpaverkan": 12.97,
    "out:Initial Cost/MSEK": 4.302,
    "out:Running cost/(Apt SEK y)": 39010,
    "out:Running Cost over RSP/MSEK": 16.464,
    "out:LCP/MSEK": -1.261,
    "out:ROI% old": 10.1,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128725,
    "out:DH kr savings": 128725,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 135.3,
    "out:Primary Energy": 126.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.52,
    "out:CO2 Operational/m2": 45.45,
    "out:Total CO2/m2": 56.97,
    "out:Total CO2 (tons)": 293.32,
    "out:Klimatpaverkan": 3.71,
    "out:Initial Cost/MSEK": 4.43,
    "out:Running cost/(Apt SEK y)": 37571,
    "out:Running Cost over RSP/MSEK": 15.855,
    "out:LCP/MSEK": -0.782,
    "out:ROI% old": 11.77,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154470,
    "out:DH kr savings": 154470,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 144.3,
    "out:Total Energy Use Post PV": 140.29,
    "out:Primary Energy": 125.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.3,
    "out:CO2 Operational/m2": 36.26,
    "out:Total CO2/m2": 54.55,
    "out:Total CO2 (tons)": 280.9,
    "out:Klimatpaverkan": -8.71,
    "out:Initial Cost/MSEK": 4.738,
    "out:Running cost/(Apt SEK y)": 39057,
    "out:Running Cost over RSP/MSEK": 16.496,
    "out:LCP/MSEK": -1.73,
    "out:ROI% old": 9.07,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 121,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 108129,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167787,
    "out:DH kr savings": 108129,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 136.29,
    "out:Primary Energy": 122.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.3,
    "out:CO2 Operational/m2": 34.82,
    "out:Total CO2/m2": 53.11,
    "out:Total CO2 (tons)": 273.48,
    "out:Klimatpaverkan": -16.13,
    "out:Initial Cost/MSEK": 4.867,
    "out:Running cost/(Apt SEK y)": 37906,
    "out:Running Cost over RSP/MSEK": 16.009,
    "out:LCP/MSEK": -1.372,
    "out:ROI% old": 10.26,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188383,
    "out:DH kr savings": 128725,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 136.29,
    "out:Primary Energy": 123.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.58,
    "out:CO2 Operational/m2": 34.82,
    "out:Total CO2/m2": 53.4,
    "out:Total CO2 (tons)": 274.93,
    "out:Klimatpaverkan": -14.68,
    "out:Initial Cost/MSEK": 4.914,
    "out:Running cost/(Apt SEK y)": 37906,
    "out:Running Cost over RSP/MSEK": 16.009,
    "out:LCP/MSEK": -1.419,
    "out:ROI% old": 10.16,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188383,
    "out:DH kr savings": 128725,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 131.29,
    "out:Primary Energy": 119.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.58,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 51.6,
    "out:Total CO2 (tons)": 265.67,
    "out:Klimatpaverkan": -23.94,
    "out:Initial Cost/MSEK": 5.042,
    "out:Running cost/(Apt SEK y)": 36466,
    "out:Running Cost over RSP/MSEK": 15.401,
    "out:LCP/MSEK": -0.939,
    "out:ROI% old": 11.62,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214128,
    "out:DH kr savings": 154470,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 138.3,
    "out:Total Energy Use Post PV": 138.3,
    "out:Primary Energy": 129.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.24,
    "out:CO2 Operational/m2": 46.53,
    "out:Total CO2/m2": 57.76,
    "out:Total CO2 (tons)": 297.43,
    "out:Klimatpaverkan": 7.82,
    "out:Initial Cost/MSEK": 4.269,
    "out:Running cost/(Apt SEK y)": 38434,
    "out:Running Cost over RSP/MSEK": 16.22,
    "out:LCP/MSEK": -0.985,
    "out:ROI% old": 10.99,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 139023,
    "out:DH kr savings": 139023,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 134.3,
    "out:Total Energy Use Post PV": 134.3,
    "out:Primary Energy": 125.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.24,
    "out:CO2 Operational/m2": 45.09,
    "out:Total CO2/m2": 56.32,
    "out:Total CO2 (tons)": 290.01,
    "out:Klimatpaverkan": 0.4,
    "out:Initial Cost/MSEK": 4.397,
    "out:Running cost/(Apt SEK y)": 37283,
    "out:Running Cost over RSP/MSEK": 15.734,
    "out:LCP/MSEK": -0.627,
    "out:ROI% old": 12.25,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 23.1,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159619,
    "out:DH kr savings": 159619,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 135.3,
    "out:Primary Energy": 127.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.52,
    "out:CO2 Operational/m2": 45.45,
    "out:Total CO2/m2": 56.97,
    "out:Total CO2 (tons)": 293.32,
    "out:Klimatpaverkan": 3.71,
    "out:Initial Cost/MSEK": 4.444,
    "out:Running cost/(Apt SEK y)": 37571,
    "out:Running Cost over RSP/MSEK": 15.855,
    "out:LCP/MSEK": -0.795,
    "out:ROI% old": 11.73,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154470,
    "out:DH kr savings": 154470,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 131.3,
    "out:Primary Energy": 123.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.52,
    "out:CO2 Operational/m2": 44.01,
    "out:Total CO2/m2": 55.53,
    "out:Total CO2 (tons)": 285.9,
    "out:Klimatpaverkan": -3.71,
    "out:Initial Cost/MSEK": 4.573,
    "out:Running cost/(Apt SEK y)": 36419,
    "out:Running Cost over RSP/MSEK": 15.369,
    "out:LCP/MSEK": -0.438,
    "out:ROI% old": 12.92,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175066,
    "out:DH kr savings": 175066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 138.3,
    "out:Total Energy Use Post PV": 134.29,
    "out:Primary Energy": 122.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.3,
    "out:CO2 Operational/m2": 34.1,
    "out:Total CO2/m2": 52.39,
    "out:Total CO2 (tons)": 269.78,
    "out:Klimatpaverkan": -19.83,
    "out:Initial Cost/MSEK": 4.881,
    "out:Running cost/(Apt SEK y)": 37330,
    "out:Running Cost over RSP/MSEK": 15.766,
    "out:LCP/MSEK": -1.142,
    "out:ROI% old": 10.94,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 23.1,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 198681,
    "out:DH kr savings": 139023,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 134.3,
    "out:Total Energy Use Post PV": 130.29,
    "out:Primary Energy": 118.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.3,
    "out:CO2 Operational/m2": 32.66,
    "out:Total CO2/m2": 50.95,
    "out:Total CO2 (tons)": 262.36,
    "out:Klimatpaverkan": -27.25,
    "out:Initial Cost/MSEK": 5.009,
    "out:Running cost/(Apt SEK y)": 36178,
    "out:Running Cost over RSP/MSEK": 15.28,
    "out:LCP/MSEK": -0.785,
    "out:ROI% old": 12.05,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 542,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 219277,
    "out:DH kr savings": 159619,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 131.29,
    "out:Primary Energy": 120.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.58,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 51.6,
    "out:Total CO2 (tons)": 265.67,
    "out:Klimatpaverkan": -23.94,
    "out:Initial Cost/MSEK": 5.056,
    "out:Running cost/(Apt SEK y)": 36466,
    "out:Running Cost over RSP/MSEK": 15.401,
    "out:LCP/MSEK": -0.953,
    "out:ROI% old": 11.59,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214128,
    "out:DH kr savings": 154470,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 127.29,
    "out:Primary Energy": 116.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.58,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 50.16,
    "out:Total CO2 (tons)": 258.25,
    "out:Klimatpaverkan": -31.36,
    "out:Initial Cost/MSEK": 5.185,
    "out:Running cost/(Apt SEK y)": 35314,
    "out:Running Cost over RSP/MSEK": 14.915,
    "out:LCP/MSEK": -0.595,
    "out:ROI% old": 12.65,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234724,
    "out:DH kr savings": 175066,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 139.69,
    "out:Total Energy Use Post PV": 139.69,
    "out:Primary Energy": 135.76,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.58,
    "out:CO2 Operational/m2": 45.8,
    "out:Total CO2/m2": 59.38,
    "out:Total CO2 (tons)": 305.74,
    "out:Klimatpaverkan": 16.13,
    "out:Initial Cost/MSEK": 4.923,
    "out:Running cost/(Apt SEK y)": 38311,
    "out:Running Cost over RSP/MSEK": 16.163,
    "out:LCP/MSEK": -1.582,
    "out:ROI% old": 9.7,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 427,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 104084,
    "out:DH kr savings": 159619,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 134.69,
    "out:Total Energy Use Post PV": 134.69,
    "out:Primary Energy": 131.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.58,
    "out:CO2 Operational/m2": 44,
    "out:Total CO2/m2": 57.58,
    "out:Total CO2 (tons)": 296.47,
    "out:Klimatpaverkan": 6.86,
    "out:Initial Cost/MSEK": 5.052,
    "out:Running cost/(Apt SEK y)": 36871,
    "out:Running Cost over RSP/MSEK": 15.555,
    "out:LCP/MSEK": -1.102,
    "out:ROI% old": 11.17,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 129829,
    "out:DH kr savings": 185364,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 135.69,
    "out:Primary Energy": 133.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.86,
    "out:CO2 Operational/m2": 44.36,
    "out:Total CO2/m2": 58.22,
    "out:Total CO2 (tons)": 299.78,
    "out:Klimatpaverkan": 10.17,
    "out:Initial Cost/MSEK": 5.098,
    "out:Running cost/(Apt SEK y)": 37159,
    "out:Running Cost over RSP/MSEK": 15.677,
    "out:LCP/MSEK": -1.271,
    "out:ROI% old": 10.73,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 489,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124680,
    "out:DH kr savings": 180215,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 130.69,
    "out:Primary Energy": 129.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.86,
    "out:CO2 Operational/m2": 42.56,
    "out:Total CO2/m2": 56.42,
    "out:Total CO2 (tons)": 290.51,
    "out:Klimatpaverkan": 0.9,
    "out:Initial Cost/MSEK": 5.227,
    "out:Running cost/(Apt SEK y)": 35719,
    "out:Running Cost over RSP/MSEK": 15.068,
    "out:LCP/MSEK": -0.791,
    "out:ROI% old": 12.12,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 150425,
    "out:DH kr savings": 205960,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 139.69,
    "out:Total Energy Use Post PV": 134.84,
    "out:Primary Energy": 127.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.64,
    "out:CO2 Operational/m2": 37.57,
    "out:Total CO2/m2": 58.21,
    "out:Total CO2 (tons)": 299.7,
    "out:Klimatpaverkan": 10.09,
    "out:Initial Cost/MSEK": 5.535,
    "out:Running cost/(Apt SEK y)": 37166,
    "out:Running Cost over RSP/MSEK": 15.692,
    "out:LCP/MSEK": -1.723,
    "out:ROI% old": 9.84,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165911,
    "out:DH kr savings": 159619,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 134.69,
    "out:Total Energy Use Post PV": 129.84,
    "out:Primary Energy": 123.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.64,
    "out:CO2 Operational/m2": 35.77,
    "out:Total CO2/m2": 56.41,
    "out:Total CO2 (tons)": 290.43,
    "out:Klimatpaverkan": 0.82,
    "out:Initial Cost/MSEK": 5.664,
    "out:Running cost/(Apt SEK y)": 35726,
    "out:Running Cost over RSP/MSEK": 15.084,
    "out:LCP/MSEK": -1.244,
    "out:ROI% old": 11.15,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191656,
    "out:DH kr savings": 185364,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 130.84,
    "out:Primary Energy": 124.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.92,
    "out:CO2 Operational/m2": 36.13,
    "out:Total CO2/m2": 57.05,
    "out:Total CO2 (tons)": 293.74,
    "out:Klimatpaverkan": 4.13,
    "out:Initial Cost/MSEK": 5.711,
    "out:Running cost/(Apt SEK y)": 36014,
    "out:Running Cost over RSP/MSEK": 15.206,
    "out:LCP/MSEK": -1.412,
    "out:ROI% old": 10.75,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186507,
    "out:DH kr savings": 180215,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 125.84,
    "out:Primary Energy": 120.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.92,
    "out:CO2 Operational/m2": 34.33,
    "out:Total CO2/m2": 55.25,
    "out:Total CO2 (tons)": 284.47,
    "out:Klimatpaverkan": -5.14,
    "out:Initial Cost/MSEK": 5.839,
    "out:Running cost/(Apt SEK y)": 34574,
    "out:Running Cost over RSP/MSEK": 14.598,
    "out:LCP/MSEK": -0.933,
    "out:ROI% old": 12,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212252,
    "out:DH kr savings": 205960,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 133.69,
    "out:Total Energy Use Post PV": 133.69,
    "out:Primary Energy": 132.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.58,
    "out:CO2 Operational/m2": 43.64,
    "out:Total CO2/m2": 57.22,
    "out:Total CO2 (tons)": 294.62,
    "out:Klimatpaverkan": 5.01,
    "out:Initial Cost/MSEK": 5.065,
    "out:Running cost/(Apt SEK y)": 36583,
    "out:Running Cost over RSP/MSEK": 15.433,
    "out:LCP/MSEK": -0.994,
    "out:ROI% old": 11.48,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 23.1,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190513,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134978,
    "out:DH kr savings": 190513,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 129.69,
    "out:Total Energy Use Post PV": 129.69,
    "out:Primary Energy": 128.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.58,
    "out:CO2 Operational/m2": 42.2,
    "out:Total CO2/m2": 55.78,
    "out:Total CO2 (tons)": 287.21,
    "out:Klimatpaverkan": -2.4,
    "out:Initial Cost/MSEK": 5.194,
    "out:Running cost/(Apt SEK y)": 35431,
    "out:Running Cost over RSP/MSEK": 14.947,
    "out:LCP/MSEK": -0.637,
    "out:ROI% old": 12.53,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 155574,
    "out:DH kr savings": 211109,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 130.69,
    "out:Primary Energy": 130.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.86,
    "out:CO2 Operational/m2": 42.56,
    "out:Total CO2/m2": 56.42,
    "out:Total CO2 (tons)": 290.51,
    "out:Klimatpaverkan": 0.9,
    "out:Initial Cost/MSEK": 5.241,
    "out:Running cost/(Apt SEK y)": 35719,
    "out:Running Cost over RSP/MSEK": 15.068,
    "out:LCP/MSEK": -0.805,
    "out:ROI% old": 12.09,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150425,
    "out:DH kr savings": 205960,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 126.69,
    "out:Primary Energy": 126.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.86,
    "out:CO2 Operational/m2": 41.12,
    "out:Total CO2/m2": 54.98,
    "out:Total CO2 (tons)": 283.1,
    "out:Klimatpaverkan": -6.51,
    "out:Initial Cost/MSEK": 5.37,
    "out:Running cost/(Apt SEK y)": 34567,
    "out:Running Cost over RSP/MSEK": 14.582,
    "out:LCP/MSEK": -0.447,
    "out:ROI% old": 13.1,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171021,
    "out:DH kr savings": 226556,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 133.69,
    "out:Total Energy Use Post PV": 128.84,
    "out:Primary Energy": 123.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.64,
    "out:CO2 Operational/m2": 35.41,
    "out:Total CO2/m2": 56.05,
    "out:Total CO2 (tons)": 288.58,
    "out:Klimatpaverkan": -1.03,
    "out:Initial Cost/MSEK": 5.677,
    "out:Running cost/(Apt SEK y)": 35438,
    "out:Running Cost over RSP/MSEK": 14.963,
    "out:LCP/MSEK": -1.136,
    "out:ROI% old": 11.43,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190513,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196805,
    "out:DH kr savings": 190513,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 129.69,
    "out:Total Energy Use Post PV": 124.84,
    "out:Primary Energy": 119.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.64,
    "out:CO2 Operational/m2": 33.97,
    "out:Total CO2/m2": 54.61,
    "out:Total CO2 (tons)": 281.16,
    "out:Klimatpaverkan": -8.45,
    "out:Initial Cost/MSEK": 5.806,
    "out:Running cost/(Apt SEK y)": 34286,
    "out:Running Cost over RSP/MSEK": 14.476,
    "out:LCP/MSEK": -0.778,
    "out:ROI% old": 12.37,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217401,
    "out:DH kr savings": 211109,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 125.84,
    "out:Primary Energy": 121.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.92,
    "out:CO2 Operational/m2": 34.33,
    "out:Total CO2/m2": 55.25,
    "out:Total CO2 (tons)": 284.47,
    "out:Klimatpaverkan": -5.14,
    "out:Initial Cost/MSEK": 5.853,
    "out:Running cost/(Apt SEK y)": 34574,
    "out:Running Cost over RSP/MSEK": 14.598,
    "out:LCP/MSEK": -0.947,
    "out:ROI% old": 11.98,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212252,
    "out:DH kr savings": 205960,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 121.84,
    "out:Primary Energy": 117.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.92,
    "out:CO2 Operational/m2": 32.89,
    "out:Total CO2/m2": 53.81,
    "out:Total CO2 (tons)": 277.05,
    "out:Klimatpaverkan": -12.56,
    "out:Initial Cost/MSEK": 5.982,
    "out:Running cost/(Apt SEK y)": 33423,
    "out:Running Cost over RSP/MSEK": 14.111,
    "out:LCP/MSEK": -0.589,
    "out:ROI% old": 12.88,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 232848,
    "out:DH kr savings": 226556,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 129.3,
    "out:Primary Energy": 120.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.23,
    "out:CO2 Operational/m2": 43.29,
    "out:Total CO2/m2": 57.51,
    "out:Total CO2 (tons)": 296.13,
    "out:Klimatpaverkan": 6.52,
    "out:Initial Cost/MSEK": 5.419,
    "out:Running cost/(Apt SEK y)": 35843,
    "out:Running Cost over RSP/MSEK": 15.126,
    "out:LCP/MSEK": -1.041,
    "out:ROI% old": 11.54,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 560,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185364,
    "out:DH kr savings": 185364,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 126.3,
    "out:Primary Energy": 117.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.23,
    "out:CO2 Operational/m2": 42.21,
    "out:Total CO2/m2": 56.43,
    "out:Total CO2 (tons)": 290.57,
    "out:Klimatpaverkan": 0.96,
    "out:Initial Cost/MSEK": 5.548,
    "out:Running cost/(Apt SEK y)": 34979,
    "out:Running Cost over RSP/MSEK": 14.761,
    "out:LCP/MSEK": -0.804,
    "out:ROI% old": 12.21,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200811,
    "out:DH kr savings": 200811,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 125.3,
    "out:Primary Energy": 117.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.51,
    "out:CO2 Operational/m2": 41.85,
    "out:Total CO2/m2": 56.35,
    "out:Total CO2 (tons)": 290.17,
    "out:Klimatpaverkan": 0.56,
    "out:Initial Cost/MSEK": 5.595,
    "out:Running cost/(Apt SEK y)": 34691,
    "out:Running Cost over RSP/MSEK": 14.639,
    "out:LCP/MSEK": -0.73,
    "out:ROI% old": 12.42,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205960,
    "out:DH kr savings": 205960,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 122.3,
    "out:Total Energy Use Post PV": 122.3,
    "out:Primary Energy": 114.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.51,
    "out:CO2 Operational/m2": 40.77,
    "out:Total CO2/m2": 55.27,
    "out:Total CO2 (tons)": 284.61,
    "out:Klimatpaverkan": -5,
    "out:Initial Cost/MSEK": 5.724,
    "out:Running cost/(Apt SEK y)": 33827,
    "out:Running Cost over RSP/MSEK": 14.274,
    "out:LCP/MSEK": -0.494,
    "out:ROI% old": 13.05,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 669,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221407,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221407,
    "out:DH kr savings": 221407,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 125.29,
    "out:Primary Energy": 113.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.29,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 52.14,
    "out:Total CO2 (tons)": 268.48,
    "out:Klimatpaverkan": -21.13,
    "out:Initial Cost/MSEK": 6.031,
    "out:Running cost/(Apt SEK y)": 34738,
    "out:Running Cost over RSP/MSEK": 14.671,
    "out:LCP/MSEK": -1.198,
    "out:ROI% old": 11.45,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245022,
    "out:DH kr savings": 185364,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 122.29,
    "out:Primary Energy": 109.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.29,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 51.06,
    "out:Total CO2 (tons)": 262.92,
    "out:Klimatpaverkan": -26.69,
    "out:Initial Cost/MSEK": 6.16,
    "out:Running cost/(Apt SEK y)": 33874,
    "out:Running Cost over RSP/MSEK": 14.307,
    "out:LCP/MSEK": -0.962,
    "out:ROI% old": 12.05,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 666,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260469,
    "out:DH kr savings": 200811,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 121.29,
    "out:Primary Energy": 110.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.57,
    "out:CO2 Operational/m2": 29.42,
    "out:Total CO2/m2": 50.98,
    "out:Total CO2 (tons)": 262.52,
    "out:Klimatpaverkan": -27.09,
    "out:Initial Cost/MSEK": 6.207,
    "out:Running cost/(Apt SEK y)": 33586,
    "out:Running Cost over RSP/MSEK": 14.185,
    "out:LCP/MSEK": -0.887,
    "out:ROI% old": 12.24,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 265618,
    "out:DH kr savings": 205960,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 122.3,
    "out:Total Energy Use Post PV": 118.29,
    "out:Primary Energy": 107.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.57,
    "out:CO2 Operational/m2": 28.34,
    "out:Total CO2/m2": 49.9,
    "out:Total CO2 (tons)": 256.96,
    "out:Klimatpaverkan": -32.65,
    "out:Initial Cost/MSEK": 6.336,
    "out:Running cost/(Apt SEK y)": 32722,
    "out:Running Cost over RSP/MSEK": 13.82,
    "out:LCP/MSEK": -0.651,
    "out:ROI% old": 12.82,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 728,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221407,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281065,
    "out:DH kr savings": 221407,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 124.3,
    "out:Total Energy Use Post PV": 124.3,
    "out:Primary Energy": 116.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.23,
    "out:CO2 Operational/m2": 41.49,
    "out:Total CO2/m2": 55.71,
    "out:Total CO2 (tons)": 286.86,
    "out:Klimatpaverkan": -2.75,
    "out:Initial Cost/MSEK": 5.562,
    "out:Running cost/(Apt SEK y)": 34403,
    "out:Running Cost over RSP/MSEK": 14.517,
    "out:LCP/MSEK": -0.575,
    "out:ROI% old": 12.81,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211109,
    "out:DH kr savings": 211109,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 120.3,
    "out:Total Energy Use Post PV": 120.3,
    "out:Primary Energy": 113.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.23,
    "out:CO2 Operational/m2": 40.05,
    "out:Total CO2/m2": 54.27,
    "out:Total CO2 (tons)": 279.45,
    "out:Klimatpaverkan": -10.16,
    "out:Initial Cost/MSEK": 5.69,
    "out:Running cost/(Apt SEK y)": 33251,
    "out:Running Cost over RSP/MSEK": 14.031,
    "out:LCP/MSEK": -0.217,
    "out:ROI% old": 13.74,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231705,
    "out:DH kr savings": 231705,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 121.3,
    "out:Total Energy Use Post PV": 121.3,
    "out:Primary Energy": 114.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.51,
    "out:CO2 Operational/m2": 40.41,
    "out:Total CO2/m2": 54.91,
    "out:Total CO2 (tons)": 282.75,
    "out:Klimatpaverkan": -6.86,
    "out:Initial Cost/MSEK": 5.737,
    "out:Running cost/(Apt SEK y)": 33539,
    "out:Running Cost over RSP/MSEK": 14.153,
    "out:LCP/MSEK": -0.386,
    "out:ROI% old": 13.33,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 684,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226556,
    "out:DH kr savings": 226556,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 117.3,
    "out:Total Energy Use Post PV": 117.3,
    "out:Primary Energy": 111.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.51,
    "out:CO2 Operational/m2": 38.97,
    "out:Total CO2/m2": 53.47,
    "out:Total CO2 (tons)": 275.34,
    "out:Klimatpaverkan": -14.27,
    "out:Initial Cost/MSEK": 5.866,
    "out:Running cost/(Apt SEK y)": 32387,
    "out:Running Cost over RSP/MSEK": 13.666,
    "out:LCP/MSEK": -0.028,
    "out:ROI% old": 14.22,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 746,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247152,
    "out:DH kr savings": 247152,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 124.3,
    "out:Total Energy Use Post PV": 120.29,
    "out:Primary Energy": 109.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.29,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 50.34,
    "out:Total CO2 (tons)": 259.21,
    "out:Klimatpaverkan": -30.4,
    "out:Initial Cost/MSEK": 6.174,
    "out:Running cost/(Apt SEK y)": 33298,
    "out:Running Cost over RSP/MSEK": 14.063,
    "out:LCP/MSEK": -0.733,
    "out:ROI% old": 12.59,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 697,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 270767,
    "out:DH kr savings": 211109,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 120.3,
    "out:Total Energy Use Post PV": 116.29,
    "out:Primary Energy": 106.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.29,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 48.9,
    "out:Total CO2 (tons)": 251.8,
    "out:Klimatpaverkan": -37.81,
    "out:Initial Cost/MSEK": 6.302,
    "out:Running cost/(Apt SEK y)": 32146,
    "out:Running Cost over RSP/MSEK": 13.577,
    "out:LCP/MSEK": -0.375,
    "out:ROI% old": 13.44,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 759,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291363,
    "out:DH kr savings": 231705,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 121.3,
    "out:Total Energy Use Post PV": 117.29,
    "out:Primary Energy": 107.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.57,
    "out:CO2 Operational/m2": 27.98,
    "out:Total CO2/m2": 49.54,
    "out:Total CO2 (tons)": 255.1,
    "out:Klimatpaverkan": -34.51,
    "out:Initial Cost/MSEK": 6.349,
    "out:Running cost/(Apt SEK y)": 32434,
    "out:Running Cost over RSP/MSEK": 13.698,
    "out:LCP/MSEK": -0.543,
    "out:ROI% old": 13.06,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 744,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 286214,
    "out:DH kr savings": 226556,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 117.3,
    "out:Total Energy Use Post PV": 113.29,
    "out:Primary Energy": 104.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.57,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 48.1,
    "out:Total CO2 (tons)": 247.69,
    "out:Klimatpaverkan": -41.92,
    "out:Initial Cost/MSEK": 6.478,
    "out:Running cost/(Apt SEK y)": 31282,
    "out:Running Cost over RSP/MSEK": 13.212,
    "out:LCP/MSEK": -0.186,
    "out:ROI% old": 13.88,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 806,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 306810,
    "out:DH kr savings": 247152,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 124.69,
    "out:Primary Energy": 122.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.57,
    "out:CO2 Operational/m2": 40.4,
    "out:Total CO2/m2": 56.97,
    "out:Total CO2 (tons)": 293.33,
    "out:Klimatpaverkan": 3.72,
    "out:Initial Cost/MSEK": 6.216,
    "out:Running cost/(Apt SEK y)": 33992,
    "out:Running Cost over RSP/MSEK": 14.339,
    "out:LCP/MSEK": -1.05,
    "out:ROI% old": 11.87,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181319,
    "out:DH kr savings": 236854,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 120.69,
    "out:Primary Energy": 119.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.57,
    "out:CO2 Operational/m2": 38.96,
    "out:Total CO2/m2": 55.53,
    "out:Total CO2 (tons)": 285.91,
    "out:Klimatpaverkan": -3.7,
    "out:Initial Cost/MSEK": 6.345,
    "out:Running cost/(Apt SEK y)": 32840,
    "out:Running Cost over RSP/MSEK": 13.852,
    "out:LCP/MSEK": -0.693,
    "out:ROI% old": 12.73,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201915,
    "out:DH kr savings": 257450,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 120.69,
    "out:Primary Energy": 120.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.85,
    "out:CO2 Operational/m2": 38.96,
    "out:Total CO2/m2": 55.81,
    "out:Total CO2 (tons)": 287.36,
    "out:Klimatpaverkan": -2.25,
    "out:Initial Cost/MSEK": 6.392,
    "out:Running cost/(Apt SEK y)": 32840,
    "out:Running Cost over RSP/MSEK": 13.852,
    "out:LCP/MSEK": -0.739,
    "out:ROI% old": 12.63,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201915,
    "out:DH kr savings": 257450,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 116.69,
    "out:Total Energy Use Post PV": 116.69,
    "out:Primary Energy": 117.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.85,
    "out:CO2 Operational/m2": 37.52,
    "out:Total CO2/m2": 54.37,
    "out:Total CO2 (tons)": 279.95,
    "out:Klimatpaverkan": -9.66,
    "out:Initial Cost/MSEK": 6.52,
    "out:Running cost/(Apt SEK y)": 31688,
    "out:Running Cost over RSP/MSEK": 13.366,
    "out:LCP/MSEK": -0.382,
    "out:ROI% old": 13.45,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222511,
    "out:DH kr savings": 278046,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 119.84,
    "out:Primary Energy": 114.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.63,
    "out:CO2 Operational/m2": 32.17,
    "out:Total CO2/m2": 55.79,
    "out:Total CO2 (tons)": 287.28,
    "out:Klimatpaverkan": -2.33,
    "out:Initial Cost/MSEK": 6.828,
    "out:Running cost/(Apt SEK y)": 32847,
    "out:Running Cost over RSP/MSEK": 13.868,
    "out:LCP/MSEK": -1.192,
    "out:ROI% old": 11.79,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 243146,
    "out:DH kr savings": 236854,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 115.84,
    "out:Primary Energy": 110.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.63,
    "out:CO2 Operational/m2": 30.73,
    "out:Total CO2/m2": 54.35,
    "out:Total CO2 (tons)": 279.87,
    "out:Klimatpaverkan": -9.74,
    "out:Initial Cost/MSEK": 6.957,
    "out:Running cost/(Apt SEK y)": 31695,
    "out:Running Cost over RSP/MSEK": 13.381,
    "out:LCP/MSEK": -0.834,
    "out:ROI% old": 12.57,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 263742,
    "out:DH kr savings": 257450,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 115.84,
    "out:Primary Energy": 111.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.91,
    "out:CO2 Operational/m2": 30.73,
    "out:Total CO2/m2": 54.64,
    "out:Total CO2 (tons)": 281.32,
    "out:Klimatpaverkan": -8.29,
    "out:Initial Cost/MSEK": 7.004,
    "out:Running cost/(Apt SEK y)": 31695,
    "out:Running Cost over RSP/MSEK": 13.381,
    "out:LCP/MSEK": -0.881,
    "out:ROI% old": 12.49,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263742,
    "out:DH kr savings": 257450,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 116.69,
    "out:Total Energy Use Post PV": 111.84,
    "out:Primary Energy": 108.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.91,
    "out:CO2 Operational/m2": 29.29,
    "out:Total CO2/m2": 53.2,
    "out:Total CO2 (tons)": 273.91,
    "out:Klimatpaverkan": -15.7,
    "out:Initial Cost/MSEK": 7.132,
    "out:Running cost/(Apt SEK y)": 30543,
    "out:Running Cost over RSP/MSEK": 12.895,
    "out:LCP/MSEK": -0.523,
    "out:ROI% old": 13.24,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 47.3,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 284338,
    "out:DH kr savings": 278046,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 119.69,
    "out:Primary Energy": 119.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.57,
    "out:CO2 Operational/m2": 38.6,
    "out:Total CO2/m2": 55.17,
    "out:Total CO2 (tons)": 284.06,
    "out:Klimatpaverkan": -5.55,
    "out:Initial Cost/MSEK": 6.358,
    "out:Running cost/(Apt SEK y)": 32552,
    "out:Running Cost over RSP/MSEK": 13.731,
    "out:LCP/MSEK": -0.585,
    "out:ROI% old": 12.97,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 738,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 207064,
    "out:DH kr savings": 262599,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 115.69,
    "out:Total Energy Use Post PV": 115.69,
    "out:Primary Energy": 116.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.57,
    "out:CO2 Operational/m2": 37.16,
    "out:Total CO2/m2": 53.73,
    "out:Total CO2 (tons)": 276.64,
    "out:Klimatpaverkan": -12.97,
    "out:Initial Cost/MSEK": 6.487,
    "out:Running cost/(Apt SEK y)": 31400,
    "out:Running Cost over RSP/MSEK": 13.244,
    "out:LCP/MSEK": -0.227,
    "out:ROI% old": 13.79,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 800,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227660,
    "out:DH kr savings": 283195,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 115.69,
    "out:Total Energy Use Post PV": 115.69,
    "out:Primary Energy": 117.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.85,
    "out:CO2 Operational/m2": 37.16,
    "out:Total CO2/m2": 54.01,
    "out:Total CO2 (tons)": 278.09,
    "out:Klimatpaverkan": -11.52,
    "out:Initial Cost/MSEK": 6.534,
    "out:Running cost/(Apt SEK y)": 31400,
    "out:Running Cost over RSP/MSEK": 13.244,
    "out:LCP/MSEK": -0.274,
    "out:ROI% old": 13.69,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 800,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 227660,
    "out:DH kr savings": 283195,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 112.69,
    "out:Total Energy Use Post PV": 112.69,
    "out:Primary Energy": 114.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.85,
    "out:CO2 Operational/m2": 36.08,
    "out:Total CO2/m2": 52.93,
    "out:Total CO2 (tons)": 272.53,
    "out:Klimatpaverkan": -17.08,
    "out:Initial Cost/MSEK": 6.663,
    "out:Running cost/(Apt SEK y)": 30536,
    "out:Running Cost over RSP/MSEK": 12.879,
    "out:LCP/MSEK": -0.038,
    "out:ROI% old": 14.2,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243107,
    "out:DH kr savings": 298642,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 114.84,
    "out:Primary Energy": 110.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.63,
    "out:CO2 Operational/m2": 30.37,
    "out:Total CO2/m2": 53.99,
    "out:Total CO2 (tons)": 278.02,
    "out:Klimatpaverkan": -11.59,
    "out:Initial Cost/MSEK": 6.971,
    "out:Running cost/(Apt SEK y)": 31407,
    "out:Running Cost over RSP/MSEK": 13.26,
    "out:LCP/MSEK": -0.726,
    "out:ROI% old": 12.8,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 799,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268891,
    "out:DH kr savings": 262599,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 115.69,
    "out:Total Energy Use Post PV": 110.84,
    "out:Primary Energy": 107.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.63,
    "out:CO2 Operational/m2": 28.93,
    "out:Total CO2/m2": 52.55,
    "out:Total CO2 (tons)": 270.6,
    "out:Klimatpaverkan": -19.01,
    "out:Initial Cost/MSEK": 7.099,
    "out:Running cost/(Apt SEK y)": 30255,
    "out:Running Cost over RSP/MSEK": 12.773,
    "out:LCP/MSEK": -0.368,
    "out:ROI% old": 13.54,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 48.6,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 862,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 289487,
    "out:DH kr savings": 283195,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 115.69,
    "out:Total Energy Use Post PV": 110.84,
    "out:Primary Energy": 108.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.91,
    "out:CO2 Operational/m2": 28.93,
    "out:Total CO2/m2": 52.84,
    "out:Total CO2 (tons)": 272.05,
    "out:Klimatpaverkan": -17.56,
    "out:Initial Cost/MSEK": 7.146,
    "out:Running cost/(Apt SEK y)": 30255,
    "out:Running Cost over RSP/MSEK": 12.773,
    "out:LCP/MSEK": -0.415,
    "out:ROI% old": 13.46,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 48.6,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 862,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 289487,
    "out:DH kr savings": 283195,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 112.69,
    "out:Total Energy Use Post PV": 107.84,
    "out:Primary Energy": 105.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.91,
    "out:CO2 Operational/m2": 27.85,
    "out:Total CO2/m2": 51.76,
    "out:Total CO2 (tons)": 266.49,
    "out:Klimatpaverkan": -23.12,
    "out:Initial Cost/MSEK": 7.275,
    "out:Running cost/(Apt SEK y)": 29391,
    "out:Running Cost over RSP/MSEK": 12.408,
    "out:LCP/MSEK": -0.179,
    "out:ROI% old": 13.93,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 908,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304934,
    "out:DH kr savings": 298642,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 125.3,
    "out:Primary Energy": 116.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.79,
    "out:CO2 Operational/m2": 41.85,
    "out:Total CO2/m2": 65.63,
    "out:Total CO2 (tons)": 337.95,
    "out:Klimatpaverkan": 48.34,
    "out:Initial Cost/MSEK": 8.585,
    "out:Running cost/(Apt SEK y)": 34691,
    "out:Running Cost over RSP/MSEK": 14.639,
    "out:LCP/MSEK": -3.72,
    "out:ROI% old": 8.1,
    "out:Payback discounted": 15,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 7,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205960,
    "out:DH kr savings": 205960,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 121.3,
    "out:Total Energy Use Post PV": 121.3,
    "out:Primary Energy": 113.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.79,
    "out:CO2 Operational/m2": 40.41,
    "out:Total CO2/m2": 64.19,
    "out:Total CO2 (tons)": 330.54,
    "out:Klimatpaverkan": 40.93,
    "out:Initial Cost/MSEK": 8.714,
    "out:Running cost/(Apt SEK y)": 33539,
    "out:Running Cost over RSP/MSEK": 14.153,
    "out:LCP/MSEK": -3.363,
    "out:ROI% old": 8.77,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 8,
    "out:Return/kSEK/y": 684,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226556,
    "out:DH kr savings": 226556,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 121.3,
    "out:Total Energy Use Post PV": 121.3,
    "out:Primary Energy": 113.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.07,
    "out:CO2 Operational/m2": 40.41,
    "out:Total CO2/m2": 64.48,
    "out:Total CO2 (tons)": 331.99,
    "out:Klimatpaverkan": 42.38,
    "out:Initial Cost/MSEK": 8.761,
    "out:Running cost/(Apt SEK y)": 33539,
    "out:Running Cost over RSP/MSEK": 14.153,
    "out:LCP/MSEK": -3.409,
    "out:ROI% old": 8.73,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 8,
    "out:Return/kSEK/y": 684,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226556,
    "out:DH kr savings": 226556,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 117.3,
    "out:Total Energy Use Post PV": 117.3,
    "out:Primary Energy": 110.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.07,
    "out:CO2 Operational/m2": 38.97,
    "out:Total CO2/m2": 63.04,
    "out:Total CO2 (tons)": 324.57,
    "out:Klimatpaverkan": 34.96,
    "out:Initial Cost/MSEK": 8.89,
    "out:Running cost/(Apt SEK y)": 32387,
    "out:Running Cost over RSP/MSEK": 13.666,
    "out:LCP/MSEK": -3.052,
    "out:ROI% old": 9.38,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 8,
    "out:Return/kSEK/y": 746,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247152,
    "out:DH kr savings": 247152,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 121.29,
    "out:Primary Energy": 109.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.85,
    "out:CO2 Operational/m2": 29.42,
    "out:Total CO2/m2": 60.26,
    "out:Total CO2 (tons)": 310.3,
    "out:Klimatpaverkan": 20.69,
    "out:Initial Cost/MSEK": 9.197,
    "out:Running cost/(Apt SEK y)": 33586,
    "out:Running Cost over RSP/MSEK": 14.185,
    "out:LCP/MSEK": -3.878,
    "out:ROI% old": 8.26,
    "out:Payback discounted": 15,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 7,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 265618,
    "out:DH kr savings": 205960,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 121.3,
    "out:Total Energy Use Post PV": 117.29,
    "out:Primary Energy": 106.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.85,
    "out:CO2 Operational/m2": 27.98,
    "out:Total CO2/m2": 58.82,
    "out:Total CO2 (tons)": 302.89,
    "out:Klimatpaverkan": 13.28,
    "out:Initial Cost/MSEK": 9.326,
    "out:Running cost/(Apt SEK y)": 32434,
    "out:Running Cost over RSP/MSEK": 13.698,
    "out:LCP/MSEK": -3.52,
    "out:ROI% old": 8.89,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 744,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286214,
    "out:DH kr savings": 226556,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 121.3,
    "out:Total Energy Use Post PV": 117.29,
    "out:Primary Energy": 106.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.13,
    "out:CO2 Operational/m2": 27.98,
    "out:Total CO2/m2": 59.11,
    "out:Total CO2 (tons)": 304.34,
    "out:Klimatpaverkan": 14.73,
    "out:Initial Cost/MSEK": 9.373,
    "out:Running cost/(Apt SEK y)": 32434,
    "out:Running Cost over RSP/MSEK": 13.698,
    "out:LCP/MSEK": -3.567,
    "out:ROI% old": 8.85,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 744,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 286214,
    "out:DH kr savings": 226556,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 117.3,
    "out:Total Energy Use Post PV": 113.29,
    "out:Primary Energy": 103.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.13,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 57.67,
    "out:Total CO2 (tons)": 296.93,
    "out:Klimatpaverkan": 7.32,
    "out:Initial Cost/MSEK": 9.502,
    "out:Running cost/(Apt SEK y)": 31282,
    "out:Running Cost over RSP/MSEK": 13.212,
    "out:LCP/MSEK": -3.209,
    "out:ROI% old": 9.46,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 806,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 306810,
    "out:DH kr savings": 247152,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 119.3,
    "out:Total Energy Use Post PV": 119.3,
    "out:Primary Energy": 112.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.79,
    "out:CO2 Operational/m2": 39.69,
    "out:Total CO2/m2": 63.47,
    "out:Total CO2 (tons)": 326.83,
    "out:Klimatpaverkan": 37.22,
    "out:Initial Cost/MSEK": 8.728,
    "out:Running cost/(Apt SEK y)": 32963,
    "out:Running Cost over RSP/MSEK": 13.909,
    "out:LCP/MSEK": -3.133,
    "out:ROI% old": 9.16,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 38.7,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 8,
    "out:Return/kSEK/y": 715,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236854,
    "out:DH kr savings": 236854,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 116.3,
    "out:Total Energy Use Post PV": 116.3,
    "out:Primary Energy": 109.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.79,
    "out:CO2 Operational/m2": 38.61,
    "out:Total CO2/m2": 62.39,
    "out:Total CO2 (tons)": 321.27,
    "out:Klimatpaverkan": 31.66,
    "out:Initial Cost/MSEK": 8.857,
    "out:Running cost/(Apt SEK y)": 32099,
    "out:Running Cost over RSP/MSEK": 13.544,
    "out:LCP/MSEK": -2.897,
    "out:ROI% old": 9.61,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 762,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 252301,
    "out:DH kr savings": 252301,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 116.3,
    "out:Total Energy Use Post PV": 116.3,
    "out:Primary Energy": 110.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.07,
    "out:CO2 Operational/m2": 38.61,
    "out:Total CO2/m2": 62.68,
    "out:Total CO2 (tons)": 322.72,
    "out:Klimatpaverkan": 33.11,
    "out:Initial Cost/MSEK": 8.904,
    "out:Running cost/(Apt SEK y)": 32099,
    "out:Running Cost over RSP/MSEK": 13.544,
    "out:LCP/MSEK": -2.944,
    "out:ROI% old": 9.56,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 762,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 252301,
    "out:DH kr savings": 252301,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 112.3,
    "out:Total Energy Use Post PV": 112.3,
    "out:Primary Energy": 107.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.07,
    "out:CO2 Operational/m2": 37.17,
    "out:Total CO2/m2": 61.24,
    "out:Total CO2 (tons)": 315.31,
    "out:Klimatpaverkan": 25.7,
    "out:Initial Cost/MSEK": 9.032,
    "out:Running cost/(Apt SEK y)": 30947,
    "out:Running Cost over RSP/MSEK": 13.058,
    "out:LCP/MSEK": -2.586,
    "out:ROI% old": 10.2,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 47.3,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 824,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 272897,
    "out:DH kr savings": 272897,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 119.3,
    "out:Total Energy Use Post PV": 115.29,
    "out:Primary Energy": 105.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.85,
    "out:CO2 Operational/m2": 27.26,
    "out:Total CO2/m2": 58.1,
    "out:Total CO2 (tons)": 299.18,
    "out:Klimatpaverkan": 9.57,
    "out:Initial Cost/MSEK": 9.34,
    "out:Running cost/(Apt SEK y)": 31858,
    "out:Running Cost over RSP/MSEK": 13.455,
    "out:LCP/MSEK": -3.291,
    "out:ROI% old": 9.25,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 775,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 296512,
    "out:DH kr savings": 236854,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 116.3,
    "out:Total Energy Use Post PV": 112.29,
    "out:Primary Energy": 102.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.85,
    "out:CO2 Operational/m2": 26.18,
    "out:Total CO2/m2": 57.02,
    "out:Total CO2 (tons)": 293.62,
    "out:Klimatpaverkan": 4.01,
    "out:Initial Cost/MSEK": 9.469,
    "out:Running cost/(Apt SEK y)": 30994,
    "out:Running Cost over RSP/MSEK": 13.09,
    "out:LCP/MSEK": -3.055,
    "out:ROI% old": 9.68,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 47.3,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 822,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 311959,
    "out:DH kr savings": 252301,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 116.3,
    "out:Total Energy Use Post PV": 112.29,
    "out:Primary Energy": 103.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.13,
    "out:CO2 Operational/m2": 26.18,
    "out:Total CO2/m2": 57.31,
    "out:Total CO2 (tons)": 295.07,
    "out:Klimatpaverkan": 5.46,
    "out:Initial Cost/MSEK": 9.516,
    "out:Running cost/(Apt SEK y)": 30994,
    "out:Running Cost over RSP/MSEK": 13.09,
    "out:LCP/MSEK": -3.102,
    "out:ROI% old": 9.63,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 47.3,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 822,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 311959,
    "out:DH kr savings": 252301,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 112.3,
    "out:Total Energy Use Post PV": 108.29,
    "out:Primary Energy": 100.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.13,
    "out:CO2 Operational/m2": 24.74,
    "out:Total CO2/m2": 55.87,
    "out:Total CO2 (tons)": 287.66,
    "out:Klimatpaverkan": -1.95,
    "out:Initial Cost/MSEK": 9.644,
    "out:Running cost/(Apt SEK y)": 29843,
    "out:Running Cost over RSP/MSEK": 12.604,
    "out:LCP/MSEK": -2.744,
    "out:ROI% old": 10.22,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 884,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 332555,
    "out:DH kr savings": 272897,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 119.69,
    "out:Primary Energy": 118.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.13,
    "out:CO2 Operational/m2": 38.6,
    "out:Total CO2/m2": 64.73,
    "out:Total CO2 (tons)": 333.29,
    "out:Klimatpaverkan": 43.68,
    "out:Initial Cost/MSEK": 9.382,
    "out:Running cost/(Apt SEK y)": 32552,
    "out:Running Cost over RSP/MSEK": 13.731,
    "out:LCP/MSEK": -3.608,
    "out:ROI% old": 8.79,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 8,
    "out:Return/kSEK/y": 738,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 207064,
    "out:DH kr savings": 262599,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 116.69,
    "out:Total Energy Use Post PV": 116.69,
    "out:Primary Energy": 115.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.13,
    "out:CO2 Operational/m2": 37.52,
    "out:Total CO2/m2": 63.65,
    "out:Total CO2 (tons)": 327.73,
    "out:Klimatpaverkan": 38.12,
    "out:Initial Cost/MSEK": 9.511,
    "out:Running cost/(Apt SEK y)": 31688,
    "out:Running Cost over RSP/MSEK": 13.366,
    "out:LCP/MSEK": -3.372,
    "out:ROI% old": 9.22,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 8,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222511,
    "out:DH kr savings": 278046,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 115.69,
    "out:Total Energy Use Post PV": 115.69,
    "out:Primary Energy": 116.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.41,
    "out:CO2 Operational/m2": 37.16,
    "out:Total CO2/m2": 63.57,
    "out:Total CO2 (tons)": 327.33,
    "out:Klimatpaverkan": 37.72,
    "out:Initial Cost/MSEK": 9.558,
    "out:Running cost/(Apt SEK y)": 31400,
    "out:Running Cost over RSP/MSEK": 13.244,
    "out:LCP/MSEK": -3.298,
    "out:ROI% old": 9.36,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 8,
    "out:Return/kSEK/y": 800,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 227660,
    "out:DH kr savings": 283195,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 112.69,
    "out:Total Energy Use Post PV": 112.69,
    "out:Primary Energy": 113.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.41,
    "out:CO2 Operational/m2": 36.08,
    "out:Total CO2/m2": 62.49,
    "out:Total CO2 (tons)": 321.77,
    "out:Klimatpaverkan": 32.16,
    "out:Initial Cost/MSEK": 9.687,
    "out:Running cost/(Apt SEK y)": 30536,
    "out:Running Cost over RSP/MSEK": 12.879,
    "out:LCP/MSEK": -3.061,
    "out:ROI% old": 9.77,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243107,
    "out:DH kr savings": 298642,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 114.84,
    "out:Primary Energy": 110.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.19,
    "out:CO2 Operational/m2": 30.37,
    "out:Total CO2/m2": 63.56,
    "out:Total CO2 (tons)": 327.25,
    "out:Klimatpaverkan": 37.64,
    "out:Initial Cost/MSEK": 9.994,
    "out:Running cost/(Apt SEK y)": 31407,
    "out:Running Cost over RSP/MSEK": 13.26,
    "out:LCP/MSEK": -3.75,
    "out:ROI% old": 8.93,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 799,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268891,
    "out:DH kr savings": 262599,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 116.69,
    "out:Total Energy Use Post PV": 111.84,
    "out:Primary Energy": 107.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.19,
    "out:CO2 Operational/m2": 29.29,
    "out:Total CO2/m2": 62.48,
    "out:Total CO2 (tons)": 321.69,
    "out:Klimatpaverkan": 32.08,
    "out:Initial Cost/MSEK": 10.123,
    "out:Running cost/(Apt SEK y)": 30543,
    "out:Running Cost over RSP/MSEK": 12.895,
    "out:LCP/MSEK": -3.514,
    "out:ROI% old": 9.33,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 47.3,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 284338,
    "out:DH kr savings": 278046,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 115.69,
    "out:Total Energy Use Post PV": 110.84,
    "out:Primary Energy": 107.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.47,
    "out:CO2 Operational/m2": 28.93,
    "out:Total CO2/m2": 62.4,
    "out:Total CO2 (tons)": 321.29,
    "out:Klimatpaverkan": 31.68,
    "out:Initial Cost/MSEK": 10.17,
    "out:Running cost/(Apt SEK y)": 30255,
    "out:Running Cost over RSP/MSEK": 12.773,
    "out:LCP/MSEK": -3.439,
    "out:ROI% old": 9.45,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 48.6,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 862,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 289487,
    "out:DH kr savings": 283195,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 112.69,
    "out:Total Energy Use Post PV": 107.84,
    "out:Primary Energy": 104.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.47,
    "out:CO2 Operational/m2": 27.85,
    "out:Total CO2/m2": 61.32,
    "out:Total CO2 (tons)": 315.73,
    "out:Klimatpaverkan": 26.12,
    "out:Initial Cost/MSEK": 10.299,
    "out:Running cost/(Apt SEK y)": 29391,
    "out:Running Cost over RSP/MSEK": 12.408,
    "out:LCP/MSEK": -3.203,
    "out:ROI% old": 9.84,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 908,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304934,
    "out:DH kr savings": 298642,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 114.69,
    "out:Total Energy Use Post PV": 114.69,
    "out:Primary Energy": 115.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.13,
    "out:CO2 Operational/m2": 36.8,
    "out:Total CO2/m2": 62.93,
    "out:Total CO2 (tons)": 324.03,
    "out:Klimatpaverkan": 34.42,
    "out:Initial Cost/MSEK": 9.525,
    "out:Running cost/(Apt SEK y)": 31112,
    "out:Running Cost over RSP/MSEK": 13.122,
    "out:LCP/MSEK": -3.143,
    "out:ROI% old": 9.57,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 815,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 232809,
    "out:DH kr savings": 288344,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 111.69,
    "out:Total Energy Use Post PV": 111.69,
    "out:Primary Energy": 112.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.13,
    "out:CO2 Operational/m2": 35.72,
    "out:Total CO2/m2": 61.85,
    "out:Total CO2 (tons)": 318.47,
    "out:Klimatpaverkan": 28.86,
    "out:Initial Cost/MSEK": 9.653,
    "out:Running cost/(Apt SEK y)": 30248,
    "out:Running Cost over RSP/MSEK": 12.758,
    "out:LCP/MSEK": -2.907,
    "out:ROI% old": 9.98,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 47.3,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 862,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 303791,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248256,
    "out:DH kr savings": 303791,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 111.69,
    "out:Total Energy Use Post PV": 111.69,
    "out:Primary Energy": 113.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.41,
    "out:CO2 Operational/m2": 35.72,
    "out:Total CO2/m2": 62.13,
    "out:Total CO2 (tons)": 319.92,
    "out:Klimatpaverkan": 30.31,
    "out:Initial Cost/MSEK": 9.7,
    "out:Running cost/(Apt SEK y)": 30248,
    "out:Running Cost over RSP/MSEK": 12.758,
    "out:LCP/MSEK": -2.954,
    "out:ROI% old": 9.94,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 47.3,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 862,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 303791,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248256,
    "out:DH kr savings": 303791,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 107.69,
    "out:Total Energy Use Post PV": 107.69,
    "out:Primary Energy": 110.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.41,
    "out:CO2 Operational/m2": 34.28,
    "out:Total CO2/m2": 60.69,
    "out:Total CO2 (tons)": 312.5,
    "out:Klimatpaverkan": 22.89,
    "out:Initial Cost/MSEK": 9.829,
    "out:Running cost/(Apt SEK y)": 29096,
    "out:Running Cost over RSP/MSEK": 12.271,
    "out:LCP/MSEK": -2.596,
    "out:ROI% old": 10.51,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 924,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 268852,
    "out:DH kr savings": 324387,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 114.69,
    "out:Total Energy Use Post PV": 109.84,
    "out:Primary Energy": 106.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.19,
    "out:CO2 Operational/m2": 28.57,
    "out:Total CO2/m2": 61.76,
    "out:Total CO2 (tons)": 317.98,
    "out:Klimatpaverkan": 28.37,
    "out:Initial Cost/MSEK": 10.137,
    "out:Running cost/(Apt SEK y)": 29967,
    "out:Running Cost over RSP/MSEK": 12.652,
    "out:LCP/MSEK": -3.284,
    "out:ROI% old": 9.66,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 877,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 294636,
    "out:DH kr savings": 288344,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 111.69,
    "out:Total Energy Use Post PV": 106.84,
    "out:Primary Energy": 103.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.19,
    "out:CO2 Operational/m2": 27.49,
    "out:Total CO2/m2": 60.68,
    "out:Total CO2 (tons)": 312.42,
    "out:Klimatpaverkan": 22.81,
    "out:Initial Cost/MSEK": 10.266,
    "out:Running cost/(Apt SEK y)": 29103,
    "out:Running Cost over RSP/MSEK": 12.287,
    "out:LCP/MSEK": -3.048,
    "out:ROI% old": 10.04,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 54.2,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 924,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 303791,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 310083,
    "out:DH kr savings": 303791,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 111.69,
    "out:Total Energy Use Post PV": 106.84,
    "out:Primary Energy": 104.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.47,
    "out:CO2 Operational/m2": 27.49,
    "out:Total CO2/m2": 60.96,
    "out:Total CO2 (tons)": 313.87,
    "out:Klimatpaverkan": 24.26,
    "out:Initial Cost/MSEK": 10.312,
    "out:Running cost/(Apt SEK y)": 29103,
    "out:Running Cost over RSP/MSEK": 12.287,
    "out:LCP/MSEK": -3.095,
    "out:ROI% old": 10,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 54.2,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 924,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 303791,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 310083,
    "out:DH kr savings": 303791,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 107.69,
    "out:Total Energy Use Post PV": 102.84,
    "out:Primary Energy": 101.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.47,
    "out:CO2 Operational/m2": 26.05,
    "out:Total CO2/m2": 59.52,
    "out:Total CO2 (tons)": 306.46,
    "out:Klimatpaverkan": 16.85,
    "out:Initial Cost/MSEK": 10.441,
    "out:Running cost/(Apt SEK y)": 27951,
    "out:Running Cost over RSP/MSEK": 11.8,
    "out:LCP/MSEK": -2.737,
    "out:ROI% old": 10.54,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 60.2,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 986,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 330679,
    "out:DH kr savings": 324387,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 132.3,
    "out:Total Energy Use Post PV": 132.3,
    "out:Primary Energy": 122.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.92,
    "out:CO2 Operational/m2": 44.37,
    "out:Total CO2/m2": 56.28,
    "out:Total CO2 (tons)": 289.8,
    "out:Klimatpaverkan": 0.19,
    "out:Initial Cost/MSEK": 4.325,
    "out:Running cost/(Apt SEK y)": 36707,
    "out:Running Cost over RSP/MSEK": 15.491,
    "out:LCP/MSEK": -0.312,
    "out:ROI% old": 13.26,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 513,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 169917,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169917,
    "out:DH kr savings": 169917,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 26.66,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.74,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 128.3,
    "out:Total Energy Use Post PV": 128.3,
    "out:Primary Energy": 119.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.92,
    "out:CO2 Operational/m2": 42.93,
    "out:Total CO2/m2": 54.84,
    "out:Total CO2 (tons)": 282.39,
    "out:Klimatpaverkan": -7.22,
    "out:Initial Cost/MSEK": 4.454,
    "out:Running cost/(Apt SEK y)": 35555,
    "out:Running Cost over RSP/MSEK": 15.004,
    "out:LCP/MSEK": 0.046,
    "out:ROI% old": 14.43,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 575,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 190513,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190513,
    "out:DH kr savings": 190513,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 446634,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 86.74,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 128.3,
    "out:Total Energy Use Post PV": 128.3,
    "out:Primary Energy": 119.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.2,
    "out:CO2 Operational/m2": 42.93,
    "out:Total CO2/m2": 55.13,
    "out:Total CO2 (tons)": 283.84,
    "out:Klimatpaverkan": -5.77,
    "out:Initial Cost/MSEK": 4.501,
    "out:Running cost/(Apt SEK y)": 35555,
    "out:Running Cost over RSP/MSEK": 15.004,
    "out:LCP/MSEK": -0.001,
    "out:ROI% old": 14.28,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 575,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 190513,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 190513,
    "out:DH kr savings": 190513,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 26.66,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.74,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 124.3,
    "out:Total Energy Use Post PV": 124.3,
    "out:Primary Energy": 116.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.2,
    "out:CO2 Operational/m2": 41.49,
    "out:Total CO2/m2": 53.69,
    "out:Total CO2 (tons)": 276.42,
    "out:Klimatpaverkan": -13.19,
    "out:Initial Cost/MSEK": 4.63,
    "out:Running cost/(Apt SEK y)": 34403,
    "out:Running Cost over RSP/MSEK": 14.517,
    "out:LCP/MSEK": 0.357,
    "out:ROI% old": 15.39,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211109,
    "out:DH kr savings": 211109,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 446634,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 86.74,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 132.3,
    "out:Total Energy Use Post PV": 128.29,
    "out:Primary Energy": 115.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.98,
    "out:CO2 Operational/m2": 31.94,
    "out:Total CO2/m2": 50.91,
    "out:Total CO2 (tons)": 262.15,
    "out:Klimatpaverkan": -27.46,
    "out:Initial Cost/MSEK": 4.937,
    "out:Running cost/(Apt SEK y)": 35602,
    "out:Running Cost over RSP/MSEK": 15.036,
    "out:LCP/MSEK": -0.469,
    "out:ROI% old": 12.93,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 573,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 169917,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 229575,
    "out:DH kr savings": 169917,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 26.66,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.74,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 128.3,
    "out:Total Energy Use Post PV": 124.29,
    "out:Primary Energy": 111.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.98,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 49.47,
    "out:Total CO2 (tons)": 254.74,
    "out:Klimatpaverkan": -34.87,
    "out:Initial Cost/MSEK": 5.066,
    "out:Running cost/(Apt SEK y)": 34450,
    "out:Running Cost over RSP/MSEK": 14.55,
    "out:LCP/MSEK": -0.112,
    "out:ROI% old": 13.97,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 635,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 190513,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 250171,
    "out:DH kr savings": 190513,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 446634,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 86.74,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 128.3,
    "out:Total Energy Use Post PV": 124.29,
    "out:Primary Energy": 112.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.26,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 49.76,
    "out:Total CO2 (tons)": 256.19,
    "out:Klimatpaverkan": -33.42,
    "out:Initial Cost/MSEK": 5.113,
    "out:Running cost/(Apt SEK y)": 34450,
    "out:Running Cost over RSP/MSEK": 14.55,
    "out:LCP/MSEK": -0.158,
    "out:ROI% old": 13.84,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 635,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 190513,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 250171,
    "out:DH kr savings": 190513,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 26.66,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.74,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 124.3,
    "out:Total Energy Use Post PV": 120.29,
    "out:Primary Energy": 109.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.26,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 48.32,
    "out:Total CO2 (tons)": 248.78,
    "out:Klimatpaverkan": -40.83,
    "out:Initial Cost/MSEK": 5.242,
    "out:Running cost/(Apt SEK y)": 33298,
    "out:Running Cost over RSP/MSEK": 14.063,
    "out:LCP/MSEK": 0.199,
    "out:ROI% old": 14.83,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 697,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 270767,
    "out:DH kr savings": 211109,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 446634,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 86.74,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 126.3,
    "out:Primary Energy": 119,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.92,
    "out:CO2 Operational/m2": 42.21,
    "out:Total CO2/m2": 54.12,
    "out:Total CO2 (tons)": 278.68,
    "out:Klimatpaverkan": -10.93,
    "out:Initial Cost/MSEK": 4.468,
    "out:Running cost/(Apt SEK y)": 34979,
    "out:Running Cost over RSP/MSEK": 14.761,
    "out:LCP/MSEK": 0.276,
    "out:ROI% old": 15.17,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 200811,
    "out:DH kr savings": 200811,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 26.66,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.74,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 122.3,
    "out:Total Energy Use Post PV": 122.3,
    "out:Primary Energy": 115.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.92,
    "out:CO2 Operational/m2": 40.77,
    "out:Total CO2/m2": 52.68,
    "out:Total CO2 (tons)": 271.27,
    "out:Klimatpaverkan": -18.34,
    "out:Initial Cost/MSEK": 4.597,
    "out:Running cost/(Apt SEK y)": 33827,
    "out:Running Cost over RSP/MSEK": 14.274,
    "out:LCP/MSEK": 0.633,
    "out:ROI% old": 16.25,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 15,
    "out:Return/kSEK/y": 669,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 221407,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221407,
    "out:DH kr savings": 221407,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 446634,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 86.74,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 123.3,
    "out:Total Energy Use Post PV": 123.3,
    "out:Primary Energy": 116.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.2,
    "out:CO2 Operational/m2": 41.13,
    "out:Total CO2/m2": 53.33,
    "out:Total CO2 (tons)": 274.57,
    "out:Klimatpaverkan": -15.04,
    "out:Initial Cost/MSEK": 4.643,
    "out:Running cost/(Apt SEK y)": 34115,
    "out:Running Cost over RSP/MSEK": 14.396,
    "out:LCP/MSEK": 0.465,
    "out:ROI% old": 15.72,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 34.1,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 653,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 216258,
    "out:DH kr savings": 216258,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 26.66,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.74,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 119.3,
    "out:Total Energy Use Post PV": 119.3,
    "out:Primary Energy": 113.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.2,
    "out:CO2 Operational/m2": 39.69,
    "out:Total CO2/m2": 51.89,
    "out:Total CO2 (tons)": 267.16,
    "out:Klimatpaverkan": -22.45,
    "out:Initial Cost/MSEK": 4.772,
    "out:Running cost/(Apt SEK y)": 32963,
    "out:Running Cost over RSP/MSEK": 13.909,
    "out:LCP/MSEK": 0.823,
    "out:ROI% old": 16.75,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 38.7,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 15,
    "out:Return/kSEK/y": 715,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 236854,
    "out:DH kr savings": 236854,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 446634,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 86.74,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 122.29,
    "out:Primary Energy": 111.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.98,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 48.75,
    "out:Total CO2 (tons)": 251.03,
    "out:Klimatpaverkan": -38.58,
    "out:Initial Cost/MSEK": 5.08,
    "out:Running cost/(Apt SEK y)": 33874,
    "out:Running Cost over RSP/MSEK": 14.307,
    "out:LCP/MSEK": 0.118,
    "out:ROI% old": 14.62,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 666,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 260469,
    "out:DH kr savings": 200811,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 26.66,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.74,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 122.3,
    "out:Total Energy Use Post PV": 118.29,
    "out:Primary Energy": 108.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.98,
    "out:CO2 Operational/m2": 28.34,
    "out:Total CO2/m2": 47.31,
    "out:Total CO2 (tons)": 243.62,
    "out:Klimatpaverkan": -45.99,
    "out:Initial Cost/MSEK": 5.209,
    "out:Running cost/(Apt SEK y)": 32722,
    "out:Running Cost over RSP/MSEK": 13.82,
    "out:LCP/MSEK": 0.476,
    "out:ROI% old": 15.59,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 728,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 221407,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281065,
    "out:DH kr savings": 221407,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 446634,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 86.74,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 123.3,
    "out:Total Energy Use Post PV": 119.29,
    "out:Primary Energy": 109.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.26,
    "out:CO2 Operational/m2": 28.7,
    "out:Total CO2/m2": 47.96,
    "out:Total CO2 (tons)": 246.92,
    "out:Klimatpaverkan": -42.69,
    "out:Initial Cost/MSEK": 5.255,
    "out:Running cost/(Apt SEK y)": 33010,
    "out:Running Cost over RSP/MSEK": 13.942,
    "out:LCP/MSEK": 0.307,
    "out:ROI% old": 15.12,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 38.7,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 713,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 275916,
    "out:DH kr savings": 216258,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 26.66,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.74,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 119.3,
    "out:Total Energy Use Post PV": 115.29,
    "out:Primary Energy": 106.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.26,
    "out:CO2 Operational/m2": 27.26,
    "out:Total CO2/m2": 46.52,
    "out:Total CO2 (tons)": 239.51,
    "out:Klimatpaverkan": -50.1,
    "out:Initial Cost/MSEK": 5.384,
    "out:Running cost/(Apt SEK y)": 31858,
    "out:Running Cost over RSP/MSEK": 13.455,
    "out:LCP/MSEK": 0.665,
    "out:ROI% old": 16.05,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 775,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 296512,
    "out:DH kr savings": 236854,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 446634,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 86.74,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 126.69,
    "out:Primary Energy": 125.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.26,
    "out:CO2 Operational/m2": 41.12,
    "out:Total CO2/m2": 55.38,
    "out:Total CO2 (tons)": 285.14,
    "out:Klimatpaverkan": -4.47,
    "out:Initial Cost/MSEK": 5.122,
    "out:Running cost/(Apt SEK y)": 34567,
    "out:Running Cost over RSP/MSEK": 14.582,
    "out:LCP/MSEK": -0.2,
    "out:ROI% old": 13.73,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171021,
    "out:DH kr savings": 226556,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 26.66,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.74,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 123.69,
    "out:Total Energy Use Post PV": 123.69,
    "out:Primary Energy": 121.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.26,
    "out:CO2 Operational/m2": 40.04,
    "out:Total CO2/m2": 54.3,
    "out:Total CO2 (tons)": 279.58,
    "out:Klimatpaverkan": -10.03,
    "out:Initial Cost/MSEK": 5.251,
    "out:Running cost/(Apt SEK y)": 33704,
    "out:Running Cost over RSP/MSEK": 14.217,
    "out:LCP/MSEK": 0.036,
    "out:ROI% old": 14.38,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 675,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 242003,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186468,
    "out:DH kr savings": 242003,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 446634,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 86.74,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 122.69,
    "out:Total Energy Use Post PV": 122.69,
    "out:Primary Energy": 122.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.54,
    "out:CO2 Operational/m2": 39.68,
    "out:Total CO2/m2": 54.22,
    "out:Total CO2 (tons)": 279.18,
    "out:Klimatpaverkan": -10.43,
    "out:Initial Cost/MSEK": 5.298,
    "out:Running cost/(Apt SEK y)": 33416,
    "out:Running Cost over RSP/MSEK": 14.095,
    "out:LCP/MSEK": 0.111,
    "out:ROI% old": 14.59,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 34.1,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 691,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191617,
    "out:DH kr savings": 247152,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 26.66,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.74,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 119.69,
    "out:Primary Energy": 119.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.54,
    "out:CO2 Operational/m2": 38.6,
    "out:Total CO2/m2": 53.14,
    "out:Total CO2 (tons)": 273.62,
    "out:Klimatpaverkan": -15.99,
    "out:Initial Cost/MSEK": 5.426,
    "out:Running cost/(Apt SEK y)": 32552,
    "out:Running Cost over RSP/MSEK": 13.731,
    "out:LCP/MSEK": 0.347,
    "out:ROI% old": 15.2,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 738,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207064,
    "out:DH kr savings": 262599,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 446634,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 86.74,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 121.84,
    "out:Primary Energy": 116.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.32,
    "out:CO2 Operational/m2": 32.89,
    "out:Total CO2/m2": 54.2,
    "out:Total CO2 (tons)": 279.1,
    "out:Klimatpaverkan": -10.51,
    "out:Initial Cost/MSEK": 5.734,
    "out:Running cost/(Apt SEK y)": 33423,
    "out:Running Cost over RSP/MSEK": 14.111,
    "out:LCP/MSEK": -0.341,
    "out:ROI% old": 13.44,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 232848,
    "out:DH kr savings": 226556,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 26.66,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.74,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 123.69,
    "out:Total Energy Use Post PV": 118.84,
    "out:Primary Energy": 112.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.32,
    "out:CO2 Operational/m2": 31.81,
    "out:Total CO2/m2": 53.12,
    "out:Total CO2 (tons)": 273.54,
    "out:Klimatpaverkan": -16.07,
    "out:Initial Cost/MSEK": 5.863,
    "out:Running cost/(Apt SEK y)": 32559,
    "out:Running Cost over RSP/MSEK": 13.746,
    "out:LCP/MSEK": -0.105,
    "out:ROI% old": 14.03,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 38.7,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 737,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 242003,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248295,
    "out:DH kr savings": 242003,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 446634,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 86.74,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 122.69,
    "out:Total Energy Use Post PV": 117.84,
    "out:Primary Energy": 113.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.6,
    "out:CO2 Operational/m2": 31.45,
    "out:Total CO2/m2": 53.05,
    "out:Total CO2 (tons)": 273.14,
    "out:Klimatpaverkan": -16.47,
    "out:Initial Cost/MSEK": 5.91,
    "out:Running cost/(Apt SEK y)": 32271,
    "out:Running Cost over RSP/MSEK": 13.625,
    "out:LCP/MSEK": -0.03,
    "out:ROI% old": 14.21,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 753,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 253444,
    "out:DH kr savings": 247152,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 26.66,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.74,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 114.84,
    "out:Primary Energy": 110.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.6,
    "out:CO2 Operational/m2": 30.37,
    "out:Total CO2/m2": 51.97,
    "out:Total CO2 (tons)": 267.58,
    "out:Klimatpaverkan": -22.03,
    "out:Initial Cost/MSEK": 6.038,
    "out:Running cost/(Apt SEK y)": 31407,
    "out:Running Cost over RSP/MSEK": 13.26,
    "out:LCP/MSEK": 0.206,
    "out:ROI% old": 14.77,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 799,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 268891,
    "out:DH kr savings": 262599,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 446634,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 86.74,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 121.69,
    "out:Primary Energy": 121.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.26,
    "out:CO2 Operational/m2": 39.32,
    "out:Total CO2/m2": 53.58,
    "out:Total CO2 (tons)": 275.88,
    "out:Klimatpaverkan": -13.73,
    "out:Initial Cost/MSEK": 5.265,
    "out:Running cost/(Apt SEK y)": 33128,
    "out:Running Cost over RSP/MSEK": 13.974,
    "out:LCP/MSEK": 0.266,
    "out:ROI% old": 15.01,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196766,
    "out:DH kr savings": 252301,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 26.66,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.74,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 117.69,
    "out:Total Energy Use Post PV": 117.69,
    "out:Primary Energy": 118.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.26,
    "out:CO2 Operational/m2": 37.88,
    "out:Total CO2/m2": 52.14,
    "out:Total CO2 (tons)": 268.46,
    "out:Klimatpaverkan": -21.15,
    "out:Initial Cost/MSEK": 5.393,
    "out:Running cost/(Apt SEK y)": 31976,
    "out:Running Cost over RSP/MSEK": 13.487,
    "out:LCP/MSEK": 0.624,
    "out:ROI% old": 15.94,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 769,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217362,
    "out:DH kr savings": 272897,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 446634,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 86.74,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 118.69,
    "out:Total Energy Use Post PV": 118.69,
    "out:Primary Energy": 119.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.54,
    "out:CO2 Operational/m2": 38.24,
    "out:Total CO2/m2": 52.78,
    "out:Total CO2 (tons)": 271.77,
    "out:Klimatpaverkan": -17.84,
    "out:Initial Cost/MSEK": 5.44,
    "out:Running cost/(Apt SEK y)": 32264,
    "out:Running Cost over RSP/MSEK": 13.609,
    "out:LCP/MSEK": 0.455,
    "out:ROI% old": 15.48,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 38.7,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 753,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212213,
    "out:DH kr savings": 267748,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 26.66,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.74,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 114.69,
    "out:Total Energy Use Post PV": 114.69,
    "out:Primary Energy": 116.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.54,
    "out:CO2 Operational/m2": 36.8,
    "out:Total CO2/m2": 51.34,
    "out:Total CO2 (tons)": 264.35,
    "out:Klimatpaverkan": -25.26,
    "out:Initial Cost/MSEK": 5.569,
    "out:Running cost/(Apt SEK y)": 31112,
    "out:Running Cost over RSP/MSEK": 13.122,
    "out:LCP/MSEK": 0.813,
    "out:ROI% old": 16.37,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 815,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 232809,
    "out:DH kr savings": 288344,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 446634,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 86.74,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 121.69,
    "out:Total Energy Use Post PV": 116.84,
    "out:Primary Energy": 112.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.32,
    "out:CO2 Operational/m2": 31.09,
    "out:Total CO2/m2": 52.4,
    "out:Total CO2 (tons)": 269.83,
    "out:Klimatpaverkan": -19.78,
    "out:Initial Cost/MSEK": 5.877,
    "out:Running cost/(Apt SEK y)": 31983,
    "out:Running Cost over RSP/MSEK": 13.503,
    "out:LCP/MSEK": 0.125,
    "out:ROI% old": 14.59,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 258593,
    "out:DH kr savings": 252301,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 26.66,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.74,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 117.69,
    "out:Total Energy Use Post PV": 112.84,
    "out:Primary Energy": 109.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.32,
    "out:CO2 Operational/m2": 29.65,
    "out:Total CO2/m2": 50.97,
    "out:Total CO2 (tons)": 262.42,
    "out:Klimatpaverkan": -27.19,
    "out:Initial Cost/MSEK": 6.005,
    "out:Running cost/(Apt SEK y)": 30831,
    "out:Running Cost over RSP/MSEK": 13.017,
    "out:LCP/MSEK": 0.482,
    "out:ROI% old": 15.43,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 830,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279189,
    "out:DH kr savings": 272897,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 446634,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 86.74,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 118.69,
    "out:Total Energy Use Post PV": 113.84,
    "out:Primary Energy": 110.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.6,
    "out:CO2 Operational/m2": 30.01,
    "out:Total CO2/m2": 51.61,
    "out:Total CO2 (tons)": 265.72,
    "out:Klimatpaverkan": -23.89,
    "out:Initial Cost/MSEK": 6.052,
    "out:Running cost/(Apt SEK y)": 31119,
    "out:Running Cost over RSP/MSEK": 13.138,
    "out:LCP/MSEK": 0.314,
    "out:ROI% old": 15.03,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 44.7,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 815,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 274040,
    "out:DH kr savings": 267748,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 26.66,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.74,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 114.69,
    "out:Total Energy Use Post PV": 109.84,
    "out:Primary Energy": 107.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.6,
    "out:CO2 Operational/m2": 28.57,
    "out:Total CO2/m2": 50.17,
    "out:Total CO2 (tons)": 258.31,
    "out:Klimatpaverkan": -31.3,
    "out:Initial Cost/MSEK": 6.181,
    "out:Running cost/(Apt SEK y)": 29967,
    "out:Running Cost over RSP/MSEK": 12.652,
    "out:LCP/MSEK": 0.672,
    "out:ROI% old": 15.84,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 877,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 294636,
    "out:DH kr savings": 288344,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 446634,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 86.74,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.64,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 117.3,
    "out:Total Energy Use Post PV": 117.3,
    "out:Primary Energy": 109.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.91,
    "out:CO2 Operational/m2": 38.97,
    "out:Total CO2/m2": 53.87,
    "out:Total CO2 (tons)": 277.39,
    "out:Klimatpaverkan": -12.22,
    "out:Initial Cost/MSEK": 5.618,
    "out:Running cost/(Apt SEK y)": 32387,
    "out:Running Cost over RSP/MSEK": 13.666,
    "out:LCP/MSEK": 0.22,
    "out:ROI% old": 14.84,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 746,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 247152,
    "out:DH kr savings": 247152,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 389491,
    "out:% savings (space heating)": 38.76,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.64,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.48,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 114.3,
    "out:Total Energy Use Post PV": 114.3,
    "out:Primary Energy": 106.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.91,
    "out:CO2 Operational/m2": 37.89,
    "out:Total CO2/m2": 52.79,
    "out:Total CO2 (tons)": 271.83,
    "out:Klimatpaverkan": -17.78,
    "out:Initial Cost/MSEK": 5.747,
    "out:Running cost/(Apt SEK y)": 31523,
    "out:Running Cost over RSP/MSEK": 13.301,
    "out:LCP/MSEK": 0.456,
    "out:ROI% old": 15.42,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 44.7,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 793,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 262599,
    "out:DH kr savings": 262599,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373191,
    "out:% savings (space heating)": 41.32,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.64,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 113.3,
    "out:Total Energy Use Post PV": 113.3,
    "out:Primary Energy": 106.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.19,
    "out:CO2 Operational/m2": 37.53,
    "out:Total CO2/m2": 52.71,
    "out:Total CO2 (tons)": 271.42,
    "out:Klimatpaverkan": -18.19,
    "out:Initial Cost/MSEK": 5.794,
    "out:Running cost/(Apt SEK y)": 31235,
    "out:Running Cost over RSP/MSEK": 13.18,
    "out:LCP/MSEK": 0.531,
    "out:ROI% old": 15.59,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 267748,
    "out:DH kr savings": 267748,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 389491,
    "out:% savings (space heating)": 38.76,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.64,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.48,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 110.3,
    "out:Primary Energy": 104.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.19,
    "out:CO2 Operational/m2": 36.45,
    "out:Total CO2/m2": 51.63,
    "out:Total CO2 (tons)": 265.86,
    "out:Klimatpaverkan": -23.75,
    "out:Initial Cost/MSEK": 5.923,
    "out:Running cost/(Apt SEK y)": 30371,
    "out:Running Cost over RSP/MSEK": 12.815,
    "out:LCP/MSEK": 0.767,
    "out:ROI% old": 16.14,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 855,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 283195,
    "out:DH kr savings": 283195,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373191,
    "out:% savings (space heating)": 41.32,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.64,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 117.3,
    "out:Total Energy Use Post PV": 113.29,
    "out:Primary Energy": 102.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.97,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 48.5,
    "out:Total CO2 (tons)": 249.74,
    "out:Klimatpaverkan": -39.87,
    "out:Initial Cost/MSEK": 6.23,
    "out:Running cost/(Apt SEK y)": 31282,
    "out:Running Cost over RSP/MSEK": 13.212,
    "out:LCP/MSEK": 0.062,
    "out:ROI% old": 14.43,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 806,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 306810,
    "out:DH kr savings": 247152,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 389491,
    "out:% savings (space heating)": 38.76,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.64,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.48,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 114.3,
    "out:Total Energy Use Post PV": 110.29,
    "out:Primary Energy": 99.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.97,
    "out:CO2 Operational/m2": 25.46,
    "out:Total CO2/m2": 47.42,
    "out:Total CO2 (tons)": 244.18,
    "out:Klimatpaverkan": -45.43,
    "out:Initial Cost/MSEK": 6.359,
    "out:Running cost/(Apt SEK y)": 30419,
    "out:Running Cost over RSP/MSEK": 12.847,
    "out:LCP/MSEK": 0.298,
    "out:ROI% old": 14.96,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 853,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 322257,
    "out:DH kr savings": 262599,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373191,
    "out:% savings (space heating)": 41.32,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.64,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 113.3,
    "out:Total Energy Use Post PV": 109.29,
    "out:Primary Energy": 99.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.25,
    "out:CO2 Operational/m2": 25.1,
    "out:Total CO2/m2": 47.34,
    "out:Total CO2 (tons)": 243.77,
    "out:Klimatpaverkan": -45.84,
    "out:Initial Cost/MSEK": 6.406,
    "out:Running cost/(Apt SEK y)": 30131,
    "out:Running Cost over RSP/MSEK": 12.725,
    "out:LCP/MSEK": 0.373,
    "out:ROI% old": 15.12,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 868,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 327406,
    "out:DH kr savings": 267748,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 389491,
    "out:% savings (space heating)": 38.76,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.64,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.48,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 106.29,
    "out:Primary Energy": 96.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.25,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 46.26,
    "out:Total CO2 (tons)": 238.21,
    "out:Klimatpaverkan": -51.4,
    "out:Initial Cost/MSEK": 6.535,
    "out:Running cost/(Apt SEK y)": 29267,
    "out:Running Cost over RSP/MSEK": 12.361,
    "out:LCP/MSEK": 0.609,
    "out:ROI% old": 15.62,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 915,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 342853,
    "out:DH kr savings": 283195,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373191,
    "out:% savings (space heating)": 41.32,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.64,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 111.3,
    "out:Total Energy Use Post PV": 111.3,
    "out:Primary Energy": 105.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.91,
    "out:CO2 Operational/m2": 36.81,
    "out:Total CO2/m2": 51.71,
    "out:Total CO2 (tons)": 266.27,
    "out:Klimatpaverkan": -23.34,
    "out:Initial Cost/MSEK": 5.761,
    "out:Running cost/(Apt SEK y)": 30659,
    "out:Running Cost over RSP/MSEK": 12.936,
    "out:LCP/MSEK": 0.807,
    "out:ROI% old": 16.29,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 48.6,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 15,
    "out:Return/kSEK/y": 840,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 278046,
    "out:DH kr savings": 278046,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 389491,
    "out:% savings (space heating)": 38.76,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.64,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.48,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 108.3,
    "out:Primary Energy": 103.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.91,
    "out:CO2 Operational/m2": 35.73,
    "out:Total CO2/m2": 50.63,
    "out:Total CO2 (tons)": 260.71,
    "out:Klimatpaverkan": -28.9,
    "out:Initial Cost/MSEK": 5.89,
    "out:Running cost/(Apt SEK y)": 29796,
    "out:Running Cost over RSP/MSEK": 12.571,
    "out:LCP/MSEK": 1.043,
    "out:ROI% old": 16.82,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 15,
    "out:Return/kSEK/y": 886,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 293493,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 293493,
    "out:DH kr savings": 293493,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373191,
    "out:% savings (space heating)": 41.32,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.64,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 108.3,
    "out:Primary Energy": 103.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.19,
    "out:CO2 Operational/m2": 35.73,
    "out:Total CO2/m2": 50.91,
    "out:Total CO2 (tons)": 262.16,
    "out:Klimatpaverkan": -27.45,
    "out:Initial Cost/MSEK": 5.937,
    "out:Running cost/(Apt SEK y)": 29796,
    "out:Running Cost over RSP/MSEK": 12.571,
    "out:LCP/MSEK": 0.996,
    "out:ROI% old": 16.68,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 15,
    "out:Return/kSEK/y": 886,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 293493,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 293493,
    "out:DH kr savings": 293493,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 389491,
    "out:% savings (space heating)": 38.76,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.64,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.48,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 105.3,
    "out:Primary Energy": 101.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.19,
    "out:CO2 Operational/m2": 34.65,
    "out:Total CO2/m2": 49.83,
    "out:Total CO2 (tons)": 256.59,
    "out:Klimatpaverkan": -33.02,
    "out:Initial Cost/MSEK": 6.065,
    "out:Running cost/(Apt SEK y)": 28932,
    "out:Running Cost over RSP/MSEK": 12.207,
    "out:LCP/MSEK": 1.232,
    "out:ROI% old": 17.19,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 15,
    "out:Return/kSEK/y": 933,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 308940,
    "out:DH kr savings": 308940,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373191,
    "out:% savings (space heating)": 41.32,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.64,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 111.3,
    "out:Total Energy Use Post PV": 107.29,
    "out:Primary Energy": 98.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.97,
    "out:CO2 Operational/m2": 24.38,
    "out:Total CO2/m2": 46.34,
    "out:Total CO2 (tons)": 238.62,
    "out:Klimatpaverkan": -50.99,
    "out:Initial Cost/MSEK": 6.373,
    "out:Running cost/(Apt SEK y)": 29555,
    "out:Running Cost over RSP/MSEK": 12.482,
    "out:LCP/MSEK": 0.649,
    "out:ROI% old": 15.74,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 54.2,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 899,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 337704,
    "out:DH kr savings": 278046,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 389491,
    "out:% savings (space heating)": 38.76,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.64,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.48,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 104.29,
    "out:Primary Energy": 95.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.97,
    "out:CO2 Operational/m2": 23.3,
    "out:Total CO2/m2": 45.26,
    "out:Total CO2 (tons)": 233.06,
    "out:Klimatpaverkan": -56.55,
    "out:Initial Cost/MSEK": 6.502,
    "out:Running cost/(Apt SEK y)": 28691,
    "out:Running Cost over RSP/MSEK": 12.117,
    "out:LCP/MSEK": 0.885,
    "out:ROI% old": 16.23,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 946,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 293493,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 353151,
    "out:DH kr savings": 293493,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373191,
    "out:% savings (space heating)": 41.32,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.64,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 104.29,
    "out:Primary Energy": 96.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.25,
    "out:CO2 Operational/m2": 23.3,
    "out:Total CO2/m2": 45.54,
    "out:Total CO2 (tons)": 234.51,
    "out:Klimatpaverkan": -55.1,
    "out:Initial Cost/MSEK": 6.549,
    "out:Running cost/(Apt SEK y)": 28691,
    "out:Running Cost over RSP/MSEK": 12.117,
    "out:LCP/MSEK": 0.838,
    "out:ROI% old": 16.11,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 946,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 293493,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 353151,
    "out:DH kr savings": 293493,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 389491,
    "out:% savings (space heating)": 38.76,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.64,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.48,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 101.29,
    "out:Primary Energy": 93.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.25,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 44.46,
    "out:Total CO2 (tons)": 228.95,
    "out:Klimatpaverkan": -60.66,
    "out:Initial Cost/MSEK": 6.677,
    "out:Running cost/(Apt SEK y)": 27827,
    "out:Running Cost over RSP/MSEK": 11.752,
    "out:LCP/MSEK": 1.075,
    "out:ROI% old": 16.58,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 993,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 368598,
    "out:DH kr savings": 308940,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373191,
    "out:% savings (space heating)": 41.32,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.64,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 112.69,
    "out:Total Energy Use Post PV": 112.69,
    "out:Primary Energy": 111.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.25,
    "out:CO2 Operational/m2": 36.08,
    "out:Total CO2/m2": 53.33,
    "out:Total CO2 (tons)": 274.58,
    "out:Klimatpaverkan": -15.03,
    "out:Initial Cost/MSEK": 6.415,
    "out:Running cost/(Apt SEK y)": 30536,
    "out:Running Cost over RSP/MSEK": 12.879,
    "out:LCP/MSEK": 0.21,
    "out:ROI% old": 14.75,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 243107,
    "out:DH kr savings": 298642,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 389491,
    "out:% savings (space heating)": 38.76,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.64,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.48,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 108.69,
    "out:Total Energy Use Post PV": 108.69,
    "out:Primary Energy": 109.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.25,
    "out:CO2 Operational/m2": 34.64,
    "out:Total CO2/m2": 51.89,
    "out:Total CO2 (tons)": 267.17,
    "out:Klimatpaverkan": -22.44,
    "out:Initial Cost/MSEK": 6.544,
    "out:Running cost/(Apt SEK y)": 29384,
    "out:Running Cost over RSP/MSEK": 12.393,
    "out:LCP/MSEK": 0.568,
    "out:ROI% old": 15.53,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 909,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 319238,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 263703,
    "out:DH kr savings": 319238,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373191,
    "out:% savings (space heating)": 41.32,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.64,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 107.69,
    "out:Total Energy Use Post PV": 107.69,
    "out:Primary Energy": 109.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.53,
    "out:CO2 Operational/m2": 34.28,
    "out:Total CO2/m2": 51.81,
    "out:Total CO2 (tons)": 266.76,
    "out:Klimatpaverkan": -22.85,
    "out:Initial Cost/MSEK": 6.591,
    "out:Running cost/(Apt SEK y)": 29096,
    "out:Running Cost over RSP/MSEK": 12.271,
    "out:LCP/MSEK": 0.642,
    "out:ROI% old": 15.68,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 924,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268852,
    "out:DH kr savings": 324387,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 389491,
    "out:% savings (space heating)": 38.76,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.64,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.48,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 104.69,
    "out:Total Energy Use Post PV": 104.69,
    "out:Primary Energy": 106.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.53,
    "out:CO2 Operational/m2": 33.2,
    "out:Total CO2/m2": 50.73,
    "out:Total CO2 (tons)": 261.2,
    "out:Klimatpaverkan": -28.41,
    "out:Initial Cost/MSEK": 6.72,
    "out:Running cost/(Apt SEK y)": 28232,
    "out:Running Cost over RSP/MSEK": 11.906,
    "out:LCP/MSEK": 0.879,
    "out:ROI% old": 16.15,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 971,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 284299,
    "out:DH kr savings": 339834,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373191,
    "out:% savings (space heating)": 41.32,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.64,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 112.69,
    "out:Total Energy Use Post PV": 107.84,
    "out:Primary Energy": 103.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.31,
    "out:CO2 Operational/m2": 27.85,
    "out:Total CO2/m2": 52.15,
    "out:Total CO2 (tons)": 268.54,
    "out:Klimatpaverkan": -21.07,
    "out:Initial Cost/MSEK": 7.027,
    "out:Running cost/(Apt SEK y)": 29391,
    "out:Running Cost over RSP/MSEK": 12.408,
    "out:LCP/MSEK": 0.069,
    "out:ROI% old": 14.43,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 908,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 304934,
    "out:DH kr savings": 298642,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 389491,
    "out:% savings (space heating)": 38.76,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.64,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.48,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 108.69,
    "out:Total Energy Use Post PV": 103.84,
    "out:Primary Energy": 100.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.31,
    "out:CO2 Operational/m2": 26.41,
    "out:Total CO2/m2": 50.71,
    "out:Total CO2 (tons)": 261.12,
    "out:Klimatpaverkan": -28.49,
    "out:Initial Cost/MSEK": 7.156,
    "out:Running cost/(Apt SEK y)": 28239,
    "out:Running Cost over RSP/MSEK": 11.922,
    "out:LCP/MSEK": 0.426,
    "out:ROI% old": 15.14,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 970,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 319238,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 325530,
    "out:DH kr savings": 319238,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373191,
    "out:% savings (space heating)": 41.32,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.64,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 107.69,
    "out:Total Energy Use Post PV": 102.84,
    "out:Primary Energy": 100.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.59,
    "out:CO2 Operational/m2": 26.05,
    "out:Total CO2/m2": 50.64,
    "out:Total CO2 (tons)": 260.72,
    "out:Klimatpaverkan": -28.89,
    "out:Initial Cost/MSEK": 7.203,
    "out:Running cost/(Apt SEK y)": 27951,
    "out:Running Cost over RSP/MSEK": 11.8,
    "out:LCP/MSEK": 0.501,
    "out:ROI% old": 15.28,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 60.2,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 986,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 330679,
    "out:DH kr savings": 324387,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 389491,
    "out:% savings (space heating)": 38.76,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.64,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.48,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 104.69,
    "out:Total Energy Use Post PV": 99.84,
    "out:Primary Energy": 97.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.59,
    "out:CO2 Operational/m2": 24.97,
    "out:Total CO2/m2": 49.56,
    "out:Total CO2 (tons)": 255.16,
    "out:Klimatpaverkan": -34.45,
    "out:Initial Cost/MSEK": 7.332,
    "out:Running cost/(Apt SEK y)": 27087,
    "out:Running Cost over RSP/MSEK": 11.435,
    "out:LCP/MSEK": 0.737,
    "out:ROI% old": 15.72,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 65,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346126,
    "out:DH kr savings": 339834,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373191,
    "out:% savings (space heating)": 41.32,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.64,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 106.69,
    "out:Total Energy Use Post PV": 106.69,
    "out:Primary Energy": 108.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.25,
    "out:CO2 Operational/m2": 33.92,
    "out:Total CO2/m2": 51.17,
    "out:Total CO2 (tons)": 263.46,
    "out:Klimatpaverkan": -26.15,
    "out:Initial Cost/MSEK": 6.558,
    "out:Running cost/(Apt SEK y)": 28808,
    "out:Running Cost over RSP/MSEK": 12.149,
    "out:LCP/MSEK": 0.797,
    "out:ROI% old": 16.02,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 54.2,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 940,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 329536,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 274001,
    "out:DH kr savings": 329536,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 389491,
    "out:% savings (space heating)": 38.76,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.64,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.48,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 103.69,
    "out:Total Energy Use Post PV": 103.69,
    "out:Primary Energy": 105.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.25,
    "out:CO2 Operational/m2": 32.84,
    "out:Total CO2/m2": 50.09,
    "out:Total CO2 (tons)": 257.9,
    "out:Klimatpaverkan": -31.71,
    "out:Initial Cost/MSEK": 6.686,
    "out:Running cost/(Apt SEK y)": 27944,
    "out:Running Cost over RSP/MSEK": 11.784,
    "out:LCP/MSEK": 1.033,
    "out:ROI% old": 16.49,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 986,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 344983,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 289448,
    "out:DH kr savings": 344983,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373191,
    "out:% savings (space heating)": 41.32,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.64,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 103.69,
    "out:Total Energy Use Post PV": 103.69,
    "out:Primary Energy": 106.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.53,
    "out:CO2 Operational/m2": 32.84,
    "out:Total CO2/m2": 50.37,
    "out:Total CO2 (tons)": 259.35,
    "out:Klimatpaverkan": -30.26,
    "out:Initial Cost/MSEK": 6.733,
    "out:Running cost/(Apt SEK y)": 27944,
    "out:Running Cost over RSP/MSEK": 11.784,
    "out:LCP/MSEK": 0.986,
    "out:ROI% old": 16.38,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 986,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 344983,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 289448,
    "out:DH kr savings": 344983,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 389491,
    "out:% savings (space heating)": 38.76,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.64,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.48,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 100.69,
    "out:Total Energy Use Post PV": 100.69,
    "out:Primary Energy": 103.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.53,
    "out:CO2 Operational/m2": 31.76,
    "out:Total CO2/m2": 49.29,
    "out:Total CO2 (tons)": 253.79,
    "out:Klimatpaverkan": -35.82,
    "out:Initial Cost/MSEK": 6.862,
    "out:Running cost/(Apt SEK y)": 27080,
    "out:Running Cost over RSP/MSEK": 11.42,
    "out:LCP/MSEK": 1.223,
    "out:ROI% old": 16.83,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360430,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304895,
    "out:DH kr savings": 360430,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373191,
    "out:% savings (space heating)": 41.32,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.64,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 106.69,
    "out:Total Energy Use Post PV": 101.84,
    "out:Primary Energy": 99.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.31,
    "out:CO2 Operational/m2": 25.69,
    "out:Total CO2/m2": 49.99,
    "out:Total CO2 (tons)": 257.42,
    "out:Klimatpaverkan": -32.19,
    "out:Initial Cost/MSEK": 7.17,
    "out:Running cost/(Apt SEK y)": 27663,
    "out:Running Cost over RSP/MSEK": 11.679,
    "out:LCP/MSEK": 0.656,
    "out:ROI% old": 15.59,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 61.8,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1001,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 329536,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 335828,
    "out:DH kr savings": 329536,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 389491,
    "out:% savings (space heating)": 38.76,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.64,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.48,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 103.69,
    "out:Total Energy Use Post PV": 98.84,
    "out:Primary Energy": 96.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.31,
    "out:CO2 Operational/m2": 24.61,
    "out:Total CO2/m2": 48.91,
    "out:Total CO2 (tons)": 251.86,
    "out:Klimatpaverkan": -37.75,
    "out:Initial Cost/MSEK": 7.298,
    "out:Running cost/(Apt SEK y)": 26799,
    "out:Running Cost over RSP/MSEK": 11.314,
    "out:LCP/MSEK": 0.892,
    "out:ROI% old": 16.03,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 66.7,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1048,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 344983,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 351275,
    "out:DH kr savings": 344983,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373191,
    "out:% savings (space heating)": 41.32,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.64,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 103.69,
    "out:Total Energy Use Post PV": 98.84,
    "out:Primary Energy": 97.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.59,
    "out:CO2 Operational/m2": 24.61,
    "out:Total CO2/m2": 49.2,
    "out:Total CO2 (tons)": 253.31,
    "out:Klimatpaverkan": -36.3,
    "out:Initial Cost/MSEK": 7.345,
    "out:Running cost/(Apt SEK y)": 26799,
    "out:Running Cost over RSP/MSEK": 11.314,
    "out:LCP/MSEK": 0.845,
    "out:ROI% old": 15.93,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 66.7,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1048,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 344983,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 351275,
    "out:DH kr savings": 344983,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 389491,
    "out:% savings (space heating)": 38.76,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.64,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.48,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 100.69,
    "out:Total Energy Use Post PV": 95.84,
    "out:Primary Energy": 94.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.59,
    "out:CO2 Operational/m2": 23.53,
    "out:Total CO2/m2": 48.12,
    "out:Total CO2 (tons)": 247.75,
    "out:Klimatpaverkan": -41.86,
    "out:Initial Cost/MSEK": 7.474,
    "out:Running cost/(Apt SEK y)": 25936,
    "out:Running Cost over RSP/MSEK": 10.949,
    "out:LCP/MSEK": 1.081,
    "out:ROI% old": 16.35,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 71.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1095,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 360430,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 366722,
    "out:DH kr savings": 360430,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373191,
    "out:% savings (space heating)": 41.32,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.48,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 112.3,
    "out:Total Energy Use Post PV": 112.3,
    "out:Primary Energy": 105.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.47,
    "out:CO2 Operational/m2": 37.17,
    "out:Total CO2/m2": 61.63,
    "out:Total CO2 (tons)": 317.35,
    "out:Klimatpaverkan": 27.74,
    "out:Initial Cost/MSEK": 8.785,
    "out:Running cost/(Apt SEK y)": 30947,
    "out:Running Cost over RSP/MSEK": 13.058,
    "out:LCP/MSEK": -2.338,
    "out:ROI% old": 10.48,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 47.3,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 824,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 272897,
    "out:DH kr savings": 272897,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 364931,
    "out:% savings (space heating)": 42.62,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.93,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 109.3,
    "out:Total Energy Use Post PV": 109.3,
    "out:Primary Energy": 102.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.47,
    "out:CO2 Operational/m2": 36.09,
    "out:Total CO2/m2": 60.55,
    "out:Total CO2 (tons)": 311.79,
    "out:Klimatpaverkan": 22.18,
    "out:Initial Cost/MSEK": 8.913,
    "out:Running cost/(Apt SEK y)": 30084,
    "out:Running Cost over RSP/MSEK": 12.693,
    "out:LCP/MSEK": -2.102,
    "out:ROI% old": 10.92,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 871,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 288344,
    "out:DH kr savings": 288344,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 349748,
    "out:% savings (space heating)": 45.01,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.93,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 108.3,
    "out:Primary Energy": 102.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.75,
    "out:CO2 Operational/m2": 35.73,
    "out:Total CO2/m2": 60.48,
    "out:Total CO2 (tons)": 311.39,
    "out:Klimatpaverkan": 21.78,
    "out:Initial Cost/MSEK": 8.96,
    "out:Running cost/(Apt SEK y)": 29796,
    "out:Running Cost over RSP/MSEK": 12.571,
    "out:LCP/MSEK": -2.027,
    "out:ROI% old": 11.05,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 886,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 293493,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 293493,
    "out:DH kr savings": 293493,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 364931,
    "out:% savings (space heating)": 42.62,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.93,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 105.3,
    "out:Primary Energy": 100.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.75,
    "out:CO2 Operational/m2": 34.65,
    "out:Total CO2/m2": 59.4,
    "out:Total CO2 (tons)": 305.83,
    "out:Klimatpaverkan": 16.22,
    "out:Initial Cost/MSEK": 9.089,
    "out:Running cost/(Apt SEK y)": 28932,
    "out:Running Cost over RSP/MSEK": 12.207,
    "out:LCP/MSEK": -1.791,
    "out:ROI% old": 11.47,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 933,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 308940,
    "out:DH kr savings": 308940,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 349748,
    "out:% savings (space heating)": 45.01,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.93,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 112.3,
    "out:Total Energy Use Post PV": 108.29,
    "out:Primary Energy": 98.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.53,
    "out:CO2 Operational/m2": 24.74,
    "out:Total CO2/m2": 56.26,
    "out:Total CO2 (tons)": 289.71,
    "out:Klimatpaverkan": 0.1,
    "out:Initial Cost/MSEK": 9.397,
    "out:Running cost/(Apt SEK y)": 29843,
    "out:Running Cost over RSP/MSEK": 12.604,
    "out:LCP/MSEK": -2.496,
    "out:ROI% old": 10.49,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 884,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 332555,
    "out:DH kr savings": 272897,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 364931,
    "out:% savings (space heating)": 42.62,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.93,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 109.3,
    "out:Total Energy Use Post PV": 105.29,
    "out:Primary Energy": 95.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.53,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 55.18,
    "out:Total CO2 (tons)": 284.14,
    "out:Klimatpaverkan": -5.47,
    "out:Initial Cost/MSEK": 9.525,
    "out:Running cost/(Apt SEK y)": 28979,
    "out:Running Cost over RSP/MSEK": 12.239,
    "out:LCP/MSEK": -2.26,
    "out:ROI% old": 10.9,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 930,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 348002,
    "out:DH kr savings": 288344,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 349748,
    "out:% savings (space heating)": 45.01,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.93,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 104.29,
    "out:Primary Energy": 95.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.81,
    "out:CO2 Operational/m2": 23.3,
    "out:Total CO2/m2": 55.11,
    "out:Total CO2 (tons)": 283.74,
    "out:Klimatpaverkan": -5.87,
    "out:Initial Cost/MSEK": 9.572,
    "out:Running cost/(Apt SEK y)": 28691,
    "out:Running Cost over RSP/MSEK": 12.117,
    "out:LCP/MSEK": -2.185,
    "out:ROI% old": 11.02,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 946,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293493,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 353151,
    "out:DH kr savings": 293493,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 364931,
    "out:% savings (space heating)": 42.62,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.93,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 101.29,
    "out:Primary Energy": 92.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.81,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 54.03,
    "out:Total CO2 (tons)": 278.18,
    "out:Klimatpaverkan": -11.43,
    "out:Initial Cost/MSEK": 9.701,
    "out:Running cost/(Apt SEK y)": 27827,
    "out:Running Cost over RSP/MSEK": 11.752,
    "out:LCP/MSEK": -1.949,
    "out:ROI% old": 11.42,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 993,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 368598,
    "out:DH kr savings": 308940,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 349748,
    "out:% savings (space heating)": 45.01,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.93,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 107.3,
    "out:Total Energy Use Post PV": 107.3,
    "out:Primary Energy": 101.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.47,
    "out:CO2 Operational/m2": 35.37,
    "out:Total CO2/m2": 59.83,
    "out:Total CO2 (tons)": 308.09,
    "out:Klimatpaverkan": 18.48,
    "out:Initial Cost/MSEK": 8.927,
    "out:Running cost/(Apt SEK y)": 29508,
    "out:Running Cost over RSP/MSEK": 12.45,
    "out:LCP/MSEK": -1.873,
    "out:ROI% old": 11.29,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 54.2,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 902,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 298642,
    "out:DH kr savings": 298642,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 364931,
    "out:% savings (space heating)": 42.62,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.93,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 104.3,
    "out:Primary Energy": 99.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.47,
    "out:CO2 Operational/m2": 34.29,
    "out:Total CO2/m2": 58.75,
    "out:Total CO2 (tons)": 302.53,
    "out:Klimatpaverkan": 12.92,
    "out:Initial Cost/MSEK": 9.056,
    "out:Running cost/(Apt SEK y)": 28644,
    "out:Running Cost over RSP/MSEK": 12.085,
    "out:LCP/MSEK": -1.637,
    "out:ROI% old": 11.7,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 949,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 314089,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 314089,
    "out:DH kr savings": 314089,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 349748,
    "out:% savings (space heating)": 45.01,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.93,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 103.3,
    "out:Total Energy Use Post PV": 103.3,
    "out:Primary Energy": 99.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.75,
    "out:CO2 Operational/m2": 33.93,
    "out:Total CO2/m2": 58.68,
    "out:Total CO2 (tons)": 302.12,
    "out:Klimatpaverkan": 12.51,
    "out:Initial Cost/MSEK": 9.103,
    "out:Running cost/(Apt SEK y)": 28356,
    "out:Running Cost over RSP/MSEK": 11.963,
    "out:LCP/MSEK": -1.562,
    "out:ROI% old": 11.83,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 60.2,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 964,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 319238,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 319238,
    "out:DH kr savings": 319238,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 364931,
    "out:% savings (space heating)": 42.62,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.93,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 100.3,
    "out:Total Energy Use Post PV": 100.3,
    "out:Primary Energy": 97.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.75,
    "out:CO2 Operational/m2": 32.85,
    "out:Total CO2/m2": 57.6,
    "out:Total CO2 (tons)": 296.56,
    "out:Klimatpaverkan": 6.95,
    "out:Initial Cost/MSEK": 9.232,
    "out:Running cost/(Apt SEK y)": 27492,
    "out:Running Cost over RSP/MSEK": 11.598,
    "out:LCP/MSEK": -1.326,
    "out:ROI% old": 12.23,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 65,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1011,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 334685,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 334685,
    "out:DH kr savings": 334685,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 349748,
    "out:% savings (space heating)": 45.01,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.93,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01001200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 107.3,
    "out:Total Energy Use Post PV": 103.29,
    "out:Primary Energy": 94.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.53,
    "out:CO2 Operational/m2": 22.94,
    "out:Total CO2/m2": 54.46,
    "out:Total CO2 (tons)": 280.44,
    "out:Klimatpaverkan": -9.17,
    "out:Initial Cost/MSEK": 9.539,
    "out:Running cost/(Apt SEK y)": 28403,
    "out:Running Cost over RSP/MSEK": 11.996,
    "out:LCP/MSEK": -2.031,
    "out:ROI% old": 11.24,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 60.2,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 962,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 358300,
    "out:DH kr savings": 298642,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 364931,
    "out:% savings (space heating)": 42.62,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.93,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 100.29,
    "out:Primary Energy": 91.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.53,
    "out:CO2 Operational/m2": 21.86,
    "out:Total CO2/m2": 53.38,
    "out:Total CO2 (tons)": 274.88,
    "out:Klimatpaverkan": -14.73,
    "out:Initial Cost/MSEK": 9.668,
    "out:Running cost/(Apt SEK y)": 27539,
    "out:Running Cost over RSP/MSEK": 11.631,
    "out:LCP/MSEK": -1.794,
    "out:ROI% old": 11.63,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 65,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1008,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 314089,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 373746,
    "out:DH kr savings": 314089,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 349748,
    "out:% savings (space heating)": 45.01,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.93,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 103.3,
    "out:Total Energy Use Post PV": 99.29,
    "out:Primary Energy": 92.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.81,
    "out:CO2 Operational/m2": 21.5,
    "out:Total CO2/m2": 53.31,
    "out:Total CO2 (tons)": 274.47,
    "out:Klimatpaverkan": -15.14,
    "out:Initial Cost/MSEK": 9.715,
    "out:Running cost/(Apt SEK y)": 27251,
    "out:Running Cost over RSP/MSEK": 11.509,
    "out:LCP/MSEK": -1.72,
    "out:ROI% old": 11.76,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 66.7,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1024,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 319238,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 378896,
    "out:DH kr savings": 319238,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 364931,
    "out:% savings (space heating)": 42.62,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.93,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 100.3,
    "out:Total Energy Use Post PV": 96.29,
    "out:Primary Energy": 89.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.81,
    "out:CO2 Operational/m2": 20.42,
    "out:Total CO2/m2": 52.23,
    "out:Total CO2 (tons)": 268.91,
    "out:Klimatpaverkan": -20.7,
    "out:Initial Cost/MSEK": 9.844,
    "out:Running cost/(Apt SEK y)": 26387,
    "out:Running Cost over RSP/MSEK": 11.144,
    "out:LCP/MSEK": -1.484,
    "out:ROI% old": 12.13,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 71.9,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1070,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 334685,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 394342,
    "out:DH kr savings": 334685,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 349748,
    "out:% savings (space heating)": 45.01,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.93,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01001201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 107.69,
    "out:Total Energy Use Post PV": 107.69,
    "out:Primary Energy": 107.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.81,
    "out:CO2 Operational/m2": 34.28,
    "out:Total CO2/m2": 61.09,
    "out:Total CO2 (tons)": 314.55,
    "out:Klimatpaverkan": 24.94,
    "out:Initial Cost/MSEK": 9.581,
    "out:Running cost/(Apt SEK y)": 29096,
    "out:Running Cost over RSP/MSEK": 12.271,
    "out:LCP/MSEK": -2.348,
    "out:ROI% old": 10.78,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 924,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268852,
    "out:DH kr savings": 324387,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 364931,
    "out:% savings (space heating)": 42.62,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.93,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 104.69,
    "out:Total Energy Use Post PV": 104.69,
    "out:Primary Energy": 105.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.81,
    "out:CO2 Operational/m2": 33.2,
    "out:Total CO2/m2": 60.01,
    "out:Total CO2 (tons)": 308.99,
    "out:Klimatpaverkan": 19.38,
    "out:Initial Cost/MSEK": 9.71,
    "out:Running cost/(Apt SEK y)": 28232,
    "out:Running Cost over RSP/MSEK": 11.906,
    "out:LCP/MSEK": -2.112,
    "out:ROI% old": 11.18,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 971,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 284299,
    "out:DH kr savings": 339834,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 349748,
    "out:% savings (space heating)": 45.01,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.93,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 103.69,
    "out:Total Energy Use Post PV": 103.69,
    "out:Primary Energy": 105.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.09,
    "out:CO2 Operational/m2": 32.84,
    "out:Total CO2/m2": 59.93,
    "out:Total CO2 (tons)": 308.59,
    "out:Klimatpaverkan": 18.98,
    "out:Initial Cost/MSEK": 9.757,
    "out:Running cost/(Apt SEK y)": 27944,
    "out:Running Cost over RSP/MSEK": 11.784,
    "out:LCP/MSEK": -2.037,
    "out:ROI% old": 11.3,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 986,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 344983,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 289448,
    "out:DH kr savings": 344983,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 364931,
    "out:% savings (space heating)": 42.62,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.93,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 100.69,
    "out:Total Energy Use Post PV": 100.69,
    "out:Primary Energy": 102.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.09,
    "out:CO2 Operational/m2": 31.76,
    "out:Total CO2/m2": 58.85,
    "out:Total CO2 (tons)": 303.02,
    "out:Klimatpaverkan": 13.41,
    "out:Initial Cost/MSEK": 9.886,
    "out:Running cost/(Apt SEK y)": 27080,
    "out:Running Cost over RSP/MSEK": 11.42,
    "out:LCP/MSEK": -1.801,
    "out:ROI% old": 11.68,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360430,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304895,
    "out:DH kr savings": 360430,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 349748,
    "out:% savings (space heating)": 45.01,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.93,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 107.69,
    "out:Total Energy Use Post PV": 102.84,
    "out:Primary Energy": 99.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.87,
    "out:CO2 Operational/m2": 26.05,
    "out:Total CO2/m2": 59.92,
    "out:Total CO2 (tons)": 308.51,
    "out:Klimatpaverkan": 18.9,
    "out:Initial Cost/MSEK": 10.193,
    "out:Running cost/(Apt SEK y)": 27951,
    "out:Running Cost over RSP/MSEK": 11.8,
    "out:LCP/MSEK": -2.489,
    "out:ROI% old": 10.8,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 60.2,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 986,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 330679,
    "out:DH kr savings": 324387,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 364931,
    "out:% savings (space heating)": 42.62,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.93,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 104.69,
    "out:Total Energy Use Post PV": 99.84,
    "out:Primary Energy": 96.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.87,
    "out:CO2 Operational/m2": 24.97,
    "out:Total CO2/m2": 58.84,
    "out:Total CO2 (tons)": 302.95,
    "out:Klimatpaverkan": 13.34,
    "out:Initial Cost/MSEK": 10.322,
    "out:Running cost/(Apt SEK y)": 27087,
    "out:Running Cost over RSP/MSEK": 11.435,
    "out:LCP/MSEK": -2.253,
    "out:ROI% old": 11.17,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 65,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346126,
    "out:DH kr savings": 339834,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 349748,
    "out:% savings (space heating)": 45.01,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.93,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 103.69,
    "out:Total Energy Use Post PV": 98.84,
    "out:Primary Energy": 96.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.15,
    "out:CO2 Operational/m2": 24.61,
    "out:Total CO2/m2": 58.76,
    "out:Total CO2 (tons)": 302.54,
    "out:Klimatpaverkan": 12.93,
    "out:Initial Cost/MSEK": 10.369,
    "out:Running cost/(Apt SEK y)": 26799,
    "out:Running Cost over RSP/MSEK": 11.314,
    "out:LCP/MSEK": -2.179,
    "out:ROI% old": 11.28,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 66.7,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1048,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 344983,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 351275,
    "out:DH kr savings": 344983,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 364931,
    "out:% savings (space heating)": 42.62,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.93,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 100.69,
    "out:Total Energy Use Post PV": 95.84,
    "out:Primary Energy": 93.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.15,
    "out:CO2 Operational/m2": 23.53,
    "out:Total CO2/m2": 57.68,
    "out:Total CO2 (tons)": 296.98,
    "out:Klimatpaverkan": 7.37,
    "out:Initial Cost/MSEK": 10.498,
    "out:Running cost/(Apt SEK y)": 25936,
    "out:Running Cost over RSP/MSEK": 10.949,
    "out:LCP/MSEK": -1.942,
    "out:ROI% old": 11.64,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 71.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1095,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360430,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 366722,
    "out:DH kr savings": 360430,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 349748,
    "out:% savings (space heating)": 45.01,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.93,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 101.69,
    "out:Total Energy Use Post PV": 101.69,
    "out:Primary Energy": 104.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.81,
    "out:CO2 Operational/m2": 32.12,
    "out:Total CO2/m2": 58.93,
    "out:Total CO2 (tons)": 303.43,
    "out:Klimatpaverkan": 13.82,
    "out:Initial Cost/MSEK": 9.724,
    "out:Running cost/(Apt SEK y)": 27368,
    "out:Running Cost over RSP/MSEK": 11.541,
    "out:LCP/MSEK": -1.761,
    "out:ROI% old": 11.7,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 61.8,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1017,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 355281,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 299746,
    "out:DH kr savings": 355281,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 364931,
    "out:% savings (space heating)": 42.62,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.93,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 98.69,
    "out:Total Energy Use Post PV": 98.69,
    "out:Primary Energy": 101.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.81,
    "out:CO2 Operational/m2": 31.04,
    "out:Total CO2/m2": 57.85,
    "out:Total CO2 (tons)": 297.87,
    "out:Klimatpaverkan": 8.26,
    "out:Initial Cost/MSEK": 9.853,
    "out:Running cost/(Apt SEK y)": 26505,
    "out:Running Cost over RSP/MSEK": 11.176,
    "out:LCP/MSEK": -1.525,
    "out:ROI% old": 12.07,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 66.7,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1064,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 370728,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 315193,
    "out:DH kr savings": 370728,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 349748,
    "out:% savings (space heating)": 45.01,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.93,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 98.69,
    "out:Total Energy Use Post PV": 98.69,
    "out:Primary Energy": 102.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.09,
    "out:CO2 Operational/m2": 31.04,
    "out:Total CO2/m2": 58.13,
    "out:Total CO2 (tons)": 299.32,
    "out:Klimatpaverkan": 9.71,
    "out:Initial Cost/MSEK": 9.9,
    "out:Running cost/(Apt SEK y)": 26505,
    "out:Running Cost over RSP/MSEK": 11.176,
    "out:LCP/MSEK": -1.572,
    "out:ROI% old": 12.02,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 66.7,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1064,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 370728,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 315193,
    "out:DH kr savings": 370728,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 364931,
    "out:% savings (space heating)": 42.62,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.93,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 95.69,
    "out:Total Energy Use Post PV": 95.69,
    "out:Primary Energy": 99.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.09,
    "out:CO2 Operational/m2": 29.96,
    "out:Total CO2/m2": 57.05,
    "out:Total CO2 (tons)": 293.76,
    "out:Klimatpaverkan": 4.15,
    "out:Initial Cost/MSEK": 10.028,
    "out:Running cost/(Apt SEK y)": 25641,
    "out:Running Cost over RSP/MSEK": 10.811,
    "out:LCP/MSEK": -1.336,
    "out:ROI% old": 12.38,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 71.9,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1111,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 386175,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 330640,
    "out:DH kr savings": 386175,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 349748,
    "out:% savings (space heating)": 45.01,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.93,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01001201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 101.69,
    "out:Total Energy Use Post PV": 96.84,
    "out:Primary Energy": 95.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.87,
    "out:CO2 Operational/m2": 23.89,
    "out:Total CO2/m2": 57.76,
    "out:Total CO2 (tons)": 297.38,
    "out:Klimatpaverkan": 7.77,
    "out:Initial Cost/MSEK": 10.336,
    "out:Running cost/(Apt SEK y)": 26223,
    "out:Running Cost over RSP/MSEK": 11.071,
    "out:LCP/MSEK": -1.902,
    "out:ROI% old": 11.66,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 70.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1079,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 355281,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 361573,
    "out:DH kr savings": 355281,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 364931,
    "out:% savings (space heating)": 42.62,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.93,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 98.69,
    "out:Total Energy Use Post PV": 93.84,
    "out:Primary Energy": 93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.87,
    "out:CO2 Operational/m2": 22.81,
    "out:Total CO2/m2": 56.68,
    "out:Total CO2 (tons)": 291.82,
    "out:Klimatpaverkan": 2.21,
    "out:Initial Cost/MSEK": 10.465,
    "out:Running cost/(Apt SEK y)": 25360,
    "out:Running Cost over RSP/MSEK": 10.706,
    "out:LCP/MSEK": -1.666,
    "out:ROI% old": 12.01,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 75.5,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1126,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 370728,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 377020,
    "out:DH kr savings": 370728,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 349748,
    "out:% savings (space heating)": 45.01,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.93,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.87,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 98.69,
    "out:Total Energy Use Post PV": 93.84,
    "out:Primary Energy": 93.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.15,
    "out:CO2 Operational/m2": 22.81,
    "out:Total CO2/m2": 56.96,
    "out:Total CO2 (tons)": 293.27,
    "out:Klimatpaverkan": 3.66,
    "out:Initial Cost/MSEK": 10.512,
    "out:Running cost/(Apt SEK y)": 25360,
    "out:Running Cost over RSP/MSEK": 10.706,
    "out:LCP/MSEK": -1.713,
    "out:ROI% old": 11.96,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 75.5,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1126,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 370728,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 377020,
    "out:DH kr savings": 370728,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 364931,
    "out:% savings (space heating)": 42.62,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.87,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01001201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.93,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 95.69,
    "out:Total Energy Use Post PV": 90.84,
    "out:Primary Energy": 90.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.15,
    "out:CO2 Operational/m2": 21.73,
    "out:Total CO2/m2": 55.88,
    "out:Total CO2 (tons)": 287.71,
    "out:Klimatpaverkan": -1.9,
    "out:Initial Cost/MSEK": 10.64,
    "out:Running cost/(Apt SEK y)": 24496,
    "out:Running Cost over RSP/MSEK": 10.341,
    "out:LCP/MSEK": -1.477,
    "out:ROI% old": 12.3,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 81.3,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1173,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 386175,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 392467,
    "out:DH kr savings": 386175,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 349748,
    "out:% savings (space heating)": 45.01,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 67.93,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.22,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 133.3,
    "out:Total Energy Use Post PV": 133.3,
    "out:Primary Energy": 123.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.24,
    "out:CO2 Operational/m2": 44.73,
    "out:Total CO2/m2": 56.97,
    "out:Total CO2 (tons)": 293.34,
    "out:Klimatpaverkan": 3.73,
    "out:Initial Cost/MSEK": 4.38,
    "out:Running cost/(Apt SEK y)": 36995,
    "out:Running Cost over RSP/MSEK": 15.612,
    "out:LCP/MSEK": -0.488,
    "out:ROI% old": 12.69,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 498,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 164768,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164768,
    "out:DH kr savings": 164768,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 474833,
    "out:% savings (space heating)": 25.34,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.22,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.3,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 129.3,
    "out:Primary Energy": 120.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.24,
    "out:CO2 Operational/m2": 43.29,
    "out:Total CO2/m2": 55.53,
    "out:Total CO2 (tons)": 285.93,
    "out:Klimatpaverkan": -3.68,
    "out:Initial Cost/MSEK": 4.509,
    "out:Running cost/(Apt SEK y)": 35843,
    "out:Running Cost over RSP/MSEK": 15.126,
    "out:LCP/MSEK": -0.131,
    "out:ROI% old": 13.87,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 560,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 185364,
    "out:DH kr savings": 185364,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454654,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.3,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.22,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 129.3,
    "out:Primary Energy": 121.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.53,
    "out:CO2 Operational/m2": 43.29,
    "out:Total CO2/m2": 55.81,
    "out:Total CO2 (tons)": 287.38,
    "out:Klimatpaverkan": -2.23,
    "out:Initial Cost/MSEK": 4.556,
    "out:Running cost/(Apt SEK y)": 35843,
    "out:Running Cost over RSP/MSEK": 15.126,
    "out:LCP/MSEK": -0.177,
    "out:ROI% old": 13.73,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 560,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185364,
    "out:DH kr savings": 185364,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 474833,
    "out:% savings (space heating)": 25.34,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.22,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.3,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 125.3,
    "out:Primary Energy": 117.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.53,
    "out:CO2 Operational/m2": 41.85,
    "out:Total CO2/m2": 54.37,
    "out:Total CO2 (tons)": 279.96,
    "out:Klimatpaverkan": -9.65,
    "out:Initial Cost/MSEK": 4.685,
    "out:Running cost/(Apt SEK y)": 34691,
    "out:Running Cost over RSP/MSEK": 14.639,
    "out:LCP/MSEK": 0.18,
    "out:ROI% old": 14.84,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205960,
    "out:DH kr savings": 205960,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454654,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.3,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.22,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 133.3,
    "out:Total Energy Use Post PV": 129.29,
    "out:Primary Energy": 116.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.3,
    "out:CO2 Operational/m2": 32.3,
    "out:Total CO2/m2": 51.6,
    "out:Total CO2 (tons)": 265.69,
    "out:Klimatpaverkan": -23.92,
    "out:Initial Cost/MSEK": 4.992,
    "out:Running cost/(Apt SEK y)": 35890,
    "out:Running Cost over RSP/MSEK": 15.158,
    "out:LCP/MSEK": -0.646,
    "out:ROI% old": 12.44,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 164768,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 224426,
    "out:DH kr savings": 164768,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 474833,
    "out:% savings (space heating)": 25.34,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.22,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.3,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 125.29,
    "out:Primary Energy": 113.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.3,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 50.16,
    "out:Total CO2 (tons)": 258.28,
    "out:Klimatpaverkan": -31.33,
    "out:Initial Cost/MSEK": 5.121,
    "out:Running cost/(Apt SEK y)": 34738,
    "out:Running Cost over RSP/MSEK": 14.671,
    "out:LCP/MSEK": -0.288,
    "out:ROI% old": 13.48,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 245022,
    "out:DH kr savings": 185364,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454654,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.3,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.22,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 125.29,
    "out:Primary Energy": 114.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.59,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 50.44,
    "out:Total CO2 (tons)": 259.73,
    "out:Klimatpaverkan": -29.88,
    "out:Initial Cost/MSEK": 5.168,
    "out:Running cost/(Apt SEK y)": 34738,
    "out:Running Cost over RSP/MSEK": 14.671,
    "out:LCP/MSEK": -0.335,
    "out:ROI% old": 13.36,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245022,
    "out:DH kr savings": 185364,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 474833,
    "out:% savings (space heating)": 25.34,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.22,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.3,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 121.29,
    "out:Primary Energy": 110.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.59,
    "out:CO2 Operational/m2": 29.42,
    "out:Total CO2/m2": 49,
    "out:Total CO2 (tons)": 252.31,
    "out:Klimatpaverkan": -37.3,
    "out:Initial Cost/MSEK": 5.297,
    "out:Running cost/(Apt SEK y)": 33586,
    "out:Running Cost over RSP/MSEK": 14.185,
    "out:LCP/MSEK": 0.023,
    "out:ROI% old": 14.35,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 265618,
    "out:DH kr savings": 205960,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454654,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.3,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.22,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 128.3,
    "out:Total Energy Use Post PV": 128.3,
    "out:Primary Energy": 120.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.24,
    "out:CO2 Operational/m2": 42.93,
    "out:Total CO2/m2": 55.17,
    "out:Total CO2 (tons)": 284.07,
    "out:Klimatpaverkan": -5.54,
    "out:Initial Cost/MSEK": 4.523,
    "out:Running cost/(Apt SEK y)": 35555,
    "out:Running Cost over RSP/MSEK": 15.004,
    "out:LCP/MSEK": -0.023,
    "out:ROI% old": 14.21,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 575,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 190513,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 190513,
    "out:DH kr savings": 190513,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 474833,
    "out:% savings (space heating)": 25.34,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.22,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.3,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 124.3,
    "out:Total Energy Use Post PV": 124.3,
    "out:Primary Energy": 117,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.24,
    "out:CO2 Operational/m2": 41.49,
    "out:Total CO2/m2": 53.73,
    "out:Total CO2 (tons)": 276.66,
    "out:Klimatpaverkan": -12.95,
    "out:Initial Cost/MSEK": 4.652,
    "out:Running cost/(Apt SEK y)": 34403,
    "out:Running Cost over RSP/MSEK": 14.517,
    "out:LCP/MSEK": 0.335,
    "out:ROI% old": 15.31,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211109,
    "out:DH kr savings": 211109,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454654,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.3,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.22,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 125.3,
    "out:Primary Energy": 118.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.53,
    "out:CO2 Operational/m2": 41.85,
    "out:Total CO2/m2": 54.37,
    "out:Total CO2 (tons)": 279.96,
    "out:Klimatpaverkan": -9.65,
    "out:Initial Cost/MSEK": 4.699,
    "out:Running cost/(Apt SEK y)": 34691,
    "out:Running Cost over RSP/MSEK": 14.639,
    "out:LCP/MSEK": 0.167,
    "out:ROI% old": 14.79,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205960,
    "out:DH kr savings": 205960,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 474833,
    "out:% savings (space heating)": 25.34,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.22,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.3,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 121.3,
    "out:Total Energy Use Post PV": 121.3,
    "out:Primary Energy": 114.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.53,
    "out:CO2 Operational/m2": 40.41,
    "out:Total CO2/m2": 52.93,
    "out:Total CO2 (tons)": 272.55,
    "out:Klimatpaverkan": -17.06,
    "out:Initial Cost/MSEK": 4.827,
    "out:Running cost/(Apt SEK y)": 33539,
    "out:Running Cost over RSP/MSEK": 14.153,
    "out:LCP/MSEK": 0.524,
    "out:ROI% old": 15.84,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 684,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226556,
    "out:DH kr savings": 226556,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454654,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.3,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.22,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 128.3,
    "out:Total Energy Use Post PV": 124.29,
    "out:Primary Energy": 113.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.3,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 49.8,
    "out:Total CO2 (tons)": 256.42,
    "out:Klimatpaverkan": -33.19,
    "out:Initial Cost/MSEK": 5.135,
    "out:Running cost/(Apt SEK y)": 34450,
    "out:Running Cost over RSP/MSEK": 14.55,
    "out:LCP/MSEK": -0.18,
    "out:ROI% old": 13.78,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 635,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 190513,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 250171,
    "out:DH kr savings": 190513,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 474833,
    "out:% savings (space heating)": 25.34,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.22,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.3,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 124.3,
    "out:Total Energy Use Post PV": 120.29,
    "out:Primary Energy": 109.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.3,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 48.36,
    "out:Total CO2 (tons)": 249.01,
    "out:Klimatpaverkan": -40.6,
    "out:Initial Cost/MSEK": 5.264,
    "out:Running cost/(Apt SEK y)": 33298,
    "out:Running Cost over RSP/MSEK": 14.063,
    "out:LCP/MSEK": 0.177,
    "out:ROI% old": 14.77,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 697,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 270767,
    "out:DH kr savings": 211109,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454654,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.3,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.22,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 121.29,
    "out:Primary Energy": 111.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.59,
    "out:CO2 Operational/m2": 29.42,
    "out:Total CO2/m2": 49,
    "out:Total CO2 (tons)": 252.31,
    "out:Klimatpaverkan": -37.3,
    "out:Initial Cost/MSEK": 5.311,
    "out:Running cost/(Apt SEK y)": 33586,
    "out:Running Cost over RSP/MSEK": 14.185,
    "out:LCP/MSEK": 0.009,
    "out:ROI% old": 14.31,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 265618,
    "out:DH kr savings": 205960,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 474833,
    "out:% savings (space heating)": 25.34,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.22,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.3,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 121.3,
    "out:Total Energy Use Post PV": 117.29,
    "out:Primary Energy": 107.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.59,
    "out:CO2 Operational/m2": 27.98,
    "out:Total CO2/m2": 47.56,
    "out:Total CO2 (tons)": 244.9,
    "out:Klimatpaverkan": -44.71,
    "out:Initial Cost/MSEK": 5.439,
    "out:Running cost/(Apt SEK y)": 32434,
    "out:Running Cost over RSP/MSEK": 13.698,
    "out:LCP/MSEK": 0.367,
    "out:ROI% old": 15.25,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 744,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286214,
    "out:DH kr savings": 226556,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454654,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.3,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.22,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 128.69,
    "out:Total Energy Use Post PV": 128.69,
    "out:Primary Energy": 126.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.59,
    "out:CO2 Operational/m2": 41.84,
    "out:Total CO2/m2": 56.43,
    "out:Total CO2 (tons)": 290.53,
    "out:Klimatpaverkan": 0.92,
    "out:Initial Cost/MSEK": 5.177,
    "out:Running cost/(Apt SEK y)": 35143,
    "out:Running Cost over RSP/MSEK": 14.825,
    "out:LCP/MSEK": -0.498,
    "out:ROI% old": 12.91,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 598,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160723,
    "out:DH kr savings": 216258,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 474833,
    "out:% savings (space heating)": 25.34,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.22,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.3,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 124.69,
    "out:Primary Energy": 123.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.59,
    "out:CO2 Operational/m2": 40.4,
    "out:Total CO2/m2": 54.99,
    "out:Total CO2 (tons)": 283.12,
    "out:Klimatpaverkan": -6.49,
    "out:Initial Cost/MSEK": 5.306,
    "out:Running cost/(Apt SEK y)": 33992,
    "out:Running Cost over RSP/MSEK": 14.339,
    "out:LCP/MSEK": -0.14,
    "out:ROI% old": 13.91,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181319,
    "out:DH kr savings": 236854,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454654,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.3,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.22,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 124.69,
    "out:Primary Energy": 123.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.87,
    "out:CO2 Operational/m2": 40.4,
    "out:Total CO2/m2": 55.27,
    "out:Total CO2 (tons)": 284.57,
    "out:Klimatpaverkan": -5.04,
    "out:Initial Cost/MSEK": 5.353,
    "out:Running cost/(Apt SEK y)": 33992,
    "out:Running Cost over RSP/MSEK": 14.339,
    "out:LCP/MSEK": -0.187,
    "out:ROI% old": 13.79,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181319,
    "out:DH kr savings": 236854,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 474833,
    "out:% savings (space heating)": 25.34,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.22,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.3,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 120.69,
    "out:Primary Energy": 120.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.87,
    "out:CO2 Operational/m2": 38.96,
    "out:Total CO2/m2": 53.83,
    "out:Total CO2 (tons)": 277.16,
    "out:Klimatpaverkan": -12.45,
    "out:Initial Cost/MSEK": 5.482,
    "out:Running cost/(Apt SEK y)": 32840,
    "out:Running Cost over RSP/MSEK": 13.852,
    "out:LCP/MSEK": 0.171,
    "out:ROI% old": 14.73,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201915,
    "out:DH kr savings": 257450,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454654,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.3,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.22,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 128.69,
    "out:Total Energy Use Post PV": 123.84,
    "out:Primary Energy": 117.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.65,
    "out:CO2 Operational/m2": 33.61,
    "out:Total CO2/m2": 55.25,
    "out:Total CO2 (tons)": 284.49,
    "out:Klimatpaverkan": -5.12,
    "out:Initial Cost/MSEK": 5.789,
    "out:Running cost/(Apt SEK y)": 33998,
    "out:Running Cost over RSP/MSEK": 14.355,
    "out:LCP/MSEK": -0.64,
    "out:ROI% old": 12.71,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 659,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 222550,
    "out:DH kr savings": 216258,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 474833,
    "out:% savings (space heating)": 25.34,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.22,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.3,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 119.84,
    "out:Primary Energy": 114.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.65,
    "out:CO2 Operational/m2": 32.17,
    "out:Total CO2/m2": 53.81,
    "out:Total CO2 (tons)": 277.08,
    "out:Klimatpaverkan": -12.53,
    "out:Initial Cost/MSEK": 5.918,
    "out:Running cost/(Apt SEK y)": 32847,
    "out:Running Cost over RSP/MSEK": 13.868,
    "out:LCP/MSEK": -0.282,
    "out:ROI% old": 13.61,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243146,
    "out:DH kr savings": 236854,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454654,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.3,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.22,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 119.84,
    "out:Primary Energy": 115.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.93,
    "out:CO2 Operational/m2": 32.17,
    "out:Total CO2/m2": 54.09,
    "out:Total CO2 (tons)": 278.53,
    "out:Klimatpaverkan": -11.08,
    "out:Initial Cost/MSEK": 5.965,
    "out:Running cost/(Apt SEK y)": 32847,
    "out:Running Cost over RSP/MSEK": 13.868,
    "out:LCP/MSEK": -0.329,
    "out:ROI% old": 13.5,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 243146,
    "out:DH kr savings": 236854,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 474833,
    "out:% savings (space heating)": 25.34,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.22,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.3,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 115.84,
    "out:Primary Energy": 111.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.93,
    "out:CO2 Operational/m2": 30.73,
    "out:Total CO2/m2": 52.65,
    "out:Total CO2 (tons)": 271.11,
    "out:Klimatpaverkan": -18.5,
    "out:Initial Cost/MSEK": 6.094,
    "out:Running cost/(Apt SEK y)": 31695,
    "out:Running Cost over RSP/MSEK": 13.381,
    "out:LCP/MSEK": 0.029,
    "out:ROI% old": 14.35,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 263742,
    "out:DH kr savings": 257450,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454654,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.3,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.22,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 123.69,
    "out:Total Energy Use Post PV": 123.69,
    "out:Primary Energy": 122.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.59,
    "out:CO2 Operational/m2": 40.04,
    "out:Total CO2/m2": 54.63,
    "out:Total CO2 (tons)": 281.27,
    "out:Klimatpaverkan": -8.34,
    "out:Initial Cost/MSEK": 5.32,
    "out:Running cost/(Apt SEK y)": 33704,
    "out:Running Cost over RSP/MSEK": 14.217,
    "out:LCP/MSEK": -0.033,
    "out:ROI% old": 14.2,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 675,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 242003,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186468,
    "out:DH kr savings": 242003,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 474833,
    "out:% savings (space heating)": 25.34,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.22,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.3,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 119.69,
    "out:Primary Energy": 119.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.59,
    "out:CO2 Operational/m2": 38.6,
    "out:Total CO2/m2": 53.19,
    "out:Total CO2 (tons)": 273.85,
    "out:Klimatpaverkan": -15.76,
    "out:Initial Cost/MSEK": 5.448,
    "out:Running cost/(Apt SEK y)": 32552,
    "out:Running Cost over RSP/MSEK": 13.731,
    "out:LCP/MSEK": 0.325,
    "out:ROI% old": 15.14,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 738,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207064,
    "out:DH kr savings": 262599,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454654,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.3,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.22,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 119.69,
    "out:Primary Energy": 120.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.87,
    "out:CO2 Operational/m2": 38.6,
    "out:Total CO2/m2": 53.47,
    "out:Total CO2 (tons)": 275.3,
    "out:Klimatpaverkan": -14.31,
    "out:Initial Cost/MSEK": 5.495,
    "out:Running cost/(Apt SEK y)": 32552,
    "out:Running Cost over RSP/MSEK": 13.731,
    "out:LCP/MSEK": 0.278,
    "out:ROI% old": 15.01,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 738,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 207064,
    "out:DH kr savings": 262599,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 474833,
    "out:% savings (space heating)": 25.34,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.22,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.3,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 116.69,
    "out:Total Energy Use Post PV": 116.69,
    "out:Primary Energy": 117.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.87,
    "out:CO2 Operational/m2": 37.52,
    "out:Total CO2/m2": 52.39,
    "out:Total CO2 (tons)": 269.74,
    "out:Klimatpaverkan": -19.87,
    "out:Initial Cost/MSEK": 5.624,
    "out:Running cost/(Apt SEK y)": 31688,
    "out:Running Cost over RSP/MSEK": 13.366,
    "out:LCP/MSEK": 0.515,
    "out:ROI% old": 15.59,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222511,
    "out:DH kr savings": 278046,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454654,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.3,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.22,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 123.69,
    "out:Total Energy Use Post PV": 118.84,
    "out:Primary Energy": 114.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.65,
    "out:CO2 Operational/m2": 31.81,
    "out:Total CO2/m2": 53.45,
    "out:Total CO2 (tons)": 275.22,
    "out:Klimatpaverkan": -14.39,
    "out:Initial Cost/MSEK": 5.932,
    "out:Running cost/(Apt SEK y)": 32559,
    "out:Running Cost over RSP/MSEK": 13.746,
    "out:LCP/MSEK": -0.174,
    "out:ROI% old": 13.87,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 38.7,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 737,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 242003,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248295,
    "out:DH kr savings": 242003,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 474833,
    "out:% savings (space heating)": 25.34,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.22,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.3,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 114.84,
    "out:Primary Energy": 110.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.65,
    "out:CO2 Operational/m2": 30.37,
    "out:Total CO2/m2": 52.01,
    "out:Total CO2 (tons)": 267.81,
    "out:Klimatpaverkan": -21.8,
    "out:Initial Cost/MSEK": 6.061,
    "out:Running cost/(Apt SEK y)": 31407,
    "out:Running Cost over RSP/MSEK": 13.26,
    "out:LCP/MSEK": 0.184,
    "out:ROI% old": 14.72,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 799,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 268891,
    "out:DH kr savings": 262599,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454654,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.3,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.22,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 114.84,
    "out:Primary Energy": 112.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.93,
    "out:CO2 Operational/m2": 30.37,
    "out:Total CO2/m2": 52.29,
    "out:Total CO2 (tons)": 269.26,
    "out:Klimatpaverkan": -20.35,
    "out:Initial Cost/MSEK": 6.107,
    "out:Running cost/(Apt SEK y)": 31407,
    "out:Running Cost over RSP/MSEK": 13.26,
    "out:LCP/MSEK": 0.137,
    "out:ROI% old": 14.61,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 799,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268891,
    "out:DH kr savings": 262599,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 474833,
    "out:% savings (space heating)": 25.34,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.22,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.3,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 116.69,
    "out:Total Energy Use Post PV": 111.84,
    "out:Primary Energy": 108.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.93,
    "out:CO2 Operational/m2": 29.29,
    "out:Total CO2/m2": 51.21,
    "out:Total CO2 (tons)": 263.7,
    "out:Klimatpaverkan": -25.91,
    "out:Initial Cost/MSEK": 6.236,
    "out:Running cost/(Apt SEK y)": 30543,
    "out:Running Cost over RSP/MSEK": 12.895,
    "out:LCP/MSEK": 0.373,
    "out:ROI% old": 15.14,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 47.3,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 284338,
    "out:DH kr savings": 278046,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454654,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.3,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.32,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 118.3,
    "out:Total Energy Use Post PV": 118.3,
    "out:Primary Energy": 110.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.23,
    "out:CO2 Operational/m2": 39.33,
    "out:Total CO2/m2": 54.56,
    "out:Total CO2 (tons)": 280.92,
    "out:Klimatpaverkan": -8.69,
    "out:Initial Cost/MSEK": 5.674,
    "out:Running cost/(Apt SEK y)": 32675,
    "out:Running Cost over RSP/MSEK": 13.788,
    "out:LCP/MSEK": 0.043,
    "out:ROI% old": 14.39,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 731,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 242003,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 242003,
    "out:DH kr savings": 242003,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 398098,
    "out:% savings (space heating)": 37.4,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.32,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.07,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 115.3,
    "out:Total Energy Use Post PV": 115.3,
    "out:Primary Energy": 108.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.23,
    "out:CO2 Operational/m2": 38.25,
    "out:Total CO2/m2": 53.48,
    "out:Total CO2 (tons)": 275.36,
    "out:Klimatpaverkan": -14.25,
    "out:Initial Cost/MSEK": 5.802,
    "out:Running cost/(Apt SEK y)": 31811,
    "out:Running Cost over RSP/MSEK": 13.423,
    "out:LCP/MSEK": 0.279,
    "out:ROI% old": 14.97,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 777,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257450,
    "out:DH kr savings": 257450,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 381407,
    "out:% savings (space heating)": 40.03,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.07,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.32,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 114.3,
    "out:Total Energy Use Post PV": 114.3,
    "out:Primary Energy": 108.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.51,
    "out:CO2 Operational/m2": 37.89,
    "out:Total CO2/m2": 53.4,
    "out:Total CO2 (tons)": 274.96,
    "out:Klimatpaverkan": -14.65,
    "out:Initial Cost/MSEK": 5.849,
    "out:Running cost/(Apt SEK y)": 31523,
    "out:Running Cost over RSP/MSEK": 13.301,
    "out:LCP/MSEK": 0.354,
    "out:ROI% old": 15.15,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 44.7,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 793,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 262599,
    "out:DH kr savings": 262599,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 398098,
    "out:% savings (space heating)": 37.4,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.32,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.07,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 111.3,
    "out:Total Energy Use Post PV": 111.3,
    "out:Primary Energy": 105.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.51,
    "out:CO2 Operational/m2": 36.81,
    "out:Total CO2/m2": 52.32,
    "out:Total CO2 (tons)": 269.4,
    "out:Klimatpaverkan": -20.21,
    "out:Initial Cost/MSEK": 5.978,
    "out:Running cost/(Apt SEK y)": 30659,
    "out:Running Cost over RSP/MSEK": 12.936,
    "out:LCP/MSEK": 0.59,
    "out:ROI% old": 15.7,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 48.6,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 840,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 278046,
    "out:DH kr savings": 278046,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 381407,
    "out:% savings (space heating)": 40.03,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.07,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.32,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 118.3,
    "out:Total Energy Use Post PV": 114.29,
    "out:Primary Energy": 103.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.29,
    "out:CO2 Operational/m2": 26.9,
    "out:Total CO2/m2": 49.19,
    "out:Total CO2 (tons)": 253.27,
    "out:Klimatpaverkan": -36.34,
    "out:Initial Cost/MSEK": 6.286,
    "out:Running cost/(Apt SEK y)": 31570,
    "out:Running Cost over RSP/MSEK": 13.334,
    "out:LCP/MSEK": -0.115,
    "out:ROI% old": 14.02,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 44.7,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 791,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 242003,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 301661,
    "out:DH kr savings": 242003,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 398098,
    "out:% savings (space heating)": 37.4,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.32,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.07,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 115.3,
    "out:Total Energy Use Post PV": 111.29,
    "out:Primary Energy": 100.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.29,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 48.11,
    "out:Total CO2 (tons)": 247.71,
    "out:Klimatpaverkan": -41.9,
    "out:Initial Cost/MSEK": 6.414,
    "out:Running cost/(Apt SEK y)": 30706,
    "out:Running Cost over RSP/MSEK": 12.969,
    "out:LCP/MSEK": 0.121,
    "out:ROI% old": 14.56,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 48.6,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 837,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 317108,
    "out:DH kr savings": 257450,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 381407,
    "out:% savings (space heating)": 40.03,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.07,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.32,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 114.3,
    "out:Total Energy Use Post PV": 110.29,
    "out:Primary Energy": 101.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.57,
    "out:CO2 Operational/m2": 25.46,
    "out:Total CO2/m2": 48.03,
    "out:Total CO2 (tons)": 247.31,
    "out:Klimatpaverkan": -42.3,
    "out:Initial Cost/MSEK": 6.461,
    "out:Running cost/(Apt SEK y)": 30419,
    "out:Running Cost over RSP/MSEK": 12.847,
    "out:LCP/MSEK": 0.196,
    "out:ROI% old": 14.72,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 853,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 322257,
    "out:DH kr savings": 262599,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 398098,
    "out:% savings (space heating)": 37.4,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.32,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.07,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 111.3,
    "out:Total Energy Use Post PV": 107.29,
    "out:Primary Energy": 98.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.57,
    "out:CO2 Operational/m2": 24.38,
    "out:Total CO2/m2": 46.95,
    "out:Total CO2 (tons)": 241.75,
    "out:Klimatpaverkan": -47.86,
    "out:Initial Cost/MSEK": 6.59,
    "out:Running cost/(Apt SEK y)": 29555,
    "out:Running Cost over RSP/MSEK": 12.482,
    "out:LCP/MSEK": 0.432,
    "out:ROI% old": 15.22,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 54.2,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 899,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 337704,
    "out:DH kr savings": 278046,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 381407,
    "out:% savings (space heating)": 40.03,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.07,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.32,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 113.3,
    "out:Total Energy Use Post PV": 113.3,
    "out:Primary Energy": 107.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.23,
    "out:CO2 Operational/m2": 37.53,
    "out:Total CO2/m2": 52.76,
    "out:Total CO2 (tons)": 271.66,
    "out:Klimatpaverkan": -17.95,
    "out:Initial Cost/MSEK": 5.816,
    "out:Running cost/(Apt SEK y)": 31235,
    "out:Running Cost over RSP/MSEK": 13.18,
    "out:LCP/MSEK": 0.509,
    "out:ROI% old": 15.54,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 267748,
    "out:DH kr savings": 267748,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 398098,
    "out:% savings (space heating)": 37.4,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.32,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.07,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 110.3,
    "out:Primary Energy": 104.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.23,
    "out:CO2 Operational/m2": 36.45,
    "out:Total CO2/m2": 51.68,
    "out:Total CO2 (tons)": 266.1,
    "out:Klimatpaverkan": -23.51,
    "out:Initial Cost/MSEK": 5.945,
    "out:Running cost/(Apt SEK y)": 30371,
    "out:Running Cost over RSP/MSEK": 12.815,
    "out:LCP/MSEK": 0.745,
    "out:ROI% old": 16.08,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 855,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 283195,
    "out:DH kr savings": 283195,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 381407,
    "out:% savings (space heating)": 40.03,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.07,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.32,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 110.3,
    "out:Primary Energy": 105.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.51,
    "out:CO2 Operational/m2": 36.45,
    "out:Total CO2/m2": 51.96,
    "out:Total CO2 (tons)": 267.55,
    "out:Klimatpaverkan": -22.06,
    "out:Initial Cost/MSEK": 5.992,
    "out:Running cost/(Apt SEK y)": 30371,
    "out:Running Cost over RSP/MSEK": 12.815,
    "out:LCP/MSEK": 0.698,
    "out:ROI% old": 15.95,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 855,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 283195,
    "out:DH kr savings": 283195,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 398098,
    "out:% savings (space heating)": 37.4,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.32,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.07,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 107.3,
    "out:Total Energy Use Post PV": 107.3,
    "out:Primary Energy": 102.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.51,
    "out:CO2 Operational/m2": 35.37,
    "out:Total CO2/m2": 50.88,
    "out:Total CO2 (tons)": 261.99,
    "out:Klimatpaverkan": -27.62,
    "out:Initial Cost/MSEK": 6.12,
    "out:Running cost/(Apt SEK y)": 29508,
    "out:Running Cost over RSP/MSEK": 12.45,
    "out:LCP/MSEK": 0.934,
    "out:ROI% old": 16.47,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 54.2,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 15,
    "out:Return/kSEK/y": 902,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 298642,
    "out:DH kr savings": 298642,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 381407,
    "out:% savings (space heating)": 40.03,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.07,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.32,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 113.3,
    "out:Total Energy Use Post PV": 109.29,
    "out:Primary Energy": 100.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.29,
    "out:CO2 Operational/m2": 25.1,
    "out:Total CO2/m2": 47.39,
    "out:Total CO2 (tons)": 244.01,
    "out:Klimatpaverkan": -45.6,
    "out:Initial Cost/MSEK": 6.428,
    "out:Running cost/(Apt SEK y)": 30131,
    "out:Running Cost over RSP/MSEK": 12.725,
    "out:LCP/MSEK": 0.351,
    "out:ROI% old": 15.07,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 868,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 327406,
    "out:DH kr savings": 267748,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 398098,
    "out:% savings (space heating)": 37.4,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.32,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.07,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 106.29,
    "out:Primary Energy": 97.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.29,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 46.31,
    "out:Total CO2 (tons)": 238.45,
    "out:Klimatpaverkan": -51.16,
    "out:Initial Cost/MSEK": 6.557,
    "out:Running cost/(Apt SEK y)": 29267,
    "out:Running Cost over RSP/MSEK": 12.361,
    "out:LCP/MSEK": 0.587,
    "out:ROI% old": 15.56,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 915,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 342853,
    "out:DH kr savings": 283195,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 381407,
    "out:% savings (space heating)": 40.03,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.07,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.32,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 106.29,
    "out:Primary Energy": 98.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.57,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 46.59,
    "out:Total CO2 (tons)": 239.9,
    "out:Klimatpaverkan": -49.71,
    "out:Initial Cost/MSEK": 6.604,
    "out:Running cost/(Apt SEK y)": 29267,
    "out:Running Cost over RSP/MSEK": 12.361,
    "out:LCP/MSEK": 0.54,
    "out:ROI% old": 15.45,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 915,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 342853,
    "out:DH kr savings": 283195,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 398098,
    "out:% savings (space heating)": 37.4,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.32,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.07,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 107.3,
    "out:Total Energy Use Post PV": 103.29,
    "out:Primary Energy": 95.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.57,
    "out:CO2 Operational/m2": 22.94,
    "out:Total CO2/m2": 45.51,
    "out:Total CO2 (tons)": 234.34,
    "out:Klimatpaverkan": -55.27,
    "out:Initial Cost/MSEK": 6.732,
    "out:Running cost/(Apt SEK y)": 28403,
    "out:Running Cost over RSP/MSEK": 11.996,
    "out:LCP/MSEK": 0.776,
    "out:ROI% old": 15.93,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 60.2,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 962,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 358300,
    "out:DH kr savings": 298642,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 381407,
    "out:% savings (space heating)": 40.03,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.07,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.32,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 113.69,
    "out:Total Energy Use Post PV": 113.69,
    "out:Primary Energy": 113.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.58,
    "out:CO2 Operational/m2": 36.44,
    "out:Total CO2/m2": 54.01,
    "out:Total CO2 (tons)": 278.12,
    "out:Klimatpaverkan": -11.49,
    "out:Initial Cost/MSEK": 6.47,
    "out:Running cost/(Apt SEK y)": 30824,
    "out:Running Cost over RSP/MSEK": 13.001,
    "out:LCP/MSEK": 0.033,
    "out:ROI% old": 14.36,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 44.7,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 831,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293493,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 237958,
    "out:DH kr savings": 293493,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 398098,
    "out:% savings (space heating)": 37.4,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.32,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.07,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 110.69,
    "out:Total Energy Use Post PV": 110.69,
    "out:Primary Energy": 110.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.58,
    "out:CO2 Operational/m2": 35.36,
    "out:Total CO2/m2": 52.93,
    "out:Total CO2 (tons)": 272.56,
    "out:Klimatpaverkan": -17.05,
    "out:Initial Cost/MSEK": 6.599,
    "out:Running cost/(Apt SEK y)": 29960,
    "out:Running Cost over RSP/MSEK": 12.636,
    "out:LCP/MSEK": 0.269,
    "out:ROI% old": 14.87,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 48.6,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 877,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 253405,
    "out:DH kr savings": 308940,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 381407,
    "out:% savings (space heating)": 40.03,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.07,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.32,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 109.69,
    "out:Total Energy Use Post PV": 109.69,
    "out:Primary Energy": 110.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.86,
    "out:CO2 Operational/m2": 35,
    "out:Total CO2/m2": 52.86,
    "out:Total CO2 (tons)": 272.15,
    "out:Klimatpaverkan": -17.46,
    "out:Initial Cost/MSEK": 6.646,
    "out:Running cost/(Apt SEK y)": 29672,
    "out:Running Cost over RSP/MSEK": 12.514,
    "out:LCP/MSEK": 0.344,
    "out:ROI% old": 15.03,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 893,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 314089,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 258554,
    "out:DH kr savings": 314089,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 398098,
    "out:% savings (space heating)": 37.4,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.32,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.07,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 106.69,
    "out:Total Energy Use Post PV": 106.69,
    "out:Primary Energy": 107.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.86,
    "out:CO2 Operational/m2": 33.92,
    "out:Total CO2/m2": 51.78,
    "out:Total CO2 (tons)": 266.59,
    "out:Klimatpaverkan": -23.02,
    "out:Initial Cost/MSEK": 6.775,
    "out:Running cost/(Apt SEK y)": 28808,
    "out:Running Cost over RSP/MSEK": 12.149,
    "out:LCP/MSEK": 0.58,
    "out:ROI% old": 15.51,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 54.2,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 940,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 329536,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 274001,
    "out:DH kr savings": 329536,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 381407,
    "out:% savings (space heating)": 40.03,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.07,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.32,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 113.69,
    "out:Total Energy Use Post PV": 108.84,
    "out:Primary Energy": 104.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.64,
    "out:CO2 Operational/m2": 28.21,
    "out:Total CO2/m2": 52.84,
    "out:Total CO2 (tons)": 272.08,
    "out:Klimatpaverkan": -17.53,
    "out:Initial Cost/MSEK": 7.082,
    "out:Running cost/(Apt SEK y)": 29679,
    "out:Running Cost over RSP/MSEK": 12.53,
    "out:LCP/MSEK": -0.108,
    "out:ROI% old": 14.07,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 893,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293493,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 299785,
    "out:DH kr savings": 293493,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 398098,
    "out:% savings (space heating)": 37.4,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.32,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.07,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 110.69,
    "out:Total Energy Use Post PV": 105.84,
    "out:Primary Energy": 101.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.64,
    "out:CO2 Operational/m2": 27.13,
    "out:Total CO2/m2": 51.76,
    "out:Total CO2 (tons)": 266.52,
    "out:Klimatpaverkan": -23.09,
    "out:Initial Cost/MSEK": 7.211,
    "out:Running cost/(Apt SEK y)": 28815,
    "out:Running Cost over RSP/MSEK": 12.165,
    "out:LCP/MSEK": 0.128,
    "out:ROI% old": 14.54,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 939,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 315232,
    "out:DH kr savings": 308940,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 381407,
    "out:% savings (space heating)": 40.03,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.07,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.32,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 109.69,
    "out:Total Energy Use Post PV": 104.84,
    "out:Primary Energy": 102.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.92,
    "out:CO2 Operational/m2": 26.77,
    "out:Total CO2/m2": 51.68,
    "out:Total CO2 (tons)": 266.11,
    "out:Klimatpaverkan": -23.5,
    "out:Initial Cost/MSEK": 7.258,
    "out:Running cost/(Apt SEK y)": 28527,
    "out:Running Cost over RSP/MSEK": 12.044,
    "out:LCP/MSEK": 0.203,
    "out:ROI% old": 14.68,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 955,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 314089,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 320381,
    "out:DH kr savings": 314089,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 398098,
    "out:% savings (space heating)": 37.4,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.32,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.07,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 106.69,
    "out:Total Energy Use Post PV": 101.84,
    "out:Primary Energy": 99.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.92,
    "out:CO2 Operational/m2": 25.69,
    "out:Total CO2/m2": 50.6,
    "out:Total CO2 (tons)": 260.55,
    "out:Klimatpaverkan": -29.06,
    "out:Initial Cost/MSEK": 7.387,
    "out:Running cost/(Apt SEK y)": 27663,
    "out:Running Cost over RSP/MSEK": 11.679,
    "out:LCP/MSEK": 0.439,
    "out:ROI% old": 15.13,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 61.8,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1001,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 329536,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 335828,
    "out:DH kr savings": 329536,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 381407,
    "out:% savings (space heating)": 40.03,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.07,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.32,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 108.69,
    "out:Total Energy Use Post PV": 108.69,
    "out:Primary Energy": 109.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.58,
    "out:CO2 Operational/m2": 34.64,
    "out:Total CO2/m2": 52.21,
    "out:Total CO2 (tons)": 268.85,
    "out:Klimatpaverkan": -20.76,
    "out:Initial Cost/MSEK": 6.613,
    "out:Running cost/(Apt SEK y)": 29384,
    "out:Running Cost over RSP/MSEK": 12.393,
    "out:LCP/MSEK": 0.499,
    "out:ROI% old": 15.36,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 909,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 319238,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263703,
    "out:DH kr savings": 319238,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 398098,
    "out:% savings (space heating)": 37.4,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.32,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.07,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 105.69,
    "out:Total Energy Use Post PV": 105.69,
    "out:Primary Energy": 107.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.58,
    "out:CO2 Operational/m2": 33.56,
    "out:Total CO2/m2": 51.13,
    "out:Total CO2 (tons)": 263.29,
    "out:Klimatpaverkan": -26.32,
    "out:Initial Cost/MSEK": 6.742,
    "out:Running cost/(Apt SEK y)": 28520,
    "out:Running Cost over RSP/MSEK": 12.028,
    "out:LCP/MSEK": 0.735,
    "out:ROI% old": 15.84,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 955,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 334685,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279150,
    "out:DH kr savings": 334685,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 381407,
    "out:% savings (space heating)": 40.03,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.07,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.32,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 105.69,
    "out:Total Energy Use Post PV": 105.69,
    "out:Primary Energy": 107.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.86,
    "out:CO2 Operational/m2": 33.56,
    "out:Total CO2/m2": 51.42,
    "out:Total CO2 (tons)": 264.74,
    "out:Klimatpaverkan": -24.87,
    "out:Initial Cost/MSEK": 6.789,
    "out:Running cost/(Apt SEK y)": 28520,
    "out:Running Cost over RSP/MSEK": 12.028,
    "out:LCP/MSEK": 0.688,
    "out:ROI% old": 15.73,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 955,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 334685,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 279150,
    "out:DH kr savings": 334685,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 398098,
    "out:% savings (space heating)": 37.4,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.32,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.07,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 101.69,
    "out:Total Energy Use Post PV": 101.69,
    "out:Primary Energy": 105.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.86,
    "out:CO2 Operational/m2": 32.12,
    "out:Total CO2/m2": 49.98,
    "out:Total CO2 (tons)": 257.33,
    "out:Klimatpaverkan": -32.28,
    "out:Initial Cost/MSEK": 6.917,
    "out:Running cost/(Apt SEK y)": 27368,
    "out:Running Cost over RSP/MSEK": 11.541,
    "out:LCP/MSEK": 1.046,
    "out:ROI% old": 16.45,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 61.8,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1017,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 355281,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 299746,
    "out:DH kr savings": 355281,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 381407,
    "out:% savings (space heating)": 40.03,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.07,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.32,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 108.69,
    "out:Total Energy Use Post PV": 103.84,
    "out:Primary Energy": 101.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.64,
    "out:CO2 Operational/m2": 26.41,
    "out:Total CO2/m2": 51.04,
    "out:Total CO2 (tons)": 262.81,
    "out:Klimatpaverkan": -26.8,
    "out:Initial Cost/MSEK": 7.225,
    "out:Running cost/(Apt SEK y)": 28239,
    "out:Running Cost over RSP/MSEK": 11.922,
    "out:LCP/MSEK": 0.357,
    "out:ROI% old": 14.99,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 970,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 319238,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 325530,
    "out:DH kr savings": 319238,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 398098,
    "out:% savings (space heating)": 37.4,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.32,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.07,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 105.69,
    "out:Total Energy Use Post PV": 100.84,
    "out:Primary Energy": 98.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.64,
    "out:CO2 Operational/m2": 25.33,
    "out:Total CO2/m2": 49.96,
    "out:Total CO2 (tons)": 257.25,
    "out:Klimatpaverkan": -32.36,
    "out:Initial Cost/MSEK": 7.354,
    "out:Running cost/(Apt SEK y)": 27375,
    "out:Running Cost over RSP/MSEK": 11.557,
    "out:LCP/MSEK": 0.594,
    "out:ROI% old": 15.44,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1017,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 334685,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 340977,
    "out:DH kr savings": 334685,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 381407,
    "out:% savings (space heating)": 40.03,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.07,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.32,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 105.69,
    "out:Total Energy Use Post PV": 100.84,
    "out:Primary Energy": 99.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.92,
    "out:CO2 Operational/m2": 25.33,
    "out:Total CO2/m2": 50.24,
    "out:Total CO2 (tons)": 258.7,
    "out:Klimatpaverkan": -30.91,
    "out:Initial Cost/MSEK": 7.401,
    "out:Running cost/(Apt SEK y)": 27375,
    "out:Running Cost over RSP/MSEK": 11.557,
    "out:LCP/MSEK": 0.547,
    "out:ROI% old": 15.34,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1017,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 334685,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 340977,
    "out:DH kr savings": 334685,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 398098,
    "out:% savings (space heating)": 37.4,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.32,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.07,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 101.69,
    "out:Total Energy Use Post PV": 96.84,
    "out:Primary Energy": 96.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.92,
    "out:CO2 Operational/m2": 23.89,
    "out:Total CO2/m2": 48.8,
    "out:Total CO2 (tons)": 251.28,
    "out:Klimatpaverkan": -38.33,
    "out:Initial Cost/MSEK": 7.529,
    "out:Running cost/(Apt SEK y)": 26223,
    "out:Running Cost over RSP/MSEK": 11.071,
    "out:LCP/MSEK": 0.904,
    "out:ROI% old": 16,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 70.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1079,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 355281,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 361573,
    "out:DH kr savings": 355281,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 381407,
    "out:% savings (space heating)": 40.03,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.07,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.57,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 114.3,
    "out:Total Energy Use Post PV": 114.3,
    "out:Primary Energy": 106.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.79,
    "out:CO2 Operational/m2": 37.89,
    "out:Total CO2/m2": 62.68,
    "out:Total CO2 (tons)": 322.74,
    "out:Klimatpaverkan": 33.13,
    "out:Initial Cost/MSEK": 8.84,
    "out:Running cost/(Apt SEK y)": 31523,
    "out:Running Cost over RSP/MSEK": 13.301,
    "out:LCP/MSEK": -2.637,
    "out:ROI% old": 10.02,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 44.7,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 793,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 262599,
    "out:DH kr savings": 262599,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373647,
    "out:% savings (space heating)": 41.25,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.57,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.54,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 111.3,
    "out:Total Energy Use Post PV": 111.3,
    "out:Primary Energy": 104.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.79,
    "out:CO2 Operational/m2": 36.81,
    "out:Total CO2/m2": 61.6,
    "out:Total CO2 (tons)": 317.18,
    "out:Klimatpaverkan": 27.57,
    "out:Initial Cost/MSEK": 8.969,
    "out:Running cost/(Apt SEK y)": 30659,
    "out:Running Cost over RSP/MSEK": 12.936,
    "out:LCP/MSEK": -2.401,
    "out:ROI% old": 10.46,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 48.6,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 840,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 278046,
    "out:DH kr savings": 278046,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 358067,
    "out:% savings (space heating)": 43.7,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.54,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.57,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 110.3,
    "out:Primary Energy": 104.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.08,
    "out:CO2 Operational/m2": 36.45,
    "out:Total CO2/m2": 61.52,
    "out:Total CO2 (tons)": 316.78,
    "out:Klimatpaverkan": 27.17,
    "out:Initial Cost/MSEK": 9.015,
    "out:Running cost/(Apt SEK y)": 30371,
    "out:Running Cost over RSP/MSEK": 12.815,
    "out:LCP/MSEK": -2.326,
    "out:ROI% old": 10.6,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 855,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 283195,
    "out:DH kr savings": 283195,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373647,
    "out:% savings (space heating)": 41.25,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.57,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.54,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 107.3,
    "out:Total Energy Use Post PV": 107.3,
    "out:Primary Energy": 101.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.08,
    "out:CO2 Operational/m2": 35.37,
    "out:Total CO2/m2": 60.44,
    "out:Total CO2 (tons)": 311.22,
    "out:Klimatpaverkan": 21.61,
    "out:Initial Cost/MSEK": 9.144,
    "out:Running cost/(Apt SEK y)": 29508,
    "out:Running Cost over RSP/MSEK": 12.45,
    "out:LCP/MSEK": -2.09,
    "out:ROI% old": 11.02,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 54.2,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 902,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 298642,
    "out:DH kr savings": 298642,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 358067,
    "out:% savings (space heating)": 43.7,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.54,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.57,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 114.3,
    "out:Total Energy Use Post PV": 110.29,
    "out:Primary Energy": 99.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.85,
    "out:CO2 Operational/m2": 25.46,
    "out:Total CO2/m2": 57.31,
    "out:Total CO2 (tons)": 295.1,
    "out:Klimatpaverkan": 5.49,
    "out:Initial Cost/MSEK": 9.452,
    "out:Running cost/(Apt SEK y)": 30419,
    "out:Running Cost over RSP/MSEK": 12.847,
    "out:LCP/MSEK": -2.795,
    "out:ROI% old": 10.06,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 853,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 322257,
    "out:DH kr savings": 262599,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373647,
    "out:% savings (space heating)": 41.25,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.57,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.54,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 111.3,
    "out:Total Energy Use Post PV": 107.29,
    "out:Primary Energy": 96.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.85,
    "out:CO2 Operational/m2": 24.38,
    "out:Total CO2/m2": 56.23,
    "out:Total CO2 (tons)": 289.53,
    "out:Klimatpaverkan": -0.08,
    "out:Initial Cost/MSEK": 9.581,
    "out:Running cost/(Apt SEK y)": 29555,
    "out:Running Cost over RSP/MSEK": 12.482,
    "out:LCP/MSEK": -2.558,
    "out:ROI% old": 10.47,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 54.2,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 899,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 337704,
    "out:DH kr savings": 278046,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 358067,
    "out:% savings (space heating)": 43.7,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.54,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.57,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 106.29,
    "out:Primary Energy": 96.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.14,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 56.15,
    "out:Total CO2 (tons)": 289.13,
    "out:Klimatpaverkan": -0.48,
    "out:Initial Cost/MSEK": 9.627,
    "out:Running cost/(Apt SEK y)": 29267,
    "out:Running Cost over RSP/MSEK": 12.361,
    "out:LCP/MSEK": -2.484,
    "out:ROI% old": 10.6,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 915,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 342853,
    "out:DH kr savings": 283195,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373647,
    "out:% savings (space heating)": 41.25,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.57,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.54,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 107.3,
    "out:Total Energy Use Post PV": 103.29,
    "out:Primary Energy": 94.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.14,
    "out:CO2 Operational/m2": 22.94,
    "out:Total CO2/m2": 55.07,
    "out:Total CO2 (tons)": 283.57,
    "out:Klimatpaverkan": -6.04,
    "out:Initial Cost/MSEK": 9.756,
    "out:Running cost/(Apt SEK y)": 28403,
    "out:Running Cost over RSP/MSEK": 11.996,
    "out:LCP/MSEK": -2.248,
    "out:ROI% old": 10.99,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 60.2,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 962,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 358300,
    "out:DH kr savings": 298642,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 358067,
    "out:% savings (space heating)": 43.7,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.54,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.57,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 108.3,
    "out:Primary Energy": 103.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.79,
    "out:CO2 Operational/m2": 35.73,
    "out:Total CO2/m2": 60.52,
    "out:Total CO2 (tons)": 311.62,
    "out:Klimatpaverkan": 22.01,
    "out:Initial Cost/MSEK": 8.982,
    "out:Running cost/(Apt SEK y)": 29796,
    "out:Running Cost over RSP/MSEK": 12.571,
    "out:LCP/MSEK": -2.049,
    "out:ROI% old": 11.03,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 886,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 293493,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 293493,
    "out:DH kr savings": 293493,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373647,
    "out:% savings (space heating)": 41.25,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.57,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.54,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 105.3,
    "out:Primary Energy": 100.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.79,
    "out:CO2 Operational/m2": 34.65,
    "out:Total CO2/m2": 59.44,
    "out:Total CO2 (tons)": 306.06,
    "out:Klimatpaverkan": 16.45,
    "out:Initial Cost/MSEK": 9.111,
    "out:Running cost/(Apt SEK y)": 28932,
    "out:Running Cost over RSP/MSEK": 12.207,
    "out:LCP/MSEK": -1.813,
    "out:ROI% old": 11.44,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 933,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 308940,
    "out:DH kr savings": 308940,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 358067,
    "out:% savings (space heating)": 43.7,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.54,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.57,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 105.3,
    "out:Primary Energy": 101.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.08,
    "out:CO2 Operational/m2": 34.65,
    "out:Total CO2/m2": 59.72,
    "out:Total CO2 (tons)": 307.51,
    "out:Klimatpaverkan": 17.9,
    "out:Initial Cost/MSEK": 9.158,
    "out:Running cost/(Apt SEK y)": 28932,
    "out:Running Cost over RSP/MSEK": 12.207,
    "out:LCP/MSEK": -1.86,
    "out:ROI% old": 11.38,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 933,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 308940,
    "out:DH kr savings": 308940,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373647,
    "out:% savings (space heating)": 41.25,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.57,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.54,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 102.3,
    "out:Total Energy Use Post PV": 102.3,
    "out:Primary Energy": 98.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.08,
    "out:CO2 Operational/m2": 33.57,
    "out:Total CO2/m2": 58.64,
    "out:Total CO2 (tons)": 301.95,
    "out:Klimatpaverkan": 12.34,
    "out:Initial Cost/MSEK": 9.287,
    "out:Running cost/(Apt SEK y)": 28068,
    "out:Running Cost over RSP/MSEK": 11.842,
    "out:LCP/MSEK": -1.624,
    "out:ROI% old": 11.79,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 61.8,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 980,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 324387,
    "out:DH kr savings": 324387,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 358067,
    "out:% savings (space heating)": 43.7,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.54,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01002200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.57,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 104.29,
    "out:Primary Energy": 96.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.85,
    "out:CO2 Operational/m2": 23.3,
    "out:Total CO2/m2": 55.15,
    "out:Total CO2 (tons)": 283.97,
    "out:Klimatpaverkan": -5.64,
    "out:Initial Cost/MSEK": 9.594,
    "out:Running cost/(Apt SEK y)": 28691,
    "out:Running Cost over RSP/MSEK": 12.117,
    "out:LCP/MSEK": -2.207,
    "out:ROI% old": 11,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 946,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293493,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 353151,
    "out:DH kr savings": 293493,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373647,
    "out:% savings (space heating)": 41.25,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.57,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.54,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 101.29,
    "out:Primary Energy": 93.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.85,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 54.07,
    "out:Total CO2 (tons)": 278.41,
    "out:Klimatpaverkan": -11.2,
    "out:Initial Cost/MSEK": 9.723,
    "out:Running cost/(Apt SEK y)": 27827,
    "out:Running Cost over RSP/MSEK": 11.752,
    "out:LCP/MSEK": -1.971,
    "out:ROI% old": 11.39,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 993,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 368598,
    "out:DH kr savings": 308940,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 358067,
    "out:% savings (space heating)": 43.7,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.54,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.57,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 101.29,
    "out:Primary Energy": 93.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.14,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 54.35,
    "out:Total CO2 (tons)": 279.86,
    "out:Klimatpaverkan": -9.75,
    "out:Initial Cost/MSEK": 9.77,
    "out:Running cost/(Apt SEK y)": 27827,
    "out:Running Cost over RSP/MSEK": 11.752,
    "out:LCP/MSEK": -2.018,
    "out:ROI% old": 11.33,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 993,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 368598,
    "out:DH kr savings": 308940,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373647,
    "out:% savings (space heating)": 41.25,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.57,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.54,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 102.3,
    "out:Total Energy Use Post PV": 98.29,
    "out:Primary Energy": 91.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.14,
    "out:CO2 Operational/m2": 21.14,
    "out:Total CO2/m2": 53.27,
    "out:Total CO2 (tons)": 274.3,
    "out:Klimatpaverkan": -15.31,
    "out:Initial Cost/MSEK": 9.899,
    "out:Running cost/(Apt SEK y)": 26963,
    "out:Running Cost over RSP/MSEK": 11.388,
    "out:LCP/MSEK": -1.782,
    "out:ROI% old": 11.71,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 68.4,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1039,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 384044,
    "out:DH kr savings": 324387,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 358067,
    "out:% savings (space heating)": 43.7,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.54,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01002201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.57,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 108.69,
    "out:Total Energy Use Post PV": 108.69,
    "out:Primary Energy": 109.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.14,
    "out:CO2 Operational/m2": 34.64,
    "out:Total CO2/m2": 61.78,
    "out:Total CO2 (tons)": 318.09,
    "out:Klimatpaverkan": 28.48,
    "out:Initial Cost/MSEK": 9.637,
    "out:Running cost/(Apt SEK y)": 29384,
    "out:Running Cost over RSP/MSEK": 12.393,
    "out:LCP/MSEK": -2.525,
    "out:ROI% old": 10.54,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 909,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 319238,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263703,
    "out:DH kr savings": 319238,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373647,
    "out:% savings (space heating)": 41.25,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.57,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.54,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 105.69,
    "out:Total Energy Use Post PV": 105.69,
    "out:Primary Energy": 106.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.14,
    "out:CO2 Operational/m2": 33.56,
    "out:Total CO2/m2": 60.7,
    "out:Total CO2 (tons)": 312.53,
    "out:Klimatpaverkan": 22.92,
    "out:Initial Cost/MSEK": 9.765,
    "out:Running cost/(Apt SEK y)": 28520,
    "out:Running Cost over RSP/MSEK": 12.028,
    "out:LCP/MSEK": -2.289,
    "out:ROI% old": 10.94,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 955,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 334685,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279150,
    "out:DH kr savings": 334685,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 358067,
    "out:% savings (space heating)": 43.7,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.54,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.57,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 104.69,
    "out:Total Energy Use Post PV": 104.69,
    "out:Primary Energy": 106.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.42,
    "out:CO2 Operational/m2": 33.2,
    "out:Total CO2/m2": 60.62,
    "out:Total CO2 (tons)": 312.12,
    "out:Klimatpaverkan": 22.51,
    "out:Initial Cost/MSEK": 9.812,
    "out:Running cost/(Apt SEK y)": 28232,
    "out:Running Cost over RSP/MSEK": 11.906,
    "out:LCP/MSEK": -2.214,
    "out:ROI% old": 11.06,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 971,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 284299,
    "out:DH kr savings": 339834,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373647,
    "out:% savings (space heating)": 41.25,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.57,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.54,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 101.69,
    "out:Total Energy Use Post PV": 101.69,
    "out:Primary Energy": 104.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.42,
    "out:CO2 Operational/m2": 32.12,
    "out:Total CO2/m2": 59.54,
    "out:Total CO2 (tons)": 306.56,
    "out:Klimatpaverkan": 16.95,
    "out:Initial Cost/MSEK": 9.941,
    "out:Running cost/(Apt SEK y)": 27368,
    "out:Running Cost over RSP/MSEK": 11.541,
    "out:LCP/MSEK": -1.978,
    "out:ROI% old": 11.44,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 61.8,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1017,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 355281,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 299746,
    "out:DH kr savings": 355281,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 358067,
    "out:% savings (space heating)": 43.7,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.54,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.57,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 108.69,
    "out:Total Energy Use Post PV": 103.84,
    "out:Primary Energy": 100.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.2,
    "out:CO2 Operational/m2": 26.41,
    "out:Total CO2/m2": 60.6,
    "out:Total CO2 (tons)": 312.04,
    "out:Klimatpaverkan": 22.43,
    "out:Initial Cost/MSEK": 10.249,
    "out:Running cost/(Apt SEK y)": 28239,
    "out:Running Cost over RSP/MSEK": 11.922,
    "out:LCP/MSEK": -2.666,
    "out:ROI% old": 10.57,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 970,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 319238,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 325530,
    "out:DH kr savings": 319238,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373647,
    "out:% savings (space heating)": 41.25,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.57,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.54,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 105.69,
    "out:Total Energy Use Post PV": 100.84,
    "out:Primary Energy": 97.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.2,
    "out:CO2 Operational/m2": 25.33,
    "out:Total CO2/m2": 59.52,
    "out:Total CO2 (tons)": 306.48,
    "out:Klimatpaverkan": 16.87,
    "out:Initial Cost/MSEK": 10.377,
    "out:Running cost/(Apt SEK y)": 27375,
    "out:Running Cost over RSP/MSEK": 11.557,
    "out:LCP/MSEK": -2.43,
    "out:ROI% old": 10.94,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1017,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 334685,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 340977,
    "out:DH kr savings": 334685,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 358067,
    "out:% savings (space heating)": 43.7,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.54,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.57,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 104.69,
    "out:Total Energy Use Post PV": 99.84,
    "out:Primary Energy": 97.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.48,
    "out:CO2 Operational/m2": 24.97,
    "out:Total CO2/m2": 59.44,
    "out:Total CO2 (tons)": 306.08,
    "out:Klimatpaverkan": 16.47,
    "out:Initial Cost/MSEK": 10.424,
    "out:Running cost/(Apt SEK y)": 27087,
    "out:Running Cost over RSP/MSEK": 11.435,
    "out:LCP/MSEK": -2.355,
    "out:ROI% old": 11.06,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 65,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 346126,
    "out:DH kr savings": 339834,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373647,
    "out:% savings (space heating)": 41.25,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.57,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.54,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 101.69,
    "out:Total Energy Use Post PV": 96.84,
    "out:Primary Energy": 95.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.48,
    "out:CO2 Operational/m2": 23.89,
    "out:Total CO2/m2": 58.36,
    "out:Total CO2 (tons)": 300.52,
    "out:Klimatpaverkan": 10.91,
    "out:Initial Cost/MSEK": 10.553,
    "out:Running cost/(Apt SEK y)": 26223,
    "out:Running Cost over RSP/MSEK": 11.071,
    "out:LCP/MSEK": -2.119,
    "out:ROI% old": 11.42,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 70.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1079,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 355281,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 361573,
    "out:DH kr savings": 355281,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 358067,
    "out:% savings (space heating)": 43.7,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.54,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.57,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 103.69,
    "out:Total Energy Use Post PV": 103.69,
    "out:Primary Energy": 105.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.14,
    "out:CO2 Operational/m2": 32.84,
    "out:Total CO2/m2": 59.98,
    "out:Total CO2 (tons)": 308.82,
    "out:Klimatpaverkan": 19.21,
    "out:Initial Cost/MSEK": 9.779,
    "out:Running cost/(Apt SEK y)": 27944,
    "out:Running Cost over RSP/MSEK": 11.784,
    "out:LCP/MSEK": -2.059,
    "out:ROI% old": 11.28,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 986,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 344983,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 289448,
    "out:DH kr savings": 344983,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373647,
    "out:% savings (space heating)": 41.25,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.57,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.54,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 100.69,
    "out:Total Energy Use Post PV": 100.69,
    "out:Primary Energy": 103.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.14,
    "out:CO2 Operational/m2": 31.76,
    "out:Total CO2/m2": 58.9,
    "out:Total CO2 (tons)": 303.26,
    "out:Klimatpaverkan": 13.65,
    "out:Initial Cost/MSEK": 9.908,
    "out:Running cost/(Apt SEK y)": 27080,
    "out:Running Cost over RSP/MSEK": 11.42,
    "out:LCP/MSEK": -1.823,
    "out:ROI% old": 11.66,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360430,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304895,
    "out:DH kr savings": 360430,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 358067,
    "out:% savings (space heating)": 43.7,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.54,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.57,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 100.69,
    "out:Total Energy Use Post PV": 100.69,
    "out:Primary Energy": 103.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.42,
    "out:CO2 Operational/m2": 31.76,
    "out:Total CO2/m2": 59.18,
    "out:Total CO2 (tons)": 304.71,
    "out:Klimatpaverkan": 15.1,
    "out:Initial Cost/MSEK": 9.955,
    "out:Running cost/(Apt SEK y)": 27080,
    "out:Running Cost over RSP/MSEK": 11.42,
    "out:LCP/MSEK": -1.87,
    "out:ROI% old": 11.6,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360430,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 304895,
    "out:DH kr savings": 360430,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373647,
    "out:% savings (space heating)": 41.25,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.57,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.54,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 97.69,
    "out:Total Energy Use Post PV": 97.69,
    "out:Primary Energy": 101.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.42,
    "out:CO2 Operational/m2": 30.68,
    "out:Total CO2/m2": 58.1,
    "out:Total CO2 (tons)": 299.15,
    "out:Klimatpaverkan": 9.54,
    "out:Initial Cost/MSEK": 10.083,
    "out:Running cost/(Apt SEK y)": 26217,
    "out:Running Cost over RSP/MSEK": 11.055,
    "out:LCP/MSEK": -1.634,
    "out:ROI% old": 11.97,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 68.4,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1080,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 375877,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 320342,
    "out:DH kr savings": 375877,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 358067,
    "out:% savings (space heating)": 43.7,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.54,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01002201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.57,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 103.69,
    "out:Total Energy Use Post PV": 98.84,
    "out:Primary Energy": 97.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.2,
    "out:CO2 Operational/m2": 24.61,
    "out:Total CO2/m2": 58.8,
    "out:Total CO2 (tons)": 302.78,
    "out:Klimatpaverkan": 13.17,
    "out:Initial Cost/MSEK": 10.391,
    "out:Running cost/(Apt SEK y)": 26799,
    "out:Running Cost over RSP/MSEK": 11.314,
    "out:LCP/MSEK": -2.201,
    "out:ROI% old": 11.26,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 66.7,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1048,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 344983,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 351275,
    "out:DH kr savings": 344983,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373647,
    "out:% savings (space heating)": 41.25,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.57,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.54,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 100.69,
    "out:Total Energy Use Post PV": 95.84,
    "out:Primary Energy": 94.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.2,
    "out:CO2 Operational/m2": 23.53,
    "out:Total CO2/m2": 57.72,
    "out:Total CO2 (tons)": 297.21,
    "out:Klimatpaverkan": 7.6,
    "out:Initial Cost/MSEK": 10.52,
    "out:Running cost/(Apt SEK y)": 25936,
    "out:Running Cost over RSP/MSEK": 10.949,
    "out:LCP/MSEK": -1.965,
    "out:ROI% old": 11.62,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 71.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1095,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360430,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 366722,
    "out:DH kr savings": 360430,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 358067,
    "out:% savings (space heating)": 43.7,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.54,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.57,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 100.69,
    "out:Total Energy Use Post PV": 95.84,
    "out:Primary Energy": 94.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.48,
    "out:CO2 Operational/m2": 23.53,
    "out:Total CO2/m2": 58,
    "out:Total CO2 (tons)": 298.66,
    "out:Klimatpaverkan": 9.05,
    "out:Initial Cost/MSEK": 10.567,
    "out:Running cost/(Apt SEK y)": 25936,
    "out:Running Cost over RSP/MSEK": 10.949,
    "out:LCP/MSEK": -2.011,
    "out:ROI% old": 11.57,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 71.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1095,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360430,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 366722,
    "out:DH kr savings": 360430,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 373647,
    "out:% savings (space heating)": 41.25,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.57,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01002201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.54,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 97.69,
    "out:Total Energy Use Post PV": 92.84,
    "out:Primary Energy": 92.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.48,
    "out:CO2 Operational/m2": 22.45,
    "out:Total CO2/m2": 56.92,
    "out:Total CO2 (tons)": 293.1,
    "out:Klimatpaverkan": 3.49,
    "out:Initial Cost/MSEK": 10.696,
    "out:Running cost/(Apt SEK y)": 25072,
    "out:Running Cost over RSP/MSEK": 10.584,
    "out:LCP/MSEK": -1.775,
    "out:ROI% old": 11.91,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 77.4,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1141,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 375877,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 382169,
    "out:DH kr savings": 375877,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 358067,
    "out:% savings (space heating)": 43.7,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.54,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 144.3,
    "out:Total Energy Use Post PV": 144.3,
    "out:Primary Energy": 133.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.24,
    "out:CO2 Operational/m2": 48.69,
    "out:Total CO2/m2": 59.92,
    "out:Total CO2 (tons)": 308.55,
    "out:Klimatpaverkan": 18.94,
    "out:Initial Cost/MSEK": 4.126,
    "out:Running cost/(Apt SEK y)": 40162,
    "out:Running Cost over RSP/MSEK": 16.95,
    "out:LCP/MSEK": -1.572,
    "out:ROI% old": 8.84,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 14.6,
    "out:Op energy cost/MSEK": 124,
    "out:El price/MSEK": 76,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 8,
    "out:Return/kSEK/y": 327,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 108129,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108129,
    "out:DH kr savings": 108129,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 140.3,
    "out:Primary Energy": 129.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.24,
    "out:CO2 Operational/m2": 47.25,
    "out:Total CO2/m2": 58.48,
    "out:Total CO2 (tons)": 301.13,
    "out:Klimatpaverkan": 11.52,
    "out:Initial Cost/MSEK": 4.255,
    "out:Running cost/(Apt SEK y)": 39010,
    "out:Running Cost over RSP/MSEK": 16.464,
    "out:LCP/MSEK": -1.214,
    "out:ROI% old": 10.21,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128725,
    "out:DH kr savings": 128725,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 140.3,
    "out:Primary Energy": 130.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.52,
    "out:CO2 Operational/m2": 47.25,
    "out:Total CO2/m2": 58.77,
    "out:Total CO2 (tons)": 302.58,
    "out:Klimatpaverkan": 12.97,
    "out:Initial Cost/MSEK": 4.302,
    "out:Running cost/(Apt SEK y)": 39010,
    "out:Running Cost over RSP/MSEK": 16.464,
    "out:LCP/MSEK": -1.261,
    "out:ROI% old": 10.1,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 120,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128725,
    "out:DH kr savings": 128725,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 135.3,
    "out:Primary Energy": 126.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.52,
    "out:CO2 Operational/m2": 45.45,
    "out:Total CO2/m2": 56.97,
    "out:Total CO2 (tons)": 293.32,
    "out:Klimatpaverkan": 3.71,
    "out:Initial Cost/MSEK": 4.43,
    "out:Running cost/(Apt SEK y)": 37571,
    "out:Running Cost over RSP/MSEK": 15.855,
    "out:LCP/MSEK": -0.782,
    "out:ROI% old": 11.77,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154470,
    "out:DH kr savings": 154470,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 144.3,
    "out:Total Energy Use Post PV": 140.29,
    "out:Primary Energy": 125.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.3,
    "out:CO2 Operational/m2": 36.26,
    "out:Total CO2/m2": 54.55,
    "out:Total CO2 (tons)": 280.9,
    "out:Klimatpaverkan": -8.71,
    "out:Initial Cost/MSEK": 4.738,
    "out:Running cost/(Apt SEK y)": 39057,
    "out:Running Cost over RSP/MSEK": 16.496,
    "out:LCP/MSEK": -1.73,
    "out:ROI% old": 9.07,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 121,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 41,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 108129,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167787,
    "out:DH kr savings": 108129,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 136.29,
    "out:Primary Energy": 122.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.3,
    "out:CO2 Operational/m2": 34.82,
    "out:Total CO2/m2": 53.11,
    "out:Total CO2 (tons)": 273.48,
    "out:Klimatpaverkan": -16.13,
    "out:Initial Cost/MSEK": 4.867,
    "out:Running cost/(Apt SEK y)": 37906,
    "out:Running Cost over RSP/MSEK": 16.009,
    "out:LCP/MSEK": -1.372,
    "out:ROI% old": 10.26,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188383,
    "out:DH kr savings": 128725,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 140.3,
    "out:Total Energy Use Post PV": 136.29,
    "out:Primary Energy": 123.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.58,
    "out:CO2 Operational/m2": 34.82,
    "out:Total CO2/m2": 53.4,
    "out:Total CO2 (tons)": 274.93,
    "out:Klimatpaverkan": -14.68,
    "out:Initial Cost/MSEK": 4.914,
    "out:Running cost/(Apt SEK y)": 37906,
    "out:Running Cost over RSP/MSEK": 16.009,
    "out:LCP/MSEK": -1.419,
    "out:ROI% old": 10.16,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 117,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 40,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 128725,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188383,
    "out:DH kr savings": 128725,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 131.29,
    "out:Primary Energy": 119.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.58,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 51.6,
    "out:Total CO2 (tons)": 265.67,
    "out:Klimatpaverkan": -23.94,
    "out:Initial Cost/MSEK": 5.042,
    "out:Running cost/(Apt SEK y)": 36466,
    "out:Running Cost over RSP/MSEK": 15.401,
    "out:LCP/MSEK": -0.939,
    "out:ROI% old": 11.62,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214128,
    "out:DH kr savings": 154470,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 138.3,
    "out:Total Energy Use Post PV": 138.3,
    "out:Primary Energy": 129.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.24,
    "out:CO2 Operational/m2": 46.53,
    "out:Total CO2/m2": 57.76,
    "out:Total CO2 (tons)": 297.43,
    "out:Klimatpaverkan": 7.82,
    "out:Initial Cost/MSEK": 4.269,
    "out:Running cost/(Apt SEK y)": 38434,
    "out:Running Cost over RSP/MSEK": 16.22,
    "out:LCP/MSEK": -0.985,
    "out:ROI% old": 10.99,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 19.6,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 73,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 139023,
    "out:DH kr savings": 139023,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 134.3,
    "out:Total Energy Use Post PV": 134.3,
    "out:Primary Energy": 125.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.24,
    "out:CO2 Operational/m2": 45.09,
    "out:Total CO2/m2": 56.32,
    "out:Total CO2 (tons)": 290.01,
    "out:Klimatpaverkan": 0.4,
    "out:Initial Cost/MSEK": 4.397,
    "out:Running cost/(Apt SEK y)": 37283,
    "out:Running Cost over RSP/MSEK": 15.734,
    "out:LCP/MSEK": -0.627,
    "out:ROI% old": 12.25,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 23.1,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159619,
    "out:DH kr savings": 159619,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 135.3,
    "out:Primary Energy": 127.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.52,
    "out:CO2 Operational/m2": 45.45,
    "out:Total CO2/m2": 56.97,
    "out:Total CO2 (tons)": 293.32,
    "out:Klimatpaverkan": 3.71,
    "out:Initial Cost/MSEK": 4.444,
    "out:Running cost/(Apt SEK y)": 37571,
    "out:Running Cost over RSP/MSEK": 15.855,
    "out:LCP/MSEK": -0.795,
    "out:ROI% old": 11.73,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154470,
    "out:DH kr savings": 154470,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 131.3,
    "out:Primary Energy": 123.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.52,
    "out:CO2 Operational/m2": 44.01,
    "out:Total CO2/m2": 55.53,
    "out:Total CO2 (tons)": 285.9,
    "out:Klimatpaverkan": -3.71,
    "out:Initial Cost/MSEK": 4.573,
    "out:Running cost/(Apt SEK y)": 36419,
    "out:Running Cost over RSP/MSEK": 15.369,
    "out:LCP/MSEK": -0.438,
    "out:ROI% old": 12.92,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175066,
    "out:DH kr savings": 175066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 138.3,
    "out:Total Energy Use Post PV": 134.29,
    "out:Primary Energy": 122.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.3,
    "out:CO2 Operational/m2": 34.1,
    "out:Total CO2/m2": 52.39,
    "out:Total CO2 (tons)": 269.78,
    "out:Klimatpaverkan": -19.83,
    "out:Initial Cost/MSEK": 4.881,
    "out:Running cost/(Apt SEK y)": 37330,
    "out:Running Cost over RSP/MSEK": 15.766,
    "out:LCP/MSEK": -1.142,
    "out:ROI% old": 10.94,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 23.1,
    "out:Op energy cost/MSEK": 116,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 39,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 139023,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 198681,
    "out:DH kr savings": 139023,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 134.3,
    "out:Total Energy Use Post PV": 130.29,
    "out:Primary Energy": 118.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.3,
    "out:CO2 Operational/m2": 32.66,
    "out:Total CO2/m2": 50.95,
    "out:Total CO2 (tons)": 262.36,
    "out:Klimatpaverkan": -27.25,
    "out:Initial Cost/MSEK": 5.009,
    "out:Running cost/(Apt SEK y)": 36178,
    "out:Running Cost over RSP/MSEK": 15.28,
    "out:LCP/MSEK": -0.785,
    "out:ROI% old": 12.05,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 542,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 219277,
    "out:DH kr savings": 159619,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 135.3,
    "out:Total Energy Use Post PV": 131.29,
    "out:Primary Energy": 120.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.58,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 51.6,
    "out:Total CO2 (tons)": 265.67,
    "out:Klimatpaverkan": -23.94,
    "out:Initial Cost/MSEK": 5.056,
    "out:Running cost/(Apt SEK y)": 36466,
    "out:Running Cost over RSP/MSEK": 15.401,
    "out:LCP/MSEK": -0.953,
    "out:ROI% old": 11.59,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154470,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214128,
    "out:DH kr savings": 154470,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 131.3,
    "out:Total Energy Use Post PV": 127.29,
    "out:Primary Energy": 116.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.58,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 50.16,
    "out:Total CO2 (tons)": 258.25,
    "out:Klimatpaverkan": -31.36,
    "out:Initial Cost/MSEK": 5.185,
    "out:Running cost/(Apt SEK y)": 35314,
    "out:Running Cost over RSP/MSEK": 14.915,
    "out:LCP/MSEK": -0.595,
    "out:ROI% old": 12.65,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 175066,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234724,
    "out:DH kr savings": 175066,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 139.69,
    "out:Total Energy Use Post PV": 139.69,
    "out:Primary Energy": 135.76,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.58,
    "out:CO2 Operational/m2": 45.8,
    "out:Total CO2/m2": 59.38,
    "out:Total CO2 (tons)": 305.74,
    "out:Klimatpaverkan": 16.13,
    "out:Initial Cost/MSEK": 4.923,
    "out:Running cost/(Apt SEK y)": 38311,
    "out:Running Cost over RSP/MSEK": 16.163,
    "out:LCP/MSEK": -1.582,
    "out:ROI% old": 9.7,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.9,
    "out:Op energy cost/MSEK": 118,
    "out:El price/MSEK": 74,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 427,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 104084,
    "out:DH kr savings": 159619,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 134.69,
    "out:Total Energy Use Post PV": 134.69,
    "out:Primary Energy": 131.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.58,
    "out:CO2 Operational/m2": 44,
    "out:Total CO2/m2": 57.58,
    "out:Total CO2 (tons)": 296.47,
    "out:Klimatpaverkan": 6.86,
    "out:Initial Cost/MSEK": 5.052,
    "out:Running cost/(Apt SEK y)": 36871,
    "out:Running Cost over RSP/MSEK": 15.555,
    "out:LCP/MSEK": -1.102,
    "out:ROI% old": 11.17,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 129829,
    "out:DH kr savings": 185364,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 135.69,
    "out:Primary Energy": 133.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.86,
    "out:CO2 Operational/m2": 44.36,
    "out:Total CO2/m2": 58.22,
    "out:Total CO2 (tons)": 299.78,
    "out:Klimatpaverkan": 10.17,
    "out:Initial Cost/MSEK": 5.098,
    "out:Running cost/(Apt SEK y)": 37159,
    "out:Running Cost over RSP/MSEK": 15.677,
    "out:LCP/MSEK": -1.271,
    "out:ROI% old": 10.73,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 489,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124680,
    "out:DH kr savings": 180215,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 130.69,
    "out:Primary Energy": 129.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.86,
    "out:CO2 Operational/m2": 42.56,
    "out:Total CO2/m2": 56.42,
    "out:Total CO2 (tons)": 290.51,
    "out:Klimatpaverkan": 0.9,
    "out:Initial Cost/MSEK": 5.227,
    "out:Running cost/(Apt SEK y)": 35719,
    "out:Running Cost over RSP/MSEK": 15.068,
    "out:LCP/MSEK": -0.791,
    "out:ROI% old": 12.12,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 150425,
    "out:DH kr savings": 205960,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 139.69,
    "out:Total Energy Use Post PV": 134.84,
    "out:Primary Energy": 127.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.64,
    "out:CO2 Operational/m2": 37.57,
    "out:Total CO2/m2": 58.21,
    "out:Total CO2 (tons)": 299.7,
    "out:Klimatpaverkan": 10.09,
    "out:Initial Cost/MSEK": 5.535,
    "out:Running cost/(Apt SEK y)": 37166,
    "out:Running Cost over RSP/MSEK": 15.692,
    "out:LCP/MSEK": -1.723,
    "out:ROI% old": 9.84,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 115,
    "out:El price/MSEK": 71,
    "out:Heating price/MSEK": 38,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 159619,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165911,
    "out:DH kr savings": 159619,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 134.69,
    "out:Total Energy Use Post PV": 129.84,
    "out:Primary Energy": 123.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.64,
    "out:CO2 Operational/m2": 35.77,
    "out:Total CO2/m2": 56.41,
    "out:Total CO2 (tons)": 290.43,
    "out:Klimatpaverkan": 0.82,
    "out:Initial Cost/MSEK": 5.664,
    "out:Running cost/(Apt SEK y)": 35726,
    "out:Running Cost over RSP/MSEK": 15.084,
    "out:LCP/MSEK": -1.244,
    "out:ROI% old": 11.15,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191656,
    "out:DH kr savings": 185364,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 135.69,
    "out:Total Energy Use Post PV": 130.84,
    "out:Primary Energy": 124.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.92,
    "out:CO2 Operational/m2": 36.13,
    "out:Total CO2/m2": 57.05,
    "out:Total CO2 (tons)": 293.74,
    "out:Klimatpaverkan": 4.13,
    "out:Initial Cost/MSEK": 5.711,
    "out:Running cost/(Apt SEK y)": 36014,
    "out:Running Cost over RSP/MSEK": 15.206,
    "out:LCP/MSEK": -1.412,
    "out:ROI% old": 10.75,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180215,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186507,
    "out:DH kr savings": 180215,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 125.84,
    "out:Primary Energy": 120.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.92,
    "out:CO2 Operational/m2": 34.33,
    "out:Total CO2/m2": 55.25,
    "out:Total CO2 (tons)": 284.47,
    "out:Klimatpaverkan": -5.14,
    "out:Initial Cost/MSEK": 5.839,
    "out:Running cost/(Apt SEK y)": 34574,
    "out:Running Cost over RSP/MSEK": 14.598,
    "out:LCP/MSEK": -0.933,
    "out:ROI% old": 12,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212252,
    "out:DH kr savings": 205960,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 133.69,
    "out:Total Energy Use Post PV": 133.69,
    "out:Primary Energy": 132.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.58,
    "out:CO2 Operational/m2": 43.64,
    "out:Total CO2/m2": 57.22,
    "out:Total CO2 (tons)": 294.62,
    "out:Klimatpaverkan": 5.01,
    "out:Initial Cost/MSEK": 5.065,
    "out:Running cost/(Apt SEK y)": 36583,
    "out:Running Cost over RSP/MSEK": 15.433,
    "out:LCP/MSEK": -0.994,
    "out:ROI% old": 11.48,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 23.1,
    "out:Op energy cost/MSEK": 112,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190513,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134978,
    "out:DH kr savings": 190513,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 129.69,
    "out:Total Energy Use Post PV": 129.69,
    "out:Primary Energy": 128.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.58,
    "out:CO2 Operational/m2": 42.2,
    "out:Total CO2/m2": 55.78,
    "out:Total CO2 (tons)": 287.21,
    "out:Klimatpaverkan": -2.4,
    "out:Initial Cost/MSEK": 5.194,
    "out:Running cost/(Apt SEK y)": 35431,
    "out:Running Cost over RSP/MSEK": 14.947,
    "out:LCP/MSEK": -0.637,
    "out:ROI% old": 12.53,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 155574,
    "out:DH kr savings": 211109,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 130.69,
    "out:Primary Energy": 130.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.86,
    "out:CO2 Operational/m2": 42.56,
    "out:Total CO2/m2": 56.42,
    "out:Total CO2 (tons)": 290.51,
    "out:Klimatpaverkan": 0.9,
    "out:Initial Cost/MSEK": 5.241,
    "out:Running cost/(Apt SEK y)": 35719,
    "out:Running Cost over RSP/MSEK": 15.068,
    "out:LCP/MSEK": -0.805,
    "out:ROI% old": 12.09,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 69,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150425,
    "out:DH kr savings": 205960,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 126.69,
    "out:Primary Energy": 126.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.86,
    "out:CO2 Operational/m2": 41.12,
    "out:Total CO2/m2": 54.98,
    "out:Total CO2 (tons)": 283.1,
    "out:Klimatpaverkan": -6.51,
    "out:Initial Cost/MSEK": 5.37,
    "out:Running cost/(Apt SEK y)": 34567,
    "out:Running Cost over RSP/MSEK": 14.582,
    "out:LCP/MSEK": -0.447,
    "out:ROI% old": 13.1,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171021,
    "out:DH kr savings": 226556,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 133.69,
    "out:Total Energy Use Post PV": 128.84,
    "out:Primary Energy": 123.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.64,
    "out:CO2 Operational/m2": 35.41,
    "out:Total CO2/m2": 56.05,
    "out:Total CO2 (tons)": 288.58,
    "out:Klimatpaverkan": -1.03,
    "out:Initial Cost/MSEK": 5.677,
    "out:Running cost/(Apt SEK y)": 35438,
    "out:Running Cost over RSP/MSEK": 14.963,
    "out:LCP/MSEK": -1.136,
    "out:ROI% old": 11.43,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 582,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 190513,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196805,
    "out:DH kr savings": 190513,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 129.69,
    "out:Total Energy Use Post PV": 124.84,
    "out:Primary Energy": 119.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.64,
    "out:CO2 Operational/m2": 33.97,
    "out:Total CO2/m2": 54.61,
    "out:Total CO2 (tons)": 281.16,
    "out:Klimatpaverkan": -8.45,
    "out:Initial Cost/MSEK": 5.806,
    "out:Running cost/(Apt SEK y)": 34286,
    "out:Running Cost over RSP/MSEK": 14.476,
    "out:LCP/MSEK": -0.778,
    "out:ROI% old": 12.37,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217401,
    "out:DH kr savings": 211109,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 130.69,
    "out:Total Energy Use Post PV": 125.84,
    "out:Primary Energy": 121.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.92,
    "out:CO2 Operational/m2": 34.33,
    "out:Total CO2/m2": 55.25,
    "out:Total CO2 (tons)": 284.47,
    "out:Klimatpaverkan": -5.14,
    "out:Initial Cost/MSEK": 5.853,
    "out:Running cost/(Apt SEK y)": 34574,
    "out:Running Cost over RSP/MSEK": 14.598,
    "out:LCP/MSEK": -0.947,
    "out:ROI% old": 11.98,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212252,
    "out:DH kr savings": 205960,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 529342,
    "out:% savings (space heating)": 16.77,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.8,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.4,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 126.69,
    "out:Total Energy Use Post PV": 121.84,
    "out:Primary Energy": 117.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.92,
    "out:CO2 Operational/m2": 32.89,
    "out:Total CO2/m2": 53.81,
    "out:Total CO2 (tons)": 277.05,
    "out:Klimatpaverkan": -12.56,
    "out:Initial Cost/MSEK": 5.982,
    "out:Running cost/(Apt SEK y)": 33423,
    "out:Running Cost over RSP/MSEK": 14.111,
    "out:LCP/MSEK": -0.589,
    "out:ROI% old": 12.88,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 232848,
    "out:DH kr savings": 226556,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 506685,
    "out:% savings (space heating)": 20.33,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.4,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 129.3,
    "out:Primary Energy": 120.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.23,
    "out:CO2 Operational/m2": 43.29,
    "out:Total CO2/m2": 57.51,
    "out:Total CO2 (tons)": 296.13,
    "out:Klimatpaverkan": 6.52,
    "out:Initial Cost/MSEK": 5.419,
    "out:Running cost/(Apt SEK y)": 35843,
    "out:Running Cost over RSP/MSEK": 15.126,
    "out:LCP/MSEK": -1.041,
    "out:ROI% old": 11.54,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 560,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185364,
    "out:DH kr savings": 185364,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 126.3,
    "out:Primary Energy": 117.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.23,
    "out:CO2 Operational/m2": 42.21,
    "out:Total CO2/m2": 56.43,
    "out:Total CO2 (tons)": 290.57,
    "out:Klimatpaverkan": 0.96,
    "out:Initial Cost/MSEK": 5.548,
    "out:Running cost/(Apt SEK y)": 34979,
    "out:Running Cost over RSP/MSEK": 14.761,
    "out:LCP/MSEK": -0.804,
    "out:ROI% old": 12.21,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 31,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200811,
    "out:DH kr savings": 200811,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 125.3,
    "out:Primary Energy": 117.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.51,
    "out:CO2 Operational/m2": 41.85,
    "out:Total CO2/m2": 56.35,
    "out:Total CO2 (tons)": 290.17,
    "out:Klimatpaverkan": 0.56,
    "out:Initial Cost/MSEK": 5.595,
    "out:Running cost/(Apt SEK y)": 34691,
    "out:Running Cost over RSP/MSEK": 14.639,
    "out:LCP/MSEK": -0.73,
    "out:ROI% old": 12.42,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205960,
    "out:DH kr savings": 205960,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 122.3,
    "out:Total Energy Use Post PV": 122.3,
    "out:Primary Energy": 114.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.51,
    "out:CO2 Operational/m2": 40.77,
    "out:Total CO2/m2": 55.27,
    "out:Total CO2 (tons)": 284.61,
    "out:Klimatpaverkan": -5,
    "out:Initial Cost/MSEK": 5.724,
    "out:Running cost/(Apt SEK y)": 33827,
    "out:Running Cost over RSP/MSEK": 14.274,
    "out:LCP/MSEK": -0.494,
    "out:ROI% old": 13.05,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 669,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221407,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221407,
    "out:DH kr savings": 221407,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 125.29,
    "out:Primary Energy": 113.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.29,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 52.14,
    "out:Total CO2 (tons)": 268.48,
    "out:Klimatpaverkan": -21.13,
    "out:Initial Cost/MSEK": 6.031,
    "out:Running cost/(Apt SEK y)": 34738,
    "out:Running Cost over RSP/MSEK": 14.671,
    "out:LCP/MSEK": -1.198,
    "out:ROI% old": 11.45,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245022,
    "out:DH kr savings": 185364,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 126.3,
    "out:Total Energy Use Post PV": 122.29,
    "out:Primary Energy": 109.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.29,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 51.06,
    "out:Total CO2 (tons)": 262.92,
    "out:Klimatpaverkan": -26.69,
    "out:Initial Cost/MSEK": 6.16,
    "out:Running cost/(Apt SEK y)": 33874,
    "out:Running Cost over RSP/MSEK": 14.307,
    "out:LCP/MSEK": -0.962,
    "out:ROI% old": 12.05,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 35.2,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 666,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200811,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260469,
    "out:DH kr savings": 200811,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 121.29,
    "out:Primary Energy": 110.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.57,
    "out:CO2 Operational/m2": 29.42,
    "out:Total CO2/m2": 50.98,
    "out:Total CO2 (tons)": 262.52,
    "out:Klimatpaverkan": -27.09,
    "out:Initial Cost/MSEK": 6.207,
    "out:Running cost/(Apt SEK y)": 33586,
    "out:Running Cost over RSP/MSEK": 14.185,
    "out:LCP/MSEK": -0.887,
    "out:ROI% old": 12.24,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 265618,
    "out:DH kr savings": 205960,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 122.3,
    "out:Total Energy Use Post PV": 118.29,
    "out:Primary Energy": 107.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.57,
    "out:CO2 Operational/m2": 28.34,
    "out:Total CO2/m2": 49.9,
    "out:Total CO2 (tons)": 256.96,
    "out:Klimatpaverkan": -32.65,
    "out:Initial Cost/MSEK": 6.336,
    "out:Running cost/(Apt SEK y)": 32722,
    "out:Running Cost over RSP/MSEK": 13.82,
    "out:LCP/MSEK": -0.651,
    "out:ROI% old": 12.82,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 728,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221407,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281065,
    "out:DH kr savings": 221407,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 124.3,
    "out:Total Energy Use Post PV": 124.3,
    "out:Primary Energy": 116.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.23,
    "out:CO2 Operational/m2": 41.49,
    "out:Total CO2/m2": 55.71,
    "out:Total CO2 (tons)": 286.86,
    "out:Klimatpaverkan": -2.75,
    "out:Initial Cost/MSEK": 5.562,
    "out:Running cost/(Apt SEK y)": 34403,
    "out:Running Cost over RSP/MSEK": 14.517,
    "out:LCP/MSEK": -0.575,
    "out:ROI% old": 12.81,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211109,
    "out:DH kr savings": 211109,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 120.3,
    "out:Total Energy Use Post PV": 120.3,
    "out:Primary Energy": 113.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.23,
    "out:CO2 Operational/m2": 40.05,
    "out:Total CO2/m2": 54.27,
    "out:Total CO2 (tons)": 279.45,
    "out:Klimatpaverkan": -10.16,
    "out:Initial Cost/MSEK": 5.69,
    "out:Running cost/(Apt SEK y)": 33251,
    "out:Running Cost over RSP/MSEK": 14.031,
    "out:LCP/MSEK": -0.217,
    "out:ROI% old": 13.74,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231705,
    "out:DH kr savings": 231705,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 121.3,
    "out:Total Energy Use Post PV": 121.3,
    "out:Primary Energy": 114.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.51,
    "out:CO2 Operational/m2": 40.41,
    "out:Total CO2/m2": 54.91,
    "out:Total CO2 (tons)": 282.75,
    "out:Klimatpaverkan": -6.86,
    "out:Initial Cost/MSEK": 5.737,
    "out:Running cost/(Apt SEK y)": 33539,
    "out:Running Cost over RSP/MSEK": 14.153,
    "out:LCP/MSEK": -0.386,
    "out:ROI% old": 13.33,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 684,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226556,
    "out:DH kr savings": 226556,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 117.3,
    "out:Total Energy Use Post PV": 117.3,
    "out:Primary Energy": 111.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.51,
    "out:CO2 Operational/m2": 38.97,
    "out:Total CO2/m2": 53.47,
    "out:Total CO2 (tons)": 275.34,
    "out:Klimatpaverkan": -14.27,
    "out:Initial Cost/MSEK": 5.866,
    "out:Running cost/(Apt SEK y)": 32387,
    "out:Running Cost over RSP/MSEK": 13.666,
    "out:LCP/MSEK": -0.028,
    "out:ROI% old": 14.22,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 746,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247152,
    "out:DH kr savings": 247152,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 124.3,
    "out:Total Energy Use Post PV": 120.29,
    "out:Primary Energy": 109.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.29,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 50.34,
    "out:Total CO2 (tons)": 259.21,
    "out:Klimatpaverkan": -30.4,
    "out:Initial Cost/MSEK": 6.174,
    "out:Running cost/(Apt SEK y)": 33298,
    "out:Running Cost over RSP/MSEK": 14.063,
    "out:LCP/MSEK": -0.733,
    "out:ROI% old": 12.59,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 697,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 270767,
    "out:DH kr savings": 211109,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 120.3,
    "out:Total Energy Use Post PV": 116.29,
    "out:Primary Energy": 106.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.29,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 48.9,
    "out:Total CO2 (tons)": 251.8,
    "out:Klimatpaverkan": -37.81,
    "out:Initial Cost/MSEK": 6.302,
    "out:Running cost/(Apt SEK y)": 32146,
    "out:Running Cost over RSP/MSEK": 13.577,
    "out:LCP/MSEK": -0.375,
    "out:ROI% old": 13.44,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 759,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291363,
    "out:DH kr savings": 231705,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 121.3,
    "out:Total Energy Use Post PV": 117.29,
    "out:Primary Energy": 107.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.57,
    "out:CO2 Operational/m2": 27.98,
    "out:Total CO2/m2": 49.54,
    "out:Total CO2 (tons)": 255.1,
    "out:Klimatpaverkan": -34.51,
    "out:Initial Cost/MSEK": 6.349,
    "out:Running cost/(Apt SEK y)": 32434,
    "out:Running Cost over RSP/MSEK": 13.698,
    "out:LCP/MSEK": -0.543,
    "out:ROI% old": 13.06,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 744,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 286214,
    "out:DH kr savings": 226556,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 117.3,
    "out:Total Energy Use Post PV": 113.29,
    "out:Primary Energy": 104.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.57,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 48.1,
    "out:Total CO2 (tons)": 247.69,
    "out:Klimatpaverkan": -41.92,
    "out:Initial Cost/MSEK": 6.478,
    "out:Running cost/(Apt SEK y)": 31282,
    "out:Running Cost over RSP/MSEK": 13.212,
    "out:LCP/MSEK": -0.186,
    "out:ROI% old": 13.88,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 806,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 306810,
    "out:DH kr savings": 247152,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 124.69,
    "out:Primary Energy": 122.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.57,
    "out:CO2 Operational/m2": 40.4,
    "out:Total CO2/m2": 56.97,
    "out:Total CO2 (tons)": 293.33,
    "out:Klimatpaverkan": 3.72,
    "out:Initial Cost/MSEK": 6.216,
    "out:Running cost/(Apt SEK y)": 33992,
    "out:Running Cost over RSP/MSEK": 14.339,
    "out:LCP/MSEK": -1.05,
    "out:ROI% old": 11.87,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181319,
    "out:DH kr savings": 236854,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 120.69,
    "out:Primary Energy": 119.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.57,
    "out:CO2 Operational/m2": 38.96,
    "out:Total CO2/m2": 55.53,
    "out:Total CO2 (tons)": 285.91,
    "out:Klimatpaverkan": -3.7,
    "out:Initial Cost/MSEK": 6.345,
    "out:Running cost/(Apt SEK y)": 32840,
    "out:Running Cost over RSP/MSEK": 13.852,
    "out:LCP/MSEK": -0.693,
    "out:ROI% old": 12.73,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201915,
    "out:DH kr savings": 257450,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 120.69,
    "out:Primary Energy": 120.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.85,
    "out:CO2 Operational/m2": 38.96,
    "out:Total CO2/m2": 55.81,
    "out:Total CO2 (tons)": 287.36,
    "out:Klimatpaverkan": -2.25,
    "out:Initial Cost/MSEK": 6.392,
    "out:Running cost/(Apt SEK y)": 32840,
    "out:Running Cost over RSP/MSEK": 13.852,
    "out:LCP/MSEK": -0.739,
    "out:ROI% old": 12.63,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201915,
    "out:DH kr savings": 257450,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 116.69,
    "out:Total Energy Use Post PV": 116.69,
    "out:Primary Energy": 117.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.85,
    "out:CO2 Operational/m2": 37.52,
    "out:Total CO2/m2": 54.37,
    "out:Total CO2 (tons)": 279.95,
    "out:Klimatpaverkan": -9.66,
    "out:Initial Cost/MSEK": 6.52,
    "out:Running cost/(Apt SEK y)": 31688,
    "out:Running Cost over RSP/MSEK": 13.366,
    "out:LCP/MSEK": -0.382,
    "out:ROI% old": 13.45,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222511,
    "out:DH kr savings": 278046,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 119.84,
    "out:Primary Energy": 114.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.63,
    "out:CO2 Operational/m2": 32.17,
    "out:Total CO2/m2": 55.79,
    "out:Total CO2 (tons)": 287.28,
    "out:Klimatpaverkan": -2.33,
    "out:Initial Cost/MSEK": 6.828,
    "out:Running cost/(Apt SEK y)": 32847,
    "out:Running Cost over RSP/MSEK": 13.868,
    "out:LCP/MSEK": -1.192,
    "out:ROI% old": 11.79,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 243146,
    "out:DH kr savings": 236854,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 115.84,
    "out:Primary Energy": 110.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.63,
    "out:CO2 Operational/m2": 30.73,
    "out:Total CO2/m2": 54.35,
    "out:Total CO2 (tons)": 279.87,
    "out:Klimatpaverkan": -9.74,
    "out:Initial Cost/MSEK": 6.957,
    "out:Running cost/(Apt SEK y)": 31695,
    "out:Running Cost over RSP/MSEK": 13.381,
    "out:LCP/MSEK": -0.834,
    "out:ROI% old": 12.57,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 263742,
    "out:DH kr savings": 257450,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 115.84,
    "out:Primary Energy": 111.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.91,
    "out:CO2 Operational/m2": 30.73,
    "out:Total CO2/m2": 54.64,
    "out:Total CO2 (tons)": 281.32,
    "out:Klimatpaverkan": -8.29,
    "out:Initial Cost/MSEK": 7.004,
    "out:Running cost/(Apt SEK y)": 31695,
    "out:Running Cost over RSP/MSEK": 13.381,
    "out:LCP/MSEK": -0.881,
    "out:ROI% old": 12.49,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263742,
    "out:DH kr savings": 257450,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 116.69,
    "out:Total Energy Use Post PV": 111.84,
    "out:Primary Energy": 108.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.91,
    "out:CO2 Operational/m2": 29.29,
    "out:Total CO2/m2": 53.2,
    "out:Total CO2 (tons)": 273.91,
    "out:Klimatpaverkan": -15.7,
    "out:Initial Cost/MSEK": 7.132,
    "out:Running cost/(Apt SEK y)": 30543,
    "out:Running Cost over RSP/MSEK": 12.895,
    "out:LCP/MSEK": -0.523,
    "out:ROI% old": 13.24,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 47.3,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 284338,
    "out:DH kr savings": 278046,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 119.69,
    "out:Primary Energy": 119.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.57,
    "out:CO2 Operational/m2": 38.6,
    "out:Total CO2/m2": 55.17,
    "out:Total CO2 (tons)": 284.06,
    "out:Klimatpaverkan": -5.55,
    "out:Initial Cost/MSEK": 6.358,
    "out:Running cost/(Apt SEK y)": 32552,
    "out:Running Cost over RSP/MSEK": 13.731,
    "out:LCP/MSEK": -0.585,
    "out:ROI% old": 12.97,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 738,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 207064,
    "out:DH kr savings": 262599,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 115.69,
    "out:Total Energy Use Post PV": 115.69,
    "out:Primary Energy": 116.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.57,
    "out:CO2 Operational/m2": 37.16,
    "out:Total CO2/m2": 53.73,
    "out:Total CO2 (tons)": 276.64,
    "out:Klimatpaverkan": -12.97,
    "out:Initial Cost/MSEK": 6.487,
    "out:Running cost/(Apt SEK y)": 31400,
    "out:Running Cost over RSP/MSEK": 13.244,
    "out:LCP/MSEK": -0.227,
    "out:ROI% old": 13.79,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 800,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227660,
    "out:DH kr savings": 283195,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 115.69,
    "out:Total Energy Use Post PV": 115.69,
    "out:Primary Energy": 117.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.85,
    "out:CO2 Operational/m2": 37.16,
    "out:Total CO2/m2": 54.01,
    "out:Total CO2 (tons)": 278.09,
    "out:Klimatpaverkan": -11.52,
    "out:Initial Cost/MSEK": 6.534,
    "out:Running cost/(Apt SEK y)": 31400,
    "out:Running Cost over RSP/MSEK": 13.244,
    "out:LCP/MSEK": -0.274,
    "out:ROI% old": 13.69,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 800,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 227660,
    "out:DH kr savings": 283195,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 112.69,
    "out:Total Energy Use Post PV": 112.69,
    "out:Primary Energy": 114.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.85,
    "out:CO2 Operational/m2": 36.08,
    "out:Total CO2/m2": 52.93,
    "out:Total CO2 (tons)": 272.53,
    "out:Klimatpaverkan": -17.08,
    "out:Initial Cost/MSEK": 6.663,
    "out:Running cost/(Apt SEK y)": 30536,
    "out:Running Cost over RSP/MSEK": 12.879,
    "out:LCP/MSEK": -0.038,
    "out:ROI% old": 14.2,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243107,
    "out:DH kr savings": 298642,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 114.84,
    "out:Primary Energy": 110.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.63,
    "out:CO2 Operational/m2": 30.37,
    "out:Total CO2/m2": 53.99,
    "out:Total CO2 (tons)": 278.02,
    "out:Klimatpaverkan": -11.59,
    "out:Initial Cost/MSEK": 6.971,
    "out:Running cost/(Apt SEK y)": 31407,
    "out:Running Cost over RSP/MSEK": 13.26,
    "out:LCP/MSEK": -0.726,
    "out:ROI% old": 12.8,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 799,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268891,
    "out:DH kr savings": 262599,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 115.69,
    "out:Total Energy Use Post PV": 110.84,
    "out:Primary Energy": 107.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.63,
    "out:CO2 Operational/m2": 28.93,
    "out:Total CO2/m2": 52.55,
    "out:Total CO2 (tons)": 270.6,
    "out:Klimatpaverkan": -19.01,
    "out:Initial Cost/MSEK": 7.099,
    "out:Running cost/(Apt SEK y)": 30255,
    "out:Running Cost over RSP/MSEK": 12.773,
    "out:LCP/MSEK": -0.368,
    "out:ROI% old": 13.54,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 48.6,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 862,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 289487,
    "out:DH kr savings": 283195,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.21,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 115.69,
    "out:Total Energy Use Post PV": 110.84,
    "out:Primary Energy": 108.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.91,
    "out:CO2 Operational/m2": 28.93,
    "out:Total CO2/m2": 52.84,
    "out:Total CO2 (tons)": 272.05,
    "out:Klimatpaverkan": -17.56,
    "out:Initial Cost/MSEK": 7.146,
    "out:Running cost/(Apt SEK y)": 30255,
    "out:Running Cost over RSP/MSEK": 12.773,
    "out:LCP/MSEK": -0.415,
    "out:ROI% old": 13.46,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 48.6,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 862,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 289487,
    "out:DH kr savings": 283195,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 454168,
    "out:% savings (space heating)": 28.59,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.21,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.47,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 112.69,
    "out:Total Energy Use Post PV": 107.84,
    "out:Primary Energy": 105.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.91,
    "out:CO2 Operational/m2": 27.85,
    "out:Total CO2/m2": 51.76,
    "out:Total CO2 (tons)": 266.49,
    "out:Klimatpaverkan": -23.12,
    "out:Initial Cost/MSEK": 7.275,
    "out:Running cost/(Apt SEK y)": 29391,
    "out:Running Cost over RSP/MSEK": 12.408,
    "out:LCP/MSEK": -0.179,
    "out:ROI% old": 13.93,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.63,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 908,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304934,
    "out:DH kr savings": 298642,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 434928,
    "out:% savings (space heating)": 31.61,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.47,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 125.3,
    "out:Primary Energy": 116.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.79,
    "out:CO2 Operational/m2": 41.85,
    "out:Total CO2/m2": 65.63,
    "out:Total CO2 (tons)": 337.95,
    "out:Klimatpaverkan": 48.34,
    "out:Initial Cost/MSEK": 8.585,
    "out:Running cost/(Apt SEK y)": 34691,
    "out:Running Cost over RSP/MSEK": 14.639,
    "out:LCP/MSEK": -3.72,
    "out:ROI% old": 8.1,
    "out:Payback discounted": 15,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 7,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205960,
    "out:DH kr savings": 205960,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 121.3,
    "out:Total Energy Use Post PV": 121.3,
    "out:Primary Energy": 113.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.79,
    "out:CO2 Operational/m2": 40.41,
    "out:Total CO2/m2": 64.19,
    "out:Total CO2 (tons)": 330.54,
    "out:Klimatpaverkan": 40.93,
    "out:Initial Cost/MSEK": 8.714,
    "out:Running cost/(Apt SEK y)": 33539,
    "out:Running Cost over RSP/MSEK": 14.153,
    "out:LCP/MSEK": -3.363,
    "out:ROI% old": 8.77,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 8,
    "out:Return/kSEK/y": 684,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226556,
    "out:DH kr savings": 226556,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 121.3,
    "out:Total Energy Use Post PV": 121.3,
    "out:Primary Energy": 113.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.07,
    "out:CO2 Operational/m2": 40.41,
    "out:Total CO2/m2": 64.48,
    "out:Total CO2 (tons)": 331.99,
    "out:Klimatpaverkan": 42.38,
    "out:Initial Cost/MSEK": 8.761,
    "out:Running cost/(Apt SEK y)": 33539,
    "out:Running Cost over RSP/MSEK": 14.153,
    "out:LCP/MSEK": -3.409,
    "out:ROI% old": 8.73,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 8,
    "out:Return/kSEK/y": 684,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226556,
    "out:DH kr savings": 226556,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 117.3,
    "out:Total Energy Use Post PV": 117.3,
    "out:Primary Energy": 110.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.07,
    "out:CO2 Operational/m2": 38.97,
    "out:Total CO2/m2": 63.04,
    "out:Total CO2 (tons)": 324.57,
    "out:Klimatpaverkan": 34.96,
    "out:Initial Cost/MSEK": 8.89,
    "out:Running cost/(Apt SEK y)": 32387,
    "out:Running Cost over RSP/MSEK": 13.666,
    "out:LCP/MSEK": -3.052,
    "out:ROI% old": 9.38,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 8,
    "out:Return/kSEK/y": 746,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247152,
    "out:DH kr savings": 247152,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 121.29,
    "out:Primary Energy": 109.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.85,
    "out:CO2 Operational/m2": 29.42,
    "out:Total CO2/m2": 60.26,
    "out:Total CO2 (tons)": 310.3,
    "out:Klimatpaverkan": 20.69,
    "out:Initial Cost/MSEK": 9.197,
    "out:Running cost/(Apt SEK y)": 33586,
    "out:Running Cost over RSP/MSEK": 14.185,
    "out:LCP/MSEK": -3.878,
    "out:ROI% old": 8.26,
    "out:Payback discounted": 15,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 7,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 265618,
    "out:DH kr savings": 205960,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 121.3,
    "out:Total Energy Use Post PV": 117.29,
    "out:Primary Energy": 106.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.85,
    "out:CO2 Operational/m2": 27.98,
    "out:Total CO2/m2": 58.82,
    "out:Total CO2 (tons)": 302.89,
    "out:Klimatpaverkan": 13.28,
    "out:Initial Cost/MSEK": 9.326,
    "out:Running cost/(Apt SEK y)": 32434,
    "out:Running Cost over RSP/MSEK": 13.698,
    "out:LCP/MSEK": -3.52,
    "out:ROI% old": 8.89,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 744,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286214,
    "out:DH kr savings": 226556,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 121.3,
    "out:Total Energy Use Post PV": 117.29,
    "out:Primary Energy": 106.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.13,
    "out:CO2 Operational/m2": 27.98,
    "out:Total CO2/m2": 59.11,
    "out:Total CO2 (tons)": 304.34,
    "out:Klimatpaverkan": 14.73,
    "out:Initial Cost/MSEK": 9.373,
    "out:Running cost/(Apt SEK y)": 32434,
    "out:Running Cost over RSP/MSEK": 13.698,
    "out:LCP/MSEK": -3.567,
    "out:ROI% old": 8.85,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 744,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 226556,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 286214,
    "out:DH kr savings": 226556,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 117.3,
    "out:Total Energy Use Post PV": 113.29,
    "out:Primary Energy": 103.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.13,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 57.67,
    "out:Total CO2 (tons)": 296.93,
    "out:Klimatpaverkan": 7.32,
    "out:Initial Cost/MSEK": 9.502,
    "out:Running cost/(Apt SEK y)": 31282,
    "out:Running Cost over RSP/MSEK": 13.212,
    "out:LCP/MSEK": -3.209,
    "out:ROI% old": 9.46,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 806,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 306810,
    "out:DH kr savings": 247152,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 119.3,
    "out:Total Energy Use Post PV": 119.3,
    "out:Primary Energy": 112.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.79,
    "out:CO2 Operational/m2": 39.69,
    "out:Total CO2/m2": 63.47,
    "out:Total CO2 (tons)": 326.83,
    "out:Klimatpaverkan": 37.22,
    "out:Initial Cost/MSEK": 8.728,
    "out:Running cost/(Apt SEK y)": 32963,
    "out:Running Cost over RSP/MSEK": 13.909,
    "out:LCP/MSEK": -3.133,
    "out:ROI% old": 9.16,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 38.7,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 8,
    "out:Return/kSEK/y": 715,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236854,
    "out:DH kr savings": 236854,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 116.3,
    "out:Total Energy Use Post PV": 116.3,
    "out:Primary Energy": 109.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.79,
    "out:CO2 Operational/m2": 38.61,
    "out:Total CO2/m2": 62.39,
    "out:Total CO2 (tons)": 321.27,
    "out:Klimatpaverkan": 31.66,
    "out:Initial Cost/MSEK": 8.857,
    "out:Running cost/(Apt SEK y)": 32099,
    "out:Running Cost over RSP/MSEK": 13.544,
    "out:LCP/MSEK": -2.897,
    "out:ROI% old": 9.61,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 762,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 252301,
    "out:DH kr savings": 252301,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 116.3,
    "out:Total Energy Use Post PV": 116.3,
    "out:Primary Energy": 110.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.07,
    "out:CO2 Operational/m2": 38.61,
    "out:Total CO2/m2": 62.68,
    "out:Total CO2 (tons)": 322.72,
    "out:Klimatpaverkan": 33.11,
    "out:Initial Cost/MSEK": 8.904,
    "out:Running cost/(Apt SEK y)": 32099,
    "out:Running Cost over RSP/MSEK": 13.544,
    "out:LCP/MSEK": -2.944,
    "out:ROI% old": 9.56,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 762,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 252301,
    "out:DH kr savings": 252301,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 112.3,
    "out:Total Energy Use Post PV": 112.3,
    "out:Primary Energy": 107.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.07,
    "out:CO2 Operational/m2": 37.17,
    "out:Total CO2/m2": 61.24,
    "out:Total CO2 (tons)": 315.31,
    "out:Klimatpaverkan": 25.7,
    "out:Initial Cost/MSEK": 9.032,
    "out:Running cost/(Apt SEK y)": 30947,
    "out:Running Cost over RSP/MSEK": 13.058,
    "out:LCP/MSEK": -2.586,
    "out:ROI% old": 10.2,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 47.3,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 824,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 272897,
    "out:DH kr savings": 272897,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01010200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 119.3,
    "out:Total Energy Use Post PV": 115.29,
    "out:Primary Energy": 105.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.85,
    "out:CO2 Operational/m2": 27.26,
    "out:Total CO2/m2": 58.1,
    "out:Total CO2 (tons)": 299.18,
    "out:Klimatpaverkan": 9.57,
    "out:Initial Cost/MSEK": 9.34,
    "out:Running cost/(Apt SEK y)": 31858,
    "out:Running Cost over RSP/MSEK": 13.455,
    "out:LCP/MSEK": -3.291,
    "out:ROI% old": 9.25,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 775,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 296512,
    "out:DH kr savings": 236854,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 116.3,
    "out:Total Energy Use Post PV": 112.29,
    "out:Primary Energy": 102.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.85,
    "out:CO2 Operational/m2": 26.18,
    "out:Total CO2/m2": 57.02,
    "out:Total CO2 (tons)": 293.62,
    "out:Klimatpaverkan": 4.01,
    "out:Initial Cost/MSEK": 9.469,
    "out:Running cost/(Apt SEK y)": 30994,
    "out:Running Cost over RSP/MSEK": 13.09,
    "out:LCP/MSEK": -3.055,
    "out:ROI% old": 9.68,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 47.3,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 822,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 311959,
    "out:DH kr savings": 252301,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 116.3,
    "out:Total Energy Use Post PV": 112.29,
    "out:Primary Energy": 103.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.13,
    "out:CO2 Operational/m2": 26.18,
    "out:Total CO2/m2": 57.31,
    "out:Total CO2 (tons)": 295.07,
    "out:Klimatpaverkan": 5.46,
    "out:Initial Cost/MSEK": 9.516,
    "out:Running cost/(Apt SEK y)": 30994,
    "out:Running Cost over RSP/MSEK": 13.09,
    "out:LCP/MSEK": -3.102,
    "out:ROI% old": 9.63,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 47.3,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 822,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252301,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 311959,
    "out:DH kr savings": 252301,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 112.3,
    "out:Total Energy Use Post PV": 108.29,
    "out:Primary Energy": 100.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.13,
    "out:CO2 Operational/m2": 24.74,
    "out:Total CO2/m2": 55.87,
    "out:Total CO2 (tons)": 287.66,
    "out:Klimatpaverkan": -1.95,
    "out:Initial Cost/MSEK": 9.644,
    "out:Running cost/(Apt SEK y)": 29843,
    "out:Running Cost over RSP/MSEK": 12.604,
    "out:LCP/MSEK": -2.744,
    "out:ROI% old": 10.22,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 884,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 332555,
    "out:DH kr savings": 272897,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01010201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 119.69,
    "out:Primary Energy": 118.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.13,
    "out:CO2 Operational/m2": 38.6,
    "out:Total CO2/m2": 64.73,
    "out:Total CO2 (tons)": 333.29,
    "out:Klimatpaverkan": 43.68,
    "out:Initial Cost/MSEK": 9.382,
    "out:Running cost/(Apt SEK y)": 32552,
    "out:Running Cost over RSP/MSEK": 13.731,
    "out:LCP/MSEK": -3.608,
    "out:ROI% old": 8.79,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 8,
    "out:Return/kSEK/y": 738,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 207064,
    "out:DH kr savings": 262599,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 116.69,
    "out:Total Energy Use Post PV": 116.69,
    "out:Primary Energy": 115.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.13,
    "out:CO2 Operational/m2": 37.52,
    "out:Total CO2/m2": 63.65,
    "out:Total CO2 (tons)": 327.73,
    "out:Klimatpaverkan": 38.12,
    "out:Initial Cost/MSEK": 9.511,
    "out:Running cost/(Apt SEK y)": 31688,
    "out:Running Cost over RSP/MSEK": 13.366,
    "out:LCP/MSEK": -3.372,
    "out:ROI% old": 9.22,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 8,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222511,
    "out:DH kr savings": 278046,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 115.69,
    "out:Total Energy Use Post PV": 115.69,
    "out:Primary Energy": 116.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.41,
    "out:CO2 Operational/m2": 37.16,
    "out:Total CO2/m2": 63.57,
    "out:Total CO2 (tons)": 327.33,
    "out:Klimatpaverkan": 37.72,
    "out:Initial Cost/MSEK": 9.558,
    "out:Running cost/(Apt SEK y)": 31400,
    "out:Running Cost over RSP/MSEK": 13.244,
    "out:LCP/MSEK": -3.298,
    "out:ROI% old": 9.36,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 8,
    "out:Return/kSEK/y": 800,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 227660,
    "out:DH kr savings": 283195,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 112.69,
    "out:Total Energy Use Post PV": 112.69,
    "out:Primary Energy": 113.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.41,
    "out:CO2 Operational/m2": 36.08,
    "out:Total CO2/m2": 62.49,
    "out:Total CO2 (tons)": 321.77,
    "out:Klimatpaverkan": 32.16,
    "out:Initial Cost/MSEK": 9.687,
    "out:Running cost/(Apt SEK y)": 30536,
    "out:Running Cost over RSP/MSEK": 12.879,
    "out:LCP/MSEK": -3.061,
    "out:ROI% old": 9.77,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243107,
    "out:DH kr savings": 298642,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 114.84,
    "out:Primary Energy": 110.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.19,
    "out:CO2 Operational/m2": 30.37,
    "out:Total CO2/m2": 63.56,
    "out:Total CO2 (tons)": 327.25,
    "out:Klimatpaverkan": 37.64,
    "out:Initial Cost/MSEK": 9.994,
    "out:Running cost/(Apt SEK y)": 31407,
    "out:Running Cost over RSP/MSEK": 13.26,
    "out:LCP/MSEK": -3.75,
    "out:ROI% old": 8.93,
    "out:Payback discounted": 14,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 799,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268891,
    "out:DH kr savings": 262599,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 116.69,
    "out:Total Energy Use Post PV": 111.84,
    "out:Primary Energy": 107.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.19,
    "out:CO2 Operational/m2": 29.29,
    "out:Total CO2/m2": 62.48,
    "out:Total CO2 (tons)": 321.69,
    "out:Klimatpaverkan": 32.08,
    "out:Initial Cost/MSEK": 10.123,
    "out:Running cost/(Apt SEK y)": 30543,
    "out:Running Cost over RSP/MSEK": 12.895,
    "out:LCP/MSEK": -3.514,
    "out:ROI% old": 9.33,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 47.3,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 284338,
    "out:DH kr savings": 278046,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 115.69,
    "out:Total Energy Use Post PV": 110.84,
    "out:Primary Energy": 107.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.47,
    "out:CO2 Operational/m2": 28.93,
    "out:Total CO2/m2": 62.4,
    "out:Total CO2 (tons)": 321.29,
    "out:Klimatpaverkan": 31.68,
    "out:Initial Cost/MSEK": 10.17,
    "out:Running cost/(Apt SEK y)": 30255,
    "out:Running Cost over RSP/MSEK": 12.773,
    "out:LCP/MSEK": -3.439,
    "out:ROI% old": 9.45,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 48.6,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 8,
    "out:Return/kSEK/y": 862,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 289487,
    "out:DH kr savings": 283195,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 112.69,
    "out:Total Energy Use Post PV": 107.84,
    "out:Primary Energy": 104.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.47,
    "out:CO2 Operational/m2": 27.85,
    "out:Total CO2/m2": 61.32,
    "out:Total CO2 (tons)": 315.73,
    "out:Klimatpaverkan": 26.12,
    "out:Initial Cost/MSEK": 10.299,
    "out:Running cost/(Apt SEK y)": 29391,
    "out:Running Cost over RSP/MSEK": 12.408,
    "out:LCP/MSEK": -3.203,
    "out:ROI% old": 9.84,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 908,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304934,
    "out:DH kr savings": 298642,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 114.69,
    "out:Total Energy Use Post PV": 114.69,
    "out:Primary Energy": 115.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.13,
    "out:CO2 Operational/m2": 36.8,
    "out:Total CO2/m2": 62.93,
    "out:Total CO2 (tons)": 324.03,
    "out:Klimatpaverkan": 34.42,
    "out:Initial Cost/MSEK": 9.525,
    "out:Running cost/(Apt SEK y)": 31112,
    "out:Running Cost over RSP/MSEK": 13.122,
    "out:LCP/MSEK": -3.143,
    "out:ROI% old": 9.57,
    "out:Payback discounted": 13,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 815,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 232809,
    "out:DH kr savings": 288344,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 111.69,
    "out:Total Energy Use Post PV": 111.69,
    "out:Primary Energy": 112.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.13,
    "out:CO2 Operational/m2": 35.72,
    "out:Total CO2/m2": 61.85,
    "out:Total CO2 (tons)": 318.47,
    "out:Klimatpaverkan": 28.86,
    "out:Initial Cost/MSEK": 9.653,
    "out:Running cost/(Apt SEK y)": 30248,
    "out:Running Cost over RSP/MSEK": 12.758,
    "out:LCP/MSEK": -2.907,
    "out:ROI% old": 9.98,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 47.3,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 862,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 303791,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248256,
    "out:DH kr savings": 303791,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 111.69,
    "out:Total Energy Use Post PV": 111.69,
    "out:Primary Energy": 113.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.41,
    "out:CO2 Operational/m2": 35.72,
    "out:Total CO2/m2": 62.13,
    "out:Total CO2 (tons)": 319.92,
    "out:Klimatpaverkan": 30.31,
    "out:Initial Cost/MSEK": 9.7,
    "out:Running cost/(Apt SEK y)": 30248,
    "out:Running Cost over RSP/MSEK": 12.758,
    "out:LCP/MSEK": -2.954,
    "out:ROI% old": 9.94,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 47.3,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 862,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 303791,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248256,
    "out:DH kr savings": 303791,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 107.69,
    "out:Total Energy Use Post PV": 107.69,
    "out:Primary Energy": 110.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.41,
    "out:CO2 Operational/m2": 34.28,
    "out:Total CO2/m2": 60.69,
    "out:Total CO2 (tons)": 312.5,
    "out:Klimatpaverkan": 22.89,
    "out:Initial Cost/MSEK": 9.829,
    "out:Running cost/(Apt SEK y)": 29096,
    "out:Running Cost over RSP/MSEK": 12.271,
    "out:LCP/MSEK": -2.596,
    "out:ROI% old": 10.51,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 924,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 268852,
    "out:DH kr savings": 324387,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01010201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 114.69,
    "out:Total Energy Use Post PV": 109.84,
    "out:Primary Energy": 106.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.19,
    "out:CO2 Operational/m2": 28.57,
    "out:Total CO2/m2": 61.76,
    "out:Total CO2 (tons)": 317.98,
    "out:Klimatpaverkan": 28.37,
    "out:Initial Cost/MSEK": 10.137,
    "out:Running cost/(Apt SEK y)": 29967,
    "out:Running Cost over RSP/MSEK": 12.652,
    "out:LCP/MSEK": -3.284,
    "out:ROI% old": 9.66,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 877,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 294636,
    "out:DH kr savings": 288344,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 111.69,
    "out:Total Energy Use Post PV": 106.84,
    "out:Primary Energy": 103.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.19,
    "out:CO2 Operational/m2": 27.49,
    "out:Total CO2/m2": 60.68,
    "out:Total CO2 (tons)": 312.42,
    "out:Klimatpaverkan": 22.81,
    "out:Initial Cost/MSEK": 10.266,
    "out:Running cost/(Apt SEK y)": 29103,
    "out:Running Cost over RSP/MSEK": 12.287,
    "out:LCP/MSEK": -3.048,
    "out:ROI% old": 10.04,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 54.2,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 924,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 303791,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 310083,
    "out:DH kr savings": 303791,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.58,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 111.69,
    "out:Total Energy Use Post PV": 106.84,
    "out:Primary Energy": 104.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.47,
    "out:CO2 Operational/m2": 27.49,
    "out:Total CO2/m2": 60.96,
    "out:Total CO2 (tons)": 313.87,
    "out:Klimatpaverkan": 24.26,
    "out:Initial Cost/MSEK": 10.312,
    "out:Running cost/(Apt SEK y)": 29103,
    "out:Running Cost over RSP/MSEK": 12.287,
    "out:LCP/MSEK": -3.095,
    "out:ROI% old": 10,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 54.2,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 924,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 303791,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 310083,
    "out:DH kr savings": 303791,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 430329,
    "out:% savings (space heating)": 32.34,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 83.58,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01010201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.05,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 107.69,
    "out:Total Energy Use Post PV": 102.84,
    "out:Primary Energy": 101.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.47,
    "out:CO2 Operational/m2": 26.05,
    "out:Total CO2/m2": 59.52,
    "out:Total CO2 (tons)": 306.46,
    "out:Klimatpaverkan": 16.85,
    "out:Initial Cost/MSEK": 10.441,
    "out:Running cost/(Apt SEK y)": 27951,
    "out:Running Cost over RSP/MSEK": 11.8,
    "out:LCP/MSEK": -2.737,
    "out:ROI% old": 10.54,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.54,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 60.2,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 986,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 1.04,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 330679,
    "out:DH kr savings": 324387,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 412173,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.05,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.85,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 133.3,
    "out:Total Energy Use Post PV": 133.3,
    "out:Primary Energy": 123.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.5,
    "out:CO2 Operational/m2": 44.73,
    "out:Total CO2/m2": 56.23,
    "out:Total CO2 (tons)": 289.51,
    "out:Klimatpaverkan": -0.1,
    "out:Initial Cost/MSEK": 4.524,
    "out:Running cost/(Apt SEK y)": 36995,
    "out:Running Cost over RSP/MSEK": 15.612,
    "out:LCP/MSEK": -0.632,
    "out:ROI% old": 12.29,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 24.1,
    "out:Op energy cost/MSEK": 114,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 498,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 164768,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164768,
    "out:DH kr savings": 164768,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 472960,
    "out:% savings (space heating)": 25.63,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.85,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.95,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 129.3,
    "out:Primary Energy": 120.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.5,
    "out:CO2 Operational/m2": 43.29,
    "out:Total CO2/m2": 54.79,
    "out:Total CO2 (tons)": 282.09,
    "out:Klimatpaverkan": -7.52,
    "out:Initial Cost/MSEK": 4.653,
    "out:Running cost/(Apt SEK y)": 35843,
    "out:Running Cost over RSP/MSEK": 15.126,
    "out:LCP/MSEK": -0.274,
    "out:ROI% old": 13.44,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 560,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 185364,
    "out:DH kr savings": 185364,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 452866,
    "out:% savings (space heating)": 28.79,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.95,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.85,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 129.3,
    "out:Primary Energy": 120.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.78,
    "out:CO2 Operational/m2": 43.29,
    "out:Total CO2/m2": 55.07,
    "out:Total CO2 (tons)": 283.54,
    "out:Klimatpaverkan": -6.07,
    "out:Initial Cost/MSEK": 4.7,
    "out:Running cost/(Apt SEK y)": 35843,
    "out:Running Cost over RSP/MSEK": 15.126,
    "out:LCP/MSEK": -0.321,
    "out:ROI% old": 13.31,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 560,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185364,
    "out:DH kr savings": 185364,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 472960,
    "out:% savings (space heating)": 25.63,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.85,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.95,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 125.3,
    "out:Primary Energy": 117.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.78,
    "out:CO2 Operational/m2": 41.85,
    "out:Total CO2/m2": 53.63,
    "out:Total CO2 (tons)": 276.13,
    "out:Klimatpaverkan": -13.48,
    "out:Initial Cost/MSEK": 4.828,
    "out:Running cost/(Apt SEK y)": 34691,
    "out:Running Cost over RSP/MSEK": 14.639,
    "out:LCP/MSEK": 0.037,
    "out:ROI% old": 14.39,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205960,
    "out:DH kr savings": 205960,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 452866,
    "out:% savings (space heating)": 28.79,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.95,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.85,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 133.3,
    "out:Total Energy Use Post PV": 129.29,
    "out:Primary Energy": 116.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.56,
    "out:CO2 Operational/m2": 32.3,
    "out:Total CO2/m2": 50.86,
    "out:Total CO2 (tons)": 261.86,
    "out:Klimatpaverkan": -27.75,
    "out:Initial Cost/MSEK": 5.136,
    "out:Running cost/(Apt SEK y)": 35890,
    "out:Running Cost over RSP/MSEK": 15.158,
    "out:LCP/MSEK": -0.79,
    "out:ROI% old": 12.09,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 111,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 557,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 164768,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 224426,
    "out:DH kr savings": 164768,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 472960,
    "out:% savings (space heating)": 25.63,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.85,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.95,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 125.29,
    "out:Primary Energy": 112.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.56,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 49.42,
    "out:Total CO2 (tons)": 254.44,
    "out:Klimatpaverkan": -35.17,
    "out:Initial Cost/MSEK": 5.265,
    "out:Running cost/(Apt SEK y)": 34738,
    "out:Running Cost over RSP/MSEK": 14.671,
    "out:LCP/MSEK": -0.432,
    "out:ROI% old": 13.11,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 245022,
    "out:DH kr savings": 185364,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 452866,
    "out:% savings (space heating)": 28.79,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.95,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.85,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 129.3,
    "out:Total Energy Use Post PV": 125.29,
    "out:Primary Energy": 113.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.84,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 49.7,
    "out:Total CO2 (tons)": 255.89,
    "out:Klimatpaverkan": -33.72,
    "out:Initial Cost/MSEK": 5.312,
    "out:Running cost/(Apt SEK y)": 34738,
    "out:Running Cost over RSP/MSEK": 14.671,
    "out:LCP/MSEK": -0.479,
    "out:ROI% old": 13,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 185364,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245022,
    "out:DH kr savings": 185364,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 472960,
    "out:% savings (space heating)": 25.63,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.85,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.95,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 125.3,
    "out:Total Energy Use Post PV": 121.29,
    "out:Primary Energy": 110.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.84,
    "out:CO2 Operational/m2": 29.42,
    "out:Total CO2/m2": 48.26,
    "out:Total CO2 (tons)": 248.48,
    "out:Klimatpaverkan": -41.13,
    "out:Initial Cost/MSEK": 5.44,
    "out:Running cost/(Apt SEK y)": 33586,
    "out:Running Cost over RSP/MSEK": 14.185,
    "out:LCP/MSEK": -0.121,
    "out:ROI% old": 13.97,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 205960,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 265618,
    "out:DH kr savings": 205960,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 452866,
    "out:% savings (space heating)": 28.79,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.95,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.85,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 128.3,
    "out:Total Energy Use Post PV": 128.3,
    "out:Primary Energy": 120.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.5,
    "out:CO2 Operational/m2": 42.93,
    "out:Total CO2/m2": 54.43,
    "out:Total CO2 (tons)": 280.24,
    "out:Klimatpaverkan": -9.37,
    "out:Initial Cost/MSEK": 4.667,
    "out:Running cost/(Apt SEK y)": 35555,
    "out:Running Cost over RSP/MSEK": 15.004,
    "out:LCP/MSEK": -0.166,
    "out:ROI% old": 13.78,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 575,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 190513,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 190513,
    "out:DH kr savings": 190513,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 472960,
    "out:% savings (space heating)": 25.63,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.85,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.95,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 124.3,
    "out:Total Energy Use Post PV": 124.3,
    "out:Primary Energy": 116.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.5,
    "out:CO2 Operational/m2": 41.49,
    "out:Total CO2/m2": 52.99,
    "out:Total CO2 (tons)": 272.82,
    "out:Klimatpaverkan": -16.79,
    "out:Initial Cost/MSEK": 4.795,
    "out:Running cost/(Apt SEK y)": 34403,
    "out:Running Cost over RSP/MSEK": 14.517,
    "out:LCP/MSEK": 0.191,
    "out:ROI% old": 14.86,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211109,
    "out:DH kr savings": 211109,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 452866,
    "out:% savings (space heating)": 28.79,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.95,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.85,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 124.3,
    "out:Total Energy Use Post PV": 124.3,
    "out:Primary Energy": 118.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.78,
    "out:CO2 Operational/m2": 41.49,
    "out:Total CO2/m2": 53.27,
    "out:Total CO2 (tons)": 274.27,
    "out:Klimatpaverkan": -15.34,
    "out:Initial Cost/MSEK": 4.842,
    "out:Running cost/(Apt SEK y)": 34403,
    "out:Running Cost over RSP/MSEK": 14.517,
    "out:LCP/MSEK": 0.145,
    "out:ROI% old": 14.71,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211109,
    "out:DH kr savings": 211109,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 472960,
    "out:% savings (space heating)": 25.63,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.85,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.95,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 120.3,
    "out:Total Energy Use Post PV": 120.3,
    "out:Primary Energy": 114.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.78,
    "out:CO2 Operational/m2": 40.05,
    "out:Total CO2/m2": 51.83,
    "out:Total CO2 (tons)": 266.86,
    "out:Klimatpaverkan": -22.75,
    "out:Initial Cost/MSEK": 4.971,
    "out:Running cost/(Apt SEK y)": 33251,
    "out:Running Cost over RSP/MSEK": 14.031,
    "out:LCP/MSEK": 0.502,
    "out:ROI% old": 15.73,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231705,
    "out:DH kr savings": 231705,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 452866,
    "out:% savings (space heating)": 28.79,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.95,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.85,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 128.3,
    "out:Total Energy Use Post PV": 124.29,
    "out:Primary Energy": 112.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.56,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 49.06,
    "out:Total CO2 (tons)": 252.59,
    "out:Klimatpaverkan": -37.02,
    "out:Initial Cost/MSEK": 5.279,
    "out:Running cost/(Apt SEK y)": 34450,
    "out:Running Cost over RSP/MSEK": 14.55,
    "out:LCP/MSEK": -0.324,
    "out:ROI% old": 13.41,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 635,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 190513,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 250171,
    "out:DH kr savings": 190513,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 472960,
    "out:% savings (space heating)": 25.63,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.85,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.95,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 124.3,
    "out:Total Energy Use Post PV": 120.29,
    "out:Primary Energy": 109.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.56,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 47.62,
    "out:Total CO2 (tons)": 245.18,
    "out:Klimatpaverkan": -44.43,
    "out:Initial Cost/MSEK": 5.407,
    "out:Running cost/(Apt SEK y)": 33298,
    "out:Running Cost over RSP/MSEK": 14.063,
    "out:LCP/MSEK": 0.034,
    "out:ROI% old": 14.37,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 697,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 270767,
    "out:DH kr savings": 211109,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 452866,
    "out:% savings (space heating)": 28.79,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.95,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.85,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 124.3,
    "out:Total Energy Use Post PV": 120.29,
    "out:Primary Energy": 110.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.84,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 47.9,
    "out:Total CO2 (tons)": 246.63,
    "out:Klimatpaverkan": -42.98,
    "out:Initial Cost/MSEK": 5.454,
    "out:Running cost/(Apt SEK y)": 33298,
    "out:Running Cost over RSP/MSEK": 14.063,
    "out:LCP/MSEK": -0.013,
    "out:ROI% old": 14.25,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 697,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 270767,
    "out:DH kr savings": 211109,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 472960,
    "out:% savings (space heating)": 25.63,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.85,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.95,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 120.3,
    "out:Total Energy Use Post PV": 116.29,
    "out:Primary Energy": 107.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.84,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 46.46,
    "out:Total CO2 (tons)": 239.21,
    "out:Klimatpaverkan": -50.4,
    "out:Initial Cost/MSEK": 5.583,
    "out:Running cost/(Apt SEK y)": 32146,
    "out:Running Cost over RSP/MSEK": 13.577,
    "out:LCP/MSEK": 0.345,
    "out:ROI% old": 15.17,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 759,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291363,
    "out:DH kr savings": 231705,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 452866,
    "out:% savings (space heating)": 28.79,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.95,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.85,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 128.69,
    "out:Total Energy Use Post PV": 128.69,
    "out:Primary Energy": 126.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.84,
    "out:CO2 Operational/m2": 41.84,
    "out:Total CO2/m2": 55.68,
    "out:Total CO2 (tons)": 286.7,
    "out:Klimatpaverkan": -2.91,
    "out:Initial Cost/MSEK": 5.321,
    "out:Running cost/(Apt SEK y)": 35143,
    "out:Running Cost over RSP/MSEK": 14.825,
    "out:LCP/MSEK": -0.642,
    "out:ROI% old": 12.56,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 108,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 598,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160723,
    "out:DH kr savings": 216258,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 472960,
    "out:% savings (space heating)": 25.63,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.85,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.95,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 124.69,
    "out:Primary Energy": 122.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.84,
    "out:CO2 Operational/m2": 40.4,
    "out:Total CO2/m2": 54.24,
    "out:Total CO2 (tons)": 279.29,
    "out:Klimatpaverkan": -10.32,
    "out:Initial Cost/MSEK": 5.45,
    "out:Running cost/(Apt SEK y)": 33992,
    "out:Running Cost over RSP/MSEK": 14.339,
    "out:LCP/MSEK": -0.284,
    "out:ROI% old": 13.54,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181319,
    "out:DH kr savings": 236854,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 452866,
    "out:% savings (space heating)": 28.79,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.95,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.85,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 124.69,
    "out:Primary Energy": 123.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.12,
    "out:CO2 Operational/m2": 40.4,
    "out:Total CO2/m2": 54.52,
    "out:Total CO2 (tons)": 280.74,
    "out:Klimatpaverkan": -8.87,
    "out:Initial Cost/MSEK": 5.497,
    "out:Running cost/(Apt SEK y)": 33992,
    "out:Running Cost over RSP/MSEK": 14.339,
    "out:LCP/MSEK": -0.331,
    "out:ROI% old": 13.43,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 66,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181319,
    "out:DH kr savings": 236854,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 472960,
    "out:% savings (space heating)": 25.63,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.85,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.95,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 120.69,
    "out:Primary Energy": 120.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.12,
    "out:CO2 Operational/m2": 38.96,
    "out:Total CO2/m2": 53.08,
    "out:Total CO2 (tons)": 273.32,
    "out:Klimatpaverkan": -16.29,
    "out:Initial Cost/MSEK": 5.625,
    "out:Running cost/(Apt SEK y)": 32840,
    "out:Running Cost over RSP/MSEK": 13.852,
    "out:LCP/MSEK": 0.027,
    "out:ROI% old": 14.35,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 36.4,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201915,
    "out:DH kr savings": 257450,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 452866,
    "out:% savings (space heating)": 28.79,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.95,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.85,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 128.69,
    "out:Total Energy Use Post PV": 123.84,
    "out:Primary Energy": 117.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.9,
    "out:CO2 Operational/m2": 33.61,
    "out:Total CO2/m2": 54.51,
    "out:Total CO2 (tons)": 280.66,
    "out:Klimatpaverkan": -8.95,
    "out:Initial Cost/MSEK": 5.933,
    "out:Running cost/(Apt SEK y)": 33998,
    "out:Running Cost over RSP/MSEK": 14.355,
    "out:LCP/MSEK": -0.783,
    "out:ROI% old": 12.4,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 659,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 222550,
    "out:DH kr savings": 216258,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 472960,
    "out:% savings (space heating)": 25.63,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.85,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.95,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 119.84,
    "out:Primary Energy": 114.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.9,
    "out:CO2 Operational/m2": 32.17,
    "out:Total CO2/m2": 53.07,
    "out:Total CO2 (tons)": 273.24,
    "out:Klimatpaverkan": -16.37,
    "out:Initial Cost/MSEK": 6.062,
    "out:Running cost/(Apt SEK y)": 32847,
    "out:Running Cost over RSP/MSEK": 13.868,
    "out:LCP/MSEK": -0.425,
    "out:ROI% old": 13.28,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243146,
    "out:DH kr savings": 236854,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 452866,
    "out:% savings (space heating)": 28.79,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.95,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.85,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 124.69,
    "out:Total Energy Use Post PV": 119.84,
    "out:Primary Energy": 114.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.18,
    "out:CO2 Operational/m2": 32.17,
    "out:Total CO2/m2": 53.35,
    "out:Total CO2 (tons)": 274.69,
    "out:Klimatpaverkan": -14.92,
    "out:Initial Cost/MSEK": 6.109,
    "out:Running cost/(Apt SEK y)": 32847,
    "out:Running Cost over RSP/MSEK": 13.868,
    "out:LCP/MSEK": -0.472,
    "out:ROI% old": 13.18,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 722,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 236854,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 243146,
    "out:DH kr savings": 236854,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 472960,
    "out:% savings (space heating)": 25.63,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.85,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.95,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 120.69,
    "out:Total Energy Use Post PV": 115.84,
    "out:Primary Energy": 111.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.18,
    "out:CO2 Operational/m2": 30.73,
    "out:Total CO2/m2": 51.91,
    "out:Total CO2 (tons)": 267.28,
    "out:Klimatpaverkan": -22.33,
    "out:Initial Cost/MSEK": 6.237,
    "out:Running cost/(Apt SEK y)": 31695,
    "out:Running Cost over RSP/MSEK": 13.381,
    "out:LCP/MSEK": -0.115,
    "out:ROI% old": 14.02,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 263742,
    "out:DH kr savings": 257450,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 452866,
    "out:% savings (space heating)": 28.79,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.95,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.85,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 122.69,
    "out:Total Energy Use Post PV": 122.69,
    "out:Primary Energy": 122.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.84,
    "out:CO2 Operational/m2": 39.68,
    "out:Total CO2/m2": 53.52,
    "out:Total CO2 (tons)": 275.58,
    "out:Klimatpaverkan": -14.03,
    "out:Initial Cost/MSEK": 5.463,
    "out:Running cost/(Apt SEK y)": 33416,
    "out:Running Cost over RSP/MSEK": 14.095,
    "out:LCP/MSEK": -0.055,
    "out:ROI% old": 14.14,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 34.1,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 691,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191617,
    "out:DH kr savings": 247152,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 472960,
    "out:% savings (space heating)": 25.63,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.85,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.95,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 118.69,
    "out:Total Energy Use Post PV": 118.69,
    "out:Primary Energy": 119.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.84,
    "out:CO2 Operational/m2": 38.24,
    "out:Total CO2/m2": 52.08,
    "out:Total CO2 (tons)": 268.17,
    "out:Klimatpaverkan": -21.44,
    "out:Initial Cost/MSEK": 5.592,
    "out:Running cost/(Apt SEK y)": 32264,
    "out:Running Cost over RSP/MSEK": 13.609,
    "out:LCP/MSEK": 0.303,
    "out:ROI% old": 15.06,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 38.7,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 753,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212213,
    "out:DH kr savings": 267748,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 452866,
    "out:% savings (space heating)": 28.79,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.95,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.85,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 119.69,
    "out:Primary Energy": 120.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.12,
    "out:CO2 Operational/m2": 38.6,
    "out:Total CO2/m2": 52.72,
    "out:Total CO2 (tons)": 271.47,
    "out:Klimatpaverkan": -18.14,
    "out:Initial Cost/MSEK": 5.639,
    "out:Running cost/(Apt SEK y)": 32552,
    "out:Running Cost over RSP/MSEK": 13.731,
    "out:LCP/MSEK": 0.135,
    "out:ROI% old": 14.63,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 738,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 207064,
    "out:DH kr savings": 262599,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 472960,
    "out:% savings (space heating)": 25.63,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.85,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.95,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 115.69,
    "out:Total Energy Use Post PV": 115.69,
    "out:Primary Energy": 117.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.12,
    "out:CO2 Operational/m2": 37.16,
    "out:Total CO2/m2": 51.28,
    "out:Total CO2 (tons)": 264.06,
    "out:Klimatpaverkan": -25.55,
    "out:Initial Cost/MSEK": 5.768,
    "out:Running cost/(Apt SEK y)": 31400,
    "out:Running Cost over RSP/MSEK": 13.244,
    "out:LCP/MSEK": 0.493,
    "out:ROI% old": 15.51,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 800,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227660,
    "out:DH kr savings": 283195,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 452866,
    "out:% savings (space heating)": 28.79,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.95,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.85,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 122.69,
    "out:Total Energy Use Post PV": 117.84,
    "out:Primary Energy": 113.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.9,
    "out:CO2 Operational/m2": 31.45,
    "out:Total CO2/m2": 52.35,
    "out:Total CO2 (tons)": 269.54,
    "out:Klimatpaverkan": -20.07,
    "out:Initial Cost/MSEK": 6.075,
    "out:Running cost/(Apt SEK y)": 32271,
    "out:Running Cost over RSP/MSEK": 13.625,
    "out:LCP/MSEK": -0.196,
    "out:ROI% old": 13.83,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 753,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 253444,
    "out:DH kr savings": 247152,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 472960,
    "out:% savings (space heating)": 25.63,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.85,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.95,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 118.69,
    "out:Total Energy Use Post PV": 113.84,
    "out:Primary Energy": 110.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.9,
    "out:CO2 Operational/m2": 30.01,
    "out:Total CO2/m2": 50.91,
    "out:Total CO2 (tons)": 262.12,
    "out:Klimatpaverkan": -27.49,
    "out:Initial Cost/MSEK": 6.204,
    "out:Running cost/(Apt SEK y)": 31119,
    "out:Running Cost over RSP/MSEK": 13.138,
    "out:LCP/MSEK": 0.162,
    "out:ROI% old": 14.66,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 44.7,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 815,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 274040,
    "out:DH kr savings": 267748,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 452866,
    "out:% savings (space heating)": 28.79,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.95,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.85,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 114.84,
    "out:Primary Energy": 111.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.18,
    "out:CO2 Operational/m2": 30.37,
    "out:Total CO2/m2": 51.55,
    "out:Total CO2 (tons)": 265.43,
    "out:Klimatpaverkan": -24.18,
    "out:Initial Cost/MSEK": 6.251,
    "out:Running cost/(Apt SEK y)": 31407,
    "out:Running Cost over RSP/MSEK": 13.26,
    "out:LCP/MSEK": -0.007,
    "out:ROI% old": 14.27,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 799,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268891,
    "out:DH kr savings": 262599,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 472960,
    "out:% savings (space heating)": 25.63,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.85,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.95,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 115.69,
    "out:Total Energy Use Post PV": 110.84,
    "out:Primary Energy": 108.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.18,
    "out:CO2 Operational/m2": 28.93,
    "out:Total CO2/m2": 50.11,
    "out:Total CO2 (tons)": 258.01,
    "out:Klimatpaverkan": -31.6,
    "out:Initial Cost/MSEK": 6.38,
    "out:Running cost/(Apt SEK y)": 30255,
    "out:Running Cost over RSP/MSEK": 12.773,
    "out:LCP/MSEK": 0.351,
    "out:ROI% old": 15.07,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.73,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 48.6,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 862,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 289487,
    "out:DH kr savings": 283195,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 452866,
    "out:% savings (space heating)": 28.79,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.95,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.94,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 118.3,
    "out:Total Energy Use Post PV": 118.3,
    "out:Primary Energy": 110.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.49,
    "out:CO2 Operational/m2": 39.33,
    "out:Total CO2/m2": 53.81,
    "out:Total CO2 (tons)": 277.09,
    "out:Klimatpaverkan": -12.52,
    "out:Initial Cost/MSEK": 5.817,
    "out:Running cost/(Apt SEK y)": 32675,
    "out:Running Cost over RSP/MSEK": 13.788,
    "out:LCP/MSEK": -0.101,
    "out:ROI% old": 14.04,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 731,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 242003,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 242003,
    "out:DH kr savings": 242003,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 396171,
    "out:% savings (space heating)": 37.71,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.72,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 115.3,
    "out:Total Energy Use Post PV": 115.3,
    "out:Primary Energy": 107.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.49,
    "out:CO2 Operational/m2": 38.25,
    "out:Total CO2/m2": 52.73,
    "out:Total CO2 (tons)": 271.53,
    "out:Klimatpaverkan": -18.08,
    "out:Initial Cost/MSEK": 5.946,
    "out:Running cost/(Apt SEK y)": 31811,
    "out:Running Cost over RSP/MSEK": 13.423,
    "out:LCP/MSEK": 0.136,
    "out:ROI% old": 14.61,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 777,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257450,
    "out:DH kr savings": 257450,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 379568,
    "out:% savings (space heating)": 40.32,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.72,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.94,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 114.3,
    "out:Total Energy Use Post PV": 114.3,
    "out:Primary Energy": 107.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.77,
    "out:CO2 Operational/m2": 37.89,
    "out:Total CO2/m2": 52.66,
    "out:Total CO2 (tons)": 271.13,
    "out:Klimatpaverkan": -18.48,
    "out:Initial Cost/MSEK": 5.993,
    "out:Running cost/(Apt SEK y)": 31523,
    "out:Running Cost over RSP/MSEK": 13.301,
    "out:LCP/MSEK": 0.21,
    "out:ROI% old": 14.79,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 44.7,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 793,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 262599,
    "out:DH kr savings": 262599,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 396171,
    "out:% savings (space heating)": 37.71,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.72,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 111.3,
    "out:Total Energy Use Post PV": 111.3,
    "out:Primary Energy": 105.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.77,
    "out:CO2 Operational/m2": 36.81,
    "out:Total CO2/m2": 51.58,
    "out:Total CO2 (tons)": 265.57,
    "out:Klimatpaverkan": -24.04,
    "out:Initial Cost/MSEK": 6.122,
    "out:Running cost/(Apt SEK y)": 30659,
    "out:Running Cost over RSP/MSEK": 12.936,
    "out:LCP/MSEK": 0.446,
    "out:ROI% old": 15.33,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 48.6,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 840,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 278046,
    "out:DH kr savings": 278046,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 379568,
    "out:% savings (space heating)": 40.32,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.72,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.94,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 118.3,
    "out:Total Energy Use Post PV": 114.29,
    "out:Primary Energy": 103.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.55,
    "out:CO2 Operational/m2": 26.9,
    "out:Total CO2/m2": 48.44,
    "out:Total CO2 (tons)": 249.44,
    "out:Klimatpaverkan": -40.17,
    "out:Initial Cost/MSEK": 6.429,
    "out:Running cost/(Apt SEK y)": 31570,
    "out:Running Cost over RSP/MSEK": 13.334,
    "out:LCP/MSEK": -0.258,
    "out:ROI% old": 13.71,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 44.7,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 791,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 242003,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 301661,
    "out:DH kr savings": 242003,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 396171,
    "out:% savings (space heating)": 37.71,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.72,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 115.3,
    "out:Total Energy Use Post PV": 111.29,
    "out:Primary Energy": 100.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.55,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 47.36,
    "out:Total CO2 (tons)": 243.88,
    "out:Klimatpaverkan": -45.73,
    "out:Initial Cost/MSEK": 6.558,
    "out:Running cost/(Apt SEK y)": 30706,
    "out:Running Cost over RSP/MSEK": 12.969,
    "out:LCP/MSEK": -0.022,
    "out:ROI% old": 14.24,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 48.6,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 837,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 257450,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 317108,
    "out:DH kr savings": 257450,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 379568,
    "out:% savings (space heating)": 40.32,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.72,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.94,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 114.3,
    "out:Total Energy Use Post PV": 110.29,
    "out:Primary Energy": 100.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.83,
    "out:CO2 Operational/m2": 25.46,
    "out:Total CO2/m2": 47.29,
    "out:Total CO2 (tons)": 243.48,
    "out:Klimatpaverkan": -46.13,
    "out:Initial Cost/MSEK": 6.605,
    "out:Running cost/(Apt SEK y)": 30419,
    "out:Running Cost over RSP/MSEK": 12.847,
    "out:LCP/MSEK": 0.052,
    "out:ROI% old": 14.4,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 853,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 322257,
    "out:DH kr savings": 262599,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 396171,
    "out:% savings (space heating)": 37.71,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.72,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 111.3,
    "out:Total Energy Use Post PV": 107.29,
    "out:Primary Energy": 97.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.83,
    "out:CO2 Operational/m2": 24.38,
    "out:Total CO2/m2": 46.21,
    "out:Total CO2 (tons)": 237.92,
    "out:Klimatpaverkan": -51.69,
    "out:Initial Cost/MSEK": 6.734,
    "out:Running cost/(Apt SEK y)": 29555,
    "out:Running Cost over RSP/MSEK": 12.482,
    "out:LCP/MSEK": 0.289,
    "out:ROI% old": 14.9,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 54.2,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 899,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 278046,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 337704,
    "out:DH kr savings": 278046,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 379568,
    "out:% savings (space heating)": 40.32,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.72,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.94,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 113.3,
    "out:Total Energy Use Post PV": 113.3,
    "out:Primary Energy": 107.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.49,
    "out:CO2 Operational/m2": 37.53,
    "out:Total CO2/m2": 52.01,
    "out:Total CO2 (tons)": 267.82,
    "out:Klimatpaverkan": -21.79,
    "out:Initial Cost/MSEK": 5.96,
    "out:Running cost/(Apt SEK y)": 31235,
    "out:Running Cost over RSP/MSEK": 13.18,
    "out:LCP/MSEK": 0.365,
    "out:ROI% old": 15.16,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 267748,
    "out:DH kr savings": 267748,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 396171,
    "out:% savings (space heating)": 37.71,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.72,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 109.3,
    "out:Total Energy Use Post PV": 109.3,
    "out:Primary Energy": 104.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.49,
    "out:CO2 Operational/m2": 36.09,
    "out:Total CO2/m2": 50.57,
    "out:Total CO2 (tons)": 260.41,
    "out:Klimatpaverkan": -29.2,
    "out:Initial Cost/MSEK": 6.088,
    "out:Running cost/(Apt SEK y)": 30084,
    "out:Running Cost over RSP/MSEK": 12.693,
    "out:LCP/MSEK": 0.723,
    "out:ROI% old": 15.98,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 871,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 288344,
    "out:DH kr savings": 288344,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 379568,
    "out:% savings (space heating)": 40.32,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.72,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.94,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 109.3,
    "out:Total Energy Use Post PV": 109.3,
    "out:Primary Energy": 104.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.77,
    "out:CO2 Operational/m2": 36.09,
    "out:Total CO2/m2": 50.86,
    "out:Total CO2 (tons)": 261.86,
    "out:Klimatpaverkan": -27.75,
    "out:Initial Cost/MSEK": 6.135,
    "out:Running cost/(Apt SEK y)": 30084,
    "out:Running Cost over RSP/MSEK": 12.693,
    "out:LCP/MSEK": 0.676,
    "out:ROI% old": 15.86,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 871,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 288344,
    "out:DH kr savings": 288344,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 396171,
    "out:% savings (space heating)": 37.71,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.72,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 106.3,
    "out:Total Energy Use Post PV": 106.3,
    "out:Primary Energy": 102.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.77,
    "out:CO2 Operational/m2": 35.01,
    "out:Total CO2/m2": 49.78,
    "out:Total CO2 (tons)": 256.3,
    "out:Klimatpaverkan": -33.31,
    "out:Initial Cost/MSEK": 6.264,
    "out:Running cost/(Apt SEK y)": 29220,
    "out:Running Cost over RSP/MSEK": 12.328,
    "out:LCP/MSEK": 0.912,
    "out:ROI% old": 16.37,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 15,
    "out:Return/kSEK/y": 917,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 303791,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 303791,
    "out:DH kr savings": 303791,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 379568,
    "out:% savings (space heating)": 40.32,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.72,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.94,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 113.3,
    "out:Total Energy Use Post PV": 109.29,
    "out:Primary Energy": 99.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.55,
    "out:CO2 Operational/m2": 25.1,
    "out:Total CO2/m2": 46.64,
    "out:Total CO2 (tons)": 240.17,
    "out:Klimatpaverkan": -49.44,
    "out:Initial Cost/MSEK": 6.572,
    "out:Running cost/(Apt SEK y)": 30131,
    "out:Running Cost over RSP/MSEK": 12.725,
    "out:LCP/MSEK": 0.207,
    "out:ROI% old": 14.74,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 868,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 327406,
    "out:DH kr savings": 267748,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 396171,
    "out:% savings (space heating)": 37.71,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.72,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 109.3,
    "out:Total Energy Use Post PV": 105.29,
    "out:Primary Energy": 97.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.55,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 45.2,
    "out:Total CO2 (tons)": 232.76,
    "out:Klimatpaverkan": -56.85,
    "out:Initial Cost/MSEK": 6.7,
    "out:Running cost/(Apt SEK y)": 28979,
    "out:Running Cost over RSP/MSEK": 12.239,
    "out:LCP/MSEK": 0.565,
    "out:ROI% old": 15.49,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 930,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 348002,
    "out:DH kr savings": 288344,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 379568,
    "out:% savings (space heating)": 40.32,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.72,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.94,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 109.3,
    "out:Total Energy Use Post PV": 105.29,
    "out:Primary Energy": 97.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.83,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 45.49,
    "out:Total CO2 (tons)": 234.21,
    "out:Klimatpaverkan": -55.4,
    "out:Initial Cost/MSEK": 6.747,
    "out:Running cost/(Apt SEK y)": 28979,
    "out:Running Cost over RSP/MSEK": 12.239,
    "out:LCP/MSEK": 0.518,
    "out:ROI% old": 15.38,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 930,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 348002,
    "out:DH kr savings": 288344,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 396171,
    "out:% savings (space heating)": 37.71,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.72,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 106.3,
    "out:Total Energy Use Post PV": 102.29,
    "out:Primary Energy": 94.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.83,
    "out:CO2 Operational/m2": 22.58,
    "out:Total CO2/m2": 44.41,
    "out:Total CO2 (tons)": 228.65,
    "out:Klimatpaverkan": -60.96,
    "out:Initial Cost/MSEK": 6.876,
    "out:Running cost/(Apt SEK y)": 28115,
    "out:Running Cost over RSP/MSEK": 11.874,
    "out:LCP/MSEK": 0.754,
    "out:ROI% old": 15.85,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 61.8,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 977,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 303791,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 363449,
    "out:DH kr savings": 303791,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 379568,
    "out:% savings (space heating)": 40.32,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.72,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.94,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 113.69,
    "out:Total Energy Use Post PV": 113.69,
    "out:Primary Energy": 113.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.83,
    "out:CO2 Operational/m2": 36.44,
    "out:Total CO2/m2": 53.27,
    "out:Total CO2 (tons)": 274.29,
    "out:Klimatpaverkan": -15.32,
    "out:Initial Cost/MSEK": 6.614,
    "out:Running cost/(Apt SEK y)": 30824,
    "out:Running Cost over RSP/MSEK": 13.001,
    "out:LCP/MSEK": -0.11,
    "out:ROI% old": 14.05,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 44.7,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 831,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293493,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 237958,
    "out:DH kr savings": 293493,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 396171,
    "out:% savings (space heating)": 37.71,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.72,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 110.69,
    "out:Total Energy Use Post PV": 110.69,
    "out:Primary Energy": 110.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.83,
    "out:CO2 Operational/m2": 35.36,
    "out:Total CO2/m2": 52.19,
    "out:Total CO2 (tons)": 268.73,
    "out:Klimatpaverkan": -20.88,
    "out:Initial Cost/MSEK": 6.743,
    "out:Running cost/(Apt SEK y)": 29960,
    "out:Running Cost over RSP/MSEK": 12.636,
    "out:LCP/MSEK": 0.126,
    "out:ROI% old": 14.55,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 48.6,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 877,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 253405,
    "out:DH kr savings": 308940,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 379568,
    "out:% savings (space heating)": 40.32,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.72,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.94,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 109.69,
    "out:Total Energy Use Post PV": 109.69,
    "out:Primary Energy": 110.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.11,
    "out:CO2 Operational/m2": 35,
    "out:Total CO2/m2": 52.11,
    "out:Total CO2 (tons)": 268.32,
    "out:Klimatpaverkan": -21.29,
    "out:Initial Cost/MSEK": 6.79,
    "out:Running cost/(Apt SEK y)": 29672,
    "out:Running Cost over RSP/MSEK": 12.514,
    "out:LCP/MSEK": 0.2,
    "out:ROI% old": 14.71,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 893,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 314089,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 258554,
    "out:DH kr savings": 314089,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 396171,
    "out:% savings (space heating)": 37.71,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.72,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 106.69,
    "out:Total Energy Use Post PV": 106.69,
    "out:Primary Energy": 107.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.11,
    "out:CO2 Operational/m2": 33.92,
    "out:Total CO2/m2": 51.03,
    "out:Total CO2 (tons)": 262.76,
    "out:Klimatpaverkan": -26.85,
    "out:Initial Cost/MSEK": 6.918,
    "out:Running cost/(Apt SEK y)": 28808,
    "out:Running Cost over RSP/MSEK": 12.149,
    "out:LCP/MSEK": 0.437,
    "out:ROI% old": 15.19,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 54.2,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 940,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 329536,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 274001,
    "out:DH kr savings": 329536,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 379568,
    "out:% savings (space heating)": 40.32,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.72,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.94,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 113.69,
    "out:Total Energy Use Post PV": 108.84,
    "out:Primary Energy": 104.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.89,
    "out:CO2 Operational/m2": 28.21,
    "out:Total CO2/m2": 52.1,
    "out:Total CO2 (tons)": 268.24,
    "out:Klimatpaverkan": -21.37,
    "out:Initial Cost/MSEK": 7.226,
    "out:Running cost/(Apt SEK y)": 29679,
    "out:Running Cost over RSP/MSEK": 12.53,
    "out:LCP/MSEK": -0.252,
    "out:ROI% old": 13.79,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 893,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293493,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 299785,
    "out:DH kr savings": 293493,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 396171,
    "out:% savings (space heating)": 37.71,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.72,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 110.69,
    "out:Total Energy Use Post PV": 105.84,
    "out:Primary Energy": 101.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.89,
    "out:CO2 Operational/m2": 27.13,
    "out:Total CO2/m2": 51.02,
    "out:Total CO2 (tons)": 262.68,
    "out:Klimatpaverkan": -26.93,
    "out:Initial Cost/MSEK": 7.355,
    "out:Running cost/(Apt SEK y)": 28815,
    "out:Running Cost over RSP/MSEK": 12.165,
    "out:LCP/MSEK": -0.016,
    "out:ROI% old": 14.26,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 939,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 315232,
    "out:DH kr savings": 308940,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 379568,
    "out:% savings (space heating)": 40.32,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.72,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.94,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 109.69,
    "out:Total Energy Use Post PV": 104.84,
    "out:Primary Energy": 101.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.17,
    "out:CO2 Operational/m2": 26.77,
    "out:Total CO2/m2": 50.94,
    "out:Total CO2 (tons)": 262.28,
    "out:Klimatpaverkan": -27.33,
    "out:Initial Cost/MSEK": 7.402,
    "out:Running cost/(Apt SEK y)": 28527,
    "out:Running Cost over RSP/MSEK": 12.044,
    "out:LCP/MSEK": 0.059,
    "out:ROI% old": 14.4,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 955,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 314089,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 320381,
    "out:DH kr savings": 314089,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 396171,
    "out:% savings (space heating)": 37.71,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.72,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 106.69,
    "out:Total Energy Use Post PV": 101.84,
    "out:Primary Energy": 98.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.17,
    "out:CO2 Operational/m2": 25.69,
    "out:Total CO2/m2": 49.86,
    "out:Total CO2 (tons)": 256.72,
    "out:Klimatpaverkan": -32.89,
    "out:Initial Cost/MSEK": 7.53,
    "out:Running cost/(Apt SEK y)": 27663,
    "out:Running Cost over RSP/MSEK": 11.679,
    "out:LCP/MSEK": 0.295,
    "out:ROI% old": 14.85,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 61.8,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1001,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 329536,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 335828,
    "out:DH kr savings": 329536,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 379568,
    "out:% savings (space heating)": 40.32,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.72,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.94,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 107.69,
    "out:Total Energy Use Post PV": 107.69,
    "out:Primary Energy": 109.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.83,
    "out:CO2 Operational/m2": 34.28,
    "out:Total CO2/m2": 51.11,
    "out:Total CO2 (tons)": 263.16,
    "out:Klimatpaverkan": -26.45,
    "out:Initial Cost/MSEK": 6.757,
    "out:Running cost/(Apt SEK y)": 29096,
    "out:Running Cost over RSP/MSEK": 12.271,
    "out:LCP/MSEK": 0.477,
    "out:ROI% old": 15.29,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 924,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268852,
    "out:DH kr savings": 324387,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 396171,
    "out:% savings (space heating)": 37.71,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.72,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 104.69,
    "out:Total Energy Use Post PV": 104.69,
    "out:Primary Energy": 106.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.83,
    "out:CO2 Operational/m2": 33.2,
    "out:Total CO2/m2": 50.03,
    "out:Total CO2 (tons)": 257.6,
    "out:Klimatpaverkan": -32.01,
    "out:Initial Cost/MSEK": 6.885,
    "out:Running cost/(Apt SEK y)": 28232,
    "out:Running Cost over RSP/MSEK": 11.906,
    "out:LCP/MSEK": 0.713,
    "out:ROI% old": 15.77,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 971,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 284299,
    "out:DH kr savings": 339834,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 379568,
    "out:% savings (space heating)": 40.32,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.72,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.94,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 104.69,
    "out:Total Energy Use Post PV": 104.69,
    "out:Primary Energy": 107.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.11,
    "out:CO2 Operational/m2": 33.2,
    "out:Total CO2/m2": 50.31,
    "out:Total CO2 (tons)": 259.05,
    "out:Klimatpaverkan": -30.56,
    "out:Initial Cost/MSEK": 6.932,
    "out:Running cost/(Apt SEK y)": 28232,
    "out:Running Cost over RSP/MSEK": 11.906,
    "out:LCP/MSEK": 0.666,
    "out:ROI% old": 15.66,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 971,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 284299,
    "out:DH kr savings": 339834,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 396171,
    "out:% savings (space heating)": 37.71,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.72,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 101.69,
    "out:Total Energy Use Post PV": 101.69,
    "out:Primary Energy": 104.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.11,
    "out:CO2 Operational/m2": 32.12,
    "out:Total CO2/m2": 49.23,
    "out:Total CO2 (tons)": 253.49,
    "out:Klimatpaverkan": -36.12,
    "out:Initial Cost/MSEK": 7.061,
    "out:Running cost/(Apt SEK y)": 27368,
    "out:Running Cost over RSP/MSEK": 11.541,
    "out:LCP/MSEK": 0.902,
    "out:ROI% old": 16.11,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 61.8,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1017,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 355281,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 299746,
    "out:DH kr savings": 355281,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 379568,
    "out:% savings (space heating)": 40.32,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.72,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.94,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 107.69,
    "out:Total Energy Use Post PV": 102.84,
    "out:Primary Energy": 100.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.89,
    "out:CO2 Operational/m2": 26.05,
    "out:Total CO2/m2": 49.94,
    "out:Total CO2 (tons)": 257.12,
    "out:Klimatpaverkan": -32.49,
    "out:Initial Cost/MSEK": 7.369,
    "out:Running cost/(Apt SEK y)": 27951,
    "out:Running Cost over RSP/MSEK": 11.8,
    "out:LCP/MSEK": 0.335,
    "out:ROI% old": 14.94,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 60.2,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 986,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 330679,
    "out:DH kr savings": 324387,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 396171,
    "out:% savings (space heating)": 37.71,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.72,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 104.69,
    "out:Total Energy Use Post PV": 99.84,
    "out:Primary Energy": 98.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.89,
    "out:CO2 Operational/m2": 24.97,
    "out:Total CO2/m2": 48.86,
    "out:Total CO2 (tons)": 251.56,
    "out:Klimatpaverkan": -38.05,
    "out:Initial Cost/MSEK": 7.497,
    "out:Running cost/(Apt SEK y)": 27087,
    "out:Running Cost over RSP/MSEK": 11.435,
    "out:LCP/MSEK": 0.572,
    "out:ROI% old": 15.37,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 65,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346126,
    "out:DH kr savings": 339834,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 379568,
    "out:% savings (space heating)": 40.32,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.72,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.94,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 104.69,
    "out:Total Energy Use Post PV": 99.84,
    "out:Primary Energy": 98.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.17,
    "out:CO2 Operational/m2": 24.97,
    "out:Total CO2/m2": 49.14,
    "out:Total CO2 (tons)": 253.01,
    "out:Klimatpaverkan": -36.6,
    "out:Initial Cost/MSEK": 7.544,
    "out:Running cost/(Apt SEK y)": 27087,
    "out:Running Cost over RSP/MSEK": 11.435,
    "out:LCP/MSEK": 0.525,
    "out:ROI% old": 15.28,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 65,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 346126,
    "out:DH kr savings": 339834,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 396171,
    "out:% savings (space heating)": 37.71,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.94,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.72,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 101.69,
    "out:Total Energy Use Post PV": 96.84,
    "out:Primary Energy": 95.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.17,
    "out:CO2 Operational/m2": 23.89,
    "out:Total CO2/m2": 48.06,
    "out:Total CO2 (tons)": 247.45,
    "out:Klimatpaverkan": -42.16,
    "out:Initial Cost/MSEK": 7.673,
    "out:Running cost/(Apt SEK y)": 26223,
    "out:Running Cost over RSP/MSEK": 11.071,
    "out:LCP/MSEK": 0.761,
    "out:ROI% old": 15.7,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 70.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1079,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 355281,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 361573,
    "out:DH kr savings": 355281,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 379568,
    "out:% savings (space heating)": 40.32,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.72,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.19,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 113.3,
    "out:Total Energy Use Post PV": 113.3,
    "out:Primary Energy": 106.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.05,
    "out:CO2 Operational/m2": 37.53,
    "out:Total CO2/m2": 61.58,
    "out:Total CO2 (tons)": 317.06,
    "out:Klimatpaverkan": 27.45,
    "out:Initial Cost/MSEK": 8.983,
    "out:Running cost/(Apt SEK y)": 31235,
    "out:Running Cost over RSP/MSEK": 13.18,
    "out:LCP/MSEK": -2.659,
    "out:ROI% old": 10.06,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 267748,
    "out:DH kr savings": 267748,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 371685,
    "out:% savings (space heating)": 41.56,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.18,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 110.3,
    "out:Primary Energy": 103.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.05,
    "out:CO2 Operational/m2": 36.45,
    "out:Total CO2/m2": 60.5,
    "out:Total CO2 (tons)": 311.5,
    "out:Klimatpaverkan": 21.89,
    "out:Initial Cost/MSEK": 9.112,
    "out:Running cost/(Apt SEK y)": 30371,
    "out:Running Cost over RSP/MSEK": 12.815,
    "out:LCP/MSEK": -2.423,
    "out:ROI% old": 10.49,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 855,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 283195,
    "out:DH kr savings": 283195,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 356194,
    "out:% savings (space heating)": 43.99,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.18,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.19,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 109.3,
    "out:Total Energy Use Post PV": 109.3,
    "out:Primary Energy": 103.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.33,
    "out:CO2 Operational/m2": 36.09,
    "out:Total CO2/m2": 60.42,
    "out:Total CO2 (tons)": 311.09,
    "out:Klimatpaverkan": 21.48,
    "out:Initial Cost/MSEK": 9.159,
    "out:Running cost/(Apt SEK y)": 30084,
    "out:Running Cost over RSP/MSEK": 12.693,
    "out:LCP/MSEK": -2.348,
    "out:ROI% old": 10.62,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 871,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 288344,
    "out:DH kr savings": 288344,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 371685,
    "out:% savings (space heating)": 41.56,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.18,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 106.3,
    "out:Total Energy Use Post PV": 106.3,
    "out:Primary Energy": 101.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.33,
    "out:CO2 Operational/m2": 35.01,
    "out:Total CO2/m2": 59.34,
    "out:Total CO2 (tons)": 305.53,
    "out:Klimatpaverkan": 15.92,
    "out:Initial Cost/MSEK": 9.288,
    "out:Running cost/(Apt SEK y)": 29220,
    "out:Running Cost over RSP/MSEK": 12.328,
    "out:LCP/MSEK": -2.112,
    "out:ROI% old": 11.04,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 917,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 303791,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 303791,
    "out:DH kr savings": 303791,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 356194,
    "out:% savings (space heating)": 43.99,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.18,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.19,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 113.3,
    "out:Total Energy Use Post PV": 109.29,
    "out:Primary Energy": 99.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.11,
    "out:CO2 Operational/m2": 25.1,
    "out:Total CO2/m2": 56.21,
    "out:Total CO2 (tons)": 289.41,
    "out:Klimatpaverkan": -0.2,
    "out:Initial Cost/MSEK": 9.595,
    "out:Running cost/(Apt SEK y)": 30131,
    "out:Running Cost over RSP/MSEK": 12.725,
    "out:LCP/MSEK": -2.817,
    "out:ROI% old": 10.09,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 868,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 327406,
    "out:DH kr savings": 267748,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 371685,
    "out:% savings (space heating)": 41.56,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.18,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 106.29,
    "out:Primary Energy": 96.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.11,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 55.13,
    "out:Total CO2 (tons)": 283.85,
    "out:Klimatpaverkan": -5.76,
    "out:Initial Cost/MSEK": 9.724,
    "out:Running cost/(Apt SEK y)": 29267,
    "out:Running Cost over RSP/MSEK": 12.361,
    "out:LCP/MSEK": -2.58,
    "out:ROI% old": 10.49,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 915,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 342853,
    "out:DH kr savings": 283195,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 356194,
    "out:% savings (space heating)": 43.99,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.18,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.19,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 109.3,
    "out:Total Energy Use Post PV": 105.29,
    "out:Primary Energy": 96.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.39,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 55.05,
    "out:Total CO2 (tons)": 283.45,
    "out:Klimatpaverkan": -6.16,
    "out:Initial Cost/MSEK": 9.771,
    "out:Running cost/(Apt SEK y)": 28979,
    "out:Running Cost over RSP/MSEK": 12.239,
    "out:LCP/MSEK": -2.506,
    "out:ROI% old": 10.62,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 930,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 348002,
    "out:DH kr savings": 288344,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 371685,
    "out:% savings (space heating)": 41.56,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.18,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 106.3,
    "out:Total Energy Use Post PV": 102.29,
    "out:Primary Energy": 93.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.39,
    "out:CO2 Operational/m2": 22.58,
    "out:Total CO2/m2": 53.97,
    "out:Total CO2 (tons)": 277.88,
    "out:Klimatpaverkan": -11.73,
    "out:Initial Cost/MSEK": 9.9,
    "out:Running cost/(Apt SEK y)": 28115,
    "out:Running Cost over RSP/MSEK": 11.874,
    "out:LCP/MSEK": -2.27,
    "out:ROI% old": 11.01,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 61.8,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 977,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 303791,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 363449,
    "out:DH kr savings": 303791,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 356194,
    "out:% savings (space heating)": 43.99,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.18,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.19,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 108.3,
    "out:Primary Energy": 102.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.05,
    "out:CO2 Operational/m2": 35.73,
    "out:Total CO2/m2": 59.78,
    "out:Total CO2 (tons)": 307.79,
    "out:Klimatpaverkan": 18.18,
    "out:Initial Cost/MSEK": 9.126,
    "out:Running cost/(Apt SEK y)": 29796,
    "out:Running Cost over RSP/MSEK": 12.571,
    "out:LCP/MSEK": -2.193,
    "out:ROI% old": 10.85,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 886,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 293493,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 293493,
    "out:DH kr savings": 293493,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 371685,
    "out:% savings (space heating)": 41.56,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.18,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 105.3,
    "out:Primary Energy": 100.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.05,
    "out:CO2 Operational/m2": 34.65,
    "out:Total CO2/m2": 58.7,
    "out:Total CO2 (tons)": 302.23,
    "out:Klimatpaverkan": 12.62,
    "out:Initial Cost/MSEK": 9.255,
    "out:Running cost/(Apt SEK y)": 28932,
    "out:Running Cost over RSP/MSEK": 12.207,
    "out:LCP/MSEK": -1.957,
    "out:ROI% old": 11.26,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 933,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 308940,
    "out:DH kr savings": 308940,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 356194,
    "out:% savings (space heating)": 43.99,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.18,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.19,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 105.3,
    "out:Primary Energy": 100.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.33,
    "out:CO2 Operational/m2": 34.65,
    "out:Total CO2/m2": 58.98,
    "out:Total CO2 (tons)": 303.68,
    "out:Klimatpaverkan": 14.07,
    "out:Initial Cost/MSEK": 9.302,
    "out:Running cost/(Apt SEK y)": 28932,
    "out:Running Cost over RSP/MSEK": 12.207,
    "out:LCP/MSEK": -2.004,
    "out:ROI% old": 11.21,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 933,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 308940,
    "out:DH kr savings": 308940,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 371685,
    "out:% savings (space heating)": 41.56,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.18,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 102.3,
    "out:Total Energy Use Post PV": 102.3,
    "out:Primary Energy": 98.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.33,
    "out:CO2 Operational/m2": 33.57,
    "out:Total CO2/m2": 57.9,
    "out:Total CO2 (tons)": 298.12,
    "out:Klimatpaverkan": 8.51,
    "out:Initial Cost/MSEK": 9.43,
    "out:Running cost/(Apt SEK y)": 28068,
    "out:Running Cost over RSP/MSEK": 11.842,
    "out:LCP/MSEK": -1.768,
    "out:ROI% old": 11.61,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 61.8,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 980,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 324387,
    "out:DH kr savings": 324387,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 356194,
    "out:% savings (space heating)": 43.99,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.18,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01011200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.19,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 108.3,
    "out:Total Energy Use Post PV": 104.29,
    "out:Primary Energy": 95.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.11,
    "out:CO2 Operational/m2": 23.3,
    "out:Total CO2/m2": 54.41,
    "out:Total CO2 (tons)": 280.14,
    "out:Klimatpaverkan": -9.47,
    "out:Initial Cost/MSEK": 9.738,
    "out:Running cost/(Apt SEK y)": 28691,
    "out:Running Cost over RSP/MSEK": 12.117,
    "out:LCP/MSEK": -2.351,
    "out:ROI% old": 10.84,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 946,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293493,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 353151,
    "out:DH kr savings": 293493,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 371685,
    "out:% savings (space heating)": 41.56,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.18,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 101.29,
    "out:Primary Energy": 93.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.11,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 53.33,
    "out:Total CO2 (tons)": 274.58,
    "out:Klimatpaverkan": -15.03,
    "out:Initial Cost/MSEK": 9.867,
    "out:Running cost/(Apt SEK y)": 27827,
    "out:Running Cost over RSP/MSEK": 11.752,
    "out:LCP/MSEK": -2.115,
    "out:ROI% old": 11.22,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 993,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 368598,
    "out:DH kr savings": 308940,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 356194,
    "out:% savings (space heating)": 43.99,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.18,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.19,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 101.29,
    "out:Primary Energy": 93.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.39,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 53.61,
    "out:Total CO2 (tons)": 276.03,
    "out:Klimatpaverkan": -13.58,
    "out:Initial Cost/MSEK": 9.914,
    "out:Running cost/(Apt SEK y)": 27827,
    "out:Running Cost over RSP/MSEK": 11.752,
    "out:LCP/MSEK": -2.162,
    "out:ROI% old": 11.17,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 993,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 368598,
    "out:DH kr savings": 308940,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 371685,
    "out:% savings (space heating)": 41.56,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.18,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 102.3,
    "out:Total Energy Use Post PV": 98.29,
    "out:Primary Energy": 90.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.39,
    "out:CO2 Operational/m2": 21.14,
    "out:Total CO2/m2": 52.53,
    "out:Total CO2 (tons)": 270.47,
    "out:Klimatpaverkan": -19.14,
    "out:Initial Cost/MSEK": 10.042,
    "out:Running cost/(Apt SEK y)": 26963,
    "out:Running Cost over RSP/MSEK": 11.388,
    "out:LCP/MSEK": -1.926,
    "out:ROI% old": 11.55,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 68.4,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1039,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 384044,
    "out:DH kr savings": 324387,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 356194,
    "out:% savings (space heating)": 43.99,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.18,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01011201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.19,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 108.69,
    "out:Total Energy Use Post PV": 108.69,
    "out:Primary Energy": 108.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.39,
    "out:CO2 Operational/m2": 34.64,
    "out:Total CO2/m2": 61.03,
    "out:Total CO2 (tons)": 314.25,
    "out:Klimatpaverkan": 24.64,
    "out:Initial Cost/MSEK": 9.78,
    "out:Running cost/(Apt SEK y)": 29384,
    "out:Running Cost over RSP/MSEK": 12.393,
    "out:LCP/MSEK": -2.669,
    "out:ROI% old": 10.39,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 909,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 319238,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263703,
    "out:DH kr savings": 319238,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 371685,
    "out:% savings (space heating)": 41.56,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.18,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 105.69,
    "out:Total Energy Use Post PV": 105.69,
    "out:Primary Energy": 106.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.39,
    "out:CO2 Operational/m2": 33.56,
    "out:Total CO2/m2": 59.95,
    "out:Total CO2 (tons)": 308.69,
    "out:Klimatpaverkan": 19.08,
    "out:Initial Cost/MSEK": 9.909,
    "out:Running cost/(Apt SEK y)": 28520,
    "out:Running Cost over RSP/MSEK": 12.028,
    "out:LCP/MSEK": -2.432,
    "out:ROI% old": 10.78,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 955,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 334685,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279150,
    "out:DH kr savings": 334685,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 356194,
    "out:% savings (space heating)": 43.99,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.18,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.19,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 104.69,
    "out:Total Energy Use Post PV": 104.69,
    "out:Primary Energy": 106.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.68,
    "out:CO2 Operational/m2": 33.2,
    "out:Total CO2/m2": 59.87,
    "out:Total CO2 (tons)": 308.29,
    "out:Klimatpaverkan": 18.68,
    "out:Initial Cost/MSEK": 9.956,
    "out:Running cost/(Apt SEK y)": 28232,
    "out:Running Cost over RSP/MSEK": 11.906,
    "out:LCP/MSEK": -2.358,
    "out:ROI% old": 10.9,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 971,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 284299,
    "out:DH kr savings": 339834,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 371685,
    "out:% savings (space heating)": 41.56,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.18,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 101.69,
    "out:Total Energy Use Post PV": 101.69,
    "out:Primary Energy": 103.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.68,
    "out:CO2 Operational/m2": 32.12,
    "out:Total CO2/m2": 58.79,
    "out:Total CO2 (tons)": 302.73,
    "out:Klimatpaverkan": 13.12,
    "out:Initial Cost/MSEK": 10.085,
    "out:Running cost/(Apt SEK y)": 27368,
    "out:Running Cost over RSP/MSEK": 11.541,
    "out:LCP/MSEK": -2.122,
    "out:ROI% old": 11.28,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 61.8,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1017,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 355281,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 299746,
    "out:DH kr savings": 355281,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 356194,
    "out:% savings (space heating)": 43.99,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.18,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.19,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 108.69,
    "out:Total Energy Use Post PV": 103.84,
    "out:Primary Energy": 100.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.45,
    "out:CO2 Operational/m2": 26.41,
    "out:Total CO2/m2": 59.86,
    "out:Total CO2 (tons)": 308.21,
    "out:Klimatpaverkan": 18.6,
    "out:Initial Cost/MSEK": 10.392,
    "out:Running cost/(Apt SEK y)": 28239,
    "out:Running Cost over RSP/MSEK": 11.922,
    "out:LCP/MSEK": -2.81,
    "out:ROI% old": 10.42,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 970,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 319238,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 325530,
    "out:DH kr savings": 319238,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 371685,
    "out:% savings (space heating)": 41.56,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.18,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 105.69,
    "out:Total Energy Use Post PV": 100.84,
    "out:Primary Energy": 97.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.45,
    "out:CO2 Operational/m2": 25.33,
    "out:Total CO2/m2": 58.78,
    "out:Total CO2 (tons)": 302.65,
    "out:Klimatpaverkan": 13.04,
    "out:Initial Cost/MSEK": 10.521,
    "out:Running cost/(Apt SEK y)": 27375,
    "out:Running Cost over RSP/MSEK": 11.557,
    "out:LCP/MSEK": -2.574,
    "out:ROI% old": 10.79,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1017,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 334685,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 340977,
    "out:DH kr savings": 334685,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 356194,
    "out:% savings (space heating)": 43.99,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.18,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.19,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 104.69,
    "out:Total Energy Use Post PV": 99.84,
    "out:Primary Energy": 97.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.73,
    "out:CO2 Operational/m2": 24.97,
    "out:Total CO2/m2": 58.7,
    "out:Total CO2 (tons)": 302.25,
    "out:Klimatpaverkan": 12.64,
    "out:Initial Cost/MSEK": 10.568,
    "out:Running cost/(Apt SEK y)": 27087,
    "out:Running Cost over RSP/MSEK": 11.435,
    "out:LCP/MSEK": -2.499,
    "out:ROI% old": 10.91,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 65,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 346126,
    "out:DH kr savings": 339834,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 371685,
    "out:% savings (space heating)": 41.56,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.18,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 101.69,
    "out:Total Energy Use Post PV": 96.84,
    "out:Primary Energy": 94.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.73,
    "out:CO2 Operational/m2": 23.89,
    "out:Total CO2/m2": 57.62,
    "out:Total CO2 (tons)": 296.69,
    "out:Klimatpaverkan": 7.08,
    "out:Initial Cost/MSEK": 10.697,
    "out:Running cost/(Apt SEK y)": 26223,
    "out:Running Cost over RSP/MSEK": 11.071,
    "out:LCP/MSEK": -2.263,
    "out:ROI% old": 11.26,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 70.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1079,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 355281,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 361573,
    "out:DH kr savings": 355281,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 356194,
    "out:% savings (space heating)": 43.99,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.18,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.19,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 103.69,
    "out:Total Energy Use Post PV": 103.69,
    "out:Primary Energy": 105.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.39,
    "out:CO2 Operational/m2": 32.84,
    "out:Total CO2/m2": 59.23,
    "out:Total CO2 (tons)": 304.99,
    "out:Klimatpaverkan": 15.38,
    "out:Initial Cost/MSEK": 9.923,
    "out:Running cost/(Apt SEK y)": 27944,
    "out:Running Cost over RSP/MSEK": 11.784,
    "out:LCP/MSEK": -2.203,
    "out:ROI% old": 11.11,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 986,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 344983,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 289448,
    "out:DH kr savings": 344983,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 371685,
    "out:% savings (space heating)": 41.56,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.18,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 100.69,
    "out:Total Energy Use Post PV": 100.69,
    "out:Primary Energy": 102.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.39,
    "out:CO2 Operational/m2": 31.76,
    "out:Total CO2/m2": 58.15,
    "out:Total CO2 (tons)": 299.42,
    "out:Klimatpaverkan": 9.81,
    "out:Initial Cost/MSEK": 10.051,
    "out:Running cost/(Apt SEK y)": 27080,
    "out:Running Cost over RSP/MSEK": 11.42,
    "out:LCP/MSEK": -1.967,
    "out:ROI% old": 11.49,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360430,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304895,
    "out:DH kr savings": 360430,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 356194,
    "out:% savings (space heating)": 43.99,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.18,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.19,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 99.69,
    "out:Total Energy Use Post PV": 99.69,
    "out:Primary Energy": 103.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.68,
    "out:CO2 Operational/m2": 31.4,
    "out:Total CO2/m2": 58.07,
    "out:Total CO2 (tons)": 299.02,
    "out:Klimatpaverkan": 9.41,
    "out:Initial Cost/MSEK": 10.098,
    "out:Running cost/(Apt SEK y)": 26792,
    "out:Running Cost over RSP/MSEK": 11.298,
    "out:LCP/MSEK": -1.892,
    "out:ROI% old": 11.61,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 65,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1049,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 365579,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 310044,
    "out:DH kr savings": 365579,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 371685,
    "out:% savings (space heating)": 41.56,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.18,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 96.69,
    "out:Total Energy Use Post PV": 96.69,
    "out:Primary Energy": 100.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.68,
    "out:CO2 Operational/m2": 30.32,
    "out:Total CO2/m2": 56.99,
    "out:Total CO2 (tons)": 293.46,
    "out:Klimatpaverkan": 3.85,
    "out:Initial Cost/MSEK": 10.227,
    "out:Running cost/(Apt SEK y)": 25929,
    "out:Running Cost over RSP/MSEK": 10.933,
    "out:LCP/MSEK": -1.656,
    "out:ROI% old": 11.97,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 70.1,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1095,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 381026,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 325491,
    "out:DH kr savings": 381026,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 356194,
    "out:% savings (space heating)": 43.99,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.18,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01011201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.19,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 103.69,
    "out:Total Energy Use Post PV": 98.84,
    "out:Primary Energy": 96.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.45,
    "out:CO2 Operational/m2": 24.61,
    "out:Total CO2/m2": 58.06,
    "out:Total CO2 (tons)": 298.94,
    "out:Klimatpaverkan": 9.33,
    "out:Initial Cost/MSEK": 10.535,
    "out:Running cost/(Apt SEK y)": 26799,
    "out:Running Cost over RSP/MSEK": 11.314,
    "out:LCP/MSEK": -2.344,
    "out:ROI% old": 11.11,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 66.7,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1048,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 344983,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 351275,
    "out:DH kr savings": 344983,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 371685,
    "out:% savings (space heating)": 41.56,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.18,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 100.69,
    "out:Total Energy Use Post PV": 95.84,
    "out:Primary Energy": 94.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.45,
    "out:CO2 Operational/m2": 23.53,
    "out:Total CO2/m2": 56.98,
    "out:Total CO2 (tons)": 293.38,
    "out:Klimatpaverkan": 3.77,
    "out:Initial Cost/MSEK": 10.664,
    "out:Running cost/(Apt SEK y)": 25936,
    "out:Running Cost over RSP/MSEK": 10.949,
    "out:LCP/MSEK": -2.108,
    "out:ROI% old": 11.46,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 71.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1095,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360430,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 366722,
    "out:DH kr savings": 360430,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 356194,
    "out:% savings (space heating)": 43.99,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.18,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.19,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 99.69,
    "out:Total Energy Use Post PV": 94.84,
    "out:Primary Energy": 94.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.73,
    "out:CO2 Operational/m2": 23.17,
    "out:Total CO2/m2": 56.9,
    "out:Total CO2 (tons)": 292.98,
    "out:Klimatpaverkan": 3.37,
    "out:Initial Cost/MSEK": 10.71,
    "out:Running cost/(Apt SEK y)": 25648,
    "out:Running Cost over RSP/MSEK": 10.827,
    "out:LCP/MSEK": -2.033,
    "out:ROI% old": 11.57,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 73.7,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1110,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 365579,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 371871,
    "out:DH kr savings": 365579,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 371685,
    "out:% savings (space heating)": 41.56,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.19,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01011201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.18,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 96.69,
    "out:Total Energy Use Post PV": 91.84,
    "out:Primary Energy": 91.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.73,
    "out:CO2 Operational/m2": 22.09,
    "out:Total CO2/m2": 55.82,
    "out:Total CO2 (tons)": 287.42,
    "out:Klimatpaverkan": -2.19,
    "out:Initial Cost/MSEK": 10.839,
    "out:Running cost/(Apt SEK y)": 24784,
    "out:Running Cost over RSP/MSEK": 10.462,
    "out:LCP/MSEK": -1.797,
    "out:ROI% old": 11.92,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 79.3,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1157,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.15,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 381026,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 387318,
    "out:DH kr savings": 381026,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 356194,
    "out:% savings (space heating)": 43.99,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.18,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.19,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 132.3,
    "out:Total Energy Use Post PV": 132.3,
    "out:Primary Energy": 123.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.62,
    "out:CO2 Operational/m2": 44.37,
    "out:Total CO2/m2": 55.98,
    "out:Total CO2 (tons)": 288.26,
    "out:Klimatpaverkan": -1.35,
    "out:Initial Cost/MSEK": 4.551,
    "out:Running cost/(Apt SEK y)": 36707,
    "out:Running Cost over RSP/MSEK": 15.491,
    "out:LCP/MSEK": -0.537,
    "out:ROI% old": 12.6,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 25,
    "out:Op energy cost/MSEK": 113,
    "out:El price/MSEK": 70,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 513,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 169917,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169917,
    "out:DH kr savings": 169917,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 469533,
    "out:% savings (space heating)": 26.17,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.19,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.32,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 128.3,
    "out:Total Energy Use Post PV": 128.3,
    "out:Primary Energy": 119.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.62,
    "out:CO2 Operational/m2": 42.93,
    "out:Total CO2/m2": 54.54,
    "out:Total CO2 (tons)": 280.85,
    "out:Klimatpaverkan": -8.76,
    "out:Initial Cost/MSEK": 4.679,
    "out:Running cost/(Apt SEK y)": 35555,
    "out:Running Cost over RSP/MSEK": 15.004,
    "out:LCP/MSEK": -0.179,
    "out:ROI% old": 13.74,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 575,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 190513,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190513,
    "out:DH kr savings": 190513,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 449595,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.32,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.19,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 128.3,
    "out:Total Energy Use Post PV": 128.3,
    "out:Primary Energy": 120.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.9,
    "out:CO2 Operational/m2": 42.93,
    "out:Total CO2/m2": 54.83,
    "out:Total CO2 (tons)": 282.3,
    "out:Klimatpaverkan": -7.31,
    "out:Initial Cost/MSEK": 4.726,
    "out:Running cost/(Apt SEK y)": 35555,
    "out:Running Cost over RSP/MSEK": 15.004,
    "out:LCP/MSEK": -0.226,
    "out:ROI% old": 13.6,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 68,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 12,
    "out:Return/kSEK/y": 575,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 190513,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 190513,
    "out:DH kr savings": 190513,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 469533,
    "out:% savings (space heating)": 26.17,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.19,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.32,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 124.3,
    "out:Total Energy Use Post PV": 124.3,
    "out:Primary Energy": 117.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.9,
    "out:CO2 Operational/m2": 41.49,
    "out:Total CO2/m2": 53.39,
    "out:Total CO2 (tons)": 274.88,
    "out:Klimatpaverkan": -14.73,
    "out:Initial Cost/MSEK": 4.855,
    "out:Running cost/(Apt SEK y)": 34403,
    "out:Running Cost over RSP/MSEK": 14.517,
    "out:LCP/MSEK": 0.132,
    "out:ROI% old": 14.67,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211109,
    "out:DH kr savings": 211109,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 449595,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.32,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.19,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 132.3,
    "out:Total Energy Use Post PV": 128.29,
    "out:Primary Energy": 115.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.68,
    "out:CO2 Operational/m2": 31.94,
    "out:Total CO2/m2": 50.61,
    "out:Total CO2 (tons)": 260.61,
    "out:Klimatpaverkan": -29,
    "out:Initial Cost/MSEK": 5.163,
    "out:Running cost/(Apt SEK y)": 35602,
    "out:Running Cost over RSP/MSEK": 15.036,
    "out:LCP/MSEK": -0.695,
    "out:ROI% old": 12.36,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 110,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 573,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 169917,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 229575,
    "out:DH kr savings": 169917,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 469533,
    "out:% savings (space heating)": 26.17,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.19,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.32,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 128.3,
    "out:Total Energy Use Post PV": 124.29,
    "out:Primary Energy": 112.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.68,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 49.17,
    "out:Total CO2 (tons)": 253.2,
    "out:Klimatpaverkan": -36.41,
    "out:Initial Cost/MSEK": 5.291,
    "out:Running cost/(Apt SEK y)": 34450,
    "out:Running Cost over RSP/MSEK": 14.55,
    "out:LCP/MSEK": -0.337,
    "out:ROI% old": 13.38,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 635,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 190513,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 250171,
    "out:DH kr savings": 190513,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 449595,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.32,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.19,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 128.3,
    "out:Total Energy Use Post PV": 124.29,
    "out:Primary Energy": 113.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.96,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 49.46,
    "out:Total CO2 (tons)": 254.65,
    "out:Klimatpaverkan": -34.96,
    "out:Initial Cost/MSEK": 5.338,
    "out:Running cost/(Apt SEK y)": 34450,
    "out:Running Cost over RSP/MSEK": 14.55,
    "out:LCP/MSEK": -0.384,
    "out:ROI% old": 13.26,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 635,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 190513,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 250171,
    "out:DH kr savings": 190513,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 469533,
    "out:% savings (space heating)": 26.17,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.19,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.32,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 124.3,
    "out:Total Energy Use Post PV": 120.29,
    "out:Primary Energy": 109.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.96,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 48.02,
    "out:Total CO2 (tons)": 247.23,
    "out:Klimatpaverkan": -42.38,
    "out:Initial Cost/MSEK": 5.467,
    "out:Running cost/(Apt SEK y)": 33298,
    "out:Running Cost over RSP/MSEK": 14.063,
    "out:LCP/MSEK": -0.026,
    "out:ROI% old": 14.22,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 697,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 270767,
    "out:DH kr savings": 211109,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 449595,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.32,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.19,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 127.3,
    "out:Total Energy Use Post PV": 127.3,
    "out:Primary Energy": 119.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.62,
    "out:CO2 Operational/m2": 42.57,
    "out:Total CO2/m2": 54.18,
    "out:Total CO2 (tons)": 278.99,
    "out:Klimatpaverkan": -10.62,
    "out:Initial Cost/MSEK": 4.693,
    "out:Running cost/(Apt SEK y)": 35267,
    "out:Running Cost over RSP/MSEK": 14.882,
    "out:LCP/MSEK": -0.071,
    "out:ROI% old": 14.07,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 109,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 591,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195662,
    "out:DH kr savings": 195662,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 469533,
    "out:% savings (space heating)": 26.17,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.19,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.32,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 123.3,
    "out:Total Energy Use Post PV": 123.3,
    "out:Primary Energy": 116.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.62,
    "out:CO2 Operational/m2": 41.13,
    "out:Total CO2/m2": 52.74,
    "out:Total CO2 (tons)": 271.58,
    "out:Klimatpaverkan": -18.03,
    "out:Initial Cost/MSEK": 4.822,
    "out:Running cost/(Apt SEK y)": 34115,
    "out:Running Cost over RSP/MSEK": 14.396,
    "out:LCP/MSEK": 0.286,
    "out:ROI% old": 15.13,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 34.1,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 653,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 216258,
    "out:DH kr savings": 216258,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 449595,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.32,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.19,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 124.3,
    "out:Total Energy Use Post PV": 124.3,
    "out:Primary Energy": 117.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.9,
    "out:CO2 Operational/m2": 41.49,
    "out:Total CO2/m2": 53.39,
    "out:Total CO2 (tons)": 274.88,
    "out:Klimatpaverkan": -14.73,
    "out:Initial Cost/MSEK": 4.869,
    "out:Running cost/(Apt SEK y)": 34403,
    "out:Running Cost over RSP/MSEK": 14.517,
    "out:LCP/MSEK": 0.118,
    "out:ROI% old": 14.63,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211109,
    "out:DH kr savings": 211109,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 469533,
    "out:% savings (space heating)": 26.17,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.19,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.32,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 120.3,
    "out:Total Energy Use Post PV": 120.3,
    "out:Primary Energy": 114.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.9,
    "out:CO2 Operational/m2": 40.05,
    "out:Total CO2/m2": 51.95,
    "out:Total CO2 (tons)": 267.47,
    "out:Klimatpaverkan": -22.14,
    "out:Initial Cost/MSEK": 4.998,
    "out:Running cost/(Apt SEK y)": 33251,
    "out:Running Cost over RSP/MSEK": 14.031,
    "out:LCP/MSEK": 0.476,
    "out:ROI% old": 15.65,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231705,
    "out:DH kr savings": 231705,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 449595,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.32,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.19,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 127.3,
    "out:Total Energy Use Post PV": 123.29,
    "out:Primary Energy": 112.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.68,
    "out:CO2 Operational/m2": 30.14,
    "out:Total CO2/m2": 48.81,
    "out:Total CO2 (tons)": 251.34,
    "out:Klimatpaverkan": -38.27,
    "out:Initial Cost/MSEK": 5.305,
    "out:Running cost/(Apt SEK y)": 34162,
    "out:Running Cost over RSP/MSEK": 14.428,
    "out:LCP/MSEK": -0.229,
    "out:ROI% old": 13.67,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 34.1,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 651,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 195662,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 255320,
    "out:DH kr savings": 195662,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 469533,
    "out:% savings (space heating)": 26.17,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.19,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.32,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 123.3,
    "out:Total Energy Use Post PV": 119.29,
    "out:Primary Energy": 108.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.68,
    "out:CO2 Operational/m2": 28.7,
    "out:Total CO2/m2": 47.37,
    "out:Total CO2 (tons)": 243.93,
    "out:Klimatpaverkan": -45.68,
    "out:Initial Cost/MSEK": 5.434,
    "out:Running cost/(Apt SEK y)": 33010,
    "out:Running Cost over RSP/MSEK": 13.942,
    "out:LCP/MSEK": 0.129,
    "out:ROI% old": 14.62,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 38.7,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 713,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 216258,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 275916,
    "out:DH kr savings": 216258,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 449595,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.32,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.19,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 124.3,
    "out:Total Energy Use Post PV": 120.29,
    "out:Primary Energy": 110.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.96,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 48.02,
    "out:Total CO2 (tons)": 247.23,
    "out:Klimatpaverkan": -42.38,
    "out:Initial Cost/MSEK": 5.481,
    "out:Running cost/(Apt SEK y)": 33298,
    "out:Running Cost over RSP/MSEK": 14.063,
    "out:LCP/MSEK": -0.04,
    "out:ROI% old": 14.18,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 697,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 211109,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 270767,
    "out:DH kr savings": 211109,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 469533,
    "out:% savings (space heating)": 26.17,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.19,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.32,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 120.3,
    "out:Total Energy Use Post PV": 116.29,
    "out:Primary Energy": 106.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.96,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 46.58,
    "out:Total CO2 (tons)": 239.82,
    "out:Klimatpaverkan": -49.79,
    "out:Initial Cost/MSEK": 5.61,
    "out:Running cost/(Apt SEK y)": 32146,
    "out:Running Cost over RSP/MSEK": 13.577,
    "out:LCP/MSEK": 0.318,
    "out:ROI% old": 15.09,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 759,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231705,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291363,
    "out:DH kr savings": 231705,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 449595,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.32,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.19,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 127.69,
    "out:Total Energy Use Post PV": 127.69,
    "out:Primary Energy": 125.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.96,
    "out:CO2 Operational/m2": 41.48,
    "out:Total CO2/m2": 55.44,
    "out:Total CO2 (tons)": 285.46,
    "out:Klimatpaverkan": -4.15,
    "out:Initial Cost/MSEK": 5.348,
    "out:Running cost/(Apt SEK y)": 34855,
    "out:Running Cost over RSP/MSEK": 14.704,
    "out:LCP/MSEK": -0.547,
    "out:ROI% old": 12.82,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.9,
    "out:Op energy cost/MSEK": 107,
    "out:El price/MSEK": 67,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 613,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 221407,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165872,
    "out:DH kr savings": 221407,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 469533,
    "out:% savings (space heating)": 26.17,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.19,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.32,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 123.69,
    "out:Total Energy Use Post PV": 123.69,
    "out:Primary Energy": 122.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.96,
    "out:CO2 Operational/m2": 40.04,
    "out:Total CO2/m2": 54,
    "out:Total CO2 (tons)": 278.04,
    "out:Klimatpaverkan": -11.57,
    "out:Initial Cost/MSEK": 5.476,
    "out:Running cost/(Apt SEK y)": 33704,
    "out:Running Cost over RSP/MSEK": 14.217,
    "out:LCP/MSEK": -0.189,
    "out:ROI% old": 13.79,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 675,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 242003,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186468,
    "out:DH kr savings": 242003,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 449595,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.32,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.19,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 123.69,
    "out:Total Energy Use Post PV": 123.69,
    "out:Primary Energy": 122.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.24,
    "out:CO2 Operational/m2": 40.04,
    "out:Total CO2/m2": 54.28,
    "out:Total CO2 (tons)": 279.49,
    "out:Klimatpaverkan": -10.12,
    "out:Initial Cost/MSEK": 5.523,
    "out:Running cost/(Apt SEK y)": 33704,
    "out:Running Cost over RSP/MSEK": 14.217,
    "out:LCP/MSEK": -0.236,
    "out:ROI% old": 13.68,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 33.1,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 675,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 242003,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186468,
    "out:DH kr savings": 242003,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 469533,
    "out:% savings (space heating)": 26.17,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.19,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.32,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 119.69,
    "out:Primary Energy": 119.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.24,
    "out:CO2 Operational/m2": 38.6,
    "out:Total CO2/m2": 52.84,
    "out:Total CO2 (tons)": 272.08,
    "out:Klimatpaverkan": -17.53,
    "out:Initial Cost/MSEK": 5.652,
    "out:Running cost/(Apt SEK y)": 32552,
    "out:Running Cost over RSP/MSEK": 13.731,
    "out:LCP/MSEK": 0.122,
    "out:ROI% old": 14.59,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 738,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207064,
    "out:DH kr savings": 262599,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 449595,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.32,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.19,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 127.69,
    "out:Total Energy Use Post PV": 122.84,
    "out:Primary Energy": 116.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.02,
    "out:CO2 Operational/m2": 33.25,
    "out:Total CO2/m2": 54.27,
    "out:Total CO2 (tons)": 279.41,
    "out:Klimatpaverkan": -10.2,
    "out:Initial Cost/MSEK": 5.96,
    "out:Running cost/(Apt SEK y)": 33710,
    "out:Running Cost over RSP/MSEK": 14.233,
    "out:LCP/MSEK": -0.688,
    "out:ROI% old": 12.64,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 34.1,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 675,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221407,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 227699,
    "out:DH kr savings": 221407,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 469533,
    "out:% savings (space heating)": 26.17,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.19,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.32,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 123.69,
    "out:Total Energy Use Post PV": 118.84,
    "out:Primary Energy": 113.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.02,
    "out:CO2 Operational/m2": 31.81,
    "out:Total CO2/m2": 52.83,
    "out:Total CO2 (tons)": 272,
    "out:Klimatpaverkan": -17.61,
    "out:Initial Cost/MSEK": 6.088,
    "out:Running cost/(Apt SEK y)": 32559,
    "out:Running Cost over RSP/MSEK": 13.746,
    "out:LCP/MSEK": -0.33,
    "out:ROI% old": 13.51,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 38.7,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 737,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 242003,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248295,
    "out:DH kr savings": 242003,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 449595,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.32,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.19,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 123.69,
    "out:Total Energy Use Post PV": 118.84,
    "out:Primary Energy": 114.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.3,
    "out:CO2 Operational/m2": 31.81,
    "out:Total CO2/m2": 53.11,
    "out:Total CO2 (tons)": 273.45,
    "out:Klimatpaverkan": -16.16,
    "out:Initial Cost/MSEK": 6.135,
    "out:Running cost/(Apt SEK y)": 32559,
    "out:Running Cost over RSP/MSEK": 13.746,
    "out:LCP/MSEK": -0.377,
    "out:ROI% old": 13.41,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 38.7,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 737,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 242003,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248295,
    "out:DH kr savings": 242003,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 469533,
    "out:% savings (space heating)": 26.17,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.19,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.32,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 119.69,
    "out:Total Energy Use Post PV": 114.84,
    "out:Primary Energy": 110.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.3,
    "out:CO2 Operational/m2": 30.37,
    "out:Total CO2/m2": 51.67,
    "out:Total CO2 (tons)": 266.04,
    "out:Klimatpaverkan": -23.57,
    "out:Initial Cost/MSEK": 6.264,
    "out:Running cost/(Apt SEK y)": 31407,
    "out:Running Cost over RSP/MSEK": 13.26,
    "out:LCP/MSEK": -0.02,
    "out:ROI% old": 14.24,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 799,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 268891,
    "out:DH kr savings": 262599,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 449595,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.32,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.19,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 122.69,
    "out:Total Energy Use Post PV": 122.69,
    "out:Primary Energy": 122.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.96,
    "out:CO2 Operational/m2": 39.68,
    "out:Total CO2/m2": 53.64,
    "out:Total CO2 (tons)": 276.19,
    "out:Klimatpaverkan": -13.42,
    "out:Initial Cost/MSEK": 5.49,
    "out:Running cost/(Apt SEK y)": 33416,
    "out:Running Cost over RSP/MSEK": 14.095,
    "out:LCP/MSEK": -0.081,
    "out:ROI% old": 14.07,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 34.1,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 65,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 691,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191617,
    "out:DH kr savings": 247152,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 469533,
    "out:% savings (space heating)": 26.17,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.19,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.32,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 118.69,
    "out:Total Energy Use Post PV": 118.69,
    "out:Primary Energy": 118.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.96,
    "out:CO2 Operational/m2": 38.24,
    "out:Total CO2/m2": 52.2,
    "out:Total CO2 (tons)": 268.77,
    "out:Klimatpaverkan": -20.84,
    "out:Initial Cost/MSEK": 5.619,
    "out:Running cost/(Apt SEK y)": 32264,
    "out:Running Cost over RSP/MSEK": 13.609,
    "out:LCP/MSEK": 0.277,
    "out:ROI% old": 14.99,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 38.7,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 753,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212213,
    "out:DH kr savings": 267748,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 449595,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.32,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.19,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 118.69,
    "out:Total Energy Use Post PV": 118.69,
    "out:Primary Energy": 119.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.24,
    "out:CO2 Operational/m2": 38.24,
    "out:Total CO2/m2": 52.48,
    "out:Total CO2 (tons)": 270.22,
    "out:Klimatpaverkan": -19.39,
    "out:Initial Cost/MSEK": 5.666,
    "out:Running cost/(Apt SEK y)": 32264,
    "out:Running Cost over RSP/MSEK": 13.609,
    "out:LCP/MSEK": 0.23,
    "out:ROI% old": 14.86,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 38.7,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 753,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212213,
    "out:DH kr savings": 267748,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 469533,
    "out:% savings (space heating)": 26.17,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.19,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.32,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 115.69,
    "out:Total Energy Use Post PV": 115.69,
    "out:Primary Energy": 116.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.24,
    "out:CO2 Operational/m2": 37.16,
    "out:Total CO2/m2": 51.4,
    "out:Total CO2 (tons)": 264.66,
    "out:Klimatpaverkan": -24.95,
    "out:Initial Cost/MSEK": 5.794,
    "out:Running cost/(Apt SEK y)": 31400,
    "out:Running Cost over RSP/MSEK": 13.244,
    "out:LCP/MSEK": 0.466,
    "out:ROI% old": 15.43,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 42.2,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 800,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227660,
    "out:DH kr savings": 283195,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 449595,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.32,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.19,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 122.69,
    "out:Total Energy Use Post PV": 117.84,
    "out:Primary Energy": 113.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.02,
    "out:CO2 Operational/m2": 31.45,
    "out:Total CO2/m2": 52.47,
    "out:Total CO2 (tons)": 270.15,
    "out:Klimatpaverkan": -19.46,
    "out:Initial Cost/MSEK": 6.102,
    "out:Running cost/(Apt SEK y)": 32271,
    "out:Running Cost over RSP/MSEK": 13.625,
    "out:LCP/MSEK": -0.223,
    "out:ROI% old": 13.76,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39.8,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 753,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 253444,
    "out:DH kr savings": 247152,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 469533,
    "out:% savings (space heating)": 26.17,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.19,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.32,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 118.69,
    "out:Total Energy Use Post PV": 113.84,
    "out:Primary Energy": 109.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.02,
    "out:CO2 Operational/m2": 30.01,
    "out:Total CO2/m2": 51.03,
    "out:Total CO2 (tons)": 262.73,
    "out:Klimatpaverkan": -26.88,
    "out:Initial Cost/MSEK": 6.231,
    "out:Running cost/(Apt SEK y)": 31119,
    "out:Running Cost over RSP/MSEK": 13.138,
    "out:LCP/MSEK": 0.135,
    "out:ROI% old": 14.6,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 44.7,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 815,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 274040,
    "out:DH kr savings": 267748,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 449595,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.32,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.19,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 118.69,
    "out:Total Energy Use Post PV": 113.84,
    "out:Primary Energy": 111.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.3,
    "out:CO2 Operational/m2": 30.01,
    "out:Total CO2/m2": 51.31,
    "out:Total CO2 (tons)": 264.18,
    "out:Klimatpaverkan": -25.43,
    "out:Initial Cost/MSEK": 6.278,
    "out:Running cost/(Apt SEK y)": 31119,
    "out:Running Cost over RSP/MSEK": 13.138,
    "out:LCP/MSEK": 0.088,
    "out:ROI% old": 14.49,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 44.7,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 815,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 274040,
    "out:DH kr savings": 267748,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 469533,
    "out:% savings (space heating)": 26.17,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.19,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.32,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 115.69,
    "out:Total Energy Use Post PV": 110.84,
    "out:Primary Energy": 107.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.3,
    "out:CO2 Operational/m2": 28.93,
    "out:Total CO2/m2": 50.23,
    "out:Total CO2 (tons)": 258.62,
    "out:Klimatpaverkan": -30.99,
    "out:Initial Cost/MSEK": 6.406,
    "out:Running cost/(Apt SEK y)": 30255,
    "out:Running Cost over RSP/MSEK": 12.773,
    "out:LCP/MSEK": 0.324,
    "out:ROI% old": 15.01,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 48.6,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 862,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 289487,
    "out:DH kr savings": 283195,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 449595,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.32,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.25,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 117.3,
    "out:Total Energy Use Post PV": 117.3,
    "out:Primary Energy": 109.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.61,
    "out:CO2 Operational/m2": 38.97,
    "out:Total CO2/m2": 53.57,
    "out:Total CO2 (tons)": 275.85,
    "out:Klimatpaverkan": -13.76,
    "out:Initial Cost/MSEK": 5.844,
    "out:Running cost/(Apt SEK y)": 32387,
    "out:Running Cost over RSP/MSEK": 13.666,
    "out:LCP/MSEK": -0.006,
    "out:ROI% old": 14.27,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 41,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 746,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 247152,
    "out:DH kr savings": 247152,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 392635,
    "out:% savings (space heating)": 38.26,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.25,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.06,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 114.3,
    "out:Total Energy Use Post PV": 114.3,
    "out:Primary Energy": 107.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.61,
    "out:CO2 Operational/m2": 37.89,
    "out:Total CO2/m2": 52.49,
    "out:Total CO2 (tons)": 270.29,
    "out:Klimatpaverkan": -19.32,
    "out:Initial Cost/MSEK": 5.973,
    "out:Running cost/(Apt SEK y)": 31523,
    "out:Running Cost over RSP/MSEK": 13.301,
    "out:LCP/MSEK": 0.231,
    "out:ROI% old": 14.84,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 44.7,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 793,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 262599,
    "out:DH kr savings": 262599,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 376193,
    "out:% savings (space heating)": 40.85,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.06,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.25,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 113.3,
    "out:Total Energy Use Post PV": 113.3,
    "out:Primary Energy": 107.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.89,
    "out:CO2 Operational/m2": 37.53,
    "out:Total CO2/m2": 52.41,
    "out:Total CO2 (tons)": 269.88,
    "out:Klimatpaverkan": -19.73,
    "out:Initial Cost/MSEK": 6.019,
    "out:Running cost/(Apt SEK y)": 31235,
    "out:Running Cost over RSP/MSEK": 13.18,
    "out:LCP/MSEK": 0.305,
    "out:ROI% old": 15.01,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 13,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 267748,
    "out:DH kr savings": 267748,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 392635,
    "out:% savings (space heating)": 38.26,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.25,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.06,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 110.3,
    "out:Primary Energy": 104.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.89,
    "out:CO2 Operational/m2": 36.45,
    "out:Total CO2/m2": 51.33,
    "out:Total CO2 (tons)": 264.32,
    "out:Klimatpaverkan": -25.29,
    "out:Initial Cost/MSEK": 6.148,
    "out:Running cost/(Apt SEK y)": 30371,
    "out:Running Cost over RSP/MSEK": 12.815,
    "out:LCP/MSEK": 0.541,
    "out:ROI% old": 15.54,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 855,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 283195,
    "out:DH kr savings": 283195,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 376193,
    "out:% savings (space heating)": 40.85,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.06,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.25,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 117.3,
    "out:Total Energy Use Post PV": 113.29,
    "out:Primary Energy": 102.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.67,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 48.2,
    "out:Total CO2 (tons)": 248.2,
    "out:Klimatpaverkan": -41.41,
    "out:Initial Cost/MSEK": 6.456,
    "out:Running cost/(Apt SEK y)": 31282,
    "out:Running Cost over RSP/MSEK": 13.212,
    "out:LCP/MSEK": -0.164,
    "out:ROI% old": 13.92,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 806,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 247152,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 306810,
    "out:DH kr savings": 247152,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 392635,
    "out:% savings (space heating)": 38.26,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.25,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.06,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 114.3,
    "out:Total Energy Use Post PV": 110.29,
    "out:Primary Energy": 99.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.67,
    "out:CO2 Operational/m2": 25.46,
    "out:Total CO2/m2": 47.12,
    "out:Total CO2 (tons)": 242.64,
    "out:Klimatpaverkan": -46.97,
    "out:Initial Cost/MSEK": 6.585,
    "out:Running cost/(Apt SEK y)": 30419,
    "out:Running Cost over RSP/MSEK": 12.847,
    "out:LCP/MSEK": 0.073,
    "out:ROI% old": 14.44,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 853,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 262599,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 322257,
    "out:DH kr savings": 262599,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 376193,
    "out:% savings (space heating)": 40.85,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.06,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.25,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 113.3,
    "out:Total Energy Use Post PV": 109.29,
    "out:Primary Energy": 100.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.95,
    "out:CO2 Operational/m2": 25.1,
    "out:Total CO2/m2": 47.04,
    "out:Total CO2 (tons)": 242.23,
    "out:Klimatpaverkan": -47.38,
    "out:Initial Cost/MSEK": 6.632,
    "out:Running cost/(Apt SEK y)": 30131,
    "out:Running Cost over RSP/MSEK": 12.725,
    "out:LCP/MSEK": 0.147,
    "out:ROI% old": 14.6,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 868,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 327406,
    "out:DH kr savings": 267748,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 392635,
    "out:% savings (space heating)": 38.26,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.25,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.06,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 106.29,
    "out:Primary Energy": 97.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.95,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 45.96,
    "out:Total CO2 (tons)": 236.67,
    "out:Klimatpaverkan": -52.94,
    "out:Initial Cost/MSEK": 6.76,
    "out:Running cost/(Apt SEK y)": 29267,
    "out:Running Cost over RSP/MSEK": 12.361,
    "out:LCP/MSEK": 0.384,
    "out:ROI% old": 15.1,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 915,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 342853,
    "out:DH kr savings": 283195,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 376193,
    "out:% savings (space heating)": 40.85,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.06,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.25,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 112.3,
    "out:Total Energy Use Post PV": 112.3,
    "out:Primary Energy": 106.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.61,
    "out:CO2 Operational/m2": 37.17,
    "out:Total CO2/m2": 51.77,
    "out:Total CO2 (tons)": 266.58,
    "out:Klimatpaverkan": -23.03,
    "out:Initial Cost/MSEK": 5.986,
    "out:Running cost/(Apt SEK y)": 30947,
    "out:Running Cost over RSP/MSEK": 13.058,
    "out:LCP/MSEK": 0.46,
    "out:ROI% old": 15.38,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 47.3,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 824,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 272897,
    "out:DH kr savings": 272897,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 392635,
    "out:% savings (space heating)": 38.26,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.25,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.06,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 109.3,
    "out:Total Energy Use Post PV": 109.3,
    "out:Primary Energy": 103.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.61,
    "out:CO2 Operational/m2": 36.09,
    "out:Total CO2/m2": 50.69,
    "out:Total CO2 (tons)": 261.02,
    "out:Klimatpaverkan": -28.59,
    "out:Initial Cost/MSEK": 6.115,
    "out:Running cost/(Apt SEK y)": 30084,
    "out:Running Cost over RSP/MSEK": 12.693,
    "out:LCP/MSEK": 0.696,
    "out:ROI% old": 15.91,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 871,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 288344,
    "out:DH kr savings": 288344,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 376193,
    "out:% savings (space heating)": 40.85,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.06,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.25,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 109.3,
    "out:Total Energy Use Post PV": 109.3,
    "out:Primary Energy": 104.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.89,
    "out:CO2 Operational/m2": 36.09,
    "out:Total CO2/m2": 50.97,
    "out:Total CO2 (tons)": 262.47,
    "out:Klimatpaverkan": -27.14,
    "out:Initial Cost/MSEK": 6.162,
    "out:Running cost/(Apt SEK y)": 30084,
    "out:Running Cost over RSP/MSEK": 12.693,
    "out:LCP/MSEK": 0.649,
    "out:ROI% old": 15.79,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 14,
    "out:Return/kSEK/y": 871,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 288344,
    "out:DH kr savings": 288344,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 392635,
    "out:% savings (space heating)": 38.26,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.25,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.06,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 105.3,
    "out:Primary Energy": 101.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.89,
    "out:CO2 Operational/m2": 34.65,
    "out:Total CO2/m2": 49.53,
    "out:Total CO2 (tons)": 255.05,
    "out:Klimatpaverkan": -34.56,
    "out:Initial Cost/MSEK": 6.291,
    "out:Running cost/(Apt SEK y)": 28932,
    "out:Running Cost over RSP/MSEK": 12.207,
    "out:LCP/MSEK": 1.007,
    "out:ROI% old": 16.57,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 15,
    "out:Return/kSEK/y": 933,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 308940,
    "out:DH kr savings": 308940,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 376193,
    "out:% savings (space heating)": 40.85,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.06,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.25,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 112.3,
    "out:Total Energy Use Post PV": 108.29,
    "out:Primary Energy": 99.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.67,
    "out:CO2 Operational/m2": 24.74,
    "out:Total CO2/m2": 46.4,
    "out:Total CO2 (tons)": 238.93,
    "out:Klimatpaverkan": -50.68,
    "out:Initial Cost/MSEK": 6.598,
    "out:Running cost/(Apt SEK y)": 29843,
    "out:Running Cost over RSP/MSEK": 12.604,
    "out:LCP/MSEK": 0.302,
    "out:ROI% old": 14.94,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 884,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 272897,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 332555,
    "out:DH kr savings": 272897,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 392635,
    "out:% savings (space heating)": 38.26,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.25,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.06,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 109.3,
    "out:Total Energy Use Post PV": 105.29,
    "out:Primary Energy": 96.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.67,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 45.32,
    "out:Total CO2 (tons)": 233.37,
    "out:Klimatpaverkan": -56.24,
    "out:Initial Cost/MSEK": 6.727,
    "out:Running cost/(Apt SEK y)": 28979,
    "out:Running Cost over RSP/MSEK": 12.239,
    "out:LCP/MSEK": 0.538,
    "out:ROI% old": 15.43,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 930,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 348002,
    "out:DH kr savings": 288344,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 376193,
    "out:% savings (space heating)": 40.85,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.06,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.25,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 109.3,
    "out:Total Energy Use Post PV": 105.29,
    "out:Primary Energy": 97.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.95,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 45.6,
    "out:Total CO2 (tons)": 234.82,
    "out:Klimatpaverkan": -54.79,
    "out:Initial Cost/MSEK": 6.774,
    "out:Running cost/(Apt SEK y)": 28979,
    "out:Running Cost over RSP/MSEK": 12.239,
    "out:LCP/MSEK": 0.491,
    "out:ROI% old": 15.32,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 930,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 348002,
    "out:DH kr savings": 288344,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 392635,
    "out:% savings (space heating)": 38.26,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.25,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.06,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 105.3,
    "out:Total Energy Use Post PV": 101.29,
    "out:Primary Energy": 94.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.95,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 44.16,
    "out:Total CO2 (tons)": 227.4,
    "out:Klimatpaverkan": -62.21,
    "out:Initial Cost/MSEK": 6.903,
    "out:Running cost/(Apt SEK y)": 27827,
    "out:Running Cost over RSP/MSEK": 11.752,
    "out:LCP/MSEK": 0.849,
    "out:ROI% old": 16.04,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 993,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 368598,
    "out:DH kr savings": 308940,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 376193,
    "out:% savings (space heating)": 40.85,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.06,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.25,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 112.69,
    "out:Total Energy Use Post PV": 112.69,
    "out:Primary Energy": 112.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.95,
    "out:CO2 Operational/m2": 36.08,
    "out:Total CO2/m2": 53.03,
    "out:Total CO2 (tons)": 273.04,
    "out:Klimatpaverkan": -16.57,
    "out:Initial Cost/MSEK": 6.641,
    "out:Running cost/(Apt SEK y)": 30536,
    "out:Running Cost over RSP/MSEK": 12.879,
    "out:LCP/MSEK": -0.016,
    "out:ROI% old": 14.25,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 243107,
    "out:DH kr savings": 298642,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 392635,
    "out:% savings (space heating)": 38.26,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.25,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.06,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 109.69,
    "out:Total Energy Use Post PV": 109.69,
    "out:Primary Energy": 109.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.95,
    "out:CO2 Operational/m2": 35,
    "out:Total CO2/m2": 51.95,
    "out:Total CO2 (tons)": 267.48,
    "out:Klimatpaverkan": -22.13,
    "out:Initial Cost/MSEK": 6.769,
    "out:Running cost/(Apt SEK y)": 29672,
    "out:Running Cost over RSP/MSEK": 12.514,
    "out:LCP/MSEK": 0.221,
    "out:ROI% old": 14.75,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 893,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 314089,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258554,
    "out:DH kr savings": 314089,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 376193,
    "out:% savings (space heating)": 40.85,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.06,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.25,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 108.69,
    "out:Total Energy Use Post PV": 108.69,
    "out:Primary Energy": 109.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.23,
    "out:CO2 Operational/m2": 34.64,
    "out:Total CO2/m2": 51.87,
    "out:Total CO2 (tons)": 267.08,
    "out:Klimatpaverkan": -22.53,
    "out:Initial Cost/MSEK": 6.816,
    "out:Running cost/(Apt SEK y)": 29384,
    "out:Running Cost over RSP/MSEK": 12.393,
    "out:LCP/MSEK": 0.295,
    "out:ROI% old": 14.9,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 909,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 319238,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263703,
    "out:DH kr savings": 319238,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 392635,
    "out:% savings (space heating)": 38.26,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.25,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.06,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 105.69,
    "out:Total Energy Use Post PV": 105.69,
    "out:Primary Energy": 107.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.23,
    "out:CO2 Operational/m2": 33.56,
    "out:Total CO2/m2": 50.79,
    "out:Total CO2 (tons)": 261.52,
    "out:Klimatpaverkan": -28.09,
    "out:Initial Cost/MSEK": 6.945,
    "out:Running cost/(Apt SEK y)": 28520,
    "out:Running Cost over RSP/MSEK": 12.028,
    "out:LCP/MSEK": 0.531,
    "out:ROI% old": 15.38,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 955,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 334685,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279150,
    "out:DH kr savings": 334685,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 376193,
    "out:% savings (space heating)": 40.85,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.06,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.25,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 112.69,
    "out:Total Energy Use Post PV": 107.84,
    "out:Primary Energy": 103.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.01,
    "out:CO2 Operational/m2": 27.85,
    "out:Total CO2/m2": 51.85,
    "out:Total CO2 (tons)": 267,
    "out:Klimatpaverkan": -22.61,
    "out:Initial Cost/MSEK": 7.253,
    "out:Running cost/(Apt SEK y)": 29391,
    "out:Running Cost over RSP/MSEK": 12.408,
    "out:LCP/MSEK": -0.157,
    "out:ROI% old": 13.98,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 908,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 304934,
    "out:DH kr savings": 298642,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 392635,
    "out:% savings (space heating)": 38.26,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.25,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.06,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 109.69,
    "out:Total Energy Use Post PV": 104.84,
    "out:Primary Energy": 100.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.01,
    "out:CO2 Operational/m2": 26.77,
    "out:Total CO2/m2": 50.77,
    "out:Total CO2 (tons)": 261.44,
    "out:Klimatpaverkan": -28.17,
    "out:Initial Cost/MSEK": 7.381,
    "out:Running cost/(Apt SEK y)": 28527,
    "out:Running Cost over RSP/MSEK": 12.044,
    "out:LCP/MSEK": 0.079,
    "out:ROI% old": 14.44,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 955,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 314089,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 320381,
    "out:DH kr savings": 314089,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 376193,
    "out:% savings (space heating)": 40.85,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.06,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.25,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 108.69,
    "out:Total Energy Use Post PV": 103.84,
    "out:Primary Energy": 101.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.29,
    "out:CO2 Operational/m2": 26.41,
    "out:Total CO2/m2": 50.7,
    "out:Total CO2 (tons)": 261.03,
    "out:Klimatpaverkan": -28.58,
    "out:Initial Cost/MSEK": 7.428,
    "out:Running cost/(Apt SEK y)": 28239,
    "out:Running Cost over RSP/MSEK": 11.922,
    "out:LCP/MSEK": 0.154,
    "out:ROI% old": 14.58,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 970,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 319238,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 325530,
    "out:DH kr savings": 319238,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 392635,
    "out:% savings (space heating)": 38.26,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.25,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.06,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 105.69,
    "out:Total Energy Use Post PV": 100.84,
    "out:Primary Energy": 98.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.29,
    "out:CO2 Operational/m2": 25.33,
    "out:Total CO2/m2": 49.62,
    "out:Total CO2 (tons)": 255.47,
    "out:Klimatpaverkan": -34.14,
    "out:Initial Cost/MSEK": 7.557,
    "out:Running cost/(Apt SEK y)": 27375,
    "out:Running Cost over RSP/MSEK": 11.557,
    "out:LCP/MSEK": 0.39,
    "out:ROI% old": 15.02,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1017,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 334685,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 340977,
    "out:DH kr savings": 334685,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 376193,
    "out:% savings (space heating)": 40.85,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.06,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.25,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 107.69,
    "out:Total Energy Use Post PV": 107.69,
    "out:Primary Energy": 109.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.95,
    "out:CO2 Operational/m2": 34.28,
    "out:Total CO2/m2": 51.23,
    "out:Total CO2 (tons)": 263.77,
    "out:Klimatpaverkan": -25.84,
    "out:Initial Cost/MSEK": 6.783,
    "out:Running cost/(Apt SEK y)": 29096,
    "out:Running Cost over RSP/MSEK": 12.271,
    "out:LCP/MSEK": 0.45,
    "out:ROI% old": 15.23,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 924,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268852,
    "out:DH kr savings": 324387,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 392635,
    "out:% savings (space heating)": 38.26,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.25,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.06,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 104.69,
    "out:Total Energy Use Post PV": 104.69,
    "out:Primary Energy": 106.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.95,
    "out:CO2 Operational/m2": 33.2,
    "out:Total CO2/m2": 50.15,
    "out:Total CO2 (tons)": 258.21,
    "out:Klimatpaverkan": -31.4,
    "out:Initial Cost/MSEK": 6.912,
    "out:Running cost/(Apt SEK y)": 28232,
    "out:Running Cost over RSP/MSEK": 11.906,
    "out:LCP/MSEK": 0.686,
    "out:ROI% old": 15.7,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 971,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 284299,
    "out:DH kr savings": 339834,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 376193,
    "out:% savings (space heating)": 40.85,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.06,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.25,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 103.69,
    "out:Total Energy Use Post PV": 103.69,
    "out:Primary Energy": 106.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.23,
    "out:CO2 Operational/m2": 32.84,
    "out:Total CO2/m2": 50.07,
    "out:Total CO2 (tons)": 257.81,
    "out:Klimatpaverkan": -31.8,
    "out:Initial Cost/MSEK": 6.959,
    "out:Running cost/(Apt SEK y)": 27944,
    "out:Running Cost over RSP/MSEK": 11.784,
    "out:LCP/MSEK": 0.761,
    "out:ROI% old": 15.85,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 986,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 344983,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 289448,
    "out:DH kr savings": 344983,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 392635,
    "out:% savings (space heating)": 38.26,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.25,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.06,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 100.69,
    "out:Total Energy Use Post PV": 100.69,
    "out:Primary Energy": 104.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.23,
    "out:CO2 Operational/m2": 31.76,
    "out:Total CO2/m2": 48.99,
    "out:Total CO2 (tons)": 252.25,
    "out:Klimatpaverkan": -37.36,
    "out:Initial Cost/MSEK": 7.088,
    "out:Running cost/(Apt SEK y)": 27080,
    "out:Running Cost over RSP/MSEK": 11.42,
    "out:LCP/MSEK": 0.997,
    "out:ROI% old": 16.3,
    "out:Payback discounted": 7,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360430,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304895,
    "out:DH kr savings": 360430,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 376193,
    "out:% savings (space heating)": 40.85,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.06,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.25,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 107.69,
    "out:Total Energy Use Post PV": 102.84,
    "out:Primary Energy": 100.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.01,
    "out:CO2 Operational/m2": 26.05,
    "out:Total CO2/m2": 50.05,
    "out:Total CO2 (tons)": 257.73,
    "out:Klimatpaverkan": -31.88,
    "out:Initial Cost/MSEK": 7.395,
    "out:Running cost/(Apt SEK y)": 27951,
    "out:Running Cost over RSP/MSEK": 11.8,
    "out:LCP/MSEK": 0.309,
    "out:ROI% old": 14.88,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 60.2,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 986,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 330679,
    "out:DH kr savings": 324387,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 392635,
    "out:% savings (space heating)": 38.26,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.25,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.06,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 104.69,
    "out:Total Energy Use Post PV": 99.84,
    "out:Primary Energy": 97.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.01,
    "out:CO2 Operational/m2": 24.97,
    "out:Total CO2/m2": 48.97,
    "out:Total CO2 (tons)": 252.17,
    "out:Klimatpaverkan": -37.44,
    "out:Initial Cost/MSEK": 7.524,
    "out:Running cost/(Apt SEK y)": 27087,
    "out:Running Cost over RSP/MSEK": 11.435,
    "out:LCP/MSEK": 0.545,
    "out:ROI% old": 15.32,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 65,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346126,
    "out:DH kr savings": 339834,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 376193,
    "out:% savings (space heating)": 40.85,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.06,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.25,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 103.69,
    "out:Total Energy Use Post PV": 98.84,
    "out:Primary Energy": 98.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.29,
    "out:CO2 Operational/m2": 24.61,
    "out:Total CO2/m2": 48.9,
    "out:Total CO2 (tons)": 251.77,
    "out:Klimatpaverkan": -37.84,
    "out:Initial Cost/MSEK": 7.571,
    "out:Running cost/(Apt SEK y)": 26799,
    "out:Running Cost over RSP/MSEK": 11.314,
    "out:LCP/MSEK": 0.62,
    "out:ROI% old": 15.45,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 66.7,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1048,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 344983,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 351275,
    "out:DH kr savings": 344983,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 392635,
    "out:% savings (space heating)": 38.26,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.25,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.06,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 100.69,
    "out:Total Energy Use Post PV": 95.84,
    "out:Primary Energy": 95.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.29,
    "out:CO2 Operational/m2": 23.53,
    "out:Total CO2/m2": 47.82,
    "out:Total CO2 (tons)": 246.21,
    "out:Klimatpaverkan": -43.4,
    "out:Initial Cost/MSEK": 7.7,
    "out:Running cost/(Apt SEK y)": 25936,
    "out:Running Cost over RSP/MSEK": 10.949,
    "out:LCP/MSEK": 0.856,
    "out:ROI% old": 15.87,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.46,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 71.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1095,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 360430,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 366722,
    "out:DH kr savings": 360430,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 376193,
    "out:% savings (space heating)": 40.85,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.06,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.49,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 113.3,
    "out:Total Energy Use Post PV": 113.3,
    "out:Primary Energy": 105.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.17,
    "out:CO2 Operational/m2": 37.53,
    "out:Total CO2/m2": 61.69,
    "out:Total CO2 (tons)": 317.67,
    "out:Klimatpaverkan": 28.06,
    "out:Initial Cost/MSEK": 9.01,
    "out:Running cost/(Apt SEK y)": 31235,
    "out:Running Cost over RSP/MSEK": 13.18,
    "out:LCP/MSEK": -2.685,
    "out:ROI% old": 10.03,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 46,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 267748,
    "out:DH kr savings": 267748,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 368079,
    "out:% savings (space heating)": 42.12,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.49,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.51,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 110.3,
    "out:Primary Energy": 103.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.17,
    "out:CO2 Operational/m2": 36.45,
    "out:Total CO2/m2": 60.61,
    "out:Total CO2 (tons)": 312.11,
    "out:Klimatpaverkan": 22.5,
    "out:Initial Cost/MSEK": 9.139,
    "out:Running cost/(Apt SEK y)": 30371,
    "out:Running Cost over RSP/MSEK": 12.815,
    "out:LCP/MSEK": -2.449,
    "out:ROI% old": 10.46,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 855,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 283195,
    "out:DH kr savings": 283195,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 352752,
    "out:% savings (space heating)": 44.53,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 68.51,
    "out:Etvv": 25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.49,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 109.3,
    "out:Total Energy Use Post PV": 109.3,
    "out:Primary Energy": 103.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.45,
    "out:CO2 Operational/m2": 36.09,
    "out:Total CO2/m2": 60.54,
    "out:Total CO2 (tons)": 311.7,
    "out:Klimatpaverkan": 22.09,
    "out:Initial Cost/MSEK": 9.186,
    "out:Running cost/(Apt SEK y)": 30084,
    "out:Running Cost over RSP/MSEK": 12.693,
    "out:LCP/MSEK": -2.374,
    "out:ROI% old": 10.59,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 9,
    "out:Return/kSEK/y": 871,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 288344,
    "out:DH kr savings": 288344,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 368079,
    "out:% savings (space heating)": 42.12,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.49,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.51,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 106.3,
    "out:Total Energy Use Post PV": 106.3,
    "out:Primary Energy": 100.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.45,
    "out:CO2 Operational/m2": 35.01,
    "out:Total CO2/m2": 59.46,
    "out:Total CO2 (tons)": 306.14,
    "out:Klimatpaverkan": 16.53,
    "out:Initial Cost/MSEK": 9.314,
    "out:Running cost/(Apt SEK y)": 29220,
    "out:Running Cost over RSP/MSEK": 12.328,
    "out:LCP/MSEK": -2.138,
    "out:ROI% old": 11.01,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 917,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 303791,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 303791,
    "out:DH kr savings": 303791,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 352752,
    "out:% savings (space heating)": 44.53,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 68.51,
    "out:Etvv": 21.25,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.49,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 113.3,
    "out:Total Energy Use Post PV": 109.29,
    "out:Primary Energy": 98.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.23,
    "out:CO2 Operational/m2": 25.1,
    "out:Total CO2/m2": 56.33,
    "out:Total CO2 (tons)": 290.02,
    "out:Klimatpaverkan": 0.41,
    "out:Initial Cost/MSEK": 9.622,
    "out:Running cost/(Apt SEK y)": 30131,
    "out:Running Cost over RSP/MSEK": 12.725,
    "out:LCP/MSEK": -2.843,
    "out:ROI% old": 10.06,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 51.4,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 868,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 267748,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 327406,
    "out:DH kr savings": 267748,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 368079,
    "out:% savings (space heating)": 42.12,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.49,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.51,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 110.3,
    "out:Total Energy Use Post PV": 106.29,
    "out:Primary Energy": 95.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.23,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 55.25,
    "out:Total CO2 (tons)": 284.46,
    "out:Klimatpaverkan": -5.15,
    "out:Initial Cost/MSEK": 9.751,
    "out:Running cost/(Apt SEK y)": 29267,
    "out:Running Cost over RSP/MSEK": 12.361,
    "out:LCP/MSEK": -2.607,
    "out:ROI% old": 10.47,
    "out:Payback discounted": 12,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 915,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283195,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 342853,
    "out:DH kr savings": 283195,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 352752,
    "out:% savings (space heating)": 44.53,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 68.51,
    "out:Etvv": 25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.49,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 109.3,
    "out:Total Energy Use Post PV": 105.29,
    "out:Primary Energy": 95.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.51,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 55.17,
    "out:Total CO2 (tons)": 284.05,
    "out:Klimatpaverkan": -5.56,
    "out:Initial Cost/MSEK": 9.798,
    "out:Running cost/(Apt SEK y)": 28979,
    "out:Running Cost over RSP/MSEK": 12.239,
    "out:LCP/MSEK": -2.532,
    "out:ROI% old": 10.59,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 930,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 288344,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 348002,
    "out:DH kr savings": 288344,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 368079,
    "out:% savings (space heating)": 42.12,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.49,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.51,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 106.3,
    "out:Total Energy Use Post PV": 102.29,
    "out:Primary Energy": 93.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.51,
    "out:CO2 Operational/m2": 22.58,
    "out:Total CO2/m2": 54.09,
    "out:Total CO2 (tons)": 278.49,
    "out:Klimatpaverkan": -11.12,
    "out:Initial Cost/MSEK": 9.926,
    "out:Running cost/(Apt SEK y)": 28115,
    "out:Running Cost over RSP/MSEK": 11.874,
    "out:LCP/MSEK": -2.296,
    "out:ROI% old": 10.98,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 61.8,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 977,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 303791,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 363449,
    "out:DH kr savings": 303791,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 352752,
    "out:% savings (space heating)": 44.53,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 68.51,
    "out:Etvv": 21.25,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.49,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 107.3,
    "out:Total Energy Use Post PV": 107.3,
    "out:Primary Energy": 102.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.17,
    "out:CO2 Operational/m2": 35.37,
    "out:Total CO2/m2": 59.53,
    "out:Total CO2 (tons)": 306.55,
    "out:Klimatpaverkan": 16.94,
    "out:Initial Cost/MSEK": 9.153,
    "out:Running cost/(Apt SEK y)": 29508,
    "out:Running Cost over RSP/MSEK": 12.45,
    "out:LCP/MSEK": -2.098,
    "out:ROI% old": 11.01,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 54.2,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 902,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 298642,
    "out:DH kr savings": 298642,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 368079,
    "out:% savings (space heating)": 42.12,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.49,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.51,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 104.3,
    "out:Primary Energy": 99.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.17,
    "out:CO2 Operational/m2": 34.29,
    "out:Total CO2/m2": 58.46,
    "out:Total CO2 (tons)": 300.98,
    "out:Klimatpaverkan": 11.37,
    "out:Initial Cost/MSEK": 9.281,
    "out:Running cost/(Apt SEK y)": 28644,
    "out:Running Cost over RSP/MSEK": 12.085,
    "out:LCP/MSEK": -1.862,
    "out:ROI% old": 11.42,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 949,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 314089,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 314089,
    "out:DH kr savings": 314089,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 352752,
    "out:% savings (space heating)": 44.53,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 68.51,
    "out:Etvv": 20,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.49,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 104.3,
    "out:Primary Energy": 100.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.45,
    "out:CO2 Operational/m2": 34.29,
    "out:Total CO2/m2": 58.74,
    "out:Total CO2 (tons)": 302.43,
    "out:Klimatpaverkan": 12.82,
    "out:Initial Cost/MSEK": 9.328,
    "out:Running cost/(Apt SEK y)": 28644,
    "out:Running Cost over RSP/MSEK": 12.085,
    "out:LCP/MSEK": -1.909,
    "out:ROI% old": 11.36,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 10,
    "out:Return/kSEK/y": 949,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 314089,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 314089,
    "out:DH kr savings": 314089,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 368079,
    "out:% savings (space heating)": 42.12,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.49,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.51,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 14.3,
    "out:Total Energy Use Pre PV": 101.3,
    "out:Total Energy Use Post PV": 101.3,
    "out:Primary Energy": 97.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.45,
    "out:CO2 Operational/m2": 33.21,
    "out:Total CO2/m2": 57.66,
    "out:Total CO2 (tons)": 296.87,
    "out:Klimatpaverkan": 7.26,
    "out:Initial Cost/MSEK": 9.457,
    "out:Running cost/(Apt SEK y)": 27780,
    "out:Running Cost over RSP/MSEK": 11.72,
    "out:LCP/MSEK": -1.673,
    "out:ROI% old": 11.76,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1001,
    "out:Return %": 11,
    "out:Return/kSEK/y": 995,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 329536,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 329536,
    "out:DH kr savings": 329536,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 352752,
    "out:% savings (space heating)": 44.53,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 68.51,
    "out:Etvv": 17,
    "out:Ef": 14.3
  },
  {
    "ID": "Z01012200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.49,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 107.3,
    "out:Total Energy Use Post PV": 103.29,
    "out:Primary Energy": 95.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.23,
    "out:CO2 Operational/m2": 22.94,
    "out:Total CO2/m2": 54.17,
    "out:Total CO2 (tons)": 278.9,
    "out:Klimatpaverkan": -10.71,
    "out:Initial Cost/MSEK": 9.765,
    "out:Running cost/(Apt SEK y)": 28403,
    "out:Running Cost over RSP/MSEK": 11.996,
    "out:LCP/MSEK": -2.256,
    "out:ROI% old": 10.99,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 60.2,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 962,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298642,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 358300,
    "out:DH kr savings": 298642,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 368079,
    "out:% savings (space heating)": 42.12,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.49,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.51,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 100.29,
    "out:Primary Energy": 92.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.23,
    "out:CO2 Operational/m2": 21.86,
    "out:Total CO2/m2": 53.09,
    "out:Total CO2 (tons)": 273.34,
    "out:Klimatpaverkan": -16.27,
    "out:Initial Cost/MSEK": 9.893,
    "out:Running cost/(Apt SEK y)": 27539,
    "out:Running Cost over RSP/MSEK": 11.631,
    "out:LCP/MSEK": -2.02,
    "out:ROI% old": 11.37,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 65,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1008,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 314089,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 373746,
    "out:DH kr savings": 314089,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 352752,
    "out:% savings (space heating)": 44.53,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 68.51,
    "out:Etvv": 20,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.49,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 104.3,
    "out:Total Energy Use Post PV": 100.29,
    "out:Primary Energy": 92.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.51,
    "out:CO2 Operational/m2": 21.86,
    "out:Total CO2/m2": 53.37,
    "out:Total CO2 (tons)": 274.79,
    "out:Klimatpaverkan": -14.82,
    "out:Initial Cost/MSEK": 9.94,
    "out:Running cost/(Apt SEK y)": 27539,
    "out:Running Cost over RSP/MSEK": 11.631,
    "out:LCP/MSEK": -2.067,
    "out:ROI% old": 11.32,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 65,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1008,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 314089,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 373746,
    "out:DH kr savings": 314089,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 368079,
    "out:% savings (space heating)": 42.12,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.49,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.51,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 14.3,
    "out:Electricity (inc PV)": 10.29,
    "out:Total Energy Use Pre PV": 101.3,
    "out:Total Energy Use Post PV": 97.29,
    "out:Primary Energy": 90.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.51,
    "out:CO2 Operational/m2": 20.78,
    "out:Total CO2/m2": 52.29,
    "out:Total CO2 (tons)": 269.23,
    "out:Klimatpaverkan": -20.38,
    "out:Initial Cost/MSEK": 10.069,
    "out:Running cost/(Apt SEK y)": 26675,
    "out:Running Cost over RSP/MSEK": 11.266,
    "out:LCP/MSEK": -1.831,
    "out:ROI% old": 11.69,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FT",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 70.1,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 727.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1055,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 12230.02,
    "out:PV (% sold (El))": 37.19,
    "out:PV (kWh self-consumed)": 20656.32,
    "out:PV (ratio sold/self-consumed)": 0.59,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 329536,
    "out:EL kWh savings": 20656,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 389193,
    "out:DH kr savings": 329536,
    "out:El kr savings": 41313,
    "out:El kr sold": 18345,
    "out:El kr saved": 41313,
    "out:El kr return": 59658,
    "out:% solar/total": 28,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 352752,
    "out:% savings (space heating)": 44.53,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 68.51,
    "out:Etvv": 17,
    "out:Ef": 10.29
  },
  {
    "ID": "Z01012201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.49,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 107.69,
    "out:Total Energy Use Post PV": 107.69,
    "out:Primary Energy": 108.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.51,
    "out:CO2 Operational/m2": 34.28,
    "out:Total CO2/m2": 60.79,
    "out:Total CO2 (tons)": 313.01,
    "out:Klimatpaverkan": 23.4,
    "out:Initial Cost/MSEK": 9.807,
    "out:Running cost/(Apt SEK y)": 29096,
    "out:Running Cost over RSP/MSEK": 12.271,
    "out:LCP/MSEK": -2.574,
    "out:ROI% old": 10.54,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 9,
    "out:Return/kSEK/y": 924,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268852,
    "out:DH kr savings": 324387,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 368079,
    "out:% savings (space heating)": 42.12,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.49,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.51,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 104.69,
    "out:Total Energy Use Post PV": 104.69,
    "out:Primary Energy": 105.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.51,
    "out:CO2 Operational/m2": 33.2,
    "out:Total CO2/m2": 59.71,
    "out:Total CO2 (tons)": 307.45,
    "out:Klimatpaverkan": 17.84,
    "out:Initial Cost/MSEK": 9.936,
    "out:Running cost/(Apt SEK y)": 28232,
    "out:Running Cost over RSP/MSEK": 11.906,
    "out:LCP/MSEK": -2.338,
    "out:ROI% old": 10.92,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 971,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 284299,
    "out:DH kr savings": 339834,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 352752,
    "out:% savings (space heating)": 44.53,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 68.51,
    "out:Etvv": 14.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.49,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 103.69,
    "out:Total Energy Use Post PV": 103.69,
    "out:Primary Energy": 105.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.79,
    "out:CO2 Operational/m2": 32.84,
    "out:Total CO2/m2": 59.63,
    "out:Total CO2 (tons)": 307.04,
    "out:Klimatpaverkan": 17.43,
    "out:Initial Cost/MSEK": 9.983,
    "out:Running cost/(Apt SEK y)": 27944,
    "out:Running Cost over RSP/MSEK": 11.784,
    "out:LCP/MSEK": -2.263,
    "out:ROI% old": 11.05,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 986,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 344983,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 289448,
    "out:DH kr savings": 344983,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 368079,
    "out:% savings (space heating)": 42.12,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.49,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.51,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 100.69,
    "out:Total Energy Use Post PV": 100.69,
    "out:Primary Energy": 103.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.79,
    "out:CO2 Operational/m2": 31.76,
    "out:Total CO2/m2": 58.55,
    "out:Total CO2 (tons)": 301.48,
    "out:Klimatpaverkan": 11.87,
    "out:Initial Cost/MSEK": 10.111,
    "out:Running cost/(Apt SEK y)": 27080,
    "out:Running Cost over RSP/MSEK": 11.42,
    "out:LCP/MSEK": -2.027,
    "out:ROI% old": 11.42,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360430,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304895,
    "out:DH kr savings": 360430,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 352752,
    "out:% savings (space heating)": 44.53,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 68.51,
    "out:Etvv": 11.04,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.49,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 107.69,
    "out:Total Energy Use Post PV": 102.84,
    "out:Primary Energy": 99.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.57,
    "out:CO2 Operational/m2": 26.05,
    "out:Total CO2/m2": 59.62,
    "out:Total CO2 (tons)": 306.96,
    "out:Klimatpaverkan": 17.35,
    "out:Initial Cost/MSEK": 10.419,
    "out:Running cost/(Apt SEK y)": 27951,
    "out:Running Cost over RSP/MSEK": 11.8,
    "out:LCP/MSEK": -2.715,
    "out:ROI% old": 10.56,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 60.2,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 9,
    "out:Return/kSEK/y": 986,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 324387,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 330679,
    "out:DH kr savings": 324387,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 368079,
    "out:% savings (space heating)": 42.12,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.49,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.51,
    "out:DHW (DH)": 16.89,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 104.69,
    "out:Total Energy Use Post PV": 99.84,
    "out:Primary Energy": 97.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.57,
    "out:CO2 Operational/m2": 24.97,
    "out:Total CO2/m2": 58.54,
    "out:Total CO2 (tons)": 301.4,
    "out:Klimatpaverkan": 11.79,
    "out:Initial Cost/MSEK": 10.548,
    "out:Running cost/(Apt SEK y)": 27087,
    "out:Running Cost over RSP/MSEK": 11.435,
    "out:LCP/MSEK": -2.479,
    "out:ROI% old": 10.93,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 65,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346126,
    "out:DH kr savings": 339834,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 352752,
    "out:% savings (space heating)": 44.53,
    "out:Total_Water Heating (DH)": 86978,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 68.51,
    "out:Etvv": 14.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.49,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 103.69,
    "out:Total Energy Use Post PV": 98.84,
    "out:Primary Energy": 96.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.85,
    "out:CO2 Operational/m2": 24.61,
    "out:Total CO2/m2": 58.46,
    "out:Total CO2 (tons)": 301,
    "out:Klimatpaverkan": 11.39,
    "out:Initial Cost/MSEK": 10.595,
    "out:Running cost/(Apt SEK y)": 26799,
    "out:Running Cost over RSP/MSEK": 11.314,
    "out:LCP/MSEK": -2.404,
    "out:ROI% old": 11.04,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 66.7,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1048,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 344983,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 351275,
    "out:DH kr savings": 344983,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 368079,
    "out:% savings (space heating)": 42.12,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.49,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.51,
    "out:DHW (DH)": 12.83,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 100.69,
    "out:Total Energy Use Post PV": 95.84,
    "out:Primary Energy": 94.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.85,
    "out:CO2 Operational/m2": 23.53,
    "out:Total CO2/m2": 57.38,
    "out:Total CO2 (tons)": 295.44,
    "out:Klimatpaverkan": 5.83,
    "out:Initial Cost/MSEK": 10.723,
    "out:Running cost/(Apt SEK y)": 25936,
    "out:Running Cost over RSP/MSEK": 10.949,
    "out:LCP/MSEK": -2.168,
    "out:ROI% old": 11.4,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 71.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1095,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360430,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 366722,
    "out:DH kr savings": 360430,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 352752,
    "out:% savings (space heating)": 44.53,
    "out:Total_Water Heating (DH)": 66047,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 68.51,
    "out:Etvv": 11.04,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.49,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 102.69,
    "out:Total Energy Use Post PV": 102.69,
    "out:Primary Energy": 104.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.51,
    "out:CO2 Operational/m2": 32.48,
    "out:Total CO2/m2": 58.99,
    "out:Total CO2 (tons)": 303.74,
    "out:Klimatpaverkan": 14.13,
    "out:Initial Cost/MSEK": 9.949,
    "out:Running cost/(Apt SEK y)": 27656,
    "out:Running Cost over RSP/MSEK": 11.663,
    "out:LCP/MSEK": -2.108,
    "out:ROI% old": 11.26,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 60.2,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1002,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 350132,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 294597,
    "out:DH kr savings": 350132,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 368079,
    "out:% savings (space heating)": 42.12,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.49,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.51,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 99.69,
    "out:Total Energy Use Post PV": 99.69,
    "out:Primary Energy": 102.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.51,
    "out:CO2 Operational/m2": 31.4,
    "out:Total CO2/m2": 57.91,
    "out:Total CO2 (tons)": 298.18,
    "out:Klimatpaverkan": 8.57,
    "out:Initial Cost/MSEK": 10.078,
    "out:Running cost/(Apt SEK y)": 26792,
    "out:Running Cost over RSP/MSEK": 11.298,
    "out:LCP/MSEK": -1.872,
    "out:ROI% old": 11.63,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 65,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1049,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 365579,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 310044,
    "out:DH kr savings": 365579,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 352752,
    "out:% savings (space heating)": 44.53,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 68.51,
    "out:Etvv": 9.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.49,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 99.69,
    "out:Total Energy Use Post PV": 99.69,
    "out:Primary Energy": 102.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.79,
    "out:CO2 Operational/m2": 31.4,
    "out:Total CO2/m2": 58.19,
    "out:Total CO2 (tons)": 299.63,
    "out:Klimatpaverkan": 10.02,
    "out:Initial Cost/MSEK": 10.125,
    "out:Running cost/(Apt SEK y)": 26792,
    "out:Running Cost over RSP/MSEK": 11.298,
    "out:LCP/MSEK": -1.919,
    "out:ROI% old": 11.58,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 65,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1049,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 365579,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 310044,
    "out:DH kr savings": 365579,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 368079,
    "out:% savings (space heating)": 42.12,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.49,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.51,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 19.69,
    "out:Total Energy Use Pre PV": 96.69,
    "out:Total Energy Use Post PV": 96.69,
    "out:Primary Energy": 100.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.79,
    "out:CO2 Operational/m2": 30.32,
    "out:Total CO2/m2": 57.11,
    "out:Total CO2 (tons)": 294.07,
    "out:Klimatpaverkan": 4.46,
    "out:Initial Cost/MSEK": 10.254,
    "out:Running cost/(Apt SEK y)": 25929,
    "out:Running Cost over RSP/MSEK": 10.933,
    "out:LCP/MSEK": -1.683,
    "out:ROI% old": 11.94,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 70.1,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 1378.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1095,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 381026,
    "out:EL kWh savings": -27767,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 325491,
    "out:DH kr savings": 381026,
    "out:El kr savings": -55535,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 352752,
    "out:% savings (space heating)": 44.53,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 68.51,
    "out:Etvv": 6.79,
    "out:Ef": 19.69
  },
  {
    "ID": "Z01012201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.49,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 102.69,
    "out:Total Energy Use Post PV": 97.84,
    "out:Primary Energy": 96.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.57,
    "out:CO2 Operational/m2": 24.25,
    "out:Total CO2/m2": 57.82,
    "out:Total CO2 (tons)": 297.7,
    "out:Klimatpaverkan": 8.09,
    "out:Initial Cost/MSEK": 10.561,
    "out:Running cost/(Apt SEK y)": 26511,
    "out:Running Cost over RSP/MSEK": 11.192,
    "out:LCP/MSEK": -2.249,
    "out:ROI% old": 11.24,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 68.4,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1064,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 350132,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 356424,
    "out:DH kr savings": 350132,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 368079,
    "out:% savings (space heating)": 42.12,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.49,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.51,
    "out:DHW (DH)": 11.47,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 99.69,
    "out:Total Energy Use Post PV": 94.84,
    "out:Primary Energy": 93.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.57,
    "out:CO2 Operational/m2": 23.17,
    "out:Total CO2/m2": 56.74,
    "out:Total CO2 (tons)": 292.14,
    "out:Klimatpaverkan": 2.53,
    "out:Initial Cost/MSEK": 10.69,
    "out:Running cost/(Apt SEK y)": 25648,
    "out:Running Cost over RSP/MSEK": 10.827,
    "out:LCP/MSEK": -2.013,
    "out:ROI% old": 11.6,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 73.7,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1110,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 365579,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 371871,
    "out:DH kr savings": 365579,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 352752,
    "out:% savings (space heating)": 44.53,
    "out:Total_Water Heating (DH)": 59070,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 68.51,
    "out:Etvv": 9.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.49,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 99.69,
    "out:Total Energy Use Post PV": 94.84,
    "out:Primary Energy": 94.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.85,
    "out:CO2 Operational/m2": 23.17,
    "out:Total CO2/m2": 57.02,
    "out:Total CO2 (tons)": 293.59,
    "out:Klimatpaverkan": 3.98,
    "out:Initial Cost/MSEK": 10.737,
    "out:Running cost/(Apt SEK y)": 25648,
    "out:Running Cost over RSP/MSEK": 10.827,
    "out:LCP/MSEK": -2.06,
    "out:ROI% old": 11.54,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 73.7,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1110,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 365579,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 371871,
    "out:DH kr savings": 365579,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 368079,
    "out:% savings (space heating)": 42.12,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.49,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z01012201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.51,
    "out:DHW (DH)": 8.22,
    "out:Property Electricity": 19.69,
    "out:Electricity (inc PV)": 14.84,
    "out:Total Energy Use Pre PV": 96.69,
    "out:Total Energy Use Post PV": 91.84,
    "out:Primary Energy": 91.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.85,
    "out:CO2 Operational/m2": 22.09,
    "out:Total CO2/m2": 55.94,
    "out:Total CO2 (tons)": 288.03,
    "out:Klimatpaverkan": -1.58,
    "out:Initial Cost/MSEK": 10.866,
    "out:Running cost/(Apt SEK y)": 24784,
    "out:Running Cost over RSP/MSEK": 10.462,
    "out:LCP/MSEK": -1.824,
    "out:ROI% old": 11.89,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 79.3,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 1049.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1157,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 3.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 7892.14,
    "out:PV (% sold (El))": 24,
    "out:PV (kWh self-consumed)": 24994.2,
    "out:PV (ratio sold/self-consumed)": 0.32,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 381026,
    "out:EL kWh savings": -2773,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 387318,
    "out:DH kr savings": 381026,
    "out:El kr savings": -5546,
    "out:El kr sold": 11838,
    "out:El kr saved": 49988,
    "out:El kr return": 61827,
    "out:% solar/total": 34,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 352752,
    "out:% savings (space heating)": 44.53,
    "out:Total_Water Heating (DH)": 42326,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 68.51,
    "out:Etvv": 6.79,
    "out:Ef": 14.84
  },
  {
    "ID": "Z00000000000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 63.71,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 109.9,
    "out:Total Energy Use Post PV": 109.9,
    "out:Primary Energy": 107.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.81,
    "out:CO2 Operational/m2": 35.25,
    "out:Total CO2/m2": 64.07,
    "out:Total CO2 (tons)": 329.88,
    "out:Klimatpaverkan": 40.27,
    "out:Initial Cost/MSEK": 8.279,
    "out:Running cost/(Apt SEK y)": 29809,
    "out:Running Cost over RSP/MSEK": 12.573,
    "out:LCP/MSEK": -1.347,
    "out:ROI% old": 11.96,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.71,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 50,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 886,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 261579,
    "out:DH kr savings": 308940,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 328047,
    "out:% savings (space heating)": 48.42,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.9,
    "out:Etvv": 25,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000000000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.94,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 105.9,
    "out:Total Energy Use Post PV": 105.9,
    "out:Primary Energy": 103.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.81,
    "out:CO2 Operational/m2": 33.81,
    "out:Total CO2/m2": 62.63,
    "out:Total CO2 (tons)": 322.47,
    "out:Klimatpaverkan": 32.86,
    "out:Initial Cost/MSEK": 8.407,
    "out:Running cost/(Apt SEK y)": 28657,
    "out:Running Cost over RSP/MSEK": 12.086,
    "out:LCP/MSEK": -0.989,
    "out:ROI% old": 12.6,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.71,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 948,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 329536,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 282175,
    "out:DH kr savings": 329536,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 308645,
    "out:% savings (space heating)": 51.47,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.13,
    "out:Etvv": 25,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000000000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 63.71,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 105.9,
    "out:Total Energy Use Post PV": 105.9,
    "out:Primary Energy": 104.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.1,
    "out:CO2 Operational/m2": 33.81,
    "out:Total CO2/m2": 62.91,
    "out:Total CO2 (tons)": 323.92,
    "out:Klimatpaverkan": 34.31,
    "out:Initial Cost/MSEK": 8.454,
    "out:Running cost/(Apt SEK y)": 28657,
    "out:Running Cost over RSP/MSEK": 12.086,
    "out:LCP/MSEK": -1.036,
    "out:ROI% old": 12.53,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.71,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 55.7,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 948,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 329536,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 282175,
    "out:DH kr savings": 329536,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 328047,
    "out:% savings (space heating)": 48.42,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.9,
    "out:Etvv": 21.25,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000000000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.94,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 101.9,
    "out:Total Energy Use Post PV": 101.9,
    "out:Primary Energy": 101.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.1,
    "out:CO2 Operational/m2": 32.37,
    "out:Total CO2/m2": 61.47,
    "out:Total CO2 (tons)": 316.5,
    "out:Klimatpaverkan": 26.89,
    "out:Initial Cost/MSEK": 8.583,
    "out:Running cost/(Apt SEK y)": 27505,
    "out:Running Cost over RSP/MSEK": 11.6,
    "out:LCP/MSEK": -0.678,
    "out:ROI% old": 13.16,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.71,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 61.8,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1010,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 350132,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 302771,
    "out:DH kr savings": 350132,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 308645,
    "out:% savings (space heating)": 51.47,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.13,
    "out:Etvv": 21.25,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000000010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 63.71,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 109.9,
    "out:Total Energy Use Post PV": 105.15,
    "out:Primary Energy": 98.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.87,
    "out:CO2 Operational/m2": 26.48,
    "out:Total CO2/m2": 62.35,
    "out:Total CO2 (tons)": 321.05,
    "out:Klimatpaverkan": 31.44,
    "out:Initial Cost/MSEK": 8.891,
    "out:Running cost/(Apt SEK y)": 28669,
    "out:Running Cost over RSP/MSEK": 12.104,
    "out:LCP/MSEK": -1.49,
    "out:ROI% old": 11.89,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.71,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 947,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 308940,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 323125,
    "out:DH kr savings": 308940,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 328047,
    "out:% savings (space heating)": 48.42,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.9,
    "out:Etvv": 25,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000000010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.94,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 105.9,
    "out:Total Energy Use Post PV": 101.15,
    "out:Primary Energy": 95.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.87,
    "out:CO2 Operational/m2": 25.04,
    "out:Total CO2/m2": 60.91,
    "out:Total CO2 (tons)": 313.63,
    "out:Klimatpaverkan": 24.02,
    "out:Initial Cost/MSEK": 9.019,
    "out:Running cost/(Apt SEK y)": 27517,
    "out:Running Cost over RSP/MSEK": 11.618,
    "out:LCP/MSEK": -1.133,
    "out:ROI% old": 12.49,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.71,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1009,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 329536,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 343721,
    "out:DH kr savings": 329536,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 308645,
    "out:% savings (space heating)": 51.47,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.13,
    "out:Etvv": 25,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000000010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 63.71,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 105.9,
    "out:Total Energy Use Post PV": 101.15,
    "out:Primary Energy": 96.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.15,
    "out:CO2 Operational/m2": 25.04,
    "out:Total CO2/m2": 61.19,
    "out:Total CO2 (tons)": 315.08,
    "out:Klimatpaverkan": 25.47,
    "out:Initial Cost/MSEK": 9.066,
    "out:Running cost/(Apt SEK y)": 27517,
    "out:Running Cost over RSP/MSEK": 11.618,
    "out:LCP/MSEK": -1.18,
    "out:ROI% old": 12.43,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.71,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1009,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 329536,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 343721,
    "out:DH kr savings": 329536,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 328047,
    "out:% savings (space heating)": 48.42,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.9,
    "out:Etvv": 21.25,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000000010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.94,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 101.9,
    "out:Total Energy Use Post PV": 97.15,
    "out:Primary Energy": 92.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.15,
    "out:CO2 Operational/m2": 23.6,
    "out:Total CO2/m2": 59.75,
    "out:Total CO2 (tons)": 307.67,
    "out:Klimatpaverkan": 18.06,
    "out:Initial Cost/MSEK": 9.195,
    "out:Running cost/(Apt SEK y)": 26365,
    "out:Running Cost over RSP/MSEK": 11.131,
    "out:LCP/MSEK": -0.822,
    "out:ROI% old": 13.01,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.71,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 70.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1072,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 350132,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 364317,
    "out:DH kr savings": 350132,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 308645,
    "out:% savings (space heating)": 51.47,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.13,
    "out:Etvv": 21.25,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000000100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 63.71,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 103.9,
    "out:Total Energy Use Post PV": 103.9,
    "out:Primary Energy": 103.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.81,
    "out:CO2 Operational/m2": 33.09,
    "out:Total CO2/m2": 61.91,
    "out:Total CO2 (tons)": 318.76,
    "out:Klimatpaverkan": 29.15,
    "out:Initial Cost/MSEK": 8.421,
    "out:Running cost/(Apt SEK y)": 28081,
    "out:Running Cost over RSP/MSEK": 11.843,
    "out:LCP/MSEK": -0.76,
    "out:ROI% old": 13,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.71,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 58.7,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 979,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 292473,
    "out:DH kr savings": 339834,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 328047,
    "out:% savings (space heating)": 48.42,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.9,
    "out:Etvv": 20,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000000100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.94,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 100.9,
    "out:Total Energy Use Post PV": 100.9,
    "out:Primary Energy": 100.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.81,
    "out:CO2 Operational/m2": 32.01,
    "out:Total CO2/m2": 60.83,
    "out:Total CO2 (tons)": 313.2,
    "out:Klimatpaverkan": 23.59,
    "out:Initial Cost/MSEK": 8.55,
    "out:Running cost/(Apt SEK y)": 27217,
    "out:Running Cost over RSP/MSEK": 11.478,
    "out:LCP/MSEK": -0.524,
    "out:ROI% old": 13.41,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.71,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1026,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 355281,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 307920,
    "out:DH kr savings": 355281,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 308645,
    "out:% savings (space heating)": 51.47,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.13,
    "out:Etvv": 20,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000000100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 63.71,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 100.9,
    "out:Total Energy Use Post PV": 100.9,
    "out:Primary Energy": 101.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.1,
    "out:CO2 Operational/m2": 32.01,
    "out:Total CO2/m2": 61.11,
    "out:Total CO2 (tons)": 314.65,
    "out:Klimatpaverkan": 25.04,
    "out:Initial Cost/MSEK": 8.597,
    "out:Running cost/(Apt SEK y)": 27217,
    "out:Running Cost over RSP/MSEK": 11.478,
    "out:LCP/MSEK": -0.57,
    "out:ROI% old": 13.34,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.71,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 63.4,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1026,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 355281,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 307920,
    "out:DH kr savings": 355281,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 328047,
    "out:% savings (space heating)": 48.42,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.9,
    "out:Etvv": 17,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000000100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.94,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 96.9,
    "out:Total Energy Use Post PV": 96.9,
    "out:Primary Energy": 98.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.1,
    "out:CO2 Operational/m2": 30.57,
    "out:Total CO2/m2": 59.67,
    "out:Total CO2 (tons)": 307.24,
    "out:Klimatpaverkan": 17.63,
    "out:Initial Cost/MSEK": 8.725,
    "out:Running cost/(Apt SEK y)": 26065,
    "out:Running Cost over RSP/MSEK": 10.992,
    "out:LCP/MSEK": -0.213,
    "out:ROI% old": 13.94,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.71,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 70.1,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1088,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 375877,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 328516,
    "out:DH kr savings": 375877,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 308645,
    "out:% savings (space heating)": 51.47,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.13,
    "out:Etvv": 17,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000000110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 63.71,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 103.9,
    "out:Total Energy Use Post PV": 99.15,
    "out:Primary Energy": 95.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.87,
    "out:CO2 Operational/m2": 24.32,
    "out:Total CO2/m2": 60.19,
    "out:Total CO2 (tons)": 309.93,
    "out:Klimatpaverkan": 20.32,
    "out:Initial Cost/MSEK": 9.033,
    "out:Running cost/(Apt SEK y)": 26941,
    "out:Running Cost over RSP/MSEK": 11.374,
    "out:LCP/MSEK": -0.903,
    "out:ROI% old": 12.86,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.71,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 66.7,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1040,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 339834,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 354019,
    "out:DH kr savings": 339834,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 328047,
    "out:% savings (space heating)": 48.42,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.9,
    "out:Etvv": 20,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000000110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.94,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 100.9,
    "out:Total Energy Use Post PV": 96.15,
    "out:Primary Energy": 91.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.87,
    "out:CO2 Operational/m2": 23.24,
    "out:Total CO2/m2": 59.11,
    "out:Total CO2 (tons)": 304.36,
    "out:Klimatpaverkan": 14.75,
    "out:Initial Cost/MSEK": 9.162,
    "out:Running cost/(Apt SEK y)": 26077,
    "out:Running Cost over RSP/MSEK": 11.01,
    "out:LCP/MSEK": -0.667,
    "out:ROI% old": 13.25,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.71,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 71.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1087,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 355281,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 369466,
    "out:DH kr savings": 355281,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 308645,
    "out:% savings (space heating)": 51.47,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.13,
    "out:Etvv": 20,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000000110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 63.71,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 100.9,
    "out:Total Energy Use Post PV": 96.15,
    "out:Primary Energy": 93.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.15,
    "out:CO2 Operational/m2": 23.24,
    "out:Total CO2/m2": 59.39,
    "out:Total CO2 (tons)": 305.82,
    "out:Klimatpaverkan": 16.21,
    "out:Initial Cost/MSEK": 9.209,
    "out:Running cost/(Apt SEK y)": 26077,
    "out:Running Cost over RSP/MSEK": 11.01,
    "out:LCP/MSEK": -0.714,
    "out:ROI% old": 13.18,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.71,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 71.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1087,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 355281,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 369466,
    "out:DH kr savings": 355281,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 328047,
    "out:% savings (space heating)": 48.42,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.9,
    "out:Etvv": 17,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000000110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.94,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 96.9,
    "out:Total Energy Use Post PV": 92.15,
    "out:Primary Energy": 89.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.15,
    "out:CO2 Operational/m2": 21.8,
    "out:Total CO2/m2": 57.95,
    "out:Total CO2 (tons)": 298.4,
    "out:Klimatpaverkan": 8.79,
    "out:Initial Cost/MSEK": 9.337,
    "out:Running cost/(Apt SEK y)": 24925,
    "out:Running Cost over RSP/MSEK": 10.523,
    "out:LCP/MSEK": -0.356,
    "out:ROI% old": 13.74,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.71,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 79.3,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1149,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 375877,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 390062,
    "out:DH kr savings": 375877,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 308645,
    "out:% savings (space heating)": 51.47,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.13,
    "out:Etvv": 17,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000100000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.87,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 94.9,
    "out:Total Energy Use Post PV": 94.9,
    "out:Primary Energy": 94.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.8,
    "out:CO2 Operational/m2": 29.85,
    "out:Total CO2/m2": 61.66,
    "out:Total CO2 (tons)": 317.47,
    "out:Klimatpaverkan": 27.86,
    "out:Initial Cost/MSEK": 9.572,
    "out:Running cost/(Apt SEK y)": 25489,
    "out:Running Cost over RSP/MSEK": 10.748,
    "out:LCP/MSEK": -0.816,
    "out:ROI% old": 13.07,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 73.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1119,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 386175,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 338814,
    "out:DH kr savings": 386175,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 251612,
    "out:% savings (space heating)": 60.44,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.05,
    "out:Etvv": 25,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000100000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.77,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 91.9,
    "out:Total Energy Use Post PV": 91.9,
    "out:Primary Energy": 91.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.8,
    "out:CO2 Operational/m2": 28.77,
    "out:Total CO2/m2": 60.58,
    "out:Total CO2 (tons)": 311.9,
    "out:Klimatpaverkan": 22.29,
    "out:Initial Cost/MSEK": 9.7,
    "out:Running cost/(Apt SEK y)": 24625,
    "out:Running Cost over RSP/MSEK": 10.383,
    "out:LCP/MSEK": -0.58,
    "out:ROI% old": 13.43,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 79.3,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1166,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 401622,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 354261,
    "out:DH kr savings": 401622,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 235685,
    "out:% savings (space heating)": 62.94,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.96,
    "out:Etvv": 25,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000100000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.87,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 90.9,
    "out:Total Energy Use Post PV": 90.9,
    "out:Primary Energy": 91.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.08,
    "out:CO2 Operational/m2": 28.41,
    "out:Total CO2/m2": 60.5,
    "out:Total CO2 (tons)": 311.5,
    "out:Klimatpaverkan": 21.89,
    "out:Initial Cost/MSEK": 9.747,
    "out:Running cost/(Apt SEK y)": 24337,
    "out:Running Cost over RSP/MSEK": 10.262,
    "out:LCP/MSEK": -0.505,
    "out:ROI% old": 13.55,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 81.3,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1181,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 406771,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 359410,
    "out:DH kr savings": 406771,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 251612,
    "out:% savings (space heating)": 60.44,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.05,
    "out:Etvv": 21.25,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000100000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.77,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 87.9,
    "out:Total Energy Use Post PV": 87.9,
    "out:Primary Energy": 88.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.08,
    "out:CO2 Operational/m2": 27.33,
    "out:Total CO2/m2": 59.42,
    "out:Total CO2 (tons)": 305.94,
    "out:Klimatpaverkan": 16.33,
    "out:Initial Cost/MSEK": 9.876,
    "out:Running cost/(Apt SEK y)": 23474,
    "out:Running Cost over RSP/MSEK": 9.897,
    "out:LCP/MSEK": -0.269,
    "out:ROI% old": 13.9,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 87.5,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1228,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 422218,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 374857,
    "out:DH kr savings": 422218,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 235685,
    "out:% savings (space heating)": 62.94,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.96,
    "out:Etvv": 21.25,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000100010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.87,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 94.9,
    "out:Total Energy Use Post PV": 90.15,
    "out:Primary Energy": 85.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.86,
    "out:CO2 Operational/m2": 21.08,
    "out:Total CO2/m2": 59.94,
    "out:Total CO2 (tons)": 308.63,
    "out:Klimatpaverkan": 19.02,
    "out:Initial Cost/MSEK": 10.184,
    "out:Running cost/(Apt SEK y)": 24350,
    "out:Running Cost over RSP/MSEK": 10.28,
    "out:LCP/MSEK": -0.959,
    "out:ROI% old": 12.94,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 83.3,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1180,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 386175,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 400360,
    "out:DH kr savings": 386175,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 251612,
    "out:% savings (space heating)": 60.44,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.05,
    "out:Etvv": 25,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000100010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.77,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 91.9,
    "out:Total Energy Use Post PV": 87.15,
    "out:Primary Energy": 83.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.86,
    "out:CO2 Operational/m2": 20,
    "out:Total CO2/m2": 58.86,
    "out:Total CO2 (tons)": 303.07,
    "out:Klimatpaverkan": 13.46,
    "out:Initial Cost/MSEK": 10.312,
    "out:Running cost/(Apt SEK y)": 23486,
    "out:Running Cost over RSP/MSEK": 9.915,
    "out:LCP/MSEK": -0.723,
    "out:ROI% old": 13.28,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 89.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1227,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 401622,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 415807,
    "out:DH kr savings": 401622,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 235685,
    "out:% savings (space heating)": 62.94,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.96,
    "out:Etvv": 25,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000100010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.87,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 90.9,
    "out:Total Energy Use Post PV": 86.15,
    "out:Primary Energy": 83.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.14,
    "out:CO2 Operational/m2": 19.64,
    "out:Total CO2/m2": 58.78,
    "out:Total CO2 (tons)": 302.67,
    "out:Klimatpaverkan": 13.06,
    "out:Initial Cost/MSEK": 10.359,
    "out:Running cost/(Apt SEK y)": 23198,
    "out:Running Cost over RSP/MSEK": 9.793,
    "out:LCP/MSEK": -0.648,
    "out:ROI% old": 13.39,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 91.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1243,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 406771,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 420956,
    "out:DH kr savings": 406771,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 251612,
    "out:% savings (space heating)": 60.44,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.05,
    "out:Etvv": 21.25,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000100010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.77,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 87.9,
    "out:Total Energy Use Post PV": 83.15,
    "out:Primary Energy": 80.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.14,
    "out:CO2 Operational/m2": 18.56,
    "out:Total CO2/m2": 57.7,
    "out:Total CO2 (tons)": 297.11,
    "out:Klimatpaverkan": 7.5,
    "out:Initial Cost/MSEK": 10.488,
    "out:Running cost/(Apt SEK y)": 22334,
    "out:Running Cost over RSP/MSEK": 9.428,
    "out:LCP/MSEK": -0.412,
    "out:ROI% old": 13.72,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 98.8,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1289,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 422218,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 436403,
    "out:DH kr savings": 422218,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 235685,
    "out:% savings (space heating)": 62.94,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.96,
    "out:Etvv": 21.25,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000100100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.87,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 89.9,
    "out:Total Energy Use Post PV": 89.9,
    "out:Primary Energy": 90.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.8,
    "out:CO2 Operational/m2": 28.05,
    "out:Total CO2/m2": 59.86,
    "out:Total CO2 (tons)": 308.2,
    "out:Klimatpaverkan": 18.59,
    "out:Initial Cost/MSEK": 9.714,
    "out:Running cost/(Apt SEK y)": 24049,
    "out:Running Cost over RSP/MSEK": 10.14,
    "out:LCP/MSEK": -0.35,
    "out:ROI% old": 13.77,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 83.3,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1197,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 411920,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 364559,
    "out:DH kr savings": 411920,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 251612,
    "out:% savings (space heating)": 60.44,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.05,
    "out:Etvv": 20,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000100100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.77,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 85.9,
    "out:Total Energy Use Post PV": 85.9,
    "out:Primary Energy": 88.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.8,
    "out:CO2 Operational/m2": 26.61,
    "out:Total CO2/m2": 58.42,
    "out:Total CO2 (tons)": 300.78,
    "out:Klimatpaverkan": 11.17,
    "out:Initial Cost/MSEK": 9.843,
    "out:Running cost/(Apt SEK y)": 22898,
    "out:Running Cost over RSP/MSEK": 9.654,
    "out:LCP/MSEK": 0.008,
    "out:ROI% old": 14.3,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 91.9,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1259,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 432516,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 385155,
    "out:DH kr savings": 432516,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 235685,
    "out:% savings (space heating)": 62.94,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.96,
    "out:Etvv": 20,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000100100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.87,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 85.9,
    "out:Total Energy Use Post PV": 85.9,
    "out:Primary Energy": 88.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.08,
    "out:CO2 Operational/m2": 26.61,
    "out:Total CO2/m2": 58.7,
    "out:Total CO2 (tons)": 302.23,
    "out:Klimatpaverkan": 12.62,
    "out:Initial Cost/MSEK": 9.89,
    "out:Running cost/(Apt SEK y)": 22898,
    "out:Running Cost over RSP/MSEK": 9.654,
    "out:LCP/MSEK": -0.039,
    "out:ROI% old": 14.23,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 91.9,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1259,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 432516,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 385155,
    "out:DH kr savings": 432516,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 251612,
    "out:% savings (space heating)": 60.44,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.05,
    "out:Etvv": 17,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000100100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.77,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 82.9,
    "out:Total Energy Use Post PV": 82.9,
    "out:Primary Energy": 85.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.08,
    "out:CO2 Operational/m2": 25.53,
    "out:Total CO2/m2": 57.62,
    "out:Total CO2 (tons)": 296.67,
    "out:Klimatpaverkan": 7.06,
    "out:Initial Cost/MSEK": 10.019,
    "out:Running cost/(Apt SEK y)": 22034,
    "out:Running Cost over RSP/MSEK": 9.289,
    "out:LCP/MSEK": 0.197,
    "out:ROI% old": 14.57,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 98.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1305,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 447963,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 400602,
    "out:DH kr savings": 447963,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 235685,
    "out:% savings (space heating)": 62.94,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.96,
    "out:Etvv": 17,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000100110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.87,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 89.9,
    "out:Total Energy Use Post PV": 85.15,
    "out:Primary Energy": 82.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.86,
    "out:CO2 Operational/m2": 19.28,
    "out:Total CO2/m2": 58.14,
    "out:Total CO2 (tons)": 299.36,
    "out:Klimatpaverkan": 9.75,
    "out:Initial Cost/MSEK": 10.326,
    "out:Running cost/(Apt SEK y)": 22910,
    "out:Running Cost over RSP/MSEK": 9.672,
    "out:LCP/MSEK": -0.494,
    "out:ROI% old": 13.6,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 94.1,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1258,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 411920,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 426105,
    "out:DH kr savings": 411920,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 251612,
    "out:% savings (space heating)": 60.44,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.05,
    "out:Etvv": 20,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000100110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.77,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 85.9,
    "out:Total Energy Use Post PV": 81.15,
    "out:Primary Energy": 79.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.86,
    "out:CO2 Operational/m2": 17.84,
    "out:Total CO2/m2": 56.7,
    "out:Total CO2 (tons)": 291.95,
    "out:Klimatpaverkan": 2.34,
    "out:Initial Cost/MSEK": 10.455,
    "out:Running cost/(Apt SEK y)": 21758,
    "out:Running Cost over RSP/MSEK": 9.185,
    "out:LCP/MSEK": -0.136,
    "out:ROI% old": 14.1,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 103.7,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1320,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 432516,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 446701,
    "out:DH kr savings": 432516,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 235685,
    "out:% savings (space heating)": 62.94,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.96,
    "out:Etvv": 20,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000100110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.87,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 85.9,
    "out:Total Energy Use Post PV": 81.15,
    "out:Primary Energy": 80.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.14,
    "out:CO2 Operational/m2": 17.84,
    "out:Total CO2/m2": 56.98,
    "out:Total CO2 (tons)": 293.4,
    "out:Klimatpaverkan": 3.79,
    "out:Initial Cost/MSEK": 10.502,
    "out:Running cost/(Apt SEK y)": 21758,
    "out:Running Cost over RSP/MSEK": 9.185,
    "out:LCP/MSEK": -0.183,
    "out:ROI% old": 14.04,
    "out:Payback discounted": 9,
    "out:Atemp": 5149,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 103.7,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1320,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 432516,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 446701,
    "out:DH kr savings": 432516,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 251612,
    "out:% savings (space heating)": 60.44,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.05,
    "out:Etvv": 17,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000100110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.77,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 82.9,
    "out:Total Energy Use Post PV": 78.15,
    "out:Primary Energy": 77.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.14,
    "out:CO2 Operational/m2": 16.76,
    "out:Total CO2/m2": 55.9,
    "out:Total CO2 (tons)": 287.84,
    "out:Klimatpaverkan": -1.77,
    "out:Initial Cost/MSEK": 10.631,
    "out:Running cost/(Apt SEK y)": 20894,
    "out:Running Cost over RSP/MSEK": 8.82,
    "out:LCP/MSEK": 0.054,
    "out:ROI% old": 14.36,
    "out:Payback discounted": 8,
    "out:Atemp": 5149,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 111.5,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1367,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 447963,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 462148,
    "out:DH kr savings": 447963,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 235685,
    "out:% savings (space heating)": 62.94,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.96,
    "out:Etvv": 17,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000200000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.16,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 89.9,
    "out:Total Energy Use Post PV": 89.9,
    "out:Primary Energy": 90.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.36,
    "out:CO2 Operational/m2": 28.05,
    "out:Total CO2/m2": 69.42,
    "out:Total CO2 (tons)": 357.43,
    "out:Klimatpaverkan": 67.82,
    "out:Initial Cost/MSEK": 12.738,
    "out:Running cost/(Apt SEK y)": 24049,
    "out:Running Cost over RSP/MSEK": 10.14,
    "out:LCP/MSEK": -3.373,
    "out:ROI% old": 10.5,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 83.3,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1197,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 411920,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 364559,
    "out:DH kr savings": 411920,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 227391,
    "out:% savings (space heating)": 64.25,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.35,
    "out:Etvv": 25,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000200000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.28,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 86.9,
    "out:Total Energy Use Post PV": 86.9,
    "out:Primary Energy": 87.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.36,
    "out:CO2 Operational/m2": 26.97,
    "out:Total CO2/m2": 68.34,
    "out:Total CO2 (tons)": 351.87,
    "out:Klimatpaverkan": 62.26,
    "out:Initial Cost/MSEK": 12.866,
    "out:Running cost/(Apt SEK y)": 23186,
    "out:Running Cost over RSP/MSEK": 9.775,
    "out:LCP/MSEK": -3.137,
    "out:ROI% old": 10.8,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 89.7,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1243,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 427367,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 380006,
    "out:DH kr savings": 427367,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 212564,
    "out:% savings (space heating)": 66.58,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 66.47,
    "out:Etvv": 25,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000200000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.16,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 85.9,
    "out:Total Energy Use Post PV": 85.9,
    "out:Primary Energy": 87.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.65,
    "out:CO2 Operational/m2": 26.61,
    "out:Total CO2/m2": 68.26,
    "out:Total CO2 (tons)": 351.47,
    "out:Klimatpaverkan": 61.86,
    "out:Initial Cost/MSEK": 12.914,
    "out:Running cost/(Apt SEK y)": 22898,
    "out:Running Cost over RSP/MSEK": 9.654,
    "out:LCP/MSEK": -3.063,
    "out:ROI% old": 10.9,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 91.9,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1259,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 432516,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 385155,
    "out:DH kr savings": 432516,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 227391,
    "out:% savings (space heating)": 64.25,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.35,
    "out:Etvv": 21.25,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000200000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.28,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 82.9,
    "out:Total Energy Use Post PV": 82.9,
    "out:Primary Energy": 85.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.65,
    "out:CO2 Operational/m2": 25.53,
    "out:Total CO2/m2": 67.18,
    "out:Total CO2 (tons)": 345.91,
    "out:Klimatpaverkan": 56.3,
    "out:Initial Cost/MSEK": 13.043,
    "out:Running cost/(Apt SEK y)": 22034,
    "out:Running Cost over RSP/MSEK": 9.289,
    "out:LCP/MSEK": -2.827,
    "out:ROI% old": 11.19,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 98.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1305,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 447963,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 400602,
    "out:DH kr savings": 447963,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 212564,
    "out:% savings (space heating)": 66.58,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 66.47,
    "out:Etvv": 21.25,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000200010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.16,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 89.9,
    "out:Total Energy Use Post PV": 85.15,
    "out:Primary Energy": 81.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.42,
    "out:CO2 Operational/m2": 19.28,
    "out:Total CO2/m2": 67.7,
    "out:Total CO2 (tons)": 348.6,
    "out:Klimatpaverkan": 58.99,
    "out:Initial Cost/MSEK": 13.35,
    "out:Running cost/(Apt SEK y)": 22910,
    "out:Running Cost over RSP/MSEK": 9.672,
    "out:LCP/MSEK": -3.517,
    "out:ROI% old": 10.52,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 94.1,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 1258,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 411920,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 426105,
    "out:DH kr savings": 411920,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 227391,
    "out:% savings (space heating)": 64.25,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.35,
    "out:Etvv": 25,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000200010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.28,
    "out:DHW (DH)": 27.1,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 86.9,
    "out:Total Energy Use Post PV": 82.15,
    "out:Primary Energy": 79.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.42,
    "out:CO2 Operational/m2": 18.2,
    "out:Total CO2/m2": 66.62,
    "out:Total CO2 (tons)": 343.04,
    "out:Klimatpaverkan": 53.43,
    "out:Initial Cost/MSEK": 13.479,
    "out:Running cost/(Apt SEK y)": 22046,
    "out:Running Cost over RSP/MSEK": 9.307,
    "out:LCP/MSEK": -3.281,
    "out:ROI% old": 10.81,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 101.2,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1305,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 27.1,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 427367,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 441552,
    "out:DH kr savings": 427367,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 212564,
    "out:% savings (space heating)": 66.58,
    "out:Total_Water Heating (DH)": 139538,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 66.47,
    "out:Etvv": 25,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000200010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.16,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 85.9,
    "out:Total Energy Use Post PV": 81.15,
    "out:Primary Energy": 78.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.7,
    "out:CO2 Operational/m2": 17.84,
    "out:Total CO2/m2": 66.54,
    "out:Total CO2 (tons)": 342.63,
    "out:Klimatpaverkan": 53.02,
    "out:Initial Cost/MSEK": 13.525,
    "out:Running cost/(Apt SEK y)": 21758,
    "out:Running Cost over RSP/MSEK": 9.185,
    "out:LCP/MSEK": -3.206,
    "out:ROI% old": 10.9,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 103.7,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1320,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 432516,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 446701,
    "out:DH kr savings": 432516,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 227391,
    "out:% savings (space heating)": 64.25,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.35,
    "out:Etvv": 21.25,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000200010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.28,
    "out:DHW (DH)": 23.04,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 82.9,
    "out:Total Energy Use Post PV": 78.15,
    "out:Primary Energy": 76.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.7,
    "out:CO2 Operational/m2": 16.76,
    "out:Total CO2/m2": 65.46,
    "out:Total CO2 (tons)": 337.07,
    "out:Klimatpaverkan": 47.46,
    "out:Initial Cost/MSEK": 13.654,
    "out:Running cost/(Apt SEK y)": 20894,
    "out:Running Cost over RSP/MSEK": 8.82,
    "out:LCP/MSEK": -2.97,
    "out:ROI% old": 11.18,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 111.5,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1367,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 23.035,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 447963,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 462148,
    "out:DH kr savings": 447963,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 212564,
    "out:% savings (space heating)": 66.58,
    "out:Total_Water Heating (DH)": 118607,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 66.47,
    "out:Etvv": 21.25,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000200100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.16,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 84.9,
    "out:Total Energy Use Post PV": 84.9,
    "out:Primary Energy": 86.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.36,
    "out:CO2 Operational/m2": 26.25,
    "out:Total CO2/m2": 67.62,
    "out:Total CO2 (tons)": 348.17,
    "out:Klimatpaverkan": 58.56,
    "out:Initial Cost/MSEK": 12.88,
    "out:Running cost/(Apt SEK y)": 22610,
    "out:Running Cost over RSP/MSEK": 9.532,
    "out:LCP/MSEK": -2.908,
    "out:ROI% old": 11.06,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 94.1,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1274,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 437665,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 390304,
    "out:DH kr savings": 437665,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 227391,
    "out:% savings (space heating)": 64.25,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.35,
    "out:Etvv": 20,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000200100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.28,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 81.9,
    "out:Total Energy Use Post PV": 81.9,
    "out:Primary Energy": 84.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.36,
    "out:CO2 Operational/m2": 25.17,
    "out:Total CO2/m2": 66.54,
    "out:Total CO2 (tons)": 342.6,
    "out:Klimatpaverkan": 52.99,
    "out:Initial Cost/MSEK": 13.009,
    "out:Running cost/(Apt SEK y)": 21746,
    "out:Running Cost over RSP/MSEK": 9.167,
    "out:LCP/MSEK": -2.672,
    "out:ROI% old": 11.35,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 101.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1321,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 453112,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 405751,
    "out:DH kr savings": 453112,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 212564,
    "out:% savings (space heating)": 66.58,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 66.47,
    "out:Etvv": 20,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000200100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.16,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 81.9,
    "out:Total Energy Use Post PV": 81.9,
    "out:Primary Energy": 84.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.65,
    "out:CO2 Operational/m2": 25.17,
    "out:Total CO2/m2": 66.82,
    "out:Total CO2 (tons)": 344.05,
    "out:Klimatpaverkan": 54.44,
    "out:Initial Cost/MSEK": 13.056,
    "out:Running cost/(Apt SEK y)": 21746,
    "out:Running Cost over RSP/MSEK": 9.167,
    "out:LCP/MSEK": -2.719,
    "out:ROI% old": 11.31,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 101.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1321,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 453112,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 405751,
    "out:DH kr savings": 453112,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 227391,
    "out:% savings (space heating)": 64.25,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.35,
    "out:Etvv": 17,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000200100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.28,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 18.9,
    "out:Total Energy Use Pre PV": 78.9,
    "out:Total Energy Use Post PV": 78.9,
    "out:Primary Energy": 82.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.65,
    "out:CO2 Operational/m2": 24.09,
    "out:Total CO2/m2": 65.74,
    "out:Total CO2 (tons)": 338.49,
    "out:Klimatpaverkan": 48.88,
    "out:Initial Cost/MSEK": 13.185,
    "out:Running cost/(Apt SEK y)": 20882,
    "out:Running Cost over RSP/MSEK": 8.802,
    "out:LCP/MSEK": -2.483,
    "out:ROI% old": 11.6,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 108.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 1322.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1368,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 468559,
    "out:EL kWh savings": -23680,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 421198,
    "out:DH kr savings": 468559,
    "out:El kr savings": -47360,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 212564,
    "out:% savings (space heating)": 66.58,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 66.47,
    "out:Etvv": 17,
    "out:Ef": 18.9
  },
  {
    "ID": "Z00000200110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.16,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 84.9,
    "out:Total Energy Use Post PV": 80.15,
    "out:Primary Energy": 78.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.42,
    "out:CO2 Operational/m2": 17.48,
    "out:Total CO2/m2": 65.9,
    "out:Total CO2 (tons)": 339.33,
    "out:Klimatpaverkan": 49.72,
    "out:Initial Cost/MSEK": 13.493,
    "out:Running cost/(Apt SEK y)": 21470,
    "out:Running Cost over RSP/MSEK": 9.063,
    "out:LCP/MSEK": -3.052,
    "out:ROI% old": 11.05,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 106.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1336,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 437665,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 451850,
    "out:DH kr savings": 437665,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 227391,
    "out:% savings (space heating)": 64.25,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.35,
    "out:Etvv": 20,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000200110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.28,
    "out:DHW (DH)": 21.68,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 81.9,
    "out:Total Energy Use Post PV": 77.15,
    "out:Primary Energy": 75.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.42,
    "out:CO2 Operational/m2": 16.4,
    "out:Total CO2/m2": 64.82,
    "out:Total CO2 (tons)": 333.77,
    "out:Klimatpaverkan": 44.16,
    "out:Initial Cost/MSEK": 13.621,
    "out:Running cost/(Apt SEK y)": 20606,
    "out:Running Cost over RSP/MSEK": 8.699,
    "out:LCP/MSEK": -2.816,
    "out:ROI% old": 11.33,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 114.3,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1383,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 21.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 453112,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 467296,
    "out:DH kr savings": 453112,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 212564,
    "out:% savings (space heating)": 66.58,
    "out:Total_Water Heating (DH)": 111630,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 66.47,
    "out:Etvv": 20,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000200110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 44.16,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 81.9,
    "out:Total Energy Use Post PV": 77.15,
    "out:Primary Energy": 76.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.7,
    "out:CO2 Operational/m2": 16.4,
    "out:Total CO2/m2": 65.1,
    "out:Total CO2 (tons)": 335.22,
    "out:Klimatpaverkan": 45.61,
    "out:Initial Cost/MSEK": 13.668,
    "out:Running cost/(Apt SEK y)": 20606,
    "out:Running Cost over RSP/MSEK": 8.699,
    "out:LCP/MSEK": -2.862,
    "out:ROI% old": 11.29,
    "out:Payback discounted": 11,
    "out:Atemp": 5149,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 114.3,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1383,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 453112,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 467296,
    "out:DH kr savings": 453112,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 227391,
    "out:% savings (space heating)": 64.25,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.35,
    "out:Etvv": 17,
    "out:Ef": 14.15
  },
  {
    "ID": "Z00000200110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 41.28,
    "out:DHW (DH)": 18.43,
    "out:Property Electricity": 18.9,
    "out:Electricity (inc PV)": 14.15,
    "out:Total Energy Use Pre PV": 78.9,
    "out:Total Energy Use Post PV": 74.15,
    "out:Primary Energy": 73.5,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.7,
    "out:CO2 Operational/m2": 15.32,
    "out:Total CO2/m2": 64.02,
    "out:Total CO2 (tons)": 329.66,
    "out:Klimatpaverkan": 40.05,
    "out:Initial Cost/MSEK": 13.796,
    "out:Running cost/(Apt SEK y)": 19742,
    "out:Running Cost over RSP/MSEK": 8.334,
    "out:LCP/MSEK": -2.626,
    "out:ROI% old": 11.57,
    "out:Payback discounted": 10,
    "out:Atemp": 5149,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 736,
    "out:Facade surface/m2": 1879,
    "out:Window surface/m2": 697,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 123,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 1000.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 1429,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 134220,
    "out:EAHP (pipework)": 133810,
    "out:EAHP (natural ventilation grills)": 257074,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 18.428,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 35.04996,
    "out:PV (m2 panels)": 184,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 32886.34,
    "out:PV (kWh sold)": 8454.93,
    "out:PV (% sold (El))": 25.71,
    "out:PV (kWh self-consumed)": 24431.41,
    "out:PV (ratio sold/self-consumed)": 0.35,
    "out:FTX (AHU)": 458662,
    "out:FTX (Ductwork)": 1489500,
    "out:FTX (Diffusers)": 268227,
    "out:FTX (Dampers)": 78281,
    "out:FTX (Replacement Cost)": 226911,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 54,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 21.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 666900,
    "out:DH (pipework)": 742500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 1.04,
    "out:Roof U-value (after)": 0.08,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 496819.4,
    "out:FTX (Facade)": 5136000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 468559,
    "out:EL kWh savings": 751,
    "out:Sewage heat recovery cost": 175638.86,
    "out:Balancing hydronic system cost": 128724.94,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 482743,
    "out:DH kr savings": 468559,
    "out:El kr savings": 1502,
    "out:El kr sold": 12682,
    "out:El kr saved": 48863,
    "out:El kr return": 61545,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 73631,
    "out:Total_Space Heating (DH)": 212564,
    "out:% savings (space heating)": 66.58,
    "out:Total_Water Heating (DH)": 94886,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7.714286,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 66.47,
    "out:Etvv": 17,
    "out:Ef": 14.15
  }
]