import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { Provider } from "jotai";
import { store as dataAccessStore } from "packages/dataAccess";
import { HashRouter } from "react-router-dom";
import { App } from "./App";

const rootElement = document.getElementById("app");

if (rootElement === null) {
  throw new Error('element with id "app" did not found');
}

const root = createRoot(rootElement);

root.render(
  <Provider store={dataAccessStore}>
    <StrictMode>
      <HashRouter>
        <App />
      </HashRouter>
    </StrictMode>
  </Provider>,
);
