import type { Datum } from "packages/dataAccess";

export const descendingComparator = (a: Datum, b: Datum, orderBy: string) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const SortDirection = {
  ascending: "asc",
  descending: "desc",
} as const;

export type SortDirection = (typeof SortDirection)[keyof typeof SortDirection];

export const getComparator = <T extends Datum>(
  sortDirection: SortDirection,
  orderBy: string,
) => {
  return sortDirection === SortDirection.descending
    ? (a: T, b: T) => descendingComparator(a, b, orderBy)
    : (a: T, b: T) => -descendingComparator(a, b, orderBy);
};

export const stableSort = <T extends Datum>(
  array: T[],
  comparator: ReturnType<typeof getComparator>,
) => {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};
