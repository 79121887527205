import type { SvgIconComponent } from "@mui/icons-material";
import type { ReactElement } from "react";

interface IconRendererProps {
  icon: SvgIconComponent;
}
export const IconRenderer = ({ icon }: IconRendererProps): ReactElement => {
  // biome-ignore lint/style/useNamingConvention: Icon is a valid component name
  const Icon = icon;
  return <Icon fontSize="large" />;
};
