import type { Dataset } from "../types";

export const filterExtractor = (dataset: Dataset): Map<string, Set<number>> => {
  const filters = new Map<string, Set<number>>();

  for (const datum of dataset) {
    for (const [key, value] of Object.entries(datum)) {
      if (key.startsWith("in")) {
        const set = filters.get(key) ?? new Set();

        set.add(Number(value));

        filters.set(key, set);
      }
    }
  }

  return filters;
};
