import type { ReactNode } from "react";

interface HeadCellModel {
  id: string;
  numeric: boolean;
  label?: string;
  component?: ReactNode;
}

export const HeadCells: HeadCellModel[] = [
  {
    id: "name",
    numeric: false,
    label: "Fastighet",
  },
  {
    id: "uniqueEnergyClass",
    numeric: false,
    label: "Energiklass",
  },
  {
    id: "totalConsumption",
    numeric: true,
    label: "Total förbrukning (MWh)",
  },
  {
    id: "atmosphere",
    numeric: true,
    label: "Atemp",
  },
  {
    id: "specificEnergy",
    numeric: true,
    component: (
      <span>
        Specifik energi (kWh/m<sup>2</sup>)
      </span>
    ),
  },

  {
    id: "targetClassReturns",
    numeric: true,
    label: "Avkastning",
  },
  {
    id: "targetClassInvestment",
    numeric: true,
    label: "Investering (kr)",
  },
];
