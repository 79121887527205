import type { InputMap } from "packages/dataAccess";
import {
  type InstallationItem,
  InstallationItems,
} from "packages/installations";

export const removeInstallationItemsWithNonEffectiveFilters = (
  item: InstallationItem,
  installationFilters: Record<InstallationItem, InputMap>,
): unknown => {
  return (
    item === InstallationItems.nuläge ||
    Array.from(installationFilters[item].entries()).some(
      ([, value]) => value > 0,
    )
  );
};
