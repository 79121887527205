import type { InputMap } from "packages/dataAccess";
import type { InstallationItem } from "../InstallationItem";

export const calculateCumulativeInstallationMap = (
  appliedInstallations: InstallationItem[],
  currentInstallation: InstallationItem,
  installationFilters: Record<string, InputMap>,
): InputMap => {
  const result = appliedInstallations
    .slice(0, appliedInstallations.indexOf(currentInstallation) + 1)
    .reduce<InputMap>((accumulation, eachImprovementItem) => {
      const currentImprovementItemMap =
        installationFilters[eachImprovementItem];

      for (const [key, value] of currentImprovementItemMap.entries()) {
        const existingValue = accumulation.get(key);

        accumulation.set(
          key,
          existingValue !== undefined && existingValue > value
            ? existingValue
            : value,
        );
      }

      return accumulation;
    }, new Map([]));

  return result;
};
