import { type Dataset, type InputMap, find } from "packages/dataAccess";
import type { InstallationDataset } from "../InstallationDataset";
import type { InstallationItem } from "../InstallationItem";
import { calculateCumulativeInstallationMap } from "./calculateCumulativeInstallationMap";

export const getRawDataForListOfInstallations = (
  dataset: Dataset,
  installations: InstallationItem[],
  installationFilters: Record<string, InputMap>,
): InstallationDataset =>
  installations.map((label) => [
    label,
    find({
      dataset,
      inputMap: calculateCumulativeInstallationMap(
        installations,
        label,
        installationFilters,
      ),
    }),
  ]);
