import CloseIcon from "@mui/icons-material/Close";
import EditOffTwoToneIcon from "@mui/icons-material/EditOffTwoTone";
import FunctionsTwoToneIcon from "@mui/icons-material/FunctionsTwoTone";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import ModeEditTwoToneIcon from "@mui/icons-material/ModeEditTwoTone";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import {
  type InputMap,
  editableFields,
  useFieldValue,
  useSetField,
} from "packages/dataAccess";
import { type ReactElement, useCallback, useMemo, useState } from "react";
import type { InstallationItem } from "../installations";
import { formatCurrency } from "../utils";
import { IconRenderer } from "./IconRenderer";
import { installationBreakdownFieldMapping } from "./installationBreakdownFieldMapping";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
    lineHeight: theme.typography.h4.lineHeight,
  },
}));

export interface CostBreakdownProps {
  inputMap: InputMap;
  installationName: InstallationItem;
  installationData: Record<string, string | number>;
}
export const CostBreakdown = ({
  inputMap,
  installationData,
  installationName,
}: CostBreakdownProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const [editingFields, setEditingFields] = useState<Set<string>>(new Set());

  const onEditField = useCallback((fieldName: string) => {
    setEditingFields((prev) => {
      const updated = new Set(prev);

      if (updated.has(fieldName)) {
        updated.delete(fieldName);
      } else {
        updated.add(fieldName);
      }

      return updated;
    });
  }, []);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const data = useMemo(
    () =>
      installationBreakdownFieldMapping[installationName].cost.breakdown.map(
        ([name, fieldName, bold]) => ({
          name,
          value: installationData[fieldName],
          bold,
        }),
      ),
    [installationData, installationName],
  );

  const setField = useSetField(inputMap);
  const readFieldValue = useFieldValue();

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
      }}
    >
      <ListItem>
        <ListItemAvatar>
          <IconRenderer icon={FunctionsTwoToneIcon} />
        </ListItemAvatar>
        <ListItemText
          primary="Investering (kr)"
          secondary={
            <Typography variant="h4">
              {formatCurrency(
                readFieldValue(installationData, "out:Initial Cost/MSEK"),
              )}
            </Typography>
          }
        />
      </ListItem>
      {installationBreakdownFieldMapping[installationName].cost.preview.map(
        ({ filedName, label, icon, breakdown, format }) => {
          const value = Number(readFieldValue(installationData, filedName));

          return (
            <ListItem key={filedName}>
              <ListItemAvatar>
                <IconRenderer icon={icon} />
              </ListItemAvatar>
              <ListItemText
                primary={label}
                secondary={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {editingFields.has(filedName) ? (
                      <StyledTextField
                        onChange={(e) => setField(e.target.value, filedName)}
                        variant="standard"
                        defaultValue={value}
                      />
                    ) : (
                      <Typography variant="h4">
                        {format && value ? format(value) : value}
                      </Typography>
                    )}
                    {editableFields.has(filedName) && (
                      <IconButton onClick={() => onEditField(filedName)}>
                        {editingFields.has(filedName) ? (
                          <EditOffTwoToneIcon />
                        ) : (
                          <ModeEditTwoToneIcon />
                        )}
                      </IconButton>
                    )}
                  </div>
                }
              />
              {data.length > 0 && Boolean(breakdown) && (
                <ListItemAvatar>
                  <IconButton
                    aria-label="cost breakdown"
                    onClick={handleClickOpen}
                  >
                    <InfoTwoToneIcon fontSize="large" />
                  </IconButton>
                </ListItemAvatar>
              )}
            </ListItem>
          );
        },
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Kostnadsfördelning</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{installationName}</TableCell>
                <TableCell align="right">Värde</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.bold === true ? <b>{row.name}</b> : row.name}
                  </TableCell>
                  <TableCell align="right">
                    {row.bold === true ? <b>{row.value}</b> : row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog>
    </List>
  );
};
