import { useAtomValue } from "jotai";
import { useCallback } from "react";
import { overridesAtom } from "../context";
import type { Datum, Value } from "../types";
import { readFieldValue } from "./readFieldValue";

export type ReadFieldValue = (datum: Datum, fieldName: string) => Value;

export const useFieldValue = (): ReadFieldValue => {
  const updatedValues = useAtomValue(overridesAtom);

  return useCallback(
    (datum, fieldName) => readFieldValue(updatedValues, datum, fieldName),
    [updatedValues],
  );
};
